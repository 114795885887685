import clsx from "clsx"

const styles = {
    primary: "bg-black text-white",
    secondary: "bg-gray-200 text-gray-600",
}

type Tab = {
    label: string | React.ReactNode
    disabled?: boolean
    tooltip?: string
}

interface TabHeadProps {
    tabs: Tab[]
    tabStyle?: "primary" | "secondary"
    bordered?: boolean
    activeTab: number
    onTabChange: (index: number, label: string | React.ReactNode) => void
}

export function TabHead(props: TabHeadProps) {
    const bordered = props.bordered ?? true

    return (
        <div
            className={`tab-labels flex ${
                bordered ? "border border-gray-300 bg-white p-2" : ""
            } w-full items-center justify-center gap-2 rounded-xl md:max-w-fit`}
        >
            {props.tabs.map((tab, index) => (
                <div
                    key={tab.label + index.toString()}
                    className={clsx(
                        "rounded-lg px-3 py-1 text-base font-semibold leading-normal transition-colors",
                        tab.disabled && "opacity-50",
                        !tab.disabled &&
                            index === props.activeTab &&
                            `active ${styles[props.tabStyle || "primary"]}`,
                        !tab.disabled &&
                            index !== props.activeTab &&
                            "hover:bg-gray-100"
                    )}
                    data-tooltip-id={
                        tab.tooltip ? "tooltip-explanation" : undefined
                    }
                    data-tooltip-content={tab.tooltip}
                >
                    <button
                        onClick={() => props.onTabChange(index, tab.label)}
                        disabled={tab.disabled}
                        className="h-full w-full"
                    >
                        {tab.label}
                    </button>
                </div>
            ))}
        </div>
    )
}
