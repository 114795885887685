import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMagicWandSparkles } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"

const NUMBER_OF_SUGGESTIONS = 2

export function Suggestions({
    suggestedQuestions,
    setQuestion,
}: {
    suggestedQuestions: string[]
    setQuestion: (suggestion: string) => void
}) {
    const [pickedSuggestion, setPickedSuggestion] = useState<string[]>([])

    useEffect(
        () =>
            setPickedSuggestion(
                _.sampleSize(suggestedQuestions, NUMBER_OF_SUGGESTIONS)
            ),
        [suggestedQuestions]
    )

    if (!pickedSuggestion) return null

    return (
        <div className="flex flex-col space-y-2 text-sm">
            <div className="flex flex-row flex-nowrap items-center space-x-2">
                <FontAwesomeIcon
                    icon={faMagicWandSparkles}
                    className="text-blue-500"
                />
                <span className="flex whitespace-nowrap">Try asking</span>
            </div>

            <span className="flex flex-wrap gap-2">
                {pickedSuggestion.map((question) => (
                    <SuggestedQuestion
                        key={question}
                        question={question}
                        setQuestion={setQuestion}
                    />
                ))}
            </span>
        </div>
    )
}

function SuggestedQuestion({
    question,
    setQuestion,
}: {
    question: string
    setQuestion: (question: string) => void
}) {
    return (
        <span
            className="max-w-full cursor-pointer flex-nowrap rounded-lg border border-blue-100 bg-blue-50 p-2 font-semibold transition-colors hover:bg-blue-100"
            onClick={() => setQuestion(question)}
        >
            {question}
        </span>
    )
}
