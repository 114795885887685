export function formatMonetaryAmount(
    value: number | null,
    currency: string | null
): string | null {
    if (value === null) {
        return null
    }

    if (!currency) {
        return new Intl.NumberFormat("en-US", {
            notation: "compact",
            compactDisplay: "short",
        }).format(value)
    }

    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency,
        notation: "compact",
        compactDisplay: "short",
    }).format(value)
}
