import { MessageBlock, UnknownMessageBlock } from "../../../types/ThreadTypes"

export interface BlockHandler<T extends MessageBlock = MessageBlock> {
    // Called when a tag is opened
    onCreated(tag: string): T

    // Called when content is added to the block
    onContent(block: T, content: string): T

    // Called when a tag is closed
    onClosed(block: T): T
}

/**
 * Basic block handler for simple blocks that just accumulate text.
 * We can still type them (e.g. loading blocks).
 */
export class AccBlockHandler<T extends MessageBlock>
    implements BlockHandler<T>
{
    constructor(private blockType: T["type"]) {}

    onCreated(tag: string): T {
        console.assert(
            tag === this.blockType,
            "Tag mismatch",
            tag,
            this.blockType
        )
        return {
            type: this.blockType,
            content: "",
            closed: false,
        } as unknown as T
    }

    onContent(block: T, content: string): T {
        return { ...block, content: block.content + content } as T
    }

    onClosed(block: T): T {
        return { ...block, closed: true } as T
    }
}

/**
 * Handler for unknown block types.
 * This just keeps the tag around and helps with debugging.
 * Also means it's easy to ignore if we're adding new types before we have
 * a UI for them.
 */
export class UnknownBlockHandler implements BlockHandler<UnknownMessageBlock> {
    onCreated(tag: string): UnknownMessageBlock {
        return {
            type: "unknown",
            content: "",
            closed: false,
            tag: tag.toLowerCase(),
        }
    }

    onContent(
        block: UnknownMessageBlock,
        content: string
    ): UnknownMessageBlock {
        return {
            ...block,
            content: block.content + content,
        }
    }

    onClosed(block: UnknownMessageBlock): UnknownMessageBlock {
        return { ...block, closed: true }
    }
}
