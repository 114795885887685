import Select, { ActionMeta, components, MultiValue } from "react-select"
import { DEAL_CLOSE_DATE_RANGE } from "./utils/dealCloseDate"
import {
    IMultiSelectOption,
    MultiSelectFilter,
    SelectCustomStyle,
} from "../MultiSelectFilter"
import {
    ICrmDealPipeline,
    ICrmDealStage,
    ICrmDealType,
    ICrmUser,
} from "../crm/types/Crm"
import ToggleButton from "../common/ToggleButton"
import colors from "tailwindcss/colors"
import { SecondaryButton } from "../common/Buttons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSliders } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useMemo, useRef, useState } from "react"
import { annotationTypes } from "../../utils/getAnnotationSections"
import clsx from "clsx"
import { getDealTypeLabel } from "../../types/Deal"

const CLOSE_DATE_OPTIONS = [
    {
        label: "Now",
        options: [
            {
                value: DEAL_CLOSE_DATE_RANGE.ThisMonth,
                label: "Closing this month",
            },
            {
                value: DEAL_CLOSE_DATE_RANGE.ThisQuarter,
                label: "Closing this quarter",
            },
            {
                value: DEAL_CLOSE_DATE_RANGE.ThisYear,
                label: "Closing this year",
            },
        ],
    },
    {
        label: "Next",
        options: [
            {
                value: DEAL_CLOSE_DATE_RANGE.NextMonth,
                label: "Closing next month",
            },
            {
                value: DEAL_CLOSE_DATE_RANGE.NextQuarter,
                label: "Closing next quarter",
            },
            {
                value: DEAL_CLOSE_DATE_RANGE.NextYear,
                label: "Closing next year",
            },
        ],
    },
    {
        label: "Last",
        options: [
            {
                value: DEAL_CLOSE_DATE_RANGE.LastMonth,
                label: "Closed last month",
            },
            {
                value: DEAL_CLOSE_DATE_RANGE.LastQuarter,
                label: "Closed last quarter",
            },
            {
                value: DEAL_CLOSE_DATE_RANGE.LastYear,
                label: "Closed last year",
            },
        ],
    },
]

interface DisplaySettingsProps {
    hideNoCalls: boolean
    setHideNoCalls: (hideNoCalls: boolean) => void
}

interface DealTableFiltersProps {
    allCrmUsers: ICrmUser[]
    ownerIds: string[]
    ownerTeams: Record<string, string[]>
    setOwnerIds: (ownerIds: string[]) => void
    closeDate: DEAL_CLOSE_DATE_RANGE
    setCloseDate: (range: DEAL_CLOSE_DATE_RANGE) => void
    pipelines: ICrmDealPipeline[]
    selectedPipeline: ICrmDealPipeline | undefined
    setSelectedPipeline: (pipeline: ICrmDealPipeline | undefined) => void
    stages: ICrmDealStage[]
    selectedStages: ICrmDealStage[]
    setSelectedStages: (stages: ICrmDealStage[]) => void
    forecastCategories: string[]
    setForecastCategories: (categories: string[]) => void
    availableForecastCategories: string[]
    dealTypes: ICrmDealType[]
    setDealTypes: (types: ICrmDealType[]) => void
}

export function DealTableFilters(
    props: DealTableFiltersProps & DisplaySettingsProps
) {
    const closeDateValue = CLOSE_DATE_OPTIONS.flatMap(
        (group) => group.options
    ).find((option) => option.value === props.closeDate)

    const defaultValue = CLOSE_DATE_OPTIONS.flatMap(
        (group) => group.options
    ).find((option) => option.value === DEAL_CLOSE_DATE_RANGE.ThisMonth)

    return (
        <div className="flex flex-wrap items-center gap-2">
            <Select
                isSearchable={false}
                defaultValue={defaultValue}
                value={closeDateValue}
                options={CLOSE_DATE_OPTIONS}
                placeholder="Filter by closing date"
                className="w-64 text-base"
                onChange={(selectedOption) => {
                    if (selectedOption) props.setCloseDate(selectedOption.value)
                }}
                styles={{
                    menuList: (provided) => ({
                        ...provided,
                        maxHeight: "none", // Remove max-height to display the full dropdown
                    }),
                    control: (provided) => ({
                        ...provided,
                        borderColor: colors.gray[300],
                        borderRadius: "0.5rem",
                    }),
                }}
            />
            <OwnerFilter
                allCrmUsers={props.allCrmUsers}
                ownerIds={props.ownerIds}
                ownerTeams={props.ownerTeams}
                setOwnerIds={props.setOwnerIds}
            />
            <StageFilter
                pipelines={props.pipelines}
                selectedPipeline={props.selectedPipeline}
                setSelectedPipeline={props.setSelectedPipeline}
                stages={props.stages}
                selectedStages={props.selectedStages}
                setSelectedStages={props.setSelectedStages}
            />
            {props.availableForecastCategories.length > 0 && (
                <ForecastCategoryFilter
                    availableForecastCategories={
                        props.availableForecastCategories
                    }
                    forecastCategories={props.forecastCategories}
                    setForecastCategories={props.setForecastCategories}
                />
            )}
            <DealTypeFilter
                availableDealTypes={[
                    "new_business",
                    "existing_business",
                    "unknown",
                ]}
                dealTypes={props.dealTypes}
                setDealTypes={props.setDealTypes}
            />
            <DisplaySettings {...props} />
        </div>
    )
}

function OwnerFilter(props: {
    allCrmUsers: ICrmUser[]
    ownerIds: string[]
    ownerTeams: Record<string, string[]>
    setOwnerIds: (ownerIds: string[]) => void
}) {
    const individualOptions = useMemo(
        () =>
            props.allCrmUsers?.map((user) => ({
                value: user.crm_id,
                label: user.name,
                isGroup: false,
            })) || [],
        [props.allCrmUsers]
    )

    const teamOptions = useMemo(
        () =>
            props.allCrmUsers
                ?.map((user) => ({
                    value: `team_${user.crm_id}`,
                    label: `${user.name}'s Team`,
                    counter: props.ownerTeams[user.crm_id]?.length,
                    teamMembers: props.ownerTeams[user.crm_id] || [],
                    isGroup: true,
                }))
                .filter((option) => option.teamMembers.length > 0) || [],
        [props.allCrmUsers, props.ownerTeams]
    )

    const ownerOptions = [
        {
            label: "Teams",
            options: teamOptions,
            value: "teams",
        },
        {
            label: "Individual Owners",
            options: individualOptions,
            value: "individual_users",
        },
    ]

    // Pending state of unapplied selections
    const [selectedOptions, setSelectedOptions] = useState<
        IMultiSelectOption[]
    >([])

    // Initialize pending selections from ownerIds
    const ownerIds = props.ownerIds
    useEffect(() => {
        if (selectedOptions.length === 0) {
            const selections = individualOptions.filter((option) =>
                ownerIds.includes(option.value)
            )
            if (selections.length > 0) {
                setSelectedOptions(selections)
            }
        }
    }, [ownerIds, individualOptions, selectedOptions])

    // Automatically select/deselect teams based on if all members are selected
    useEffect(() => {
        teamOptions.forEach((team) => {
            const isTeamSelected = selectedOptions.some(
                (selection) => selection.value === team.value
            )
            const areAllMembersSelected = team.teamMembers.every((memberId) =>
                selectedOptions.some(
                    (selection) => selection.value === memberId
                )
            )

            if (areAllMembersSelected && !isTeamSelected) {
                // If all members are selected but team isn't, add the team
                const newSelections = Array.from(
                    new Set([...selectedOptions, team])
                )
                setSelectedOptions(newSelections)
            } else if (!areAllMembersSelected && isTeamSelected) {
                // If not all members are selected but team is, remove the team
                const newSelections = selectedOptions.filter(
                    (selection) => selection.value !== team.value
                )
                setSelectedOptions(newSelections)
            }
        })
    }, [selectedOptions, teamOptions])

    function handleOwnerChange(
        newValue: MultiValue<IMultiSelectOption>,
        actionMeta: ActionMeta<IMultiSelectOption>
    ) {
        if (!newValue) return

        if (actionMeta.action === "select-option" && actionMeta.option) {
            const option = actionMeta.option
            const team = teamOptions.find((t) => t.value === option.value)
            if (team) {
                // Selecting a team - add all team members
                const newSelections = new Set([
                    ...selectedOptions,
                    team,
                    ...individualOptions.filter((opt) =>
                        team.teamMembers.includes(opt.value)
                    ),
                ])
                setSelectedOptions(Array.from(newSelections))
            } else {
                // Selecting an individual
                setSelectedOptions([...selectedOptions, option])
            }
        } else if (
            actionMeta.action === "deselect-option" &&
            actionMeta.option
        ) {
            const option = actionMeta.option
            const team = teamOptions.find((t) => t.value === option.value)
            if (team) {
                // Deselecting a team - remove team and all its members
                setSelectedOptions(
                    selectedOptions.filter(
                        (opt) =>
                            opt.value !== team.value &&
                            !team.teamMembers.includes(opt.value)
                    )
                )
            } else {
                // Deselecting an individual
                setSelectedOptions(
                    selectedOptions.filter((opt) => opt.value !== option.value)
                )
            }
        } else if (actionMeta.action === "clear") {
            setSelectedOptions([])
        }
    }

    return (
        <MultiSelectFilter
            title="owners"
            icon="👤"
            options={ownerOptions}
            value={selectedOptions}
            onChange={handleOwnerChange}
            onApply={(options) => {
                // Only apply individual owners
                const ownerIds = options
                    .filter((option) => !option.isGroup)
                    .map((option) => option.value)
                props.setOwnerIds(Array.from(new Set(ownerIds)))
            }}
        />
    )
}

function ForecastCategoryFilter(props: {
    availableForecastCategories: string[]
    forecastCategories: string[]
    setForecastCategories: (categories: string[]) => void
}) {
    return (
        <MultiSelectFilter
            title="forecast categories"
            icon="📊"
            options={props.availableForecastCategories.map((category) => ({
                value: category,
                label: category,
            }))}
            value={props.forecastCategories.map((category) => ({
                value: category,
                label: category,
            }))}
            onApply={(selectedOption) => {
                if (selectedOption) {
                    const selectedCategories = selectedOption.map(
                        (option) => option.value
                    )
                    props.setForecastCategories(selectedCategories)
                }
            }}
        />
    )
}

function DealTypeFilter(props: {
    availableDealTypes: ICrmDealType[]
    dealTypes: ICrmDealType[]
    setDealTypes: (types: ICrmDealType[]) => void
}) {
    return (
        <MultiSelectFilter
            title="deal types"
            icon="📊"
            options={props.availableDealTypes.map((type) => ({
                value: type,
                label: getDealTypeLabel(type),
            }))}
            value={props.dealTypes.map((type) => ({
                value: type,
                label: getDealTypeLabel(type),
            }))}
            onApply={(selectedOption) => {
                if (selectedOption) {
                    const selectedTypes = selectedOption.map(
                        (option) => option.value
                    )
                    props.setDealTypes(selectedTypes)
                }
            }}
        />
    )
}

function StageFilter(props: {
    pipelines: ICrmDealPipeline[]
    selectedPipeline?: ICrmDealPipeline
    setSelectedPipeline: (pipeline: ICrmDealPipeline | undefined) => void
    stages: ICrmDealStage[]
    selectedStages: ICrmDealStage[]
    setSelectedStages: (stages: ICrmDealStage[]) => void
}) {
    return (
        <div className="flex gap-2">
            {props.pipelines.length > 0 && (
                <Select
                    className="min-w-fit text-sm"
                    placeholder="All pipelines"
                    isClearable={false}
                    isSearchable={false}
                    styles={SelectCustomStyle}
                    components={{ ValueContainer: PipelineValueContainer }}
                    options={props.pipelines.map((pipeline) => ({
                        value: pipeline.crm_id,
                        label: pipeline.name,
                    }))}
                    value={{
                        value: props.selectedPipeline?.crm_id,
                        label: props.selectedPipeline?.name,
                    }}
                    onChange={(selectedOption) => {
                        const selectedPipeline = props.pipelines.find(
                            (pipeline) =>
                                pipeline.crm_id === selectedOption?.value
                        )
                        props.setSelectedPipeline(selectedPipeline)
                        props.setSelectedStages([])
                    }}
                />
            )}
            <MultiSelectFilter
                title="stages"
                icon="🎯"
                options={props.stages.map((stage) => ({
                    value: stage.crm_id,
                    label: stage.name,
                }))}
                value={props.selectedStages.map((stage) => ({
                    value: stage.crm_id,
                    label: stage.name,
                }))}
                onApply={(selectedOption) => {
                    if (selectedOption) {
                        const selectedStages = selectedOption
                            .map((option) =>
                                props.stages.find(
                                    (stage) => stage.crm_id === option.value
                                )
                            )
                            .filter(
                                (stage) => stage !== undefined
                            ) as ICrmDealStage[]
                        props.setSelectedStages(selectedStages)
                    }
                }}
            />
        </div>
    )

    function PipelineValueContainer({ children, ...rest }: any) {
        return (
            <components.ValueContainer {...rest}>
                <div className="flex items-center gap-2 whitespace-nowrap">
                    🔁
                    {children}
                </div>
            </components.ValueContainer>
        )
    }
}

export function AnnotationFilters(props: {
    annotationTags: string[]
    annotationFilters: string[]
    setAnnotationFilters: (annotationFilters: string[]) => void
}) {
    const options = annotationTypes.filter((tag) =>
        props.annotationTags.includes(tag.tag)
    )

    // Append "summary" option
    options.push({
        tag: "summary",
        label: "Summary",
        acronym: "S",
        emoji: "📋",
    })

    function toggleAnnotationFilter(clickedTag: string) {
        const prev = props.annotationFilters
        props.setAnnotationFilters(
            prev.includes(clickedTag)
                ? prev.filter((tag) => tag !== clickedTag)
                : [...prev, clickedTag]
        )
    }

    return (
        <div className="flex gap-2">
            <div className="flex h-10 items-center divide-x divide-gray-300 overflow-auto rounded-lg border border-gray-300 font-semibold">
                {options.map((option) => {
                    const isSelected = props.annotationFilters.includes(
                        option.tag
                    )

                    const tooltip = `${option.emoji ?? ""} ${
                        isSelected ? "Hide" : "Display"
                    } ${option.label}`

                    return (
                        <button
                            key={option.tag}
                            className={clsx(
                                "h-full px-2 transition-colors hover:bg-gray-100",
                                isSelected ? "bg-gray-200" : "bg-white"
                            )}
                            onClick={() => {
                                toggleAnnotationFilter(option.tag)
                            }}
                            data-tooltip-id="tooltip-explanation"
                            data-tooltip-content={tooltip}
                        >
                            {option.acronym || option.label}
                        </button>
                    )
                })}
            </div>
        </div>
    )
}

function DisplaySettings(props: DisplaySettingsProps) {
    const [showPopup, setShowPopup] = useState(false)
    const popupRef = useRef<HTMLDivElement>(null)

    const handleButtonClick = () => {
        setShowPopup((prev) => !prev)
    }

    // Close the popup if the user clicks outside of it
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                popupRef.current &&
                !popupRef.current.contains(event.target as Node)
            ) {
                setShowPopup(false)
            }
        }
        if (showPopup) {
            document.addEventListener("mousedown", handleClickOutside, {
                // We need to run in the capture phase as the clear emails X
                // gets detached after updating the state (disappears from the
                // CreatableSelect component)
                capture: true,
            })
        } else {
            document.removeEventListener("mousedown", handleClickOutside)
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [showPopup, popupRef])

    return (
        <div className="flex h-10 flex-col" ref={popupRef}>
            <SecondaryButton
                className="flex h-full flex-row items-center justify-center gap-2 text-sm text-gray-600 hover:text-gray-800"
                onClick={handleButtonClick}
            >
                <FontAwesomeIcon icon={faSliders} className="h-4 w-4" />
            </SecondaryButton>

            {showPopup && (
                <div className="mt-1 md:relative">
                    <DisplaySettingPopup {...props} />
                </div>
            )}
        </div>
    )
}

function DisplaySettingPopup(props: DisplaySettingsProps) {
    return (
        <div className="absolute z-10 flex min-w-max flex-col justify-start gap-2 rounded-lg border bg-white p-3 text-base shadow-lg">
            <div className="flex items-center gap-2 px-2">
                <ToggleButton
                    checked={props.hideNoCalls}
                    onChange={props.setHideNoCalls}
                />
                <span>Hide deals with no calls</span>
            </div>
        </div>
    )
}
