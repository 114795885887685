import { useState } from "react"
import { TabHead } from "../common/Tabs"
import { CustomInsights } from "./CustomInsights"
import { ICall } from "../../types/Call"
import { Annotations } from "./Annotations"
import { TalkStats } from "./TalkStats"
import { getAnnotationSections } from "../../utils/getAnnotationSections"
import { goToTimestamp } from "./utils/mediaPlayer"
import { IAnnotation } from "../../types/Annotation"
import ReactPlayer from "react-player"
import { ChatBubbleTextIcon } from "../../assets/icons/ChatBubbleTextIcon"
import { Tasks } from "../tasks/CallTaskList"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLightbulb } from "@fortawesome/pro-regular-svg-icons"
import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons"

export enum TabsEnum {
    Annotations = "Overview",
    CustomInsights = "Insights",
    TalkStats = "Stats",
}
export type Tab = { type: TabsEnum; label: React.ReactNode }

export const Tabs = {
    [TabsEnum.Annotations]: {
        type: TabsEnum.Annotations,
        label: (
            <div className="flex flex-row items-center gap-2 p-0.5">
                <FontAwesomeIcon icon={faMagnifyingGlass} className="h-5 w-5" />
                <span className="hidden md:block">
                    {TabsEnum.Annotations.toString()}
                </span>
            </div>
        ),
    },
    [TabsEnum.CustomInsights]: {
        type: TabsEnum.CustomInsights,
        label: (
            <div className="flex flex-row items-center gap-2 p-0.5">
                <FontAwesomeIcon icon={faLightbulb} className="h-6 w-6" />
                <span className="hidden md:block">
                    {TabsEnum.CustomInsights.toString()}
                </span>
            </div>
        ),
    },
    [TabsEnum.TalkStats]: {
        type: TabsEnum.TalkStats,
        label: (
            <div className="flex flex-row items-center gap-2 p-0.5">
                <ChatBubbleTextIcon />
                <span className="hidden md:block">
                    {TabsEnum.TalkStats.toString()}
                </span>
            </div>
        ),
    },
}

export function CallAnalysis(props: {
    tabs: Tab[]
    call: ICall
    selectedAnnotation?: IAnnotation | undefined
    selectAnnotation: (annotation: IAnnotation) => void
    playerRef: React.MutableRefObject<ReactPlayer | null>
}) {
    const tabs = props.tabs
    const call = props.call

    const [activeTab, setActiveTab] = useState(0)

    const isActive = (tabType: TabsEnum) =>
        tabs[activeTab] && tabs[activeTab].type === tabType

    const annotationSections = call.annotations
        ? getAnnotationSections(call.annotations)
        : []

    return (
        <div>
            {tabs.length > 1 && (
                <div className="mb-3 mt-12 max-w-fit">
                    <TabHead
                        tabs={tabs}
                        activeTab={activeTab}
                        onTabChange={(index) => {
                            setActiveTab(index)
                        }}
                    />
                </div>
            )}
            {isActive(TabsEnum.CustomInsights) && (
                <CustomInsights
                    callId={call.id}
                    callTitle={call.title}
                    inferredValues={call.inferred_crm_field_values || []}
                />
            )}
            {isActive(TabsEnum.Annotations) && (
                <div className="flex flex-col gap-8">
                    <Tasks
                        call={call}
                        goToTimestamp={goToTimestamp(props.playerRef)}
                    />
                    <Annotations
                        callTitle={call.title}
                        annotationSections={annotationSections}
                        selectAnnotation={props.selectAnnotation}
                        selectedAnnotation={props.selectedAnnotation}
                        goToTimestamp={goToTimestamp(props.playerRef)}
                        participants={call.parties}
                    />
                </div>
            )}
            {isActive(TabsEnum.TalkStats) && (
                <TalkStats participants={call.parties} />
            )}
        </div>
    )
}
