import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { ISharedCallPreview, IShareOptions } from "../../types/Call"
import { queries } from "../../api/queries"
import ReactPlayer from "react-player"
import {
    getRemainingDays,
    getSimpleDate,
    secondsToTimestamp,
} from "../../utils/datetime"
import { SecondaryButton } from "../common/Buttons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { faTrashCan } from "@fortawesome/free-regular-svg-icons"
import axios from "axios"
import { faLink } from "@fortawesome/free-solid-svg-icons"

export function SharedCallPreview(props: { sharedCall: ISharedCallPreview }) {
    const sharable = props.sharedCall.snippet ? "A snippet" : "Entire call"
    const timeRange = props.sharedCall.snippet
        ? `${secondsToTimestamp(
              props.sharedCall.snippet.start_sec
          )} to ${secondsToTimestamp(props.sharedCall.snippet.end_sec)}`
        : props.sharedCall.options?.start_time

    const { data: media } = useQuery({
        ...queries.sharedCall.media(props.sharedCall.share_id),
        enabled: !!props.sharedCall.snippet,
        staleTime: Infinity, // Don't refetch media as it will disrupt playback
    })

    const playerHeight = media?.type === "video" ? "100%" : "50px"

    return (
        <div className="rounded-lg bg-white p-4 shadow-lg">
            <div className="mb-5">
                <span className="font-semibold">{sharable}</span>
                {timeRange && <span> from {timeRange}</span>}
            </div>

            <div className="my-3 text-sm text-gray-600">
                {parseOptions(props.sharedCall.options)}
            </div>
            {media?.signed_url && (
                <div className="my-3 w-full overflow-hidden rounded-lg">
                    <ReactPlayer
                        url={media.signed_url}
                        height={playerHeight}
                        width="100%"
                        controls={true}
                    />
                </div>
            )}

            <div className="flex items-center justify-between gap-4">
                <div className="space-y-1">
                    <div className="text-sm text-gray-600">
                        Shared on{" "}
                        <span className="font-semibold">
                            {getSimpleDate(props.sharedCall.created_at_utc)}
                        </span>
                    </div>
                    <div className="text-sm font-semibold text-red-700">
                        Link expires in{" "}
                        {getRemainingDays(props.sharedCall.expiry_date)} days
                    </div>
                </div>
                <div className="flex gap-2">
                    <CopyButton callShareId={props.sharedCall.share_id} />
                    <DeleteButton
                        callId={props.sharedCall.call_id}
                        callShareId={props.sharedCall.share_id}
                    />
                </div>
            </div>
        </div>
    )
}

function CopyButton(props: { callShareId: string }) {
    const queryClient = useQueryClient()
    const { addNotification } = useNotification()

    const handleCopy = async () => {
        try {
            const data = await queryClient.fetchQuery(
                queries.sharedCall.url(props.callShareId)
            )
            await navigator.clipboard.writeText(data)
            addNotification(
                "Shareable link copied!",
                "",
                NotificationType.Success
            )
        } catch (error) {
            addNotification(
                "Failed to get shareable link!",
                "",
                NotificationType.Error
            )
        }
    }

    return (
        <SecondaryButton onClick={handleCopy}>
            <FontAwesomeIcon icon={faLink} />
        </SecondaryButton>
    )
}

function DeleteButton(props: { callId: string; callShareId: string }) {
    const queryClient = useQueryClient()
    const { addNotification } = useNotification()

    const mutation = useMutation({
        mutationFn: () =>
            axios.delete(
                `${process.env.REACT_APP_API_DOMAIN}/calls/${props.callId}/shares/${props.callShareId}`
            ),
        onSuccess: () => {
            queryClient.invalidateQueries(queries.calls.shares(props.callId))
            addNotification(
                "shareable link deleted!",
                "",
                NotificationType.Success
            )
        },
        onError: (error) => {
            const errorMessage = axios.isAxiosError(error)
                ? error.response?.data["detail"]
                : error.message

            addNotification(
                "Failed to delete shareable link!",
                errorMessage,
                NotificationType.Error
            )
        },
    })
    return (
        <SecondaryButton
            onClick={() => mutation.mutate()}
            disabled={mutation.isPending}
        >
            <FontAwesomeIcon icon={faTrashCan} />
        </SecondaryButton>
    )
}

function parseOptions(options: IShareOptions | null) {
    if (!options) {
        return ""
    }
    let parsedOptions = []
    if (options.share_summary) {
        parsedOptions.push("AI Summary")
    }
    if (options.share_annotations) {
        parsedOptions.push("Overview tab")
    }
    if (options.share_custom_insights) {
        parsedOptions.push("Insights tab")
    }
    if (options.share_talk_stats) {
        parsedOptions.push("Talk stats tab")
    }
    if (options.enable_ask_glyphic) {
        parsedOptions.push("Ask Glyphic")
    }

    if (parsedOptions.length === 0) {
        return ""
    }
    return "Includes " + parsedOptions.join(", ")
}
