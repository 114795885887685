export function CircularProgress({
    completed,
    total,
}: {
    completed: number
    total: number
}) {
    const percentage = total === 0 ? 0 : (completed / total) * 100

    // Radius of the circle in SVG units
    const radius = 8
    const circumference = 2 * Math.PI * radius

    // Calculate how much of the circle should be "hidden" based on percentage
    // When strokeDashoffset equals circumference, the circle is fully hidden
    // When strokeDashoffset is 0, the circle is fully visible
    const strokeDashoffset = circumference - (percentage / 100) * circumference

    return (
        <div className="flex items-center gap-2 text-gray-500">
            <span>
                {completed} / {total}
            </span>
            <svg className="h-5 w-5 -rotate-90 transform" viewBox="0 0 20 20">
                {/* Background circle */}
                <circle
                    className="text-gray-200"
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    r={radius}
                    cx="10"
                    cy="10"
                />
                {/* Progress circle */}
                <circle
                    className="text-green-500 transition-all duration-300"
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                    r={radius}
                    cx="10"
                    cy="10"
                />
            </svg>
        </div>
    )
}
