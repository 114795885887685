import { useEffect, useState } from "react"
import LoadingSpinner from "../common/LoadingSpinner"
import { CompaniesTable } from "./CompaniesTable"
import { useQuery } from "@tanstack/react-query"
import { ErrorPage } from "../common/ErrorPage"
import { FilterIcon } from "../../assets/icons/FilterIcon"
import { useSearchParam } from "../common/hooks/useSearchParam"
import { companyQueries } from "../../api/queries/companies"
import { SortingState } from "@tanstack/react-table"

export function Companies() {
    const [filterTerm, setFilterTerm] = useSearchParam("filter")
    const [pageParam, setPageParam] = useSearchParam("page")
    const [sorting, setSorting] = useState<SortingState>([])

    // Get the current page from URL or default to 1
    const currentPage = parseInt(pageParam || "1")

    // Reset page to 1 when filter changes
    useEffect(() => {
        if (filterTerm !== "" && pageParam !== "1") {
            setPageParam("1")
        }
    }, [filterTerm, pageParam, setPageParam])

    // Convert table sorting to API parameters
    const orderByDesc = sorting.length > 0 ? sorting[0].desc : undefined

    const { data, isPending, isError } = useQuery(
        companyQueries.listAll(
            currentPage - 1,
            filterTerm || undefined,
            orderByDesc
        )
    )

    useEffect(() => {
        document.title = "Companies - " + process.env.REACT_APP_DOCUMENT_TITLE

        return () => {
            // Reset the title when the component unmounts
            document.title = process.env.REACT_APP_DOCUMENT_TITLE!
        }
    }, [])

    if (isPending) return <LoadingSpinner />
    if (isError) {
        return <ErrorPage error={{ message: "Failed to load companies" }} />
    }

    if (data?.records.length === 0 && !filterTerm) return <Empty />

    return (
        <section className="my-6 space-y-4 rounded-lg px-8 py-6">
            <div className="flex flex-col justify-between gap-2 md:flex-row">
                <h1 className="text-2xl font-bold">All Companies</h1>
                <Filter filterTerm={filterTerm} setFilterTerm={setFilterTerm} />
            </div>

            {data?.records.length === 0 && filterTerm ? (
                <div className="flex h-64 items-center justify-center text-lg font-bold text-gray-600">
                    No companies found for "{filterTerm}"
                </div>
            ) : (
                <CompaniesTable
                    companies={data?.records || []}
                    filterTerm={filterTerm}
                    pagination={data?.pagination || { page_count: 1 }}
                    currentPage={currentPage - 1} // Convert to 0-indexed for PageNavigator
                    sorting={sorting}
                    onSortingChange={setSorting}
                    setPageParam={setPageParam}
                />
            )}
        </section>
    )
}

function Filter(props: {
    filterTerm: string
    setFilterTerm: (filterTerm: string) => void
}) {
    const [inputValue, setInputValue] = useState(props.filterTerm)

    useEffect(() => {
        const handler = setTimeout(() => {
            props.setFilterTerm(inputValue)
        }, 600) // 600ms delay to allow for typing

        return () => {
            clearTimeout(handler)
        }
    }, [inputValue, props])

    return (
        <div className="border-block flex w-full flex-row items-center gap-2 rounded-lg border bg-white py-1 pl-4 text-base md:w-1/2">
            <FilterIcon className="text-gray-500" />
            <input
                autoFocus
                className="w-full py-2 outline-none placeholder:text-gray-600"
                type="text"
                placeholder="Filter by company name"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
            />
        </div>
    )
}

function Empty() {
    return (
        <div className="flex h-screen items-center justify-center text-lg font-bold text-gray-600">
            Companies you talk to will show up here
        </div>
    )
}
