import { useEffect } from "react"
import { SettingsSection } from "./Card"
import { useUser } from "../../providers/UserProvider"
import { useQueryClient } from "@tanstack/react-query"
import { useUpdateUserMemory } from "../../api/mutations/user-memory"
import { TextArea } from "./TextArea"

export function UserMemory() {
    const user = useUser()
    const queryClient = useQueryClient()

    const { mutate, isPending } = useUpdateUserMemory()

    // Invalidate the user query when the component is mounted to ensure we get the latest user data
    useEffect(() => {
        queryClient.invalidateQueries({ queryKey: ["user"] })
    }, [queryClient])

    if (!user) {
        return <></>
    }

    return (
        <SettingsSection
            title="Personal memory"
            description={
                <p>
                    Your Ask Glyphic memory is a description of your preferences
                    based on the interactions you've had with Ask Glyphic. This
                    helps personalize your Ask Glyphic experience and make
                    future interactions more relevant and efficient.
                </p>
            }
            id="user-memory"
        >
            <TextArea
                value={user?.memory || ""}
                placeholder={"Enter your user memory here..."}
                onSave={mutate}
                isPending={isPending}
            />
        </SettingsSection>
    )
}
