import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import { useMemo, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencil, faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import LoadingSpinner from "../common/LoadingSpinner"
import { stringToColour } from "../../utils/stringToColour"
import { Card } from "./Card"
import { queries } from "../../api/queries"
import { ICallTag } from "../../types/Call"
import { NavigateLink } from "../common/NavigateLink"
import { groupTags } from "../call-page/TagInput"
import { encodeTag } from "../../utils/tagUtils"
import Creatable from "react-select/creatable"
import * as Popover from "@radix-ui/react-popover"

export function CallTags() {
    const { data: tags, isPending } = useQuery(queries.callTags.list())

    // Group tags by their group property
    const groupedTags = useMemo(() => groupTags(tags || []), [tags])

    if (isPending) return <LoadingSpinner />

    return (
        <Card title="Call Tags">
            <div className="mb-4">
                <span className="text-sm text-gray-500">
                    Use tags to help organize and filter calls in your
                    organization.
                </span>
            </div>
            <div className="flex justify-end">
                <AddTagForm />
            </div>

            {/* Display tags grouped by their group property */}
            {Object.entries(groupedTags).map(([group, groupTags]) => {
                if (groupTags.length === 0) return null

                const isUngrouped = group === "ungrouped"

                return (
                    <div key={group} className="mb-6">
                        <h3 className="text-md mb-2 font-medium">
                            {isUngrouped ? "Ungrouped Tags" : group}
                            {!isUngrouped && (
                                <span className="ml-2 rounded-full bg-amber-100 px-2 py-0.5 text-xs text-amber-700">
                                    Mutually Exclusive
                                </span>
                            )}
                        </h3>
                        <div className="w-full overflow-auto rounded-lg border border-gray-300 text-sm">
                            <table className="w-full">
                                <thead>
                                    <tr className="h-12 bg-gray-50">
                                        <th className="w-64 px-4 py-2 text-left">
                                            Tag Name
                                        </th>
                                        <th className="w-32 p-2">Edit</th>
                                        <th className="w-32 p-2">Delete</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-300 border-t border-gray-300">
                                    {(isUngrouped
                                        ? [...groupTags].sort((a, b) =>
                                              a.name.localeCompare(b.name)
                                          ) // Only sort ungrouped tags
                                        : groupTags
                                    ) // Preserve original order for grouped tags (important for stage inference)
                                        .map((tag) => (
                                            <TagRow key={tag.id} tag={tag} />
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )
            })}
        </Card>
    )
}

export function Tag(props: { text: string; groupName?: string }) {
    const tagColour = stringToColour(props.text)
    const [hovered, setHovered] = useState(false)

    // Create URL for this tag using the utility function
    const getTagUrl = () => {
        return `/calls?tags=${encodeTag({
            name: props.text,
            group: props.groupName,
        })}`
    }

    return (
        <NavigateLink href={getTagUrl()}>
            <div
                className="flex w-fit items-center rounded-md border px-2 py-1 text-sm capitalize"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                style={{
                    backgroundColor: hovered ? tagColour[100] : tagColour[50],
                    borderColor: tagColour[300],
                }}
            >
                <span
                    className="mr-1.5 h-2 w-2 flex-shrink-0 flex-grow-0 rounded-full"
                    style={{ backgroundColor: tagColour[500] }}
                ></span>
                {props.groupName && props.groupName !== "ungrouped" && (
                    <span className="mr-1 text-xs text-gray-500">
                        {props.groupName}:
                    </span>
                )}
                <span>{props.text}</span>
            </div>
        </NavigateLink>
    )
}

function TagRow({ tag }: { tag: ICallTag }) {
    const [isEditing, setIsEditing] = useState(false)
    const [editedTagName, setEditedTagName] = useState(tag.name)
    const [editedDescription, setEditedDescription] = useState(
        tag.description || ""
    )
    const [editedGroup, setEditedGroup] = useState(tag.group || "")
    const queryClient = useQueryClient()
    const { addNotification } = useNotification()

    const editTag = useMutation({
        mutationFn: async (data: {
            name: string
            description: string | null
            group: string | null
        }) => {
            await axios.put(
                `${process.env.REACT_APP_API_DOMAIN}/call_tags/${tag.id}`,
                {
                    name: data.name,
                    description: data.description,
                    group: data.group,
                }
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: queries.callTags.list().queryKey,
            })
            setIsEditing(false)
            addNotification(
                "Tag updated successfully",
                "",
                NotificationType.Success
            )
        },
        onError: (error: any) => {
            // Extract the detailed error message from Axios error response
            const errorMessage = error.response?.data?.detail || `${error}`
            addNotification(
                "Failed to update tag",
                errorMessage,
                NotificationType.Error
            )
        },
    })

    const deleteTag = useMutation({
        mutationFn: async () => {
            await axios.delete(
                `${process.env.REACT_APP_API_DOMAIN}/call_tags/${tag.id}`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: queries.callTags.list().queryKey,
            })
            addNotification(
                "Tag deleted successfully",
                "",
                NotificationType.Success
            )
        },
        onError: (error: any) => {
            // If we get a 404, the tag was already deleted, so refresh the data
            if (axios.isAxiosError(error) && error.response?.status === 404) {
                queryClient.invalidateQueries({
                    queryKey: queries.callTags.list().queryKey,
                })
                return
            }
            // Extract the detailed error message from Axios error response
            const errorMessage = error.response?.data?.detail || `${error}`
            addNotification(
                "Failed to delete tag",
                errorMessage,
                NotificationType.Error
            )
        },
    })

    return (
        <tr>
            <td className="w-64 px-4 py-2">
                {isEditing ? (
                    <div className="space-y-2">
                        <div>
                            <label className="block text-xs text-gray-500">
                                Name
                            </label>
                            <input
                                type="text"
                                autoFocus
                                value={editedTagName}
                                onChange={(e) =>
                                    setEditedTagName(e.target.value)
                                }
                                className="w-fit min-w-[100px] rounded border border-gray-300 px-2 py-1"
                            />
                        </div>
                        <div>
                            <label className="block text-xs text-gray-500">
                                Description
                            </label>
                            <input
                                type="text"
                                value={editedDescription}
                                onChange={(e) =>
                                    setEditedDescription(e.target.value)
                                }
                                className="w-fit min-w-[100px] rounded border border-gray-300 px-2 py-1"
                                placeholder="Optional"
                            />
                        </div>
                        <div>
                            <label className="block text-xs text-gray-500">
                                Group
                            </label>
                            <input
                                type="text"
                                value={editedGroup}
                                onChange={(e) => setEditedGroup(e.target.value)}
                                className="w-fit min-w-[100px] rounded border border-gray-300 px-2 py-1"
                                placeholder="Optional"
                            />
                        </div>
                    </div>
                ) : (
                    <div>
                        <Tag text={tag.name} />
                        {tag.description && (
                            <div className="mt-1 text-xs text-gray-500">
                                {tag.description}
                            </div>
                        )}
                    </div>
                )}
            </td>
            <td className="w-32 p-2 text-center">
                {isEditing ? (
                    <div className="space-x-2">
                        <PrimaryButton
                            onClick={() => {
                                editTag.mutate({
                                    name: editedTagName,
                                    description: editedDescription || null,
                                    group: editedGroup || null,
                                })
                            }}
                        >
                            Save
                        </PrimaryButton>
                        <SecondaryButton
                            onClick={() => {
                                setIsEditing(false)
                                setEditedTagName(tag.name)
                                setEditedDescription(tag.description || "")
                                setEditedGroup(tag.group || "")
                            }}
                        >
                            Cancel
                        </SecondaryButton>
                    </div>
                ) : (
                    <SecondaryButton
                        onClick={() => {
                            setIsEditing(true)
                            setEditedTagName(tag.name)
                        }}
                    >
                        <FontAwesomeIcon icon={faPencil} />
                    </SecondaryButton>
                )}
            </td>
            <td className="w-32 p-2 text-center">
                <SecondaryButton
                    className="hover:text-red-500"
                    onClick={() => deleteTag.mutate()}
                >
                    <FontAwesomeIcon icon={faTrashCan} />
                </SecondaryButton>
            </td>
        </tr>
    )
}

function AddTagForm() {
    const [newTagName, setNewTagName] = useState("")
    const [newTagDescription, setNewTagDescription] = useState("")
    const [newTagGroup, setNewTagGroup] = useState("")
    const { data: existingTags } = useQuery(queries.callTags.list())
    const queryClient = useQueryClient()
    const { addNotification } = useNotification()

    // Get list of existing group names
    const existingGroups = new Set<string>()
    existingTags?.forEach((tag) => {
        if (tag.group) {
            existingGroups.add(tag.group)
        }
    })

    // List of existing groups for suggestions
    const existingGroupsList = Array.from(existingGroups)

    const addTag = useMutation({
        mutationFn: async (data: {
            name: string
            description: string | null
            group: string | null
        }) => {
            await axios.post(`${process.env.REACT_APP_API_DOMAIN}/call_tags`, {
                name: data.name,
                description: data.description,
                group: data.group,
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: queries.callTags.list().queryKey,
            })
            setNewTagName("")
            setNewTagDescription("")
            setNewTagGroup("")
            addNotification(
                "Tag created successfully",
                "",
                NotificationType.Success
            )
        },
        onError: (error: any) => {
            // Extract the detailed error message from Axios error response
            const errorMessage = error.response?.data?.detail || `${error}`
            addNotification(
                "Failed to add tag",
                errorMessage,
                NotificationType.Error
            )
        },
    })

    return (
        <Popover.Root>
            <Popover.Trigger>
                <SecondaryButton className="flex items-center gap-2">
                    <FontAwesomeIcon icon={faPlus} />
                    Create new tag
                </SecondaryButton>
            </Popover.Trigger>
            <Popover.Content>
                <div className="mr-10">
                    <Card title="Create new tag">
                        <form
                            onSubmit={(e) => {
                                e.preventDefault()
                                if (newTagName.trim()) {
                                    addTag.mutate({
                                        name: newTagName.trim(),
                                        description:
                                            newTagDescription.trim() || null,
                                        group: newTagGroup.trim() || null,
                                    })
                                }
                            }}
                        >
                            <div className="flex flex-col gap-4">
                                <div>
                                    <label className="mb-1 block text-sm text-gray-500">
                                        Tag Name
                                    </label>
                                    <input
                                        type="text"
                                        value={newTagName}
                                        onChange={(e) =>
                                            setNewTagName(e.target.value)
                                        }
                                        placeholder="Enter new tag name"
                                        className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>

                                <div>
                                    <label className="mb-1 block text-sm text-gray-500">
                                        Group (Optional)
                                        <span className="ml-1 text-xs text-blue-600">
                                            (Tags in the same group are mutually
                                            exclusive)
                                        </span>
                                    </label>
                                    <div className="relative">
                                        <Creatable
                                            className="w-full"
                                            value={
                                                newTagGroup
                                                    ? {
                                                          value: newTagGroup,
                                                          label: newTagGroup,
                                                      }
                                                    : null
                                            }
                                            options={existingGroupsList.map(
                                                (group) => ({
                                                    value: group,
                                                    label: group,
                                                })
                                            )}
                                            placeholder="Choose or enter new group name"
                                            onChange={(option) =>
                                                setNewTagGroup(
                                                    option ? option.value : ""
                                                )
                                            }
                                            isClearable
                                            formatCreateLabel={(inputValue) =>
                                                `Create group "${inputValue}"`
                                            }
                                            styles={{
                                                control: (styles) => ({
                                                    ...styles,
                                                    borderRadius: "0.375rem",
                                                }),
                                            }}
                                        />
                                    </div>
                                    <p className="mt-1 text-xs text-gray-500">
                                        Example groups: "Deal Stage",
                                        "Priority", "Status"
                                    </p>
                                </div>
                                <div>
                                    <label className="mb-1 block text-sm text-gray-500">
                                        Description (Optional)
                                    </label>
                                    <textarea
                                        value={newTagDescription}
                                        onChange={(e) =>
                                            setNewTagDescription(e.target.value)
                                        }
                                        placeholder="Enter description"
                                        className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        rows={4}
                                    />
                                </div>
                            </div>
                            <div className="mt-4">
                                <PrimaryButton
                                    type="submit"
                                    disabled={!newTagName.trim()}
                                >
                                    Add Tag
                                </PrimaryButton>
                            </div>
                        </form>
                    </Card>
                </div>
            </Popover.Content>
        </Popover.Root>
    )
}
