export function LogoLink(props: { url: string; logo: React.ReactNode }) {
    return (
        <a
            className={`text-gray-400 transition-colors hover:cursor-pointer hover:text-gray-800`}
            href={props.url}
            target="_blank"
            rel="noreferrer"
        >
            {props.logo}
        </a>
    )
}
