import { ResourceType } from "./ResourceTypes"

export interface QuickAction {
    question: string
    title: string | null
}

export enum AccessLevel {
    Personal = "personal",
    Team = "team",
    Organization = "organization",
    Glyphic = "glyphic",
}

export interface SavedQuickAction extends QuickAction {
    id: string
    organization_id: string
    user_id: string | null
    user_name: string | null
    resource_type: ResourceType
    access_level: AccessLevel
    created_at_utc: string
    is_hidden: boolean
}
