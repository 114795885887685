import { StatusCircleEmpty } from "../../assets/icons/rubric/StatusCircles"
import { ICompanyAnnotationRubricResults } from "../../types/Company"
import { annotationTypes } from "../../utils/getAnnotationSections"
import { StatusIcon } from "../company-view/Summary"

export function QualificationScores(props: {
    scores: ICompanyAnnotationRubricResults[] | null
    length: number
    showAcronyms?: boolean
}) {
    const { scores, length } = props
    return (
        <div className="flex w-full items-center justify-evenly gap-1 overflow-auto">
            {scores &&
                // we show scores in the same order as the annotations
                annotationTypes.map((annotation) => {
                    const score = scores.find(
                        (score) => score.tag === annotation.tag
                    )
                    if (!score) {
                        return null
                    }

                    return (
                        <div
                            key={score.tag}
                            className="flex flex-col items-center gap-1"
                        >
                            {props.showAcronyms && (
                                <span className="flex w-3 justify-center text-sm font-semibold text-gray-600">
                                    {annotation.acronym}
                                </span>
                            )}
                            <StatusIcon
                                key={score.name}
                                score={score.score}
                                tag={score.tag}
                                data-tooltip-id="tooltip-explanation"
                                data-tooltip-html={`<b>${score.name}:</b><br/>${score.feedback_headline}`}
                            />
                        </div>
                    )
                })}
            {!scores &&
                Array.from({ length }, (_, i) => (
                    <StatusCircleEmpty
                        key={i}
                        className="h-4 w-4 flex-shrink-0 text-gray-200"
                    />
                ))}
        </div>
    )
}

export function QualificationScoresVertical(props: {
    scores: ICompanyAnnotationRubricResults[] | null
    length: number
}) {
    const { scores, length } = props
    return (
        <div className="flex w-full flex-col items-start gap-1 overflow-auto">
            {scores &&
                // we show scores in the same order as the annotations
                annotationTypes.map((annotation) => {
                    const score = scores.find(
                        (score) => score.tag === annotation.tag
                    )
                    if (!score) {
                        return null
                    }

                    return (
                        <div
                            className="flex items-center gap-1"
                            data-tooltip-id="tooltip-explanation"
                            data-tooltip-html={`<b>${score.name}:</b><br/>${score.feedback_headline}`}
                        >
                            <span className="flex w-3 justify-center text-sm font-semibold text-gray-600">
                                {annotation.acronym}
                            </span>
                            <StatusIcon
                                key={score.name}
                                score={score.score}
                                tag={score.tag}
                            />
                        </div>
                    )
                })}
            {!scores &&
                Array.from({ length }, (_, i) => (
                    <StatusCircleEmpty
                        key={i}
                        className="h-4 w-4 flex-shrink-0 text-gray-200"
                    />
                ))}
        </div>
    )
}
