import { faCircle } from "@fortawesome/pro-regular-svg-icons"
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import clsx from "clsx"
import { UpdateTaskMutation } from "../../../api/mutations/tasks"
import { useCallback } from "react"
import { getHeapInstance } from "../../../utils/heap"

interface TaskCompleteButtonProps {
    taskId: string
    isCompleted: boolean
    updateTask?: UpdateTaskMutation
    isUpdating?: boolean
}

export function TaskCompleteButton({
    taskId,
    isCompleted,
    updateTask,
    isUpdating,
}: TaskCompleteButtonProps) {
    const handleComplete = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation() // Prevent triggering the parent's onClick

            if (!updateTask) return

            // We're toggling the state
            const newCompletedState = !isCompleted

            getHeapInstance()?.track("task-completion-toggled", {
                task_id: taskId,
                completion_state: newCompletedState,
            })

            updateTask({
                taskId: taskId,
                updates: {
                    completed_at_utc: newCompletedState
                        ? new Date().toISOString()
                        : null,
                },
            })
        },
        [updateTask, taskId, isCompleted]
    )

    return (
        <button
            className={clsx(
                "h-4 h-5 w-4 w-5 flex-shrink-0 justify-center transition-all hover:opacity-80 disabled:opacity-50",
                !updateTask && "cursor-not-allowed"
            )}
            disabled={!updateTask || isUpdating}
            onClick={handleComplete}
        >
            <FontAwesomeIcon
                icon={isCompleted ? faCircleCheck : faCircle}
                className={clsx(
                    "text-lg transition-all duration-200",
                    isCompleted
                        ? "scale-100 text-green-500"
                        : "scale-90 text-gray-300",
                    updateTask && "hover:scale-110"
                )}
                style={{ width: "20px", height: "20px" }}
            />
        </button>
    )
}
