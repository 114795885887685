import React, { useRef, useCallback, useState, useEffect } from "react"
import { PriorityButton } from "../common/Buttons"
import { Logo } from "../../assets/Logo"
import { SimpleCard } from "../common/SimpleCard"

interface AskGlyphicTextBoxProps {
    placeholder: string
    input: string
    autoFocus?: boolean
    setInput: (input: string) => void
    submit: (input: string) => Promise<void>
}

export default function AskGlyphicTextBox(props: AskGlyphicTextBoxProps) {
    const [isLoading, setLoading] = useState<boolean>(false)
    const textareaRef = useRef<HTMLTextAreaElement>(null)

    const handleInputChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { value } = event.target
        props.setInput(value)
    }

    const handleTextareaResize = () => {
        const textareaElement = textareaRef.current
        if (textareaElement) {
            textareaElement.style.height = "auto"
            textareaElement.style.height = `${textareaElement.scrollHeight}px`
        }
    }

    useEffect(() => {
        handleTextareaResize()
    }, [props.input])

    const handleSubmit = useCallback(
        async (event: React.FormEvent) => {
            event.preventDefault()
            props.setInput("")
            setLoading(true)
            try {
                await props.submit(props.input)
            } finally {
                setLoading(false)
            }
        },
        [props]
    )

    return (
        <SimpleCard className="p-4">
            <form
                onSubmit={handleSubmit}
                className="flex w-full flex-1 items-end space-x-2"
            >
                <textarea
                    name="message"
                    autoFocus={props.autoFocus ?? true}
                    value={props.input}
                    onChange={handleInputChange}
                    onInput={handleTextareaResize}
                    placeholder={props.placeholder}
                    className="w-full resize-none rounded-lg bg-transparent pb-2 outline-none"
                    style={{ height: "auto" }}
                    onKeyDown={(event) => {
                        if (isLoading || !props.input) return
                        if (event.key === "Enter" && !event.shiftKey) {
                            event.preventDefault()
                            handleSubmit(event)
                        }
                    }}
                    rows={1}
                    ref={textareaRef}
                />
                <PriorityButton
                    id="frigade-ask-glyphic-entry"
                    type={"submit"}
                    className="flex w-fit flex-row items-center px-4 py-2 md:min-w-fit"
                    disabled={isLoading || !props.input}
                >
                    <Logo />
                    <span className="hidden md:block md:w-24">Ask Glyphic</span>
                </PriorityButton>
            </form>
        </SimpleCard>
    )
}
