import {
    faCalendar,
    faTag,
    faUser,
    faLightbulb,
    faUsers,
    faGlobe,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ReactNode } from "react"
import {
    CallsToInclude,
    ISearchFilterInsight,
    ISearchFilters,
} from "../../types/search"
import { getSimpleDate } from "../../utils/datetime"

export function FilterPill(props: {
    icon: any
    text: ReactNode
    className?: string
}) {
    const baseClasses = "flex items-center space-x-2 rounded-lg bg-gray-100"

    return (
        <div className={`${baseClasses} ${props.className || "p-2"}`}>
            <FontAwesomeIcon icon={props.icon} />
            <span className="whitespace-nowrap text-xs font-semibold">
                {props.text}
            </span>
        </div>
    )
}

export function hasAnyFilters(filters: ISearchFilters): boolean {
    return (
        !!filters.since_date ||
        !!filters.until_date ||
        (!!filters.users && filters.users.length > 0) ||
        (!!filters.tags && filters.tags.length > 0) ||
        (!!filters.insights && filters.insights.length > 0) ||
        filters.include_calls !== CallsToInclude.ALL
    )
}

export function FiltersContainer({ filters }: { filters: ISearchFilters }) {
    return (
        <div className="flex flex-wrap gap-1.5">
            {filters.users && filters.users.length > 0 && (
                <FilterPill
                    icon={faUser}
                    text={filters.users.map((user) => user.email).join(", ")}
                />
            )}

            {filters.since_date && (
                <FilterPill
                    icon={faCalendar}
                    text={`Since ${getSimpleDate(filters.since_date)}`}
                />
            )}

            {filters.until_date && (
                <FilterPill
                    icon={faCalendar}
                    text={`Until ${getSimpleDate(filters.until_date)}`}
                />
            )}

            {filters.tags && filters.tags.length > 0 && (
                <FilterPill
                    icon={faTag}
                    text={filters.tags.map((tag) => tag.name).join(", ")}
                />
            )}

            <InsightsPill insights={filters.insights} />

            <IncludeCallsPill includedCalls={filters.include_calls} />
        </div>
    )
}

function InsightsPill(props: { insights?: ISearchFilterInsight[] }) {
    if (!props.insights || props.insights.length === 0) {
        return null
    }

    return (
        <>
            {props.insights.map((insight) => (
                <FilterPill
                    key={insight.field_name}
                    icon={faLightbulb}
                    text={
                        <span>
                            {insight.field_name}{" "}
                            <span className="font-normal">is any of</span>{" "}
                            {insight.values.join(", ")}
                        </span>
                    }
                />
            ))}
        </>
    )
}

function IncludeCallsPill(props: { includedCalls?: CallsToInclude }) {
    if (!props.includedCalls || props.includedCalls === CallsToInclude.ALL) {
        return null
    }

    const isInternal = props.includedCalls === CallsToInclude.INTERNAL
    const icon = isInternal ? faUsers : faGlobe
    const text = isInternal ? "Internal calls" : "External calls"

    return <FilterPill icon={icon} text={text} />
}
