import { useCallback } from "react"
import clsx from "clsx"

interface TaskDescriptionProps {
    description: string
    isCompleted: boolean
    isExpanded: boolean
}

export function TaskDescription(props: TaskDescriptionProps) {
    // TaskDescription renders a task's description text with optional strikethrough styling
    // for completed tasks. If the text is truncated due to overflow, it adds a tooltip
    // showing the full description on hover.
    const setTooltipIfNeeded = useCallback(
        (el: HTMLSpanElement | null) => {
            if (!el) return

            const isTextTruncated = el.scrollWidth > el.clientWidth
            if (isTextTruncated) {
                el.setAttribute("data-tooltip-id", "tooltip-explanation")
                el.setAttribute("data-tooltip-content", props.description)
            }
        },
        [props.description]
    )

    return (
        <span
            className={clsx(
                props.isExpanded || "truncate",
                props.isCompleted
                    ? "text-gray-400 line-through"
                    : "text-gray-900"
            )}
            ref={setTooltipIfNeeded}
        >
            {props.description}
        </span>
    )
}
