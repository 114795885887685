import React, { useState, useRef, useEffect, useMemo } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTag, faXmark, faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { stringToColour } from "../../utils/stringToColour"
import { ICallTag } from "../../types/Call"

export const groupTags = (tags: ICallTag[]): Record<string, ICallTag[]> => {
    return tags.reduce((groups, tag) => {
        const groupName = tag.group || "ungrouped"
        groups[groupName] = groups[groupName] || []
        groups[groupName].push(tag)
        return groups
    }, {} as Record<string, ICallTag[]>)
}

// Type that ensures name is required while other properties remain optional
type TagWithName = Partial<ICallTag> & { name: string }

interface TagInputProps {
    existingTags: ICallTag[]
    allTags: ICallTag[]
    onAddTag: (tag: TagWithName) => void
    onCancel: () => void
}

export function TagInput({
    onAddTag,
    onCancel,
    existingTags,
    allTags,
}: TagInputProps) {
    const [inputValue, setInputValue] = useState("")
    const [showSuggestions, setShowSuggestions] = useState(true)
    const inputRef = useRef<HTMLInputElement>(null)

    // Get list of existing tag IDs
    const existingTagIds = existingTags.map((tag) => tag.id)

    // Sort available tags into groups, filtering out existing tags
    const availableTags = allTags.filter(
        (tag) => !existingTagIds.includes(tag.id)
    )

    // Group tags for display
    const groupedTags = useMemo(() => groupTags(availableTags), [availableTags])

    // Filter groups based on input
    const filteredGroupedTags: Record<string, ICallTag[]> = {}
    if (inputValue.trim()) {
        Object.entries(groupedTags).forEach(([group, tags]) => {
            const filtered = tags.filter(
                (tag) =>
                    tag.name.toLowerCase().includes(inputValue.toLowerCase()) ||
                    (tag.description &&
                        tag.description
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())) ||
                    group.toLowerCase().includes(inputValue.toLowerCase())
            )
            if (filtered.length > 0) {
                filteredGroupedTags[group] = filtered
            }
        })
    } else {
        Object.assign(filteredGroupedTags, groupedTags)
    }

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)
    }

    const handleInputFocus = () => {
        setShowSuggestions(true)
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter" && inputValue.trim()) {
            // When user manually enters a tag name, we just pass the name
            onAddTag({ name: inputValue.trim() })
        } else if (e.key === "Escape") {
            e.preventDefault()
            onCancel()
        }
    }

    const handleSuggestionClick = (tag: ICallTag) => {
        // When user selects a tag from suggestions, we pass the entire tag object
        onAddTag(tag)
    }

    return (
        <div className="relative">
            <div className="flex items-center rounded-md border border-gray-200 bg-gray-100 px-2 shadow-sm">
                <FontAwesomeIcon
                    icon={faTag}
                    className="ml-1 mr-1 text-sm text-gray-400"
                />
                <input
                    ref={inputRef}
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    onKeyDown={handleKeyDown}
                    className="h-full flex-grow bg-transparent px-1 py-0.5 text-sm focus:outline-none"
                    placeholder="Search or add a new tag..."
                />
                <button
                    className="h-full p-0.5 text-gray-400 hover:text-gray-600 focus:outline-none"
                    onClick={onCancel}
                >
                    <FontAwesomeIcon icon={faXmark} size="xs" />
                </button>
            </div>
            {showSuggestions && (
                <div className="absolute z-10 mt-1 max-h-60 w-72 overflow-y-auto rounded-md border border-gray-200 bg-white shadow-lg">
                    {Object.keys(filteredGroupedTags).length === 0 && (
                        <div className="px-3 py-2 text-sm text-gray-500">
                            No matching tags found, press Enter to create a new
                            tag.
                        </div>
                    )}

                    {Object.entries(filteredGroupedTags).map(
                        ([group, tags]) => {
                            if (tags.length === 0) return null

                            const isUngrouped = group === "ungrouped"

                            const tagToReplace = isUngrouped
                                ? undefined
                                : existingTags.find(
                                      (tag) => tag.group === group
                                  )

                            return (
                                <div
                                    key={group}
                                    className="border-b border-gray-100 last:border-b-0"
                                >
                                    {!isUngrouped && (
                                        <div className="sticky top-0 bg-gray-50 px-3 py-1 text-xs font-medium text-gray-700">
                                            {group}
                                            {tagToReplace && (
                                                <div className="flex items-center text-amber-600">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        size="sm"
                                                        className="mr-1"
                                                    />
                                                    <span className="text-[10px]">
                                                        Will replace{" "}
                                                        <span className="font-extrabold">
                                                            {tagToReplace.name}
                                                        </span>{" "}
                                                        tag
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <ul>
                                        {(isUngrouped
                                            ? [...tags].sort((a, b) =>
                                                  a.name.localeCompare(b.name)
                                              ) // Sort ungrouped tags alphabetically
                                            : tags
                                        ).map((tag) => (
                                            <li
                                                key={tag.id}
                                                className="flex cursor-pointer items-center px-3 py-1.5 text-sm transition-colors duration-150 ease-in-out hover:bg-gray-100"
                                                onClick={() =>
                                                    handleSuggestionClick(tag)
                                                }
                                            >
                                                <span
                                                    className="mr-1.5 h-2 w-2 rounded-full"
                                                    style={{
                                                        backgroundColor:
                                                            stringToColour(
                                                                tag.name
                                                            )[500],
                                                    }}
                                                ></span>
                                                <div>
                                                    <div>{tag.name}</div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )
                        }
                    )}
                </div>
            )}
        </div>
    )
}
