import {
    FiltersMessageBlock,
    isCitationMessageBlock,
    isTextMessageBlock,
    LoadingDocsMessageBlock,
    Turn,
    DisplayMessageBlock,
    MessageBlock,
} from "./types/ThreadTypes"
import { UserMessage, AiMessage } from "./Message"
import { AnswerFeedback } from "./types/QuestionTypes"
import { getFullTextFromBlocks } from "./utils/getFullTextFromBlocks"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLightbulb, faFolder } from "@fortawesome/pro-light-svg-icons"
import { faBrain, faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { hasAnyFilters, FiltersContainer } from "../shared/FilterComponents"

export function AskGlyphicThread(props: {
    turns: Turn[]
    receivingResponse: boolean
    submitFeedback: (feedback: AnswerFeedback) => Promise<void>
    isReadOnly?: boolean
    sendEmail: (message: string) => Promise<void>
}) {
    /**
     * For rendering, we're going to separate some block types as:
     * - Notification blocks: render at the top of the message and disappear
     *   once closed
     * - Informational blocks: render at the top of the message and persist
     * - Content blocks: render as they come
     */
    return (
        <div className="space-y-3">
            {props.turns.map((turn, i) => {
                if (turn.role === "human") {
                    const textContent = getFullTextFromBlocks(turn.blocks)
                    return (
                        <UserMessage key={i + "user"} message={textContent} />
                    )
                }

                const contentBlocks = turn.blocks.filter(
                    (block) =>
                        isTextMessageBlock(block) ||
                        isCitationMessageBlock(block)
                ) as DisplayMessageBlock[]

                // Check if the AI turn contains a memory-updated block
                const isMemoryUpdated = turn.blocks.some(
                    (block) => block.type === "memory-updated"
                )

                return (
                    <div
                        key={i + "ai"}
                        className="flex animate-fadeInUp flex-col gap-1"
                    >
                        <InformationalBlocks
                            blocks={turn.blocks}
                            isLoading={contentBlocks.length === 0}
                        />

                        <AiMessage
                            messageBlocks={contentBlocks}
                            isComplete={
                                !props.receivingResponse ||
                                i < props.turns.length - 1
                            }
                            isLast={i === props.turns.length - 1}
                            submitFeedback={props.submitFeedback}
                            isReadOnly={props.isReadOnly}
                            sendEmail={props.sendEmail}
                        />

                        {isMemoryUpdated && <MemoryUpdatedNotice />}
                    </div>
                )
            })}
        </div>
    )
}

function InformationalBlocks(props: {
    blocks: MessageBlock[]
    isLoading: boolean
}) {
    const filterBlock = props.blocks.find(
        (block) => block.type === "filters"
    ) as FiltersMessageBlock | undefined
    const lookingForDocuments = filterBlock && !filterBlock.closed
    const hasFilters =
        filterBlock?.closed && hasAnyFilters(filterBlock?.filters)

    const loadingDocsBlock = props.blocks.find(
        (block) => block.type === "loading-docs" && !block.closed
    ) as LoadingDocsMessageBlock | undefined

    const showThinkingPill =
        props.isLoading && !lookingForDocuments && !loadingDocsBlock

    return (
        <>
            {lookingForDocuments && <LookingForCallsPill />}
            {loadingDocsBlock && <LoadingDocsPill />}
            {showThinkingPill && <ThinkingPill />}
            {hasFilters && (
                <div className="flex inline-flex flex-row items-center gap-1 py-1.5 text-sm font-medium text-gray-800">
                    <FiltersContainer filters={filterBlock.filters} />
                </div>
            )}
        </>
    )
}

function ThinkingPill() {
    return <SimplePill icon={faLightbulb} text="Thinking..." />
}

function LookingForCallsPill() {
    return (
        <SimplePill
            icon={faMagnifyingGlass}
            text="Looking for relevant calls..."
        />
    )
}

function LoadingDocsPill() {
    return <SimplePill icon={faFolder} text="Loading data..." />
}

function SimplePill(props: { icon: IconProp; text: string }) {
    return (
        <div className="flex inline-flex animate-pulse flex-row items-center gap-1 py-1.5 text-sm font-medium text-gray-800">
            <FontAwesomeIcon icon={props.icon} />
            <span className="whitespace-nowrap text-xs font-semibold">
                {props.text}
            </span>
        </div>
    )
}
function MemoryUpdatedNotice() {
    return (
        <p
            className="ml-1 whitespace-nowrap text-right text-[10px] text-gray-500"
            data-tooltip-id="memory-updated"
        >
            <FontAwesomeIcon icon={faBrain} className="mr-1" />
            Memory updated
        </p>
    )
}
