import { Tooltip } from "react-tooltip"

export function CitationTooltip() {
    return (
        <Tooltip
            id="citation-tooltip"
            className="z-[60] !rounded-md !border !border-gray-200 !bg-white !text-gray-800 !opacity-100 !shadow-lg"
            style={{
                maxWidth: "320px",
                padding: "8px",
            }}
            clickable
            place="top"
            render={({ activeAnchor }) => {
                if (!activeAnchor) return null

                const title = activeAnchor.getAttribute("data-title")
                const content = activeAnchor.getAttribute("data-content")

                if (!title) return null

                // This is a similar render to the CitationCard component, but
                // truncated to 3 lines.
                return (
                    <div className="flex flex-col gap-1">
                        <div className="text-sm font-medium">{title}</div>
                        {content && (
                            <div className="line-clamp-3 text-sm text-gray-600">
                                {content}
                            </div>
                        )}
                    </div>
                )
            }}
        />
    )
}
