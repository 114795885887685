import {
    ColumnDef,
    Row,
    SortingState,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from "@tanstack/react-table"
import {
    ICompanyTableCallPreview,
    ICompanyTableEntry,
} from "../../types/Company"
import { useMemo } from "react"
import DetailsTableHeaderGroup from "../calls-list/DetailsTableHeaderGroup"
import { PageNavigator } from "../calls-list/PageNavigator"
import { getSimpleDate } from "../../utils/datetime"
import { ScreenSize, screenLargerOrEqualTo } from "../../utils/screenSize"
import { CompanyLogo } from "./CompanyLogo"
import { NavigateLink } from "../common/NavigateLink"
import { ParticipantsList } from "../calls-list/ParticipantsList"
import { IPaginationDetails } from "../../types/pagination"

export function CompaniesTable(props: {
    companies: ICompanyTableEntry[]
    filterTerm: string
    pagination: IPaginationDetails
    currentPage: number
    sorting: SortingState
    onSortingChange: (sorting: SortingState) => void
    setPageParam: (newValue: string) => void
}) {
    const columns = useMemo<ColumnDef<ICompanyTableEntry, any>[]>(() => {
        return [
            {
                id: "logo",
                accessorFn: (row) => row.image_url,
                header: "",
                cell: (info) => <CompanyLogo image_url={info.getValue()} />,
                enableSorting: false,
                size: 10,
                enableGlobalFilter: false,
            },
            {
                id: "name",
                accessorFn: (row) => row.name || row.domain,
                header: "Name",
                cell: (info) => (
                    <span className="block overflow-hidden overflow-ellipsis font-bold">
                        {info.getValue()}
                    </span>
                ),
                sortingFn: "text",
                enableSorting: true,
                enableGlobalFilter: true,
            },
            {
                id: "people",
                accessorFn: (row) => row.people,
                header: "People",
                cell: (info) => (
                    <ParticipantsList
                        parties={info.getValue()}
                        maxUserCircles={
                            screenLargerOrEqualTo(ScreenSize.md) ? 3 : 1
                        }
                    />
                ),
                size: 30,
                enableGlobalFilter: false,
                enableSorting: false,
            },
            {
                id: "last_call",
                accessorFn: (row) => row.last_call,
                header: "Last call date",
                cell: (info) => (
                    <CallPreview call={info.getValue()} showTitle={false} />
                ),
                sortingFn: sortLastCall,
                size: 40,
                enableGlobalFilter: false,
                enableSorting: false,
            },
            {
                id: "next_call",
                accessorFn: (row) => row.next_call,
                header: "Upcoming call",
                cell: (info) => (
                    <CallPreview call={info.getValue()} showTitle={true} />
                ),
                sortingFn: sortNextCall,
                enableGlobalFilter: false,
                enableSorting: false,
            },
        ]
    }, [])

    const showAllColumns: boolean = screenLargerOrEqualTo(ScreenSize.md)

    const table = useReactTable({
        data: props.companies,
        columns,

        enableSorting: true,

        state: {
            columnVisibility: {
                latest_call_timestamp: showAllColumns,
            },
            globalFilter: props.filterTerm,
            sorting: props.sorting,
        },
        getCoreRowModel: getCoreRowModel(),
        globalFilterFn: "includesString",

        onSortingChange: (updater) => {
            const newSorting =
                updater instanceof Function ? updater(props.sorting) : updater
            props.onSortingChange(newSorting)
            props.setPageParam("1") // Reset page to 1 when sorting changes
        },
    })

    return (
        <div>
            <div className="text-base">
                <table className="w-full table-fixed border-separate border-spacing-y-2 p-2">
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <DetailsTableHeaderGroup
                                key={headerGroup.id}
                                headerGroup={headerGroup}
                            />
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row) => (
                            <CompanyRow key={row.id} row={row} />
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="mt-4">
                <PageNavigator
                    currentPage={props.currentPage}
                    setCurrentPage={() => {}} // PageNavigator handles URL params internally
                    pageCount={props.pagination.page_count}
                />
            </div>
        </div>
    )
}

function CallPreview(props: {
    call: ICompanyTableCallPreview | null
    showTitle: boolean
}) {
    if (!props.call) {
        return <></>
    }
    return (
        <span className="block space-x-2 overflow-hidden overflow-ellipsis whitespace-nowrap">
            <span>{getSimpleDate(props.call.time)}</span>
            {props.showTitle && props.call.title && (
                <span className="text-gray-500">{props.call.title}</span>
            )}
        </span>
    )
}

function sortLastCall(
    rowA: Row<ICompanyTableEntry>,
    rowB: Row<ICompanyTableEntry>,
    columnId: string
) {
    return sortCallPreview(rowA, rowB, columnId, -1)
}

function sortNextCall(
    rowA: Row<ICompanyTableEntry>,
    rowB: Row<ICompanyTableEntry>,
    columnId: string
) {
    return sortCallPreview(rowA, rowB, columnId, 1)
}

function sortCallPreview(
    rowA: Row<ICompanyTableEntry>,
    rowB: Row<ICompanyTableEntry>,
    columnId: string,
    sortNull: 1 | -1
): number {
    const valA: ICompanyTableCallPreview | null = rowA.getValue(columnId)
    const valB: ICompanyTableCallPreview | null = rowB.getValue(columnId)

    if (valA === null && valB === null) {
        return 0
    } else if (valA === null) {
        return sortNull
    } else if (valB === null) {
        return sortNull * -1
    } else {
        return valA.time.localeCompare(valB.time)
    }
}

export function CompanyRow(props: { row: Row<ICompanyTableEntry> }) {
    const href = `/companies/${props.row.original.domain}`

    return (
        <tr
            className="h-16 rounded-xl border-b bg-white shadow-md shadow-gray-100 transition-colors
                    ease-out last:border-0 hover:cursor-pointer hover:bg-slate-100"
        >
            {props.row.getVisibleCells().map((cell) => (
                <td
                    key={cell.id}
                    className="overflow-y-clip first:rounded-l-xl last:rounded-r-xl"
                >
                    <NavigateLink
                        href={href}
                        className="flex h-full w-full items-center p-2"
                    >
                        {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                        )}
                    </NavigateLink>
                </td>
            ))}
        </tr>
    )
}
