import SuperTokens from "supertokens-auth-react"
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui"
import { ThirdPartyEmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/thirdpartyemailpassword/prebuiltui"
import { Routes, BrowserRouter, Route } from "react-router-dom"
import * as reactRouterDom from "react-router-dom"
import { AuthPage, SuperTokensConfig } from "./config"
import Call from "./components/call-page/Call"
import { WithNavbarLayout } from "./components/NavbarLayout"
import { CallsList } from "./components/calls-list/CallsList"
import { About } from "./components/about/About"
import { GoogleApiDisclosure } from "./components/about/GoogleApiDisclosure"
import { TermsOfService } from "./components/about/TermsOfService"
import { Support } from "./components/about/Support"
import { ZoomDocumentation } from "./components/about/ZoomDocumentation"
import { SharedCall } from "./components/call-page/SharedCall"
import { Schedule } from "./components/schedule/Schedule"
import { CallPrepTempPage } from "./components/call-prep/CallPrepTempPage"
import { DealsList } from "./components/deals/DealsList"
import { NotificationProvider } from "./providers/NotificationProvider"
import { Company } from "./components/company-view/Company"
import { Companies } from "./components/company-view/CompaniesList"
import { SuperTokensProviders } from "./SuperTokensProviders"
import { CallPrepPage } from "./components/call-prep/CallPrepPage"
import { QueryClientProvider } from "@tanstack/react-query"
import { ActivityPage } from "./components/activity/ActivityPage"
import { Home } from "./components/home-page/Home"
import OrgView from "./components/OrgView"
import { SessionAuth } from "supertokens-auth-react/recipe/session"
import { queryClient } from "./utils/reactQueryClient"
import { StrategicInsights } from "./components/strategic-insights/StrategicInsights"
import { useUser } from "./providers/UserProvider"
import { Permission } from "./types/Permission"
import { ErrorPage } from "./components/common/ErrorPage"
import { hasPermission } from "./utils/Permissions"
import {
    CREATE_INSIGHT_PATH,
    CreateInsightPage,
} from "./components/settings/insights/CreateInsights"
import { TasksPage } from "./components/tasks/TasksPage"
import { FeatureFlags, canUseFeature } from "./utils/FeatureFlags"
import { PersonalPage } from "./components/settings/page/PersonalPage"
import { NotificationsPage } from "./components/settings/page/NotificationsPage"
import { QuickActionsPage } from "./components/settings/page/QuickActionsPage"
import { CallTagsPage } from "./components/settings/page/CallTagsPage"
import { InsightsPage } from "./components/settings/page/InsightsPage"
import { OrganisationalPage } from "./components/settings/page/OrganisationalPage"
import { UserManagementPage } from "./components/settings/page/UserManagementPage"
import { RootSettingsRedirect } from "./components/settings/RootSettingsRedirect"

SuperTokens.init(SuperTokensConfig)

function App() {
    return (
        <SuperTokensProviders>
            <QueryClientProvider client={queryClient}>
                <NotificationProvider>
                    <BrowserRouter>
                        <Routes>
                            {/* This shows the login UI on "/auth" route */}
                            {getSuperTokensRoutesForReactRouterDom(
                                reactRouterDom,
                                [ThirdPartyEmailPasswordPreBuiltUI]
                            )}
                            <Route
                                path="/"
                                element={
                                    /* This protects the "/" route so that it shows
                                    <Home /> only if the user is logged in.
                                    Else it redirects the user to "/auth" */
                                    <SessionAuth>
                                        <OrgView />
                                    </SessionAuth>
                                }
                            />
                            <Route
                                path="/home"
                                element={
                                    <WithNavbarLayout title="Home">
                                        <Home />
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/calls"
                                element={
                                    <WithNavbarLayout title="Calls">
                                        <CallsList />
                                    </WithNavbarLayout>
                                }
                            />
                            <Route path="/auth" element={<AuthPage />} />
                            <Route
                                path="/deals"
                                element={
                                    <WithNavbarLayout title="Deals">
                                        <ProtectedRoute
                                            requiredPermission={
                                                Permission.VIEW_OTHERS_CALLS
                                            }
                                        >
                                            <DealsList />
                                        </ProtectedRoute>
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/companies"
                                element={
                                    <WithNavbarLayout title="Companies">
                                        <ProtectedRoute
                                            requiredPermission={
                                                Permission.VIEW_OTHERS_CALLS
                                            }
                                        >
                                            <Companies />
                                        </ProtectedRoute>
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/companies/:domain"
                                element={
                                    <WithNavbarLayout title="Company Details">
                                        <ProtectedRoute
                                            requiredPermission={
                                                Permission.VIEW_OTHERS_CALLS
                                            }
                                        >
                                            <Company />
                                        </ProtectedRoute>
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/calls/:call_id"
                                element={
                                    <WithNavbarLayout title="Call Details">
                                        <Call />
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/schedule"
                                element={
                                    <WithNavbarLayout title="Schedule">
                                        <Schedule />
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/tasks"
                                element={
                                    <WithNavbarLayout title="Tasks">
                                        <TasksPage />
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/activity"
                                element={
                                    <WithNavbarLayout title="Activity">
                                        <ProtectedRoute
                                            requiredPermission={
                                                Permission.VIEW_ACTIVITY_PAGE
                                            }
                                        >
                                            <ActivityPage />
                                        </ProtectedRoute>
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/shared/calls/:shared_call_id"
                                element={<SharedCall />}
                            />
                            <Route
                                path="/call-prep-test"
                                element={
                                    <WithNavbarLayout title="Call Preparation">
                                        <CallPrepTempPage />
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/call-prep/:prep_sheet_id"
                                element={
                                    <WithNavbarLayout title="Call Preparation">
                                        <CallPrepPage />
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/settings/*"
                                element={
                                    <WithNavbarLayout title="Settings">
                                        <Routes>
                                            <Route
                                                path="/"
                                                element={
                                                    <RootSettingsRedirect />
                                                }
                                            />
                                            <Route
                                                path="/personal"
                                                element={<PersonalPage />}
                                            />
                                            <Route
                                                path="/notifications"
                                                element={<NotificationsPage />}
                                            />
                                            <Route
                                                path="/quick-actions"
                                                element={<QuickActionsPage />}
                                            />
                                            <Route
                                                path="/insights"
                                                element={<InsightsPage />}
                                            />
                                            <Route
                                                path="/call-tags"
                                                element={<CallTagsPage />}
                                            />
                                            <Route
                                                path="/organizational"
                                                element={
                                                    <ProtectedRoute
                                                        requiredPermission={
                                                            Permission.VIEW_EDIT_ORG_SETTINGS
                                                        }
                                                    >
                                                        <OrganisationalPage />
                                                    </ProtectedRoute>
                                                }
                                            />
                                            <Route
                                                path="/user-management"
                                                element={<UserManagementPage />}
                                            />
                                        </Routes>
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/insights"
                                element={
                                    <WithNavbarLayout title="Strategic Insights">
                                        <ProtectedRoute
                                            requiredPermission={
                                                Permission.VIEW_OTHERS_CALLS
                                            }
                                        >
                                            <StrategicInsights />
                                        </ProtectedRoute>
                                    </WithNavbarLayout>
                                }
                            />
                            <Route
                                path="/about/*"
                                element={
                                    <Routes>
                                        <Route path="/" element={<About />} />
                                        <Route
                                            path="google-api-disclosure"
                                            element={<GoogleApiDisclosure />}
                                        />
                                        <Route
                                            path="terms-of-service"
                                            element={<TermsOfService />}
                                        />
                                        <Route
                                            path="support"
                                            element={<Support />}
                                        />
                                        <Route
                                            path="zoom-documentation"
                                            element={<ZoomDocumentation />}
                                        />
                                    </Routes>
                                }
                            />
                            {/* Not visible in the navbar */}
                            <Route
                                path={CREATE_INSIGHT_PATH}
                                element={
                                    <WithNavbarLayout title="Create Insight">
                                        <ProtectedRoute
                                            requiredPermission={
                                                Permission.EDIT_CUSTOM_INSIGHTS
                                            }
                                        >
                                            <CreateInsightPage />
                                        </ProtectedRoute>
                                    </WithNavbarLayout>
                                }
                            />
                        </Routes>
                    </BrowserRouter>
                </NotificationProvider>
            </QueryClientProvider>
        </SuperTokensProviders>
    )
}

function ProtectedRoute(props: {
    requiredPermission: Permission
    children: React.ReactNode
}) {
    const user = useUser()
    const hasPermissions = user && hasPermission(user, props.requiredPermission)

    if (!hasPermissions) {
        return (
            <ErrorPage
                error={{
                    code: 403,
                    message:
                        "Sorry, you don't have the necessary permissions to view this page.",
                }}
            />
        )
    }

    return <>{props.children}</>
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function UnreleasedRoute(props: {
    requiredFeatureFlag: FeatureFlags
    children: React.ReactNode
}) {
    const user = useUser()
    const hasFeatureFlag =
        user && canUseFeature(props.requiredFeatureFlag, user.organizationId)

    if (!hasFeatureFlag) {
        return <ErrorPage error={{ code: 404, message: "Page not found" }} />
    }

    return <>{props.children}</>
}

export default App
