import { createQueryKeys } from "@lukemorales/query-key-factory"
import axios from "axios"
import { ICRMDeal } from "../../types/Deal"
import { ICompany, ICompanyTableEntry } from "../../types/Company"
import { IPaginatedResponse } from "../../types/pagination"

export const companyQueries = createQueryKeys("companies", {
    byDomain: (companyDomain: string) => ({
        queryKey: ["companies", companyDomain],
        queryFn: async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/companies/${companyDomain}`
            )
            return response.data as ICompany
        },
    }),

    deals: (companyDomain: string) => ({
        queryKey: ["companies/deals", companyDomain],
        queryFn: async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/companies/${companyDomain}/deals`
            )
            return response.data as ICRMDeal[]
        },
    }),

    dealsFromCrm: (companyDomain: string) => ({
        queryKey: ["companies/crm/deals", companyDomain],
        queryFn: async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/companies/${companyDomain}/crm/deals`
            )
            return response.data as ICRMDeal[]
        },
    }),

    listAll: (
        page_number: number,
        company_name?: string,
        order_by_desc: boolean = false
    ) => ({
        queryKey: ["companies", page_number, company_name, order_by_desc],
        queryFn: async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/companies`,
                {
                    params: {
                        page_number,
                        company_name,
                        order_by_desc,
                    },
                }
            )
            return response.data as IPaginatedResponse<ICompanyTableEntry>
        },
    }),
})
