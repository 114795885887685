export interface ISearchFilterUser {
    id: string
    email: string
}

export interface ISearchFilterTag {
    id: string
    name: string
}

export interface ISearchFilterInsight {
    field_name: string
    values: string[]
}

export enum CallsToInclude {
    ALL = "all",
    INTERNAL = "internal",
    EXTERNAL = "external",
}

export interface ISearchFilters {
    since_date?: string | null
    until_date?: string | null
    users?: ISearchFilterUser[]
    tags?: ISearchFilterTag[]
    insights?: ISearchFilterInsight[]
    include_calls?: CallsToInclude
}

export interface ISearchRequest {
    query: string
    filters: ISearchFilters
}

export enum SearchResultType {
    CALL = "call",
    COMPANY = "company",
}

export interface ISearchResultHighlightElement {
    value: string
    type: "text" | "hit"
}

export interface ISearchResultHighlight {
    field_name: string
    path: string
    score: number
    texts: ISearchResultHighlightElement[]
}

export interface ISearchResult {
    id: string
    score: number
    highlight: ISearchResultHighlight[]
    type: SearchResultType
}

export interface ICallSearchResultMetadata {
    title: string | null
    start_time: string | null
}

export interface ICallSearchResult extends ISearchResult {
    call_metadata: ICallSearchResultMetadata
}

export interface ICompanySearchResult extends ISearchResult {
    primary_domain: string
    name: string | null
    image_url: string | null
}
