import { faChevronLeft, faSearch } from "@fortawesome/free-solid-svg-icons"
import { faPaperPlaneAlt } from "@fortawesome/pro-regular-svg-icons"
import { faSparkles } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useDebounce } from "@uidotdev/usehooks"
import axios from "axios"
import clsx from "clsx"
import { useCallback, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import ScrollToBottom from "react-scroll-to-bottom"
import { RecordingIcon } from "../../assets/icons/RecordingIcon"
import {
    ICallSearchResult,
    ICompanySearchResult,
    ISearchFilters,
    ISearchResult,
    ISearchResultHighlight,
    ISearchResultHighlightElement,
    SearchResultType,
} from "../../types/search"
import { sendFollowUpEmail } from "../../utils/createEmailLink"
import { getFormattedDateTime } from "../../utils/datetime"
import { getHeapInstance } from "../../utils/heap"
import { getCacheValue, setCacheValue } from "../../utils/localStorageCache"
import { PriorityButton } from "../common/Buttons"
import { Modal } from "../common/Modal"
import { CompanyLogo } from "../company-view/CompanyLogo"
import { AskGlyphicThread } from "../question-answering/AskGlyphicThread"
import { useFeedbackApi } from "../question-answering/hooks/useFeedbackApi"
import { useThread } from "../question-answering/hooks/useThread"
import { Thread, Turn } from "../question-answering/types/ThreadTypes"
import { SearchEmptyState } from "./SearchEmptyState"
import { SearchFilters } from "./SearchFilters"
import { updateLastAiTurnBlocks } from "../question-answering/utils/updateLastAiTurnBlocks"
import { createUserBlock } from "../question-answering/utils/blocks/createUserBlock"
import { FiltersContainer } from "../shared/FilterComponents"
import { PageTitle } from "../PageTitle"

const LAST_QUESTION_CACHE_KEY = "global-last-question"

export function SearchModal(props: {
    isOpen: boolean
    onClose: () => void
    initialFilters: ISearchFilters | undefined
}) {
    const navigate = useNavigate()

    function navigateTo(url: string, e?: React.MouseEvent<HTMLAnchorElement>) {
        if (e) e.preventDefault() // Disallow default <a> href navigation

        // Navigate to a search result's page
        if (e && (e.ctrlKey || e.metaKey)) {
            window.open(url, "_blank")
        } else {
            props.onClose() // Only close the modal if the user is not opening in a new tab
            navigate(url)
        }
    }

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} noCloseButton>
            <PageTitle title="Search" />
            <Search
                navigateTo={navigateTo}
                initialFilters={props.initialFilters}
            />
        </Modal>
    )
}

function Search(props: {
    navigateTo: (url: string, e?: React.MouseEvent<HTMLAnchorElement>) => void
    initialFilters: ISearchFilters | undefined
}) {
    const [askGlyphicThreadId, setAskGlyphicThreadId] = useState<string | null>(
        null
    )
    const [askGlyphicThreadTurns, setAskGlyphicThreadTurns] = useState<Turn[]>(
        []
    )
    const [askGlyphicError, setAskGlyphicError] = useState<string | null>(null)
    const [askGlyphicFilters, setAskGlyphicFilters] = useState<ISearchFilters>()

    const {
        createThread: createAskGlyphicThread,
        sendMessage: sendAskGlyphicMessage,
        rerunThread: rerunAskGlyphicThread,
        isLoading: receivingAskGlyphicResponse,
        responseBlocks: askGlyphicResponseBlocks,
    } = useThread({
        onStreamError: (error) => {
            setAskGlyphicError("Failed to generate a response")
            setAskGlyphicThreadTurns((prev) => prev.slice(0, -1))
            console.error(error)
        },
    })

    useEffect(() => {
        if (receivingAskGlyphicResponse) {
            setAskGlyphicError(null)
            setAskGlyphicThreadTurns((prev) => [
                ...prev,
                {
                    role: "ai",
                    blocks: [],
                },
            ])
        }
    }, [receivingAskGlyphicResponse])

    useEffect(() => {
        setAskGlyphicThreadTurns((prev) =>
            updateLastAiTurnBlocks(prev, askGlyphicResponseBlocks)
        )
    }, [askGlyphicResponseBlocks])

    const submitMessage = useCallback(
        async (threadId: string, message: string) => {
            setAskGlyphicThreadTurns((prev) => [
                ...prev,
                {
                    role: "human",
                    blocks: [createUserBlock(message)],
                },
            ])
            await sendAskGlyphicMessage(threadId, message)
        },
        [sendAskGlyphicMessage]
    )

    const startAskGlyphicThread = async (
        initialMessage: string,
        filters: ISearchFilters
    ) => {
        const thread = await createAskGlyphicThread({ filters })
        setAskGlyphicThread(thread)
        submitMessage(thread.id, initialMessage)
        setCacheValue(LAST_QUESTION_CACHE_KEY, initialMessage)
        getHeapInstance()?.track("ask-glyphic-global", {
            initialMessage: initialMessage,
            sinceDate: filters.since_date,
        })
    }
    const setAskGlyphicThread = (thread: Thread | null) => {
        setAskGlyphicThreadId(thread?.id || null)
        setAskGlyphicThreadTurns(thread?.turns || [])
        setAskGlyphicError(null)
        setAskGlyphicFilters(thread?.filters || undefined)
    }

    if (askGlyphicThreadId) {
        return (
            <GlobalAskGlyphicThread
                threadId={askGlyphicThreadId}
                turns={askGlyphicThreadTurns}
                filters={askGlyphicFilters}
                receivingResponse={receivingAskGlyphicResponse}
                setThread={setAskGlyphicThread}
                submitMessage={submitMessage}
                rerunThread={rerunAskGlyphicThread}
                error={askGlyphicError}
            />
        )
    }

    return (
        <SearchView
            navigateTo={props.navigateTo}
            setThread={setAskGlyphicThread}
            startThread={startAskGlyphicThread}
            initialFilters={props.initialFilters}
        />
    )
}

function SearchView({
    navigateTo,
    startThread,
    setThread,
    initialFilters,
}: {
    navigateTo: (url: string, e?: React.MouseEvent<HTMLAnchorElement>) => void
    startThread: (initialMessage: string, filters: ISearchFilters) => void
    setThread: (thread: Thread | null) => void
    initialFilters: ISearchFilters | undefined
}) {
    const queryClient = useQueryClient()
    const inputRef = useRef<HTMLInputElement>(null)
    const [searchFilters, setSearchFilters] = useState<ISearchFilters>(
        initialFilters || {}
    )
    const [searchTerm, setSearchTerm] = useState("")
    // Debounce the search term to avoid making too many requests between keystrokes
    const debounceTimeMs = 200
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeMs)

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "ArrowUp" && !searchTerm.trim()) {
                const lastQuestion = getCacheValue(LAST_QUESTION_CACHE_KEY, "")
                if (lastQuestion) {
                    setSearchTerm(lastQuestion)
                    // Prevent default to avoid cursor moving to start of input
                    e.preventDefault()
                }
            }
        }

        document.addEventListener("keydown", handleKeyDown)
        return () => document.removeEventListener("keydown", handleKeyDown)
    }, [searchTerm])

    useEffect(() => {
        // Focus the input when the component mounts
        if (inputRef.current) {
            inputRef.current.focus()
        }

        // Invalidate queries, so they are not stale when the component is re-opened
        return () => {
            queryClient.removeQueries({ queryKey: ["search/"] })
        }
    }, [queryClient])

    const { data: results, isPending } = useQuery<ISearchResult[]>({
        queryKey: ["search/", debouncedSearchTerm, searchFilters],
        queryFn: async () => {
            const results = await axios.post(
                `${process.env.REACT_APP_API_DOMAIN}/search`,
                {
                    query: debouncedSearchTerm,
                    filters: searchFilters,
                }
            )
            getHeapInstance()?.track("search-query", {
                query: debouncedSearchTerm,
                resultCount: results.data.length,
            })
            return results.data
        },
        // Never refetch queries while component is open. They are cleared on component unmount
        staleTime: Infinity,
    })

    const startThreadWithFilters = useCallback(
        (initialMessage: string) => {
            startThread(initialMessage, searchFilters)
        },
        [startThread, searchFilters]
    )

    return (
        <div className="flex h-[600px] w-[800px] max-w-full flex-col">
            <div className="flex w-full flex-col items-start divide-y-[1px] px-4">
                <div className="flex w-full flex-row items-center py-2">
                    <FontAwesomeIcon
                        icon={faSearch}
                        className="text-gray-500"
                    />
                    <input
                        id="search-input"
                        ref={inputRef}
                        type="text"
                        className="w-full border-none p-3 focus:outline-none"
                        placeholder="Search or just ask a question..."
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value)
                        }}
                        autoComplete="off"
                        autoFocus
                    />
                </div>
                <div className="flex w-full flex-row flex-wrap items-center gap-2 py-2">
                    <SearchFilters
                        searchFilters={searchFilters}
                        setSearchFilters={setSearchFilters}
                    />
                </div>
            </div>
            <div className="flex flex-grow flex-col space-y-4 overflow-auto px-4 py-6">
                {searchTerm.trim() ? (
                    <SearchContent
                        searchTerm={searchTerm}
                        isPending={isPending}
                        results={results || []}
                        navigateTo={navigateTo}
                        debouncedSearchTerm={debouncedSearchTerm}
                        startThread={startThreadWithFilters}
                    />
                ) : (
                    <SearchEmptyState
                        selectThread={setThread}
                        startThread={startThreadWithFilters}
                    />
                )}
            </div>
        </div>
    )
}

function GlobalAskGlyphicThread({
    threadId,
    turns,
    filters,
    receivingResponse,
    setThread,
    submitMessage,
    rerunThread,
    error,
}: {
    threadId: string
    turns: Turn[]
    filters?: ISearchFilters
    receivingResponse: boolean
    setThread: (thread: Thread | null) => void
    submitMessage: (threadId: string, message: string) => Promise<void>
    rerunThread: (threadId: string) => Promise<void>
    error: string | null
}) {
    const { sendFeedback } = useFeedbackApi()
    return (
        <div className="flex h-[600px] w-[800px] max-w-full flex-col divide-y-[1px]">
            <GlobalAskGlyphicThreadHeader
                filters={filters}
                setThread={setThread}
            />
            <ScrollToBottom
                className="h-full flex-grow overflow-auto"
                followButtonClassName="hidden"
            >
                <div className="px-4 py-6">
                    <AskGlyphicThread
                        turns={turns}
                        receivingResponse={receivingResponse}
                        submitFeedback={(feedback) =>
                            sendFeedback(threadId, feedback)
                        }
                        sendEmail={sendFollowUpEmail}
                    />
                </div>
            </ScrollToBottom>
            {error && (
                <div className="mt-4 flex items-center justify-between bg-red-50 p-3">
                    <span className="text-sm text-red-600">{error}</span>
                    <PriorityButton
                        onClick={() => threadId && rerunThread(threadId)}
                        className="ml-4 text-sm"
                    >
                        Retry
                    </PriorityButton>
                </div>
            )}
            <AskGlyphicTextbox
                threadId={threadId}
                submitMessage={submitMessage}
                disabled={receivingResponse}
            />
        </div>
    )
}

function GlobalAskGlyphicThreadHeader({
    filters,
    setThread,
}: {
    filters?: ISearchFilters
    setThread: (thread: Thread | null) => void
}) {
    return (
        <span className="flex h-12 items-center justify-between p-4">
            <FontAwesomeIcon
                icon={faChevronLeft}
                className="cursor-pointer text-gray-500 hover:text-gray-700"
                onClick={() => setThread(null)}
            />
            <div className="scrollbar-hide ml-4 flex space-x-2 overflow-x-auto">
                {filters && <FiltersContainer filters={filters} />}
            </div>
        </span>
    )
}

function AskGlyphicTextbox(props: {
    threadId: string
    submitMessage: (threadId: string, message: string) => Promise<void>
    disabled?: boolean
}) {
    const [inputText, setInputText] = useState("")

    const submitInputText = () => {
        if (!inputText.trim()) return
        props.submitMessage(props.threadId, inputText)
        setInputText("")
    }

    const isSubmitDisabled = props.disabled || !inputText.trim()

    return (
        <span className="flex h-12 items-center px-4 py-8">
            <input
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                onKeyDown={(e) => {
                    if (props.disabled) return
                    if (e.key === "Enter" && inputText.trim()) {
                        e.preventDefault()
                        submitInputText()
                    }
                }}
                type="text"
                className="w-full border-none p-3 focus:outline-none"
                placeholder="Ask a follow-up question..."
                autoFocus
            />
            <PriorityButton
                onClick={submitInputText}
                disabled={isSubmitDisabled}
            >
                <FontAwesomeIcon icon={faPaperPlaneAlt} />
            </PriorityButton>
        </span>
    )
}

function SearchContent(props: {
    searchTerm: string
    isPending: boolean
    results: ISearchResult[]
    navigateTo: (url: string, e?: React.MouseEvent<HTMLAnchorElement>) => void
    debouncedSearchTerm: string
    startThread: (initialMessage: string) => void
}) {
    const [selectedIndex, setSelectedIndex] = useState(-1) // -1 represents AI Search

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "ArrowUp") {
                setSelectedIndex((prevIdx) => Math.max(prevIdx - 1, -1))
            } else if (e.key === "ArrowDown") {
                setSelectedIndex((prevIdx) =>
                    Math.min(prevIdx + 1, props.results.length - 1)
                )
            } else if (e.key === "Enter") {
                if (selectedIndex === -1) {
                    props.startThread(props.searchTerm)
                } else if (
                    selectedIndex >= 0 &&
                    selectedIndex < props.results.length
                ) {
                    props.navigateTo(getResultUrl(props.results[selectedIndex]))
                }
            }
        }

        document.addEventListener("keydown", handleKeyDown)
        return () => {
            document.removeEventListener("keydown", handleKeyDown)
        }
    }, [selectedIndex, props])

    return (
        <div className="space-y-4">
            <AISearchButton
                searchTerm={props.searchTerm}
                onClick={() => props.startThread(props.searchTerm)}
                selected={selectedIndex === -1}
            />
            <div className="space-y-1">
                <span className="text-sm text-gray-600">Quick results</span>
                {props.isPending && <LoadingState />}
                {!props.isPending && props.results.length === 0 && (
                    <div className="text-center text-sm text-gray-500">
                        No quick results found. Try asking Glyphic AI Search.
                    </div>
                )}
                <SearchResultList
                    results={props.results}
                    navigateTo={props.navigateTo}
                    searchTerm={props.debouncedSearchTerm}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                />
            </div>
        </div>
    )
}

function AISearchButton(props: {
    searchTerm: string
    selected: boolean
    onClick?: () => void
}) {
    return (
        <div
            onClick={props.onClick}
            className={clsx(
                "flex items-center justify-between rounded-lg px-4 py-3 text-base",
                "cursor-pointer transition-all duration-300 ease-in-out",
                "hover:bg-gradient-to-r hover:from-[#ffc267] hover:via-[#ffdaa2] hover:to-[#ffffff]",
                "transform text-gray-800 hover:-translate-y-0.5 hover:shadow-lg",
                "border border-gray-200",
                props.selected
                    ? "border-hidden bg-gradient-to-r from-[#ffc267] via-[#ffdaa2] to-[#ffffff] shadow-md ring-2 ring-[#ffdaa2] ring-offset-2"
                    : "bg-transparent"
            )}
        >
            <div className="max-w-xs truncate rounded-full bg-white bg-opacity-50 px-3 py-1 text-sm text-gray-800">
                {props.searchTerm.trim()}
            </div>
            <div className="flex items-center space-x-2">
                <FontAwesomeIcon
                    icon={faSparkles}
                    className="h-6 w-6 animate-pulse text-gray-600"
                />
                <span className="text-lg font-semibold">AI Search</span>
            </div>
        </div>
    )
}

function SearchResultList(props: {
    results: ISearchResult[]
    navigateTo: (url: string, e?: React.MouseEvent<HTMLAnchorElement>) => void
    searchTerm: string
    selectedIndex: number
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>
}) {
    const searchTerm = props.searchTerm
    const setSelectedIndex = props.setSelectedIndex

    // Navigate to a search result's page, and track event in heap
    const navigateTo = useCallback(
        (url: string, e?: React.MouseEvent<HTMLAnchorElement>) => {
            getHeapInstance()?.track("search-click", {
                query: searchTerm,
                selectedResultIdx: props.selectedIndex,
                url: url,
            })
            props.navigateTo(url, e)
        },
        [searchTerm, props]
    )

    // Reset selected index when the search term changes
    useEffect(() => {
        setSelectedIndex(-1)
    }, [searchTerm, setSelectedIndex])

    return (
        <div className="space-y-2">
            {props.results.map((result, idx) => {
                const isCall = result.type === SearchResultType.CALL
                const isCompany = result.type === SearchResultType.COMPANY
                const isSelected = idx === props.selectedIndex
                const resultUrl = getResultUrl(result)
                const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) =>
                    navigateTo(resultUrl, e)
                if (isCall) {
                    return (
                        <CallSearchResult
                            key={result.id}
                            result={result as ICallSearchResult}
                            isSelected={isSelected}
                            setIsSelected={() => props.setSelectedIndex(idx)}
                            handleClick={handleClick}
                            href={resultUrl}
                        />
                    )
                }
                if (isCompany)
                    return (
                        <CompanySearchResult
                            key={result.id}
                            result={result as ICompanySearchResult}
                            isSelected={isSelected}
                            setIsSelected={() => props.setSelectedIndex(idx)}
                            handleClick={handleClick}
                            href={resultUrl}
                        />
                    )
                return <></>
            })}
        </div>
    )
}

function LoadingState() {
    const elementHeights = [14, 18, 12, 16, 14, 18, 12, 16, 14, 18, 12]
    return (
        <div className="space-y-4">
            {elementHeights.map((height, idx) => (
                <div
                    key={idx}
                    className={`h-${height} animate-pulse rounded bg-gray-100`}
                />
            ))}
        </div>
    )
}

function CallSearchResult(props: {
    result: ICallSearchResult
    isSelected: boolean
    setIsSelected: () => void
    handleClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
    href: string
}) {
    const result = props.result

    return (
        <SearchResult
            id={result.id}
            heading={result.call_metadata.title || "Untitled Call"}
            subheading={
                result.call_metadata.start_time
                    ? getFormattedDateTime(result.call_metadata.start_time)
                    : ""
            }
            highlights={result.highlight}
            isSelected={props.isSelected}
            setIsSelected={props.setIsSelected}
            handleClick={props.handleClick}
            image={
                <div className="flex h-10 w-10 items-center justify-center rounded-md bg-gray-300">
                    <RecordingIcon className="h-7 w-7 text-white" />
                </div>
            }
            href={props.href}
        />
    )
}

function CompanySearchResult(props: {
    result: ICompanySearchResult
    isSelected: boolean
    setIsSelected: () => void
    handleClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
    href: string
}) {
    const result = props.result
    return (
        <SearchResult
            id={result.id}
            heading={result.name || result.primary_domain}
            subheading={result.primary_domain}
            highlights={result.highlight}
            isSelected={props.isSelected}
            setIsSelected={props.setIsSelected}
            handleClick={props.handleClick}
            image={<CompanyLogo image_url={result.image_url} />}
            href={props.href}
        />
    )
}

function SearchResult(props: {
    id: string
    image: any
    heading: string
    subheading: string
    highlights: ISearchResultHighlight[]
    isSelected: boolean
    setIsSelected: () => void
    handleClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
    href: string
}) {
    const highlight = props.highlights[0]
    return (
        <a
            key={props.id}
            className={clsx(
                "flex space-x-2 rounded-md px-3 py-2 text-base hover:cursor-pointer",
                props.isSelected && "bg-gray-100"
            )}
            onClick={props.handleClick}
            onMouseOver={props.setIsSelected}
            href={props.href}
        >
            <div className="pt-1">{props.image}</div>
            <div className="w-full space-y-1">
                <div className="flex justify-between">
                    <span className="space-x-2">
                        <span className="font-semibold">{props.heading}</span>
                    </span>
                    <span className="text-gray-400">{props.subheading}</span>
                </div>
                {highlight && (
                    <div className="text-gray-700">
                        <span>{highlight.field_name}: </span>
                        {highlight.texts.map(
                            (
                                segment: ISearchResultHighlightElement,
                                index: number
                            ) => (
                                <span
                                    key={index}
                                    className={clsx(
                                        segment.type === "hit" && "font-bold",
                                        segment.type === "text" &&
                                            "text-gray-400"
                                    )}
                                >
                                    {segment.value}
                                </span>
                            )
                        )}
                    </div>
                )}
            </div>
        </a>
    )
}

function getResultUrl(result: any): string {
    if (result.type === SearchResultType.CALL) {
        return `/calls/${result.id}`
    }
    if (result.type === SearchResultType.COMPANY) {
        return `/companies/${result.primary_domain}`
    }
    return ""
}
