import { useIntegrations } from "../../../hooks/useIntegrations"

import { InvitationSettings } from "../InvitationSettings"
import { BaseSettingsPage } from "./BaseSettingsPage"

export function UserManagementPage() {
    const { orgName } = useIntegrations()

    return (
        <BaseSettingsPage pageTitle={"User Management"} alwaysFullWidth>
            <InvitationSettings orgName={orgName} />
        </BaseSettingsPage>
    )
}
