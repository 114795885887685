import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { IResolvedCrmFieldValue } from "../crm/types/Crm"
import { LoadingPulse } from "../common/LoadingPulse"
import { crmIconFromUrl } from "../crm/utils/crmIconFromUrl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LogoLink } from "../common/LogoLink"
import { ReadOnlyFieldList } from "../CustomInsights"
import { useUser } from "../../providers/UserProvider"
import { hasPermission } from "../../utils/Permissions"
import { Permission } from "../../types/Permission"
import { Link } from "react-router-dom"
import { CREATE_INSIGHT_PATH } from "../settings/insights/CreateInsights"
import { faCirclePlus } from "@fortawesome/pro-regular-svg-icons"

export function CustomInsights(props: {
    companyDomain: string
    companyCrmUrl?: string | null
}) {
    const user = useUser()
    const canEditCustomInsights =
        user && hasPermission(user, Permission.EDIT_CUSTOM_INSIGHTS)
    const {
        data: fields,
        isPending,
        isError,
    } = useQuery<IResolvedCrmFieldValue[]>({
        queryKey: [`companies/${props.companyDomain}/crm/fields`],
        queryFn: async () => {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/companies/${props.companyDomain}/crm/fields`
            )
            return data
        },
        staleTime: 1000 * 60 * 5, // 5 minutes
    })

    if (isError) {
        return (
            <div className="text-sm text-gray-600">
                Unable to load custom insights
            </div>
        )
    }

    if (fields && fields.length === 0) {
        return (
            <div className="text-sm text-gray-600">
                No custom insights available
            </div>
        )
    }

    return (
        <div className="space-y-4 rounded-lg border bg-white p-3 text-base">
            <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                    {props.companyCrmUrl && (
                        <LogoLink
                            url={props.companyCrmUrl}
                            logo={
                                <FontAwesomeIcon
                                    icon={crmIconFromUrl(props.companyCrmUrl)}
                                    size="lg"
                                />
                            }
                        />
                    )}
                    <h2 className="font-bold">Company custom insights</h2>
                </div>
                {canEditCustomInsights ? (
                    <Link to={CREATE_INSIGHT_PATH} state={{ from: "company" }}>
                        <FontAwesomeIcon
                            icon={faCirclePlus}
                            className="h-5 w-5 text-gray-600 hover:text-gray-800"
                            data-tooltip-id="tooltip-explanation"
                            data-tooltip-content="Create a new company insight"
                        />
                    </Link>
                ) : (
                    <FontAwesomeIcon
                        icon={faCirclePlus}
                        className="h-5 w-5 opacity-20"
                        data-tooltip-id="tooltip-explanation"
                        data-tooltip-content="You need to be an admin to create a new insight"
                    />
                )}
            </div>
            <div>
                {isPending ? (
                    <LoadingPulse rows={5} />
                ) : (
                    <ReadOnlyFieldList fields={fields} />
                )}
            </div>
        </div>
    )
}
