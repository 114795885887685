import { useState } from "react"
import { Modal } from "../common/Modal"
import { DangerousButton, SecondaryButton } from "../common/Buttons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashCan } from "@fortawesome/free-regular-svg-icons"
import { useNavigate } from "react-router-dom"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import axios from "axios"
import { getHeapInstance } from "../../utils/heap"

export function DeleteCallButton(props: { callId: string }) {
    const navigate = useNavigate()
    const { addNotification } = useNotification()
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    return (
        <>
            <button
                className="flex flex-col items-center text-sm text-gray-600 hover:text-gray-800"
                onClick={() => setShowConfirmationModal(true)}
            >
                <FontAwesomeIcon icon={faTrashCan} className="h-4 w-4" />
                <span className="hidden md:block">Delete</span>
            </button>
            <Modal
                isOpen={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
            >
                <div className="p-3">
                    <div className="mb-4 mr-10 text-lg font-semibold">
                        Are you sure you want to delete this call?
                    </div>
                    <div className="mt-8 flex justify-end">
                        <SecondaryButton
                            onClick={() => setShowConfirmationModal(false)}
                            className="mr-4"
                        >
                            Cancel
                        </SecondaryButton>
                        <DangerousButton
                            onClick={async () => {
                                setShowConfirmationModal(false)
                                getHeapInstance()?.track("delete-call-clicked")
                                const error = await deleteCall(props.callId)
                                if (!error) {
                                    navigate("/calls")
                                } else {
                                    addNotification(
                                        "Failed to delete call",
                                        `${error}`,
                                        NotificationType.Error
                                    )
                                }
                            }}
                        >
                            Delete
                        </DangerousButton>
                    </div>
                </div>
            </Modal>
        </>
    )
}

async function deleteCall(callId: string): Promise<unknown | null> {
    try {
        await axios.delete(
            `${process.env.REACT_APP_API_DOMAIN}/calls/${callId}`
        )
        return null
    } catch (error) {
        console.log(error)
        return error
    }
}
