import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ICallPrepData } from "../../types/CallPrep"
import { Participant } from "../call-prep/Participants"
import { JoinButton } from "../call-prep/UpcomingCallInfo"
import { Cues } from "../call-prep/Cues"
import { CaseStudies, CaseStudyContent } from "../call-prep/CaseStudies"
import { useEffect, useState } from "react"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { Location, useLocation } from "react-router-dom"
import { HorizontalParticipantList } from "../calls-list/ParticipantsList"
import { getHeapInstance } from "../../utils/heap"
import { Suggestions } from "../call-prep/Suggestions"
import { ActivityPreview } from "./ActivityPreview"
import { RecordingIcon } from "../../assets/icons/RecordingIcon"

export function CallPrepPreview(props: {
    sheet: ICallPrepData
    highlightBorder?: boolean
}) {
    const location = useLocation()
    const sheetId = props.sheet._id
    const isExpandable =
        props.sheet.suggestions || props.sheet.cues || props.sheet.case_studies
    const prepSheetHashParam = location.hash.replace("#", "")
    const sheetIsInUrl = prepSheetHashParam === sheetId

    function getFrom(location: Location, isInUrl: boolean): string {
        if (location.state?.meetingId) {
            return "schedule"
        } else if (isInUrl) {
            return "email_or_slack"
        } else {
            return "companies_list"
        }
    }

    const from = getFrom(location, sheetIsInUrl)
    const [showFull, setShowFull] = useState<boolean>(
        isExpandable && sheetIsInUrl
    )

    useEffect(() => {
        if (showFull) {
            getHeapInstance()?.track("opened-prep-sheet", {
                prep_sheet_id: sheetId,
                from,
            })
        }
    }, [showFull, sheetId, from])

    return (
        <ActivityPreview
            heading={
                <h2 className="text-base font-bold text-gray-900">
                    {props.sheet.call_info.title}
                </h2>
            }
            subheading={
                <HorizontalParticipantList parties={props.sheet.participants} />
            }
            date={props.sheet.call_info.start_time}
            icon={<RecordingIcon className="mt-1" />}
            button={
                isExpandable ? (
                    <FontAwesomeIcon
                        icon={showFull ? faChevronUp : faChevronDown}
                    ></FontAwesomeIcon>
                ) : undefined
            }
            onClick={
                isExpandable
                    ? () => {
                          setShowFull((prev) => !prev)
                      }
                    : undefined
            }
            rowClickable={isExpandable && !showFull}
            highlightBorder={props.highlightBorder}
        >
            <div
                className={`space-y-4 overflow-auto px-3 transition-max-height duration-500 ${
                    showFull ? "max-h-[200vh]" : "max-h-0"
                }`}
            >
                <div className="mt-5 flex gap-6">
                    <div className="flex flex-row flex-wrap gap-2">
                        {props.sheet.participants.map((participant) => (
                            <div className="w-full border-l pl-2">
                                <Participant
                                    key={participant.id}
                                    participant={participant}
                                />
                            </div>
                        ))}
                    </div>
                    {props.sheet.call_info.meeting_url && (
                        <div className="ml-auto">
                            <JoinButton
                                meetingUrl={props.sheet.call_info.meeting_url}
                            />
                        </div>
                    )}
                </div>
                {props.sheet.suggestions &&
                props.sheet.suggestions.length > 0 ? (
                    <Suggestions suggestions={props.sheet.suggestions} />
                ) : (
                    props.sheet.cues && <Cues cues={props.sheet.cues} />
                )}
                {props.sheet.case_studies && (
                    <CaseStudiesSection
                        caseStudies={props.sheet.case_studies}
                    />
                )}
            </div>
        </ActivityPreview>
    )
}

function CaseStudiesSection(props: { caseStudies: CaseStudyContent[] }) {
    return (
        <div className="space-y-2">
            <h3 className="font-bold">Similar Case Studies</h3>
            <CaseStudies caseStudies={props.caseStudies} />
        </div>
    )
}
