import { SettingsSection } from "./Card"
import { useQuery } from "@tanstack/react-query"
import { TextArea } from "./TextArea"
import { useUpdateOrgMemory } from "../../api/mutations/org-memory"
import { queries } from "../../api/queries"

export function OrgMemory(props: { orgName?: string }) {
    const organizationName = props.orgName || "Organization"

    const { data } = useQuery(queries.organization.orgMemory())

    const { mutate, isPending } = useUpdateOrgMemory()

    return (
        <SettingsSection
            title={`${organizationName}'s memory`}
            description={
                <>
                    <p>
                        Your organization's memory is a description of your
                        organization's preferences based on the interactions
                        you've had with Ask Glyphic.
                    </p>
                    <p>
                        This helps personalize your Ask Glyphic experience for
                        your whole organization and make future interactions
                        more relevant and efficient.
                    </p>
                </>
            }
        >
            <TextArea
                value={data || ""}
                placeholder={"Enter your organization memory here..."}
                onSave={mutate}
                isPending={isPending}
            />
        </SettingsSection>
    )
}
