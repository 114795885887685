import { getDealTypeLabel, ICRMDeal, IDealLossReason } from "../../types/Deal"
import { CompanyLogo } from "../company-view/CompanyLogo"
import { DateTime } from "luxon"
import { getSimpleDateWithYearIfDifferent } from "../../utils/datetime"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faBullseye,
    faMoneyBillWave,
    faStarOfLife,
    faUserCircle,
} from "@fortawesome/free-solid-svg-icons"
import { faCalendar, faMessages } from "@fortawesome/pro-regular-svg-icons"
import { faCircleXmark, faRotate } from "@fortawesome/pro-solid-svg-icons"
import { obstacleScoreMap, StatusIcon } from "../company-view/Summary"
import {
    QualificationScores,
    QualificationScoresVertical,
} from "./QualificationScores"
import { NavigateLink } from "../common/NavigateLink"
import { DealWithoutCompanyLink } from "./DealDetailsRow"
import clsx from "clsx"
import colors from "tailwindcss/colors"
import { formatMonetaryAmount } from "../../utils/monetaryAmount"

export function DealCard(props: { deal: ICRMDeal }) {
    const deal = props.deal
    const isClosed = deal.is_closed

    const rubricScoresWithoutObstacles =
        props.deal.company?.qualification_rubric_scores?.filter(
            (score) => score.tag !== "obstacles"
        )

    const displayScoresVertically = rubricScoresWithoutObstacles?.length! <= 6

    return (
        <div className="flex w-full flex-col space-y-2 rounded-lg bg-white p-3 shadow-md shadow-gray-200">
            <div className="flex flex-row justify-between">
                {deal.company ? (
                    <NavigateLink
                        href={`/companies/${deal.company?.domain}`}
                        className="flex h-full w-full items-center overflow-hidden"
                    >
                        <DealCardHeader deal={deal} />
                    </NavigateLink>
                ) : (
                    <DealWithoutCompanyLink
                        crmDealId={deal.id}
                        cellContent={<DealCardHeader deal={deal} />}
                    />
                )}
                {deal.deal_type !== "unknown" && (
                    <div
                        className="flex items-start text-gray-600"
                        data-tooltip-id="tooltip-explanation"
                        data-tooltip-content={getDealTypeLabel(deal.deal_type)}
                    >
                        {deal.deal_type === "new_business" && (
                            <FontAwesomeIcon icon={faStarOfLife} size="xs" />
                        )}
                        {deal.deal_type === "existing_business" && (
                            <FontAwesomeIcon icon={faRotate} size="xs" />
                        )}
                    </div>
                )}
            </div>

            <div className="flex flex-row items-start gap-2">
                <div className="flex w-full flex-col gap-2 text-xs text-gray-600">
                    <DealInfo deal={deal} />

                    {!isClosed && <DealCardTimeline deal={deal} />}

                    {!isClosed && !displayScoresVertically && (
                        <QualificationScores
                            scores={rubricScoresWithoutObstacles || null}
                            length={rubricScoresWithoutObstacles?.length || 0}
                            showAcronyms={true}
                        />
                    )}

                    {!isClosed && <DealCardObstaclesBanner deal={deal} />}

                    {isClosed &&
                        deal.loss_reasons?.map((lossReason) => (
                            <DealCardLossReasonBanner
                                key={lossReason.loss_category}
                                lossReason={lossReason}
                            />
                        ))}
                </div>
                {!isClosed && displayScoresVertically && (
                    <div>
                        <QualificationScoresVertical
                            scores={rubricScoresWithoutObstacles || null}
                            length={rubricScoresWithoutObstacles?.length || 0}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

function DealCardHeader(props: { deal: ICRMDeal }) {
    const { deal } = props
    return (
        <div className="group flex w-full items-start justify-between hover:cursor-pointer">
            <div className="flex items-center">
                {deal?.company?.image_url && (
                    <CompanyLogo
                        image_url={deal?.company?.image_url}
                        className="mr-2 h-12 w-12"
                    />
                )}
                <div>
                    <h2 className="line-clamp-1 text-lg font-semibold text-gray-900 group-hover:underline">
                        {deal.name}
                    </h2>
                    <p className="text-sm text-gray-600">
                        {deal.company?.name}
                    </p>
                </div>
            </div>
        </div>
    )
}

function DealInfo(props: { deal: ICRMDeal }) {
    const { deal } = props
    return (
        <div className="flex h-full max-w-[90%] items-center gap-4">
            <div
                className="flex items-center gap-2"
                data-tooltip-id="tooltip-explanation"
                data-tooltip-content="Deal amount"
            >
                <FontAwesomeIcon icon={faMoneyBillWave} />
                <span>
                    {formatMonetaryAmount(deal.amount, deal.currency) ||
                        "No amount"}
                </span>
            </div>
            <div
                className="flex items-center gap-2 overflow-hidden"
                data-tooltip-id="tooltip-explanation"
                data-tooltip-content="Deal owner"
            >
                <FontAwesomeIcon icon={faUserCircle} />
                <span className="truncate">
                    {deal.owner?.name || "Unassigned"}
                </span>
            </div>
            {deal.forecast_category && (
                <div
                    className="whitespace-nowrap rounded-md border border-gray-200 bg-gray-50 px-2 py-1 text-xs text-gray-600"
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content="Forecast category"
                >
                    <span>{deal.forecast_category}</span>
                </div>
            )}
        </div>
    )
}

function DealCardTimeline(props: { deal: ICRMDeal }) {
    const { deal } = props

    const isPastCloseDate =
        deal.close_date && DateTime.fromISO(deal.close_date) < DateTime.now()

    return (
        <div className="flex h-full gap-2">
            <DealTimelineCell
                icon={faMessages}
                text={
                    deal.company?.last_call_time
                        ? formatToRelativeDate(deal.company?.last_call_time)!
                        : "No previous"
                }
                tooltip="Time since last call"
            />
            <DealTimelineCell
                icon={faCalendar}
                text={
                    deal.company?.next_call_time
                        ? formatToRelativeDate(deal.company?.next_call_time)!
                        : "No meeting"
                }
                tooltip="Time until next call"
            />
            <DealTimelineCell
                icon={faBullseye}
                text={
                    deal.close_date
                        ? getSimpleDateWithYearIfDifferent(deal.close_date)
                        : "No date"
                }
                tooltip={
                    isPastCloseDate
                        ? "Deal close date has passed"
                        : "Deal close date"
                }
                bgColor={
                    !deal.is_closed && isPastCloseDate
                        ? "bg-red-100"
                        : undefined
                }
            />
        </div>
    )
}

function DealTimelineCell(props: {
    icon: any
    text: string
    tooltip: string
    bgColor?: string
}) {
    return (
        <div
            className={clsx(
                "flex h-full flex-grow flex-col items-start gap-1 rounded-md  px-2 py-1",
                props.bgColor || "bg-gray-50"
            )}
            data-tooltip-id="tooltip-explanation"
            data-tooltip-content={props.tooltip}
        >
            <FontAwesomeIcon icon={props.icon} />
            <span>{props.text}</span>
        </div>
    )
}

function DealCardObstaclesBanner(props: { deal: ICRMDeal }) {
    const obstacles = props.deal.company?.qualification_rubric_scores?.find(
        (score) => score.tag === "obstacles"
    )
    if (!obstacles) return null

    // Only highlight if obstacle score is low
    const obstacleColour =
        obstacles.score === 1
            ? obstacleScoreMap[obstacles.score as keyof typeof obstacleScoreMap]
                  .color[100]
            : obstacles.score === 2
            ? obstacleScoreMap[obstacles.score as keyof typeof obstacleScoreMap]
                  .color[50]
            : colors.gray[50]

    const textColour = obstacles.score <= 2 ? colors.black : colors.gray[600]

    return (
        <div
            className="flex items-start gap-2 rounded p-2"
            style={{
                backgroundColor: obstacleColour,
                color: textColour,
            }}
            data-tooltip-id="tooltip-explanation"
            data-tooltip-html={`<b>Obstacles:</b><br>${obstacles.feedback}`}
        >
            <span className="pt-1">
                <StatusIcon score={obstacles.score} tag="obstacles" />
            </span>
            <span>{obstacles.feedback_headline || "No obstacles"}</span>
        </div>
    )
}

function DealCardLossReasonBanner(props: { lossReason: IDealLossReason }) {
    return (
        <div
            className="flex items-start space-x-2 rounded bg-red-50 p-2"
            data-tooltip-id="tooltip-explanation"
            data-tooltip-html={`<b>Loss Reason:</b><br>${props.lossReason.reasoning}`}
        >
            <FontAwesomeIcon
                icon={faCircleXmark}
                className="pt-1 text-red-500"
            />
            <div className="flex flex-col gap-1">
                <span className="font-semibold text-red-500">
                    {props.lossReason.matched_option}
                </span>
                <span className="text-xs text-black">
                    {props.lossReason.description}
                </span>
            </div>
        </div>
    )
}

function formatToRelativeDate(dateStr: string) {
    const date = DateTime.fromISO(dateStr, { zone: "utc" })
    const base = DateTime.now().setZone("utc").startOf("day")
    if (date.hasSame(base, "day")) {
        return "today"
    }
    return date.toRelative({ base, style: "short" })
}
