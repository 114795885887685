import { UpdateTaskMutation } from "../../../api/mutations/tasks"
import { ICall } from "../../../types/Call"
import { secondsToTimestamp } from "../../../utils/datetime"
import { CallTask } from "../types/Task"
import { AssigneeSelector } from "./AssigneeSelector"
import { TaskCompleteButton } from "./TaskCompleteButton"
import { TaskDescription } from "./TaskDescription"
import { TaskDueDate, TaskDueDateEditor } from "./TaskDueDate"
import { Timestamp } from "./Timestamp"
import { ExpandedTaskView } from "./ExpandedTaskView"
import clsx from "clsx"

interface TaskCallProps {
    call?: ICall
    goToTimestamp?: (timestamp: string) => void
}

interface TaskUpdateProps {
    isUpdating?: boolean
    updateTask?: UpdateTaskMutation
}

export function CallTaskItem(
    props: {
        task: CallTask
        isExpanded: boolean
        onTaskClick?: () => void
    } & TaskCallProps &
        TaskUpdateProps
) {
    const isCompleted = props.task.completed_at_utc !== null
    const timestamp = props.task.timestamp
        ? secondsToTimestamp(props.task.timestamp)
        : null

    return (
        <div className="flex w-full flex-col">
            <div
                className={clsx(
                    "group flex w-full max-w-full flex-row items-center justify-between overflow-hidden",
                    "gap-2 rounded-lg p-2 transition-all duration-200",
                    props.onTaskClick && "cursor-pointer hover:bg-gray-50"
                )}
                onClick={props.onTaskClick}
            >
                <div className="flex min-w-0 flex-1 flex-shrink-0 flex-row items-center gap-2">
                    <TaskCompleteButton
                        taskId={props.task.id}
                        isCompleted={isCompleted}
                        updateTask={props.updateTask}
                        isUpdating={props.isUpdating}
                    />
                    <div className="flex min-w-0 flex-1 flex-col items-start">
                        <div className="flex w-full min-w-0 flex-row items-center">
                            <TaskDescription
                                isExpanded={props.isExpanded}
                                description={props.task.description}
                                isCompleted={isCompleted}
                            />
                        </div>
                        {!props.isExpanded && props.task.due_date && (
                            <div className="flex w-fit flex-shrink-0 flex-row items-center whitespace-nowrap text-sm">
                                <TaskDueDateEditor
                                    taskId={props.task.id}
                                    date={props.task.due_date}
                                    updateTask={props.updateTask}
                                >
                                    <TaskDueDate
                                        dueDateString={props.task.due_date}
                                    />
                                </TaskDueDateEditor>
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex flex-shrink-0 flex-row items-center gap-2">
                    {timestamp && props.goToTimestamp && (
                        <Timestamp
                            timestamp={timestamp}
                            onClick={props.goToTimestamp}
                        />
                    )}
                    <AssigneeSelector
                        currentAssignee={props.task.assignee || null}
                        participants={props.call?.parties ?? []}
                        taskId={props.task.id}
                        callId={props.task.call_id}
                        updateTask={props.updateTask}
                    />
                </div>
            </div>
            <div
                className={clsx(
                    "grid transition-all duration-200 ease-in-out",
                    props.isExpanded ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
                )}
            >
                <div className="overflow-hidden">
                    <ExpandedTaskView
                        task={props.task}
                        updateTask={props.updateTask}
                    />
                </div>
            </div>
        </div>
    )
}
