import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query"
import { useNotification } from "../../providers/NotificationProvider"
import axios from "axios"
import { NotificationType } from "../common/Notifcations"
import { queries } from "../../api/queries"
import { ICall, ICallTag } from "../../types/Call"
import { useMemo, useState } from "react"
import { groupTags, TagInput } from "./TagInput"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons"
import { stringToColour } from "../../utils/stringToColour"
import { Tag } from "../settings/CallTags"
import { FrigadeCallNewTagTour } from "../Frigade"
import clsx from "clsx"

// Type that ensures name is required while other properties remain optional
type TagWithName = Partial<ICallTag> & { name: string }

const useDeleteTag = (callId: string) => {
    const queryClient = useQueryClient()
    const { addNotification } = useNotification()

    return useMutation({
        mutationFn: async (tagId: string) => {
            await axios.delete(
                `${process.env.REACT_APP_API_DOMAIN}/calls/${callId}/tags/${tagId}`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: queries.calls.byId(callId).queryKey,
            })
        },
        onError: (error: any) => {
            // Extract the detailed error message from Axios error response
            const errorMessage = error.response?.data?.detail || `${error}`
            addNotification(
                "Failed to delete tag",
                errorMessage,
                NotificationType.Error
            )
        },
    })
}

const useAddTag = (callId: string) => {
    const queryClient = useQueryClient()
    const { addNotification } = useNotification()

    return useMutation({
        mutationFn: async (tag: TagWithName) => {
            const response = await axios.post(
                `${process.env.REACT_APP_API_DOMAIN}/calls/${callId}/tags`,
                {
                    name: tag.name,
                    // Don't set description from call page
                    description: null,
                    // If a tag was selected from suggestions, use its group
                    // Otherwise for manually entered tags, use null (ungrouped)
                    group: tag.group || null,
                }
            )
            return response.data as ICallTag[]
        },
        onSuccess: (data, variables, context) => {
            queryClient.setQueryData(
                queries.calls.byId(callId).queryKey,
                (call: ICall) => {
                    return {
                        ...call,
                        tags: [...data],
                    }
                }
            )
            queryClient.invalidateQueries(queries.callTags.list())
        },
        onError: (error: any) => {
            // Extract the detailed error message from Axios error response
            const errorMessage = error.response?.data?.detail || `${error}`
            addNotification(
                "Failed to add tag",
                errorMessage,
                NotificationType.Error
            )
        },
    })
}

export function CallTags(props: { tags?: ICallTag[]; callId: string }) {
    const [isAddingTag, setIsAddingTag] = useState(false)

    const { mutate: addTag, isPending } = useAddTag(props.callId)

    const { data: allTags } = useQuery(queries.callTags.list())

    const handleAddTag = (tag: TagWithName) => {
        addTag(tag, {
            onSuccess: () => {
                setIsAddingTag(false)
            },
        })
    }

    // Memoize the tag grouping operation
    const groupedTags = useMemo(() => groupTags(props.tags || []), [props.tags])

    const processedGroups = useMemo(() => {
        return Object.entries(groupedTags)
            .filter(([_, tags]) => tags.length > 0) // Filter out empty groups
            .sort(([groupA], [groupB]) => {
                // Special sort: "ungrouped" always at the end, others alphabetically
                if (groupA === "ungrouped") return 1 // ungrouped comes after
                if (groupB === "ungrouped") return -1 // ungrouped comes after
                return groupA.localeCompare(groupB) // alphabetical sort for other groups
            })
            .map(([group, tags]) => {
                const isUngrouped = group === "ungrouped"
                return {
                    group,
                    tags: isUngrouped
                        ? [...tags].sort((a, b) => a.name.localeCompare(b.name)) // Sort ungrouped tags
                        : [...tags], // Preserve original order for grouped tags
                }
            })
    }, [groupedTags])

    return (
        <div className="flex w-full flex-wrap items-center gap-2 ">
            <h3 className="flex-none text-base font-bold">Tags:</h3>
            {/* Display each group of tags */}
            {processedGroups.map(({ group, tags }) => (
                <div
                    key={group}
                    className={clsx(
                        "flex items-center gap-2",
                        isPending && "animate-pulse opacity-10"
                    )}
                >
                    {tags.map((tag) => (
                        <RemovableTag
                            key={tag.id}
                            callId={props.callId}
                            tag={tag}
                            groupName={group}
                        />
                    ))}
                </div>
            ))}

            {/* Add tag button/input */}
            <div className="ml-2 flex">
                {isAddingTag ? (
                    <TagInput
                        existingTags={props.tags || []}
                        allTags={allTags || []}
                        onAddTag={handleAddTag}
                        onCancel={() => setIsAddingTag(false)}
                    />
                ) : (
                    <AddTagButton onClick={() => setIsAddingTag(true)} />
                )}
            </div>
        </div>
    )
}

function RemovableTag(props: {
    tag: ICallTag
    callId: string
    groupName: string
}) {
    const [showDelete, setShowDelete] = useState(false)
    const deleteTag = useDeleteTag(props.callId)
    const tagColour = stringToColour(props.tag.name)

    return (
        <div
            className="relative"
            onMouseEnter={() => setShowDelete(true)}
            onMouseLeave={() => setShowDelete(false)}
        >
            <Tag text={props.tag.name} groupName={props.groupName} />
            {showDelete && (
                <button
                    className="absolute bottom-0 right-0 top-0 rounded-r-md p-0.5 px-1.5 text-gray-500 transition-colors hover:text-gray-700"
                    style={{ backgroundColor: tagColour[300] }}
                    onClick={() => deleteTag.mutate(props.tag.id)}
                >
                    <FontAwesomeIcon icon={faXmark} size="xs" />
                </button>
            )}
        </div>
    )
}

function AddTagButton({ onClick }: { onClick: () => void }) {
    return (
        <>
            <FrigadeCallNewTagTour />
            <button
                id="frigade-call-new-tag"
                className="flex items-center gap-1 rounded-md border border-gray-200 px-2 py-1 text-sm transition-colors hover:bg-gray-100"
                onClick={onClick}
            >
                <FontAwesomeIcon
                    icon={faPlus}
                    className="h-3 w-3 text-gray-500"
                />
                New Tag
            </button>
        </>
    )
}
