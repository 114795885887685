import clsx from "clsx"
import { getFormattedDateTime } from "../../utils/datetime"
import { SimpleCard } from "../common/SimpleCard"

export function ActivityPreview(props: {
    heading: React.ReactNode
    subheading: React.ReactNode
    date: string
    icon: React.ReactNode
    button?: React.ReactNode
    backgroundColor?: string
    onClick?: () => void
    rowClickable: boolean
    highlightBorder?: boolean
    children: React.ReactNode
}) {
    // If rowClickable is true, the entire row will be clickable, otherwise just the header is
    const rowClickable = props.rowClickable
    return (
        <SimpleCard
            className={clsx(
                "transition-colors ease-out",
                props.backgroundColor,
                { "border-gray-500": props.highlightBorder },
                { "hover:cursor-pointer hover:bg-gray-100": rowClickable }
            )}
        >
            <div
                className="w-full space-y-3 p-3"
                onClick={rowClickable ? props.onClick : undefined}
            >
                <div
                    className="grid grid-cols-3 items-start gap-2 hover:cursor-pointer md:grid-cols-6"
                    onClick={rowClickable ? undefined : props.onClick}
                >
                    <div className="col-span-3 flex w-full flex-nowrap space-x-2">
                        <div>{props.icon}</div>
                        {props.heading}
                    </div>
                    <div className="col-span-2 text-sm text-gray-600">
                        {props.subheading}
                    </div>
                    <div className="col-span-1 flex justify-end space-x-2">
                        <span className="text-sm">
                            {getFormattedDateTime(props.date)}
                        </span>
                        {props.button}
                    </div>
                </div>
                <div className="text-base">{props.children}</div>
            </div>
        </SimpleCard>
    )
}
