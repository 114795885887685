import { MessageBlock, Turn } from "../types/ThreadTypes"

export function updateLastAiTurnBlocks(
    turns: Turn[],
    blocks: MessageBlock[]
): Turn[] {
    const lastTurn = turns[turns.length - 1]
    if (!lastTurn || lastTurn.role !== "ai") {
        return turns
    }
    return [
        ...turns.slice(0, -1),
        {
            ...lastTurn,
            blocks,
        },
    ]
}
