import { Link, useLocation, useParams } from "react-router-dom"
import { CallPrep } from "./CallPrep"
import { useEffect, useState } from "react"
import { ICallPrepData, IGeneratedCallPrepResponse } from "../../types/CallPrep"
import axios from "axios"
import LoadingSpinner from "../common/LoadingSpinner"
import { ErrorPage } from "../common/ErrorPage"
import { SecondaryButton } from "../common/Buttons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowsRotate } from "@fortawesome/free-solid-svg-icons"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"

export function CallPrepPage() {
    const params = useParams()
    const location = useLocation()
    const { addNotification } = useNotification()
    const callPrepSheetId: string = params.prep_sheet_id as string
    const [callPrepSheet, setCallPrepSheet] = useState<
        ICallPrepData | undefined
    >()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function getPrepSheet() {
            try {
                setLoading(true)
                const prepSheet = await getCallPrepData(callPrepSheetId)
                setCallPrepSheet(prepSheet)
            } catch (error) {
                addNotification(
                    "Failed to load prep sheet",
                    "",
                    NotificationType.Error
                )
            } finally {
                setLoading(false)
            }
        }
        getPrepSheet()
    }, [callPrepSheetId, addNotification])

    async function handleRefresh() {
        try {
            setLoading(true)
            setCallPrepSheet(
                (await createCallPrepSheet(location.state.meetingId)).prep_sheet
            )
        } catch (error) {
            addNotification(
                "Failed to regenerate prep sheet",
                "",
                NotificationType.Error
            )
        } finally {
            setLoading(false)
        }
    }

    if (loading) {
        return (
            <div className="flex justify-center p-20">
                <LoadingSpinner />
            </div>
        )
    }

    if (callPrepSheet === undefined) {
        return (
            <ErrorPage error={{ message: "Failed to load call prep sheet" }} />
        )
    }

    const allowRegnerate = !!location.state?.meetingId // Only allow regenerate if passed meeting info

    return (
        <div>
            <div className="flex flex-row items-center justify-between space-x-2 border-b bg-white px-6 py-2 md:h-12">
                <Link to="/schedule">
                    <SecondaryButton className="flex flex-row items-center gap-2">
                        <FontAwesomeIcon icon={faArrowLeft} />
                        <div>Your schedule</div>
                    </SecondaryButton>
                </Link>
                {allowRegnerate && (
                    <SecondaryButton
                        className="h-8"
                        onClick={handleRefresh}
                        data-tooltip-id="tooltip-explanation"
                        data-tooltip-content="Regenerate prep sheet"
                    >
                        <FontAwesomeIcon icon={faArrowsRotate} />
                    </SecondaryButton>
                )}
            </div>
            <section className="mx-auto max-w-5xl p-4">
                <CallPrep sheet={callPrepSheet} />
            </section>
        </div>
    )
}

async function getCallPrepData(id: string): Promise<ICallPrepData> {
    const response = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/call_prep/${id}`
    )
    return response.data as ICallPrepData
}

export async function createCallPrepSheet(
    meetingId: string
): Promise<IGeneratedCallPrepResponse> {
    const response = await axios.put(
        `${process.env.REACT_APP_API_DOMAIN}/call_prep/?meeting_id=${meetingId}`
    )
    return response.data as IGeneratedCallPrepResponse
}
