import { UseQueryResult } from "@tanstack/react-query"
import { InsightResult, InsightsResponse } from "../../../types/Insights"
import colors from "tailwindcss/colors"
import { LoadingPulse } from "../../common/LoadingPulse"
import { Line, LineChart, ResponsiveContainer } from "recharts"
import {
    IStrategicInsightNavigationItem,
    scrollToInsight,
} from "../utils/navigation"
import { canShowSummaryCharts } from "./DealPatternsCharts"
import _ from "lodash"
import { SimpleCard } from "../../common/SimpleCard"

export const MOST_MENTIONED_NAV_ITEM: IStrategicInsightNavigationItem = {
    id: "most-mentioned",
    displayName: "Most Mentioned Insights",
    isSummary: true,
}

export function MostMentionsChart(props: {
    insightsQueryResult: UseQueryResult<InsightsResponse, Error>
}) {
    const result = props.insightsQueryResult
    if (result.isPending)
        return (
            <div>
                <LoadingPulse rows={1} height="h-80" />
            </div>
        )

    if (!canShowSummaryCharts(result.data)) {
        return null
    }

    const insights = result.data.insights
    const topInsights = getMostMentionedInsights(insights)
    const insightsToShow = topInsights.slice(0, 3)

    return (
        <SimpleCard>
            <div className="p-6" id={MOST_MENTIONED_NAV_ITEM.id}>
                <h2 className="mb-2 text-xl font-semibold text-gray-900">
                    {MOST_MENTIONED_NAV_ITEM.displayName}
                </h2>
                <div className="mb-4 text-sm text-gray-500">
                    {`The most frequently mentioned topics in your calls, over the selected period.`}
                </div>
                <div className="grid grid-cols-3 gap-2">
                    {insightsToShow.map((insight, index) => (
                        <InsightSection
                            key={insight.insightId}
                            insight={insight}
                            index={index}
                        />
                    ))}
                </div>
            </div>
        </SimpleCard>
    )
}

function InsightSection(props: {
    insight: InsightMentionTrend
    index: number
}) {
    return (
        <div
            key={props.insight.insightId}
            className="flex h-full w-full flex-col overflow-auto rounded-md bg-gray-50 p-2 text-sm transition-colors hover:cursor-pointer hover:bg-gray-100"
            onClick={() => scrollToInsight(props.insight.insightId)}
        >
            <div className="flex items-center justify-between">
                <div className="font-semibold">
                    <span className="text-gray-500">{props.index + 1}. </span>
                    {props.insight.insightName}
                </div>
            </div>
            <div className="ml-4 flex flex-col items-end justify-between 2xl:flex-row 2xl:items-center">
                <span className="w-full">{props.insight.insightValue}</span>
                <span className="flex items-center gap-1">
                    <SparkLine dataPoints={props.insight.lineChartPoints} />
                    <span className="whitespace-nowrap font-medium">
                        {props.insight.totalCount} calls
                    </span>
                </span>
            </div>
        </div>
    )
}

function SparkLine(props: { dataPoints: number[] }) {
    return (
        <div className="h-8 w-24">
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    data={props.dataPoints.map((value, idx) => ({
                        value,
                        idx,
                    }))}
                >
                    <Line
                        type="monotone"
                        dataKey="value"
                        stroke={colors.blue[600]}
                        dot={false}
                        strokeWidth={1.5}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

interface InsightMentionTrend {
    insightId: string
    insightName: string
    insightValue: string
    totalCount: number
    lineChartPoints: number[]
}

/**
 * This method finds the most frequently mentioned value for each insight.
 * Returns: an array of insights with their most common value, sorted by total mention count
 */
export function getMostMentionedInsights(
    insights: InsightResult[]
): InsightMentionTrend[] {
    const insightsWithData = insights.filter(
        (insight) => insight.lineChart?.data.length
    )

    const insightMentionTrends = insightsWithData.map((insight) => {
        const data = insight.lineChart!.data
        const insightValues = Object.keys(data[0].values)

        const valueStats = insightValues.map((insightValue) => {
            const lineChartPoints = data.map(
                (bucket) => bucket.values[insightValue]
            )
            const totalCount = _.sum(lineChartPoints)

            return {
                insightName: insight.displayName,
                insightId: insight.id,
                insightValue,
                totalCount,
                // Exclude latest incomplete period
                lineChartPoints: lineChartPoints.slice(0, -1),
            }
        })

        return _.maxBy(valueStats, "totalCount")!
    })

    return _.orderBy(insightMentionTrends, ["totalCount"], ["desc"])
}
