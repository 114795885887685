/**
 * This module implements a parser for the message block protocol we're
 * implementing for streaming different types of content from the AI service.
 *
 * The protocol is simple and uses simplified XML tags to communicate blocks.
 * Notes:
 * - Every block has to be closed in the end.
 * - Text is a special case, the default, which doesn't need to have an explicit
 *   opening or closing tag (you can still use <text>...</text> if you want).
 *
 * These XML blocs are converted into a list of Message Blocks which is what
 * the rest of the system uses. These are typed, making it easy to render
 * different blocks in the UI.
 *
 * Some blocks simply aggregate the tokens (see AccBlockHandler) but some
 * tools output more complex blocks (e.g. filters) which needs parsing.
 * For this we use handlers.
 *
 * Handlers parse the life cycle of a block:
 * - onCreated: called when a new block is created for its tag
 * - onContent: called when a new chunk of content is added to the block
 * - onClosed: called when the block is closed
 *
 * This allows us to simply create parsers for complex tools but also decide on
 * default values, how to parse, how to close (e.g. citations may only parse
 * once the entire content is streamed).
 *
 * The UI can also decide to render blocks in whatever order it wants
 * (e.g. filters might always show at the top) or ignore them completely.
 *
 * AI responses are always stored as text (backwards compatible) so we also
 * have to parse the entire content when loading history, with
 * `parseFullTextToBlocks`. This splits the text into blocks to parse using
 * the existing handlers, but blocks of text (not tags) are handled as a single
 * chunk.
 */

import { getHeapInstance } from "../../../../utils/heap"
import {
    LoadingDocsMessageBlock,
    MemoryUpdatedMessageBlock,
    MessageBlock,
    MessageBlockType,
    TextMessageBlock,
} from "../../types/ThreadTypes"
import {
    AccBlockHandler,
    BlockHandler,
    UnknownBlockHandler,
} from "./handlers/BaseBlockHandlers"
import { FiltersBlockHandler } from "./handlers/FiltersBlockHandler"
import { CitationsBlockHandler } from "./handlers/CitationsBlockHandler"

const HANDLERS: Record<MessageBlockType, BlockHandler<any>> = {
    text: new AccBlockHandler<TextMessageBlock>("text"),
    "loading-docs": new AccBlockHandler<LoadingDocsMessageBlock>(
        "loading-docs"
    ),
    citation: new CitationsBlockHandler(),
    filters: new FiltersBlockHandler(),
    "memory-updated": new AccBlockHandler<MemoryUpdatedMessageBlock>(
        "memory-updated"
    ),
    unknown: new UnknownBlockHandler(),
}

/**
 * Parse a complete text content into message blocks
 * Used for converting server-side messages that are already complete
 */
export function parseFullTextToBlocks(content: string): MessageBlock[] {
    if (!content) {
        return []
    }

    try {
        // Simple regex-based tokenizer to handle tags and content
        const tokens = content.match(/<[^>]+>|[^<>]+/g) || []

        // Process each token sequentially
        let blocks: MessageBlock[] = []
        for (const token of tokens) {
            blocks = updateBlocksWithDelta(blocks, token)
        }

        return closeLastBlock(blocks)
    } catch (error) {
        console.error("Error parsing message blocks:", error)

        // Fallback to treating the entire content as text
        const textHandler = HANDLERS["text"]!
        const textBlock = textHandler.onCreated("text")
        return [{ ...textHandler.onContent(textBlock, content), closed: true }]
    }
}

/**
 * Update message blocks with a new delta.
 * This is used to update in real-time, during streaming.
 */
export function updateBlocksWithDelta(
    previousBlocks: MessageBlock[],
    delta: string
): MessageBlock[] {
    const blockResult = getBlockForDelta(previousBlocks, delta)
    if (!blockResult) return previousBlocks

    const { block, isNew } = blockResult

    if (previousBlocks.length === 0) {
        return [block]
    }

    const newBlocks = [...previousBlocks]

    if (isNew) {
        // Close the previous block if it exists and we're creating a new one
        if (newBlocks.length > 0 && !newBlocks[newBlocks.length - 1].closed) {
            const prevBlock = newBlocks[newBlocks.length - 1]
            const result = handleClosingTag(prevBlock.type, prevBlock)
            if (result) {
                newBlocks[newBlocks.length - 1] = result.block
            }
        }
        newBlocks.push(block)
    } else {
        newBlocks[newBlocks.length - 1] = block
    }

    return newBlocks
}

export function closeLastBlock(blocks: MessageBlock[]): MessageBlock[] {
    if (blocks.length === 0) {
        return blocks
    }

    const newBlocks = [...blocks]
    const lastBlock = newBlocks[newBlocks.length - 1]

    if (!lastBlock.closed) {
        const handler = HANDLERS[lastBlock.type]
        if (handler) {
            newBlocks[newBlocks.length - 1] = handler.onClosed(lastBlock)
        }
    }

    return newBlocks
}

/**
 * Process a single delta (chunk) from a streaming response
 * and update the message blocks accordingly
 */
function getBlockForDelta(
    previousBlocks: MessageBlock[],
    delta: string
): { block: MessageBlock; isNew: boolean } | null {
    const lastBlock =
        previousBlocks.length > 0
            ? previousBlocks[previousBlocks.length - 1]
            : null

    try {
        const tag = getTag(delta)
        if (tag) {
            if (tag.isOpening) {
                return handleOpeningTag(tag.tagName)
            } else {
                return handleClosingTag(tag.tagName, lastBlock)
            }
        }

        return processTextContent(lastBlock, delta)
    } catch (error) {
        handleParsingError(delta, error)
        return null
    }
}

function processTextContent(
    lastBlock: MessageBlock | null,
    content: string
): { block: MessageBlock; isNew: boolean } {
    if (!lastBlock || lastBlock.closed) {
        return createNewTextBlock(content)
    }

    return appendContentToExistingBlock(lastBlock, content)
}

function handleParsingError(delta: string, error: unknown) {
    getHeapInstance()?.track("message-block-parsing-error", {
        tag: delta.startsWith("<")
            ? delta.slice(1, -1).replace(/^\//, "")
            : "no-tag",
        delta,
        error: error instanceof Error ? error.message : String(error),
    })
    console.error("Error parsing message block:", error)
}

function getTag(token: string): { tagName: string; isOpening: boolean } | null {
    const match = token.match(/^<(\/)?([a-zA-Z_-]+)>$/)
    if (!match) return null

    return {
        tagName: match[2],
        isOpening: !match[1],
    }
}

function handleOpeningTag(tagName: string): {
    block: MessageBlock
    isNew: boolean
} {
    let newBlock: MessageBlock

    if (tagName in HANDLERS) {
        const handler = HANDLERS[tagName as MessageBlockType]
        newBlock = handler.onCreated(tagName)
    } else {
        newBlock = createUnknownBlock(tagName)
    }

    return {
        block: newBlock,
        isNew: true,
    }
}

function createUnknownBlock(unknownTagName: string): MessageBlock {
    getHeapInstance()?.track("unknown-message-block", {
        tagName: unknownTagName,
    })

    console.warn(
        "No handler found for tag",
        unknownTagName,
        "and no unknown handler available"
    )

    return {
        type: "unknown",
        content: "",
        tag: unknownTagName.toLowerCase(),
        closed: false,
    }
}

function handleClosingTag(
    tagName: string,
    lastBlock: MessageBlock | null
): { block: MessageBlock; isNew: boolean } | null {
    if (!lastBlock || lastBlock.closed) {
        console.error("No last block found while closing tag", tagName)
        // We shouldn't close without opening
        return null
    }

    // Use the appropriate handler to close the block
    const handler = HANDLERS[lastBlock.type]
    const closedBlock = handler
        ? handler.onClosed(lastBlock)
        : { ...lastBlock, closed: true }

    return {
        block: closedBlock,
        isNew: false,
    }
}

function createNewTextBlock(content: string): {
    block: MessageBlock
    isNew: boolean
} {
    const textHandler = HANDLERS["text"]!
    const newBlock = textHandler.onCreated("text")

    return {
        block: textHandler.onContent(newBlock, content),
        isNew: true,
    }
}

function appendContentToExistingBlock(
    block: MessageBlock,
    content: string
): { block: MessageBlock; isNew: boolean } {
    const handler = HANDLERS[block.type]!
    console.assert(handler, "No handler found for block type", block.type)

    return {
        block: handler.onContent(block, content),
        isNew: false,
    }
}
