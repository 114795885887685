import { Link } from "react-router-dom"
import { ICustomInsightTestRun } from "../../../types/CustomInsights"
import { SimpleCard } from "../../common/SimpleCard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons"
import { LoadingPulse } from "../../common/LoadingPulse"
import { FieldValue } from "../../CustomInsights"

export function InsightTestResults(props: {
    results?: ICustomInsightTestRun[]
    isLoading: boolean
}) {
    return (
        <div>
            <h1 className="text-xl font-bold">Test results</h1>
            <span className="text-sm text-gray-600">
                {props.isLoading
                    ? "Running the prompt over a few of your recent calls... This might take a few seconds."
                    : `Information collected from ${
                          props.results!.length
                      } of your most recent calls`}
            </span>
            <div className="mt-5 space-y-4">
                {props.isLoading
                    ? Array.from({ length: 5 }).map((_, i) => (
                          <SimpleCard key={i}>
                              <div className="w-full space-y-3 p-4">
                                  <LoadingPulse rows={2} />
                              </div>
                          </SimpleCard>
                      ))
                    : props.results?.map((result: ICustomInsightTestRun) => (
                          <Result key={result.callId} result={result} />
                      ))}
            </div>
        </div>
    )
}

function Result(props: { result: ICustomInsightTestRun }) {
    return (
        <SimpleCard key={props.result.callId}>
            <Link
                className="w-full space-y-3 p-4 hover:bg-gray-100"
                to={`/calls/${props.result.callId}`}
                target="_blank"
            >
                <div className="flex justify-between">
                    <div className="font-semibold">
                        {props.result.callTitle}
                    </div>
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </div>
                <FieldValue
                    value={props.result.value}
                    displayValue={props.result.displayValue}
                    valueAdditionalContext={props.result.valueAdditionalContext}
                />
            </Link>
        </SimpleCard>
    )
}
