import { PageTitle } from "../PageTitle"

export interface IError {
    code?: number
    message?: string
}

export function ErrorPage(props: { error: IError }) {
    const errorCode = props.error.code || "Unknown"
    return (
        <div className="flex flex-col items-center justify-center space-y-4 px-20 pt-48">
            <PageTitle title="Oops!" />
            <h1 className="text-9xl font-bold text-delta">
                Error: {errorCode}
            </h1>
            <h2 className="text-2xl">{props.error.message}</h2>
            <p className="text-gray-500">
                Please try again or contact support if the problem persists.
            </p>
        </div>
    )
}
