import { useMemo, useState } from "react"
import { ICRMDeal, ICrmDealUpdate, getDealTypeLabel } from "../../types/Deal"
import {
    ColumnDef,
    Row,
    SortingState,
    Updater,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import { getSimpleDate, getSimpleDateWithoutYear } from "../../utils/datetime"
import DealDetailsRow from "./DealDetailsRow"
import DetailsTableHeaderGroup from "../calls-list/DetailsTableHeaderGroup"
import { CompanyLogo } from "../company-view/CompanyLogo"
import { getCacheValue, setCacheValue } from "../../utils/localStorageCache"
import { ScreenSize, screenLargerOrEqualTo } from "../../utils/screenSize"
import { ICrmDealStage } from "../crm/types/Crm"
import { ICompanyAnnotationRubricResults } from "../../types/Company"
import { QualificationScores } from "./QualificationScores"
import { StatusIcon } from "../company-view/Summary"
import { annotationTypes } from "../../utils/getAnnotationSections"
import { Permission } from "../../types/Permission"
import { hasPermission } from "../../utils/Permissions"
import { useUser } from "../../providers/UserProvider"
import { EditableCloseDateCell, EditableDealStageCell } from "./editable-cells"
import { FrigadeDealEditingTour } from "../Frigade"
import { formatMonetaryAmount } from "../../utils/monetaryAmount"

interface DealDetailsTableProps {
    deals: ICRMDeal[]
    dealStages: ICrmDealStage[]
    filterTerm: string
    annotationTags: string[]
    annotationFilters: string[]
    updateDeal: (deal: ICrmDealUpdate) => Promise<any>
}

const DEAL_TABLE_SORT_CACHE_KEY = "deal-table-sorting"

export function DealsDetailsTable(props: DealDetailsTableProps) {
    // Check if we should display qualification scores
    const dealWithRubricScores = useMemo(() => {
        return props.deals.find(
            (deal) =>
                (deal.company?.qualification_rubric_scores?.length || 0) > 0
        )
    }, [props.deals])
    const hasQualificationScores = dealWithRubricScores !== undefined
    const qualificationScoreLength =
        dealWithRubricScores?.company?.qualification_rubric_scores?.length
    const annotationFilters = props.annotationFilters
    const annotationTags = props.annotationTags
    const dealStages = props.dealStages

    const user = useUser()
    const canEditDeals =
        (user && hasPermission(user, Permission.EDIT_CRM_DEALS)) || false
    const updateDeal = props.updateDeal

    const columns = useMemo<ColumnDef<ICRMDeal, any>[]>(() => {
        return [
            {
                id: "name",
                accessorFn: (row) => row.name,
                enableGlobalFilter: true,
            },
            {
                id: "overview",
                accessorFn: (row) => row,
                header: "Deal",
                cell: (info) => (
                    <DealOverviewCell
                        deal={info.getValue()}
                        annotationFilters={annotationFilters}
                    />
                ),
                enableGlobalFilter: true,
                sortingFn: sortDealByName,
            },
            {
                id: "last_call",
                accessorFn: (row) => row.company?.last_call_time || null,
                header: "Last Call",
                cell: (info) => <DateCell date={info.getValue()} />,
                enableGlobalFilter: false,
                size: 24,
            },
            {
                id: "next_call",
                accessorFn: (row) => row.company?.next_call_time || null,
                header: "Next Call",
                cell: (info) => <DateCell date={info.getValue()} />,
                enableGlobalFilter: false,
                size: 24,
            },
            {
                id: "qualification_rubric_scores",
                accessorFn: (row) => row.company?.qualification_rubric_scores,
                header: () => (
                    <RubricScoresHeader annotationTags={annotationTags} />
                ),
                enableGlobalFilter: false,
                cell: (info) => (
                    <QualificationScores
                        scores={info.getValue()}
                        length={qualificationScoreLength || 0}
                    />
                ),
                sortingFn: sortQualificationScores,
                size: 44,
            },
            {
                id: "stage",
                accessorFn: (row) => row.stage,
                header: () => (
                    <span id="tooltip-crm-deal-edit">Deal Stage</span>
                ),
                enableGlobalFilter: false,
                cell: (info) => (
                    <>
                        {info.row.index === 0 && canEditDeals && (
                            <FrigadeDealEditingTour />
                        )}
                        <EditableDealStageCell
                            value={info.getValue()}
                            dealStages={dealStages}
                            isEditable={canEditDeals}
                            onChange={async (newStage: ICrmDealStage) => {
                                await updateDeal({
                                    id: info.row.original.id,
                                    stage: newStage,
                                })
                            }}
                        />
                    </>
                ),
                sortingFn: sortStageRow,
                size: 40,
                meta: {
                    isEditable: canEditDeals,
                },
            },
            {
                id: "close_date",
                accessorFn: (row) => row.close_date,
                header: "Close Date",
                cell: (info) => (
                    <EditableCloseDateCell
                        value={info.getValue()}
                        isEditable={canEditDeals}
                        onChange={async (newDate: string) => {
                            await updateDeal({
                                id: info.row.original.id,
                                close_date: newDate,
                            })
                        }}
                    />
                ),
                enableGlobalFilter: false,
                size: 32,
                meta: {
                    isEditable: canEditDeals,
                },
            },
            {
                id: "amount",
                accessorFn: (row) =>
                    formatMonetaryAmount(row.amount, row.currency) || "--",
                header: "Amount",
                enableGlobalFilter: false,
                size: 24,
            },
            {
                id: "deal_type",
                accessorFn: (row) => row.deal_type,
                header: "Deal Type",
                cell: (info) => (
                    <span>{getDealTypeLabel(info.getValue())}</span>
                ),
                enableGlobalFilter: false,
                size: 32,
            },
        ]
    }, [
        qualificationScoreLength,
        annotationTags,
        annotationFilters,
        canEditDeals,
        dealStages,
        updateDeal,
    ])

    const showAllColumns: boolean = screenLargerOrEqualTo(ScreenSize.md)

    const defaultSorting: SortingState = [
        { id: "close_date", desc: false },
        { id: "name", desc: false },
    ]
    if (!showAllColumns) {
        // If we're only showing a subset of columns, we should sort by the name
        // otherwise it's confusing to the user.
        defaultSorting.shift()
    }

    const [sorting, setSorting] = useState<SortingState>(
        getCacheValue(DEAL_TABLE_SORT_CACHE_KEY, defaultSorting)
    )

    const table = useReactTable({
        data: props.deals,
        columns,

        enableSorting: true,

        state: {
            columnVisibility: {
                name: false,
                overview: true,
                next_call: showAllColumns,
                last_call: showAllColumns,
                stage: showAllColumns,
                amount: showAllColumns,
                close_date: showAllColumns,
                deal_type: showAllColumns,
                qualification_rubric_scores:
                    showAllColumns && hasQualificationScores,
            },
            globalFilter: props.filterTerm,
            sorting: sorting,
        },
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),

        globalFilterFn: "includesString",

        onSortingChange: (sortUpdater: Updater<SortingState>) => {
            // When we use onSortingChange, we need to maintain the sorting state ourselves in a useState
            // https://tanstack.com/table/v8/docs/api/features/sorting#onsortingchange
            const newSorting =
                sortUpdater instanceof Function
                    ? sortUpdater(sorting)
                    : sortUpdater
            setSorting(newSorting)
            setCacheValue(DEAL_TABLE_SORT_CACHE_KEY, newSorting)
        },
    })

    return (
        <div>
            <div className="text-xs lg:text-sm">
                <table className="w-full table-fixed border-separate border-spacing-y-2">
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <DetailsTableHeaderGroup
                                key={headerGroup.id}
                                headerGroup={headerGroup}
                            />
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row) => (
                            <DealDetailsRow key={row.id} row={row} />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

function RubricScoresHeader(props: { annotationTags: string[] }) {
    const tags = annotationTypes.filter((tag) =>
        props.annotationTags.includes(tag.tag)
    )
    return (
        <span className="w-44 space-x-[2px]">
            {tags.map((tag) => (
                <span
                    key={tag.tag}
                    className="inline-flex h-[16px] w-[16px] items-center justify-center overflow-auto rounded-full border border-gray-300 bg-gray-200 text-xs"
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content={`${tag.emoji} ${tag.label}`}
                >
                    {tag.acronym}
                </span>
            ))}
        </span>
    )
}

function DealOverviewCell(props: {
    deal: ICRMDeal
    annotationFilters: string[]
}) {
    const scores = props.deal.company?.qualification_rubric_scores
    const filteredScores: ICompanyAnnotationRubricResults[] | undefined =
        scores?.filter((score) => props.annotationFilters.includes(score.tag))
    const showSummary =
        props.annotationFilters.includes("summary") &&
        props.deal.company?.summary

    return (
        <div className="w-full space-y-2">
            <div className="flex items-center space-x-2">
                <CompanyLogo
                    image_url={props.deal?.company?.image_url || null}
                />
                <span className="text-base font-semibold">
                    {props.deal.name}
                </span>
                <span>- {props.deal.owner?.name}</span>
            </div>
            {showSummary && <div className="border-b border-gray-200" />}
            {showSummary && (
                <div className="text-gray-500">
                    {props.deal.company?.summary}
                </div>
            )}
            {filteredScores && filteredScores.length > 0 && (
                <div className="border-b border-gray-200" />
            )}
            {filteredScores &&
                filteredScores.map((score) => {
                    const tooltip = `<b>${score.name}:</b><br/>${score.feedback}`
                    return (
                        <div
                            key={score.tag}
                            className="flex w-fit items-center gap-2"
                            data-tooltip-id="tooltip-explanation"
                            data-tooltip-html={tooltip}
                        >
                            <StatusIcon score={score.score} tag={score.tag} />
                            {score.feedback_headline}
                        </div>
                    )
                })}
        </div>
    )
}

function DateCell(props: { date: string | null }) {
    const displayValue = props.date
        ? getSimpleDateWithoutYear(props.date)
        : "--"
    // Show full date with year on hover
    const fullDate = props.date ? getSimpleDate(props.date) : ""
    return (
        <span
            data-tooltip-id="tooltip-explanation"
            data-tooltip-content={fullDate}
        >
            {displayValue}
        </span>
    )
}

function sortDealByName(rowA: Row<ICRMDeal>, rowB: Row<ICRMDeal>) {
    const valA = rowA.original.name
    const valB = rowB.original.name

    return valA.localeCompare(valB, undefined, { sensitivity: "base" })
}

function sortStageRow(
    rowA: Row<ICRMDeal>,
    rowB: Row<ICRMDeal>,
    columnId: string
) {
    const valA: ICrmDealStage | null = rowA.getValue(columnId)
    const valB: ICrmDealStage | null = rowB.getValue(columnId)

    return sortStage(valA, valB)
}

function sortStage(valA: ICrmDealStage | null, valB: ICrmDealStage | null) {
    const aOrder = valA?.order || 0
    const bOrder = valB?.order || 0
    return aOrder - bOrder
}

function sortQualificationScores(
    rowA: Row<ICRMDeal>,
    rowB: Row<ICRMDeal>,
    columnId: string
) {
    // Sort by sum of scores

    const valA: ICompanyAnnotationRubricResults[] | null =
        rowA.getValue(columnId)
    const valB: ICompanyAnnotationRubricResults[] | null =
        rowB.getValue(columnId)

    const sumA = valA?.reduce((acc, score) => acc + score.score, 0) || 0
    const sumB = valB?.reduce((acc, score) => acc + score.score, 0) || 0

    if (sumA === sumB) {
        return 0
    } else {
        return sumA < sumB ? 1 : -1
    }
}
