import { useState } from "react"
import { INewsArticle } from "../../types/CompanyProfile"
import { getSimpleDate } from "../../utils/datetime"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"

const NUM_NEWS_TO_SHOW_COLLAPSED = 3

interface CompanyNewsProps {
    news: INewsArticle[] | null
    isLoading?: boolean
}

export function CompanyNews(props: CompanyNewsProps) {
    const [isCollapsed, setIsCollapsed] = useState(true)

    if (props.isLoading) {
        return <LoadingNews />
    } else if (!props.news || props.news.length === 0) {
        return <MissingNews />
    }

    const newsToDisplay = isCollapsed
        ? props.news.slice(0, NUM_NEWS_TO_SHOW_COLLAPSED)
        : props.news
    const isCollapsable = props.news.length > NUM_NEWS_TO_SHOW_COLLAPSED

    return (
        <div className="flex flex-col gap-4 overflow-x-auto">
            <span className="p-1 text-base font-semibold leading-normal text-gray-900">
                News
            </span>
            <div className="space-y-6 pb-1">
                {newsToDisplay.map((article) => (
                    <NewsArticle key={article.url} article={article} />
                ))}
            </div>
            {isCollapsable && (
                <button
                    className="place-self-end p-1 text-sm font-semibold leading-tight tracking-tight text-gray-800 outline-none"
                    onClick={() => setIsCollapsed((prev) => !prev)}
                >
                    {isCollapsed ? "View more stories" : "View fewer stories"}{" "}
                    {isCollapsed ? (
                        <FontAwesomeIcon icon={faChevronDown} />
                    ) : (
                        <FontAwesomeIcon icon={faChevronUp} />
                    )}
                </button>
            )}
        </div>
    )
}

function LoadingNews() {
    return (
        <div className="flex w-full flex-col items-start gap-2 p-1">
            <span className="text-base font-semibold leading-normal text-gray-900">
                News
            </span>
            <span className="animate-pulse text-xs font-light leading-tight text-gray-800">
                Loading content
            </span>
        </div>
    )
}

function MissingNews() {
    return (
        <div className="flex w-full flex-col items-start gap-2 p-1">
            <span className="text-base font-semibold leading-normal text-gray-900">
                News
            </span>
            <span className="text-xs font-light leading-tight text-gray-800">
                No news to show
            </span>
        </div>
    )
}

function NewsArticle(props: { article: INewsArticle }) {
    const article = props.article
    return (
        <a
            className="flex flex-col items-baseline space-y-2 rounded-lg p-1 hover:bg-gray-50 hover:delay-150"
            href={article.url}
            target="_blank"
            rel="noreferrer"
        >
            <span className="text-xs font-light leading-tight text-gray-800">
                {article.publisher}
            </span>
            <span className="text-base font-bold leading-tight text-gray-800">
                {article.title}
            </span>
            {article.posted_on && (
                <span className="text-xs font-light leading-tight text-gray-800">
                    {getSimpleDate(article.posted_on)}
                </span>
            )}
        </a>
    )
}
