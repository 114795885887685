import {
    PropsWithChildren,
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useRef,
    useState,
} from "react"
import {
    Notification,
    NotificationProps,
    NotificationType,
} from "../components/common/Notifcations"

export type AddNotificationType = (
    highlight: string,
    message: ReactNode,
    type: NotificationType,
    onClose?: () => void
) => void

enum NotificationTimeout {
    Short = 6000, // 6 seconds
    Long = 15000, // 15 seconds
    Permanent = Infinity, // Never timeout
}

type NotificationContextType = {
    addNotification: AddNotificationType
}

type TimerInfo = { [notificationId: string]: NodeJS.Timeout }

const NotificationContext = createContext<NotificationContextType>({
    addNotification: () => {},
})

export function NotificationProvider(props: PropsWithChildren<{}>) {
    const [notifications, setNotifications] = useState<NotificationProps[]>([])
    const timersRef = useRef<TimerInfo>({})

    const removeNotification = useCallback((notificationId: string) => {
        setNotifications((prevNotifications) =>
            prevNotifications.filter(
                (notification) => notification.notificationId !== notificationId
            )
        )

        const timeout = timersRef.current[notificationId]
        clearTimeout(timeout)
        delete timersRef.current[notificationId]
    }, [])

    const addNotification = useCallback(
        (
            highlight: string,
            message: ReactNode,
            type: NotificationType,
            onClose?: () => void
        ) => {
            const notificationId = crypto.randomUUID()
            const newNotification: NotificationProps = {
                notificationId,
                highlight,
                message,
                type,
                onClose: () => {
                    if (onClose) {
                        onClose()
                    }
                    removeNotification(notificationId)
                },
            }

            setNotifications((prevNotifications) => [
                ...prevNotifications,
                newNotification,
            ])

            const timeoutLength = getTimeout(type)
            if (timeoutLength !== NotificationTimeout.Permanent) {
                const timeout = setTimeout(() => {
                    removeNotification(notificationId)
                }, timeoutLength)

                timersRef.current[notificationId] = timeout
            }
        },
        [removeNotification]
    )

    const contextValue: NotificationContextType = {
        addNotification,
    }

    return (
        <NotificationContext.Provider value={contextValue}>
            {props.children}
            <div className="fixed bottom-0 right-0 z-[60] mb-4 mr-4 flex flex-col gap-8 border-none bg-transparent">
                {notifications.map((notification) => (
                    <Notification
                        key={notification.notificationId}
                        {...notification}
                    />
                ))}
            </div>
        </NotificationContext.Provider>
    )
}

function getTimeout(type: NotificationType) {
    switch (type) {
        case NotificationType.Success:
            return NotificationTimeout.Short
        case NotificationType.Error:
            return NotificationTimeout.Long
        case NotificationType.Warning:
            return NotificationTimeout.Long
        case NotificationType.Info:
            return NotificationTimeout.Permanent
        case NotificationType.Broadcast:
            return NotificationTimeout.Permanent
    }
}

export const useNotification = () => useContext(NotificationContext)
