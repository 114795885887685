import { useMutation, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import { NotificationType } from "../../components/common/Notifcations"
import { useNotification } from "../../providers/NotificationProvider"
import { queries } from "../queries"

export function useUpdateOrgMemory() {
    const { addNotification } = useNotification()
    const queryClient = useQueryClient()

    const { mutate, isPending } = useMutation({
        mutationFn: async (new_memory: string) => {
            await axios.put(
                `${process.env.REACT_APP_API_DOMAIN}/organization/settings/memory`,
                { memory: new_memory }
            )
        },
        onSuccess: () => {
            addNotification(
                "Organization memory updated",
                "",
                NotificationType.Success
            )
            queryClient.invalidateQueries(queries.organization.orgMemory())
        },
        onError: () => {
            addNotification(
                "Failed to update organization memory",
                "",
                NotificationType.Error
            )
        },
    })

    return {
        mutate,
        isPending,
    }
}
