import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShareSquare as faMail } from "@fortawesome/free-regular-svg-icons"
import { CopyIcon } from "../../assets/icons/CopyIcon"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { useCallback } from "react"
import { getHeapInstance } from "../../utils/heap"
import { AnswerFeedback } from "./types/QuestionTypes"
import { ResponseFeedback } from "./ResponseFeedback"

interface MessageFooterProps {
    isComplete: boolean
    isLast: boolean
    isReadOnly?: boolean
    submitFeedback: (feedback: AnswerFeedback) => Promise<void>
    fullContent: string
    sendEmail: (message: string) => Promise<void>
}

export function MessageFooter(props: MessageFooterProps) {
    const renderFeedbackButton = useCallback(() => {
        if (props.isLast && props.isReadOnly !== true) {
            return <ResponseFeedback submitFeedback={props.submitFeedback} />
        }
        return null
    }, [props.isLast, props.isReadOnly, props.submitFeedback])

    if (!props.isComplete) return null

    return (
        <div className="group my-4 flex h-4 flex-row items-center gap-4 p-1 text-lg">
            {renderFeedbackButton()}
            <div className="block">
                <CopyMessageButton message={props.fullContent} />
            </div>
            <div className="block">
                <EmailMessageButton
                    message={props.fullContent}
                    sendEmail={props.sendEmail}
                />
            </div>
        </div>
    )
}

interface CopyMessageButtonProps {
    message: string
}

export function CopyMessageButton(props: CopyMessageButtonProps) {
    const { addNotification } = useNotification()

    const copy = useCallback(async () => {
        try {
            await navigator.clipboard.writeText(props.message)
            getHeapInstance()?.track("askglyphic-message-copied", {
                message: props.message,
            })
            addNotification("Response copied", "", NotificationType.Success)
        } catch (error) {
            console.error("Failed to copy message:", error)
        }
    }, [props.message, addNotification])

    return (
        <button
            className="flex flex-nowrap items-center gap-1 text-gray-500 hover:text-gray-800"
            onClick={copy}
            data-tooltip-id="tooltip-explanation"
            data-tooltip-content="Copy response to clipboard"
        >
            <CopyIcon width="1em" height="1em" />
            <span className="text-base">Copy</span>
        </button>
    )
}

interface EmailMessageButtonProps {
    message: string
    sendEmail: (message: string) => Promise<void>
}

export function EmailMessageButton(props: EmailMessageButtonProps) {
    const { addNotification } = useNotification()

    const handleSendEmail = useCallback(async () => {
        try {
            await props.sendEmail(props.message)
            getHeapInstance()?.track("askglyphic-message-emailed", {
                message: props.message,
            })
        } catch (error) {
            addNotification(
                "Failed to email message!",
                `${error}`,
                NotificationType.Error
            )
        }
    }, [addNotification, props])

    return (
        <button
            className="flex flex-nowrap items-center gap-1 text-gray-500 hover:text-gray-800"
            onClick={handleSendEmail}
            data-tooltip-id="tooltip-explanation"
            data-tooltip-content="Create a new email with this response"
        >
            <FontAwesomeIcon icon={faMail} width="1em" height="1em" />
            <span className="text-base">Email</span>
        </button>
    )
}
