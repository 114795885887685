import { useIntegrations } from "../../../hooks/useIntegrations"

import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import gongLogo from "../../../assets/gong-square.png"
import zoomLogo from "../../../assets/zoom-square.png"
import aircallLogo from "../../../assets/aircall.png"

import { IntegrationSetting } from "../IntegrationSetting"

import { SettingsSection } from "../Card"

import {
    deleteOAuthAccessToken,
    generateOAuthAccessToken,
    navigateToExternal,
} from "../oauth_utils"

import { useQueryClient } from "@tanstack/react-query"

import { SlackSettings } from "../SlackSettings"
import { CrmIntegrationSettings } from "../CrmIntegrationSettings"
import { OrgRecordingPreferences } from "../OrgRecordingPreferences"
import { ProductDescription } from "../ProductDescription"
import { OrgMemory } from "../OrgMemory"
import { BaseSettingsPage } from "./BaseSettingsPage"

export const callbackParams = [
    "gongCallback",
    "zoomCallback",
    "aircallCallback",
    "slackCallback",
]

export function OrganisationalPage() {
    const queryClient = useQueryClient()

    const { orgName, hasGong, hasZoom, hasSlack } = useIntegrations()

    const refetchOrg = () => {
        queryClient.refetchQueries({
            queryKey: ["organization"],
        })
    }

    return (
        <BaseSettingsPage pageTitle={"Organizational Settings"}>
            <CrmIntegrationSettings />

            <SettingsSection
                title="Messaging"
                description="Connect Glyphic to your Slack workspace. This will give your team access to call prep sheets and call analysis right in Slack"
            >
                <SlackSettings
                    authorized={hasSlack}
                    setAuthorized={refetchOrg}
                />
            </SettingsSection>
            <OrgRecordingPreferences orgName={orgName} />
            <SettingsSection
                title="Call recorder"
                description="Integrate a company wide call recorder to import past calls & process upcoming calls."
            >
                <GongSettings authorized={hasGong} setAuthorized={refetchOrg} />
                <ZoomSettings authorized={hasZoom} setAuthorized={refetchOrg} />
                <AircallSettings
                    authorized={hasZoom}
                    setAuthorized={refetchOrg}
                />
            </SettingsSection>
            <ProductDescription orgName={orgName} />
            <OrgMemory orgName={orgName} />
        </BaseSettingsPage>
    )
}

function GongSettings(props: {
    authorized: boolean
    setAuthorized: (b: boolean) => void
}) {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const setAuthorized = props.setAuthorized
    const oauthCallbackUrl = `${process.env.REACT_APP_WEBSITE_DOMAIN}/settings?gongCallback=true`

    useEffect(() => {
        async function generateTokenFromCallback() {
            if (searchParams.get("gongCallback") === "true") {
                // If we're loading settings page from Gong OAuth callback,
                // send req to backend to generate auth token
                const gongAuthCode = searchParams.get("code")!
                if (gongAuthCode) {
                    const success = await generateOAuthAccessToken(
                        "gong_token",
                        gongAuthCode,
                        oauthCallbackUrl
                    )
                    if (success) {
                        setAuthorized(true)
                    }
                }
                // If there is no gongAuthCode passed in the callback, the user has likely denied granting access
                navigate("/settings/organizational")
            }
        }
        generateTokenFromCallback()
    }, [searchParams, navigate, setAuthorized, oauthCallbackUrl])

    async function deleteGongToken() {
        const success = await deleteOAuthAccessToken("gong_token")
        if (success) {
            setAuthorized(false)
        }
    }

    return (
        <IntegrationSetting
            name="Gong"
            logo={gongLogo}
            onEnable={async () => navigateToExternal(gongUrl(oauthCallbackUrl))}
            onDisable={deleteGongToken}
            authorized={props.authorized}
        />
    )
}

function gongUrl(callbackUrl: string): string {
    const gongUrlPrefix = "https://app.gong.io/oauth2/authorize?"

    let url = new URL(gongUrlPrefix)
    url.searchParams.set(
        "client_id",
        process.env.REACT_APP_GONG_COPILOT_APP_ID!
    )
    url.searchParams.set("response_type", "code")
    url.searchParams.set("redirect_uri", callbackUrl)

    return url.toString()
}

function ZoomSettings(props: {
    authorized: boolean
    setAuthorized: (b: boolean) => void
}) {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const setAuthorized = props.setAuthorized
    const zoomCallbackUrl = `${process.env.REACT_APP_WEBSITE_DOMAIN}/settings?zoomCallback=true`

    useEffect(() => {
        async function generateTokenFromCallback() {
            if (searchParams.get("zoomCallback") === "true") {
                // If we're loading settings page from Zoom OAuth callback,
                // send req to backend to generate auth token
                const zoomAuthCode = searchParams.get("code")!
                if (zoomAuthCode) {
                    const success = await generateOAuthAccessToken(
                        "zoom_token",
                        zoomAuthCode,
                        zoomCallbackUrl
                    )
                    if (success) {
                        setAuthorized(true)
                    }
                }
                // If there is no zoomAuthCode passed in the callback, the user has likely denied granting access
                navigate("/settings/organizational")
            }
        }
        generateTokenFromCallback()
    }, [searchParams, navigate, setAuthorized, zoomCallbackUrl])

    async function deleteZoomToken() {
        const success = await deleteOAuthAccessToken("zoom_token")
        if (success) {
            setAuthorized(false)
        }
    }

    return (
        <IntegrationSetting
            name="Zoom Cloud Recordings"
            logo={zoomLogo}
            onEnable={async () => navigateToExternal(zoomUrl())}
            onDisable={async () => deleteZoomToken()}
            authorized={props.authorized}
        />
    )
}

function AircallSettings(props: {
    authorized: boolean
    setAuthorized: (b: boolean) => void
}) {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const setAuthorized = props.setAuthorized
    const aircallCallbackUrl = `${process.env.REACT_APP_WEBSITE_DOMAIN}/settings?aircallCallback=true`

    useEffect(() => {
        async function generateTokenFromCallback() {
            if (searchParams.get("aircallCallback") === "true") {
                const aircallAuthCode = searchParams.get("code")!
                if (aircallAuthCode) {
                    const success = await generateOAuthAccessToken(
                        "aircall_token",
                        aircallAuthCode,
                        aircallCallbackUrl
                    )
                    if (success) {
                        setAuthorized(true)
                    }
                }
                navigate("/settings/organizational")
            }
        }
        generateTokenFromCallback()
    }, [searchParams, navigate, setAuthorized, aircallCallbackUrl])

    async function deleteAircallToken() {
        const success = await deleteOAuthAccessToken("aircall_token")
        if (success) {
            setAuthorized(false)
        }
    }

    return (
        <IntegrationSetting
            name="Aircall"
            logo={aircallLogo}
            onEnable={async () => {
                navigateToExternal(aircallUrl())
            }}
            onDisable={async () => deleteAircallToken()}
            authorized={props.authorized}
        />
    )
}

function zoomUrl() {
    const zoomUrlPrefix = "https://zoom.us/oauth/authorize?"
    const redirect_uri = `${process.env.REACT_APP_WEBSITE_DOMAIN}/settings?zoomCallback=true`

    let url = new URL(zoomUrlPrefix)
    url.searchParams.set(
        "client_id",
        process.env.REACT_APP_ZOOM_COPILOT_APP_ID! // TODO replace with production client ID?
    )
    url.searchParams.set("response_type", "code")
    url.searchParams.set("redirect_uri", redirect_uri)

    return url.toString()
}

function aircallUrl() {
    const aircallUrlPrefix = "https://dashboard.aircall.io/oauth/authorize?"
    const redirect_uri = `${process.env.REACT_APP_WEBSITE_DOMAIN}/settings?aircallCallback=true`

    let url = new URL(aircallUrlPrefix)
    url.searchParams.set("client_id", process.env.REACT_APP_AIRCALL_CLIENT_ID!)
    url.searchParams.set("response_type", "code")
    url.searchParams.set("redirect_uri", redirect_uri)
    url.searchParams.set("scope", "public_api")

    return url.toString()
}
