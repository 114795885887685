import { useEffect, useMemo, useRef, useState } from "react"
import { ICallComment, IEmojiReaction } from "../../types/Comment"
import {
    getFormattedDateTime,
    getRelativeToNow,
    secondsToTimestamp,
    timestampToSeconds,
} from "../../utils/datetime"
import { UserCircle } from "../UserCircle"
import {
    DangerousButton,
    PrimaryButton,
    SecondaryButton,
} from "../common/Buttons"
import {
    useAddComment,
    useDeleteComment,
    useUpdateComment,
    useUpdateCommentReaction,
} from "../../api/mutations/call-comments"
import { useUser } from "../../providers/UserProvider"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faEllipsis,
    faPencil,
    faTrashCan,
} from "@fortawesome/pro-solid-svg-icons"
import { Modal } from "../common/Modal"
import { Mention, MentionsInput } from "react-mentions"
import { useQuery } from "@tanstack/react-query"
import { queries } from "../../api/queries"
import clsx from "clsx"
import { addEmojiReactions } from "./utils/emojis"
import { EmojiReactionDisplay, EmojiReactionPicker } from "./EmojiReactions"
import { getHeapInstance } from "../../utils/heap"
import { faLink } from "@fortawesome/free-solid-svg-icons"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"

// @[John Doe](11fcbc0a1383a7a07c293501)
const MENTION_REGEX = /@\[(.*?)\]\((.*?)\)/g

export function Comment(props: {
    callId: string
    comment: ICallComment
    goToTimestamp: (timestamp: string) => void
    parentId?: string
    hideReplyButton?: boolean
    highlighted?: boolean
}) {
    const isReply = !!props.parentId
    const user = useUser()
    const { addNotification } = useNotification()

    const { mutateAsync: addComment, isPending: isAddingComment } =
        useAddComment()

    const { mutateAsync: updateComment, isPending: isUpdatingComment } =
        useUpdateComment()

    const { mutateAsync: deleteComment } = useDeleteComment()

    const { mutateAsync: updateCommentReaction } = useUpdateCommentReaction()

    const [showReplyBox, setShowReplyBox] = useState(false)

    const isByUser = user?.id === props.comment.author.user_id

    const [isEditing, setIsEditing] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const [selectedEmojis, setSelectedEmojis] = useState<IEmojiReaction[]>(
        props.comment.emoji_reactions || []
    )

    const onSelectEmoji = (emoji: string) => {
        const { updatedEmojis, isUpdated } = addEmojiReactions(
            selectedEmojis,
            emoji,
            user!
        )

        if (isUpdated) {
            setSelectedEmojis(updatedEmojis)

            updateCommentReaction({
                callId: props.callId,
                commentId: props.comment.id,
                emoji_reactions: updatedEmojis,
            })

            getHeapInstance()?.track("comment-reacted", {
                emoji,
            })
        }
    }

    const handleEmojiClick = (emoji: string) => {
        const updatedEmojis = selectedEmojis
            .map((reaction) => {
                if (reaction.emoji !== emoji) {
                    return reaction
                }
                if (reaction.users.some((u) => u.user_id === user!.id)) {
                    return {
                        ...reaction,
                        count: reaction.count - 1,
                        users: reaction.users.filter(
                            (u) => u.user_id !== user!.id
                        ),
                    }
                }
                return {
                    ...reaction,
                    count: reaction.count + 1,
                    users: [
                        ...reaction.users,
                        { name: user!.name, user_id: user!.id },
                    ],
                }
            })
            .filter((reaction) => reaction.count > 0)

        setSelectedEmojis(updatedEmojis)

        updateCommentReaction({
            callId: props.callId,
            commentId: props.comment.id,
            emoji_reactions: updatedEmojis,
        })

        getHeapInstance()?.track("comment-reacted", {
            emoji,
        })
    }

    if (isEditing) {
        return (
            <CommentBox
                timestamp={
                    isReply
                        ? undefined
                        : timestampToSeconds(props.comment.call_timestamp)
                }
                content={props.comment.content}
                onSubmit={async (content) => {
                    await updateComment({
                        callId: props.callId,
                        commentId: props.comment.id,
                        content,
                    })
                    getHeapInstance()?.track("comment-edited")
                    setIsEditing(false)
                }}
                disabled={isUpdatingComment}
                onDismiss={() => setIsEditing(false)}
            />
        )
    }

    const handleReply = async (content: string) => {
        await addComment({
            callId: props.callId,
            content,
            call_timestamp: props.comment.call_timestamp,
            parent_id: props.parentId || props.comment.id,
        })
        getHeapInstance()?.track("comment-replied")
        setShowReplyBox(false)
    }

    const showNestedArea =
        props.comment.replies.length > 0 || (showReplyBox && !isReply)

    return (
        <div>
            <div
                className={clsx(
                    "group relative rounded-lg p-3 shadow-md shadow-gray-200",
                    props.highlighted ? "bg-gray-100" : "bg-white"
                )}
            >
                <div className="absolute right-0 top-0 mr-2 mt-2">
                    <CommentOptions
                        isByUser={isByUser}
                        onEdit={() => setIsEditing(true)}
                        onDelete={() => setShowDeleteModal(true)}
                        onShare={() => {
                            const linkId = isReply
                                ? props.parentId
                                : props.comment.id
                            navigator.clipboard.writeText(
                                `${window.location.origin}/calls/${props.callId}?commentId=${linkId}`
                            )
                            addNotification(
                                "Link copied to clipboard",
                                "",
                                NotificationType.Info
                            )
                        }}
                    />
                </div>

                {!isReply && (
                    <button
                        className="font-bold hover:underline"
                        onClick={() =>
                            props.goToTimestamp(props.comment.call_timestamp)
                        }
                    >
                        {props.comment.call_timestamp}
                    </button>
                )}
                <CommentContent
                    content={props.comment.content}
                    lastEditedAt={props.comment.content_last_edited_at_utc}
                />
                <div className="mt-3 flex items-center">
                    {!props.hideReplyButton && (
                        <button
                            className="font-bold text-blue-500 transition-colors hover:text-blue-600"
                            onClick={() => setShowReplyBox(true)}
                        >
                            Reply
                        </button>
                    )}
                    <EmojiReactionPicker onSelectEmoji={onSelectEmoji} />
                    <div className="ml-auto flex items-center gap-2">
                        <Author commenter={props.comment.author} />
                        <span
                            className="text-gray-500"
                            data-tooltip-id="tooltip-explanation"
                            data-tooltip-content={getFormattedDateTime(
                                props.comment.created_at_utc
                            )}
                        >
                            {getRelativeToNow(props.comment.created_at_utc)}
                        </span>
                    </div>
                </div>
                <EmojiReactionDisplay
                    emojis={selectedEmojis}
                    handleEmojiClick={handleEmojiClick}
                    user={user!}
                />
            </div>
            {showNestedArea && (
                <div className="ml-6 mt-3 border-l-2 border-gray-200 pl-4">
                    {props.comment.replies.map((reply, index) => {
                        const isLastReply =
                            index === props.comment.replies.length - 1
                        return (
                            <div key={index} className="mt-2 first:mt-0">
                                <Comment
                                    callId={props.callId}
                                    comment={reply}
                                    goToTimestamp={props.goToTimestamp}
                                    parentId={props.comment.id}
                                    hideReplyButton={!isLastReply}
                                    highlighted={props.highlighted}
                                />
                            </div>
                        )
                    })}
                    {showReplyBox && (
                        <div className="mt-2">
                            <CommentBox
                                onSubmit={handleReply}
                                disabled={isAddingComment}
                                onDismiss={() => setShowReplyBox(false)}
                            />
                        </div>
                    )}
                </div>
            )}
            {showReplyBox && !showNestedArea && (
                <CommentBox
                    onSubmit={handleReply}
                    disabled={isAddingComment}
                    onDismiss={() => setShowReplyBox(false)}
                />
            )}
            <CommentDeleteModal
                isOpen={showDeleteModal}
                setIsOpen={setShowDeleteModal}
                comment={props.comment}
                onDelete={async () => {
                    await deleteComment({
                        callId: props.callId,
                        commentId: props.comment.id,
                    })
                    getHeapInstance()?.track("comment-deleted")
                }}
            />
        </div>
    )
}

export function CommentBox(props: {
    onSubmit: (content: string) => void
    disabled: boolean
    timestamp?: number
    onDismiss?: () => void
    content?: string
}) {
    const commentBoxRef = useRef<HTMLDivElement>(null)
    const [content, setContent] = useState(props.content || "")

    const handleSubmit = async () => {
        const trimmedContent = content.trim()
        if (!trimmedContent) return

        try {
            await props.onSubmit(trimmedContent)
            setContent("")
        } catch (error) {
            console.error(error)
        }
    }

    const onDismiss = props.onDismiss
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                onDismiss &&
                !content &&
                commentBoxRef.current &&
                !commentBoxRef.current.contains(event.target as Node)
            ) {
                onDismiss()
            }
        }

        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [onDismiss, content])

    return (
        <div
            ref={commentBoxRef}
            className="flex flex-col rounded-lg bg-white p-3 shadow-md shadow-gray-200"
        >
            {props.timestamp !== undefined && (
                <span className="font-bold">
                    {secondsToTimestamp(props.timestamp)}
                </span>
            )}

            <CommentBoxMentionsInput
                content={content}
                setContent={setContent}
                onKeyDown={(event) => {
                    if (event.key === "Enter" && !event.shiftKey) {
                        event.preventDefault()
                        handleSubmit()
                    }
                }}
            />
            <div className="mt-3 flex justify-end gap-2">
                {props.onDismiss && (
                    <SecondaryButton onClick={props.onDismiss}>
                        Cancel
                    </SecondaryButton>
                )}
                <PrimaryButton
                    onClick={() => handleSubmit()}
                    disabled={props.disabled || !content.trim()}
                >
                    {props.content ? "Save" : "Comment"}
                </PrimaryButton>
            </div>
        </div>
    )
}

function CommentContent({
    content,
    lastEditedAt,
}: {
    content: string
    lastEditedAt: string | null
}) {
    const contentElements = useMemo(() => {
        const elements: React.ReactNode[] = []
        let lastIndex = 0

        let mentionMatch
        while ((mentionMatch = MENTION_REGEX.exec(content)) !== null) {
            // Append any text before the current match.
            if (mentionMatch.index > lastIndex) {
                elements.push(content.substring(lastIndex, mentionMatch.index))
            }
            const name = mentionMatch[1]
            elements.push(
                <span
                    key={mentionMatch.index}
                    className="font-semibold text-slate-500"
                >
                    @{name}
                </span>
            )
            lastIndex = MENTION_REGEX.lastIndex
        }

        // Append any remaining text after the last match.
        if (lastIndex < content.length) {
            elements.push(content.substring(lastIndex))
        }

        return elements
    }, [content])

    return (
        <div className="whitespace-pre-wrap">
            {contentElements}
            {lastEditedAt && (
                <span
                    className="ml-1 text-sm text-gray-500"
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content={getFormattedDateTime(lastEditedAt)}
                >
                    {"(edited)"}
                </span>
            )}
        </div>
    )
}

function CommentOptions(props: {
    isByUser: boolean
    onEdit: () => void
    onDelete: () => void
    onShare: () => void
}) {
    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <button className="flex h-8 w-8 items-center justify-center rounded-full hover:bg-gray-100 focus:outline-none">
                    <FontAwesomeIcon
                        icon={faEllipsis}
                        className="h-4 w-4 text-gray-400"
                    />
                </button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
                <DropdownMenu.Content
                    className="z-10 rounded-md border border-gray-200 bg-white shadow-lg"
                    sideOffset={2}
                    alignOffset={10}
                    align="end"
                >
                    <DropdownMenu.Item className="text-sm text-gray-700 hover:bg-gray-100 focus:outline-none">
                        <button
                            className="flex items-center gap-2 px-4 py-2"
                            onClick={props.onShare}
                        >
                            <FontAwesomeIcon
                                icon={faLink}
                                className="h-4 w-4"
                            />
                            Copy link
                        </button>
                    </DropdownMenu.Item>
                    {props.isByUser && (
                        <>
                            <DropdownMenu.Item className="text-sm text-gray-700 hover:bg-gray-100 focus:outline-none">
                                <button
                                    className="flex items-center gap-2 px-4 py-2"
                                    onClick={props.onEdit}
                                >
                                    <FontAwesomeIcon
                                        icon={faPencil}
                                        className="h-4 w-4"
                                    />
                                    Edit
                                </button>
                            </DropdownMenu.Item>

                            <DropdownMenu.Item className="text-sm text-gray-700 hover:bg-gray-100 focus:outline-none">
                                <button
                                    className="flex items-center gap-2 px-4 py-2"
                                    onClick={props.onDelete}
                                >
                                    <FontAwesomeIcon
                                        icon={faTrashCan}
                                        className="h-4 w-4"
                                    />
                                    Delete
                                </button>
                            </DropdownMenu.Item>
                        </>
                    )}
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    )
}

function CommentDeleteModal(props: {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    onDelete: () => void
    comment: ICallComment
}) {
    return (
        <Modal isOpen={props.isOpen} onClose={() => props.setIsOpen(false)}>
            <div className="space-y-4 p-4">
                <div className="text-md mr-10 font-semibold">
                    Are you sure you want to delete this comment?
                </div>
                {props.comment.replies.length > 0 && (
                    <div className="text-sm text-gray-500">
                        Deleting it will also delete all its replies.
                    </div>
                )}
                <div className="flex justify-end gap-4">
                    <SecondaryButton onClick={() => props.setIsOpen(false)}>
                        Cancel
                    </SecondaryButton>
                    <DangerousButton
                        onClick={async () => {
                            await props.onDelete()
                            props.setIsOpen(false)
                        }}
                    >
                        Delete
                    </DangerousButton>
                </div>
            </div>
        </Modal>
    )
}

function CommentBoxMentionsInput(props: {
    content: string
    setContent: (content: string) => void
    onKeyDown: (
        event:
            | React.KeyboardEvent<HTMLTextAreaElement>
            | React.KeyboardEvent<HTMLInputElement>
    ) => void
}) {
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const { data: orgUsers } = useQuery(queries.users.list())

    const style = {
        input: {
            border: "none",
            outline: "none",
        },
        suggestions: {
            list: {
                border: "1px solid rgba(0,0,0,0.15)",
                borderRadius: "0.375rem",
                overflow: "auto",
                maxHeight: "200px",
            },
        },
    }

    const initialContent = useRef(props.content)
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
            inputRef.current.setSelectionRange(
                initialContent.current.length,
                initialContent.current.length
            )
        }
    }, [])

    return (
        <MentionsInput
            inputRef={inputRef}
            rows={1}
            placeholder="Leave a comment..."
            value={props.content}
            onChange={(event) => props.setContent(event.target.value)}
            onKeyDown={props.onKeyDown}
            style={style}
            allowSuggestionsAboveCursor={true}
        >
            <Mention
                trigger="@"
                data={
                    orgUsers?.map((user) => ({
                        id: user.id,
                        display: user.name || user.email,
                    })) || []
                }
                renderSuggestion={(
                    suggestion,
                    search,
                    highlightedDisplay,
                    index,
                    focused
                ) => (
                    <UserSuggestion suggestion={suggestion} focused={focused} />
                )}
                displayTransform={(_, display) => `@${display}`}
                className="bg-gray-100"
            />
        </MentionsInput>
    )
}

function UserSuggestion(props: {
    suggestion: { id: string | number; display?: string }
    focused: boolean
}) {
    return (
        <div
            className={clsx(
                "flex items-center gap-2 px-3 py-2",
                props.focused && "bg-gray-100"
            )}
        >
            <UserCircle
                size="24px"
                user={{
                    name: props.suggestion.display,
                }}
            />
            <span className="font-semibold">{props.suggestion.display}</span>
        </div>
    )
}

function Author(props: { commenter: ICallComment["author"] }) {
    return (
        <div className="flex items-center gap-2">
            <UserCircle size="24px" user={props.commenter} />
            <span className="font-bold">{props.commenter.name}</span>
        </div>
    )
}
