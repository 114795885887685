import { IEmojiReaction } from "../../../types/Comment"
import { IUser } from "../../../types/User"

export const addEmojiReactions = (
    selectedEmojis: IEmojiReaction[],
    emoji: string,
    user: IUser
): {
    updatedEmojis: IEmojiReaction[]
    isUpdated: boolean
} => {
    const reaction = selectedEmojis.find((e) => e.emoji === emoji)

    if (!reaction) {
        const newReaction: IEmojiReaction = {
            emoji,
            count: 1,
            users: [{ name: user.name, user_id: user.id }],
        }
        return {
            updatedEmojis: [...selectedEmojis, newReaction],
            isUpdated: true,
        }
    }

    if (reaction.users.some((u) => u.user_id === user.id)) {
        return {
            updatedEmojis: selectedEmojis,
            isUpdated: false,
        }
    }

    const newReaction: IEmojiReaction = {
        ...reaction,
        count: reaction.count + 1,
        users: [...reaction.users, { name: user.name, user_id: user.id }],
    }

    return {
        updatedEmojis: selectedEmojis.map((r) =>
            r.emoji === emoji ? newReaction : r
        ),
        isUpdated: true,
    }
}
