import clsx from "clsx"
import { PageTitle } from "../../PageTitle"

export function BaseSettingsPage(props: {
    children: React.ReactNode
    pageTitle: string
    alwaysFullWidth?: boolean
}) {
    return (
        <div className="px-6 py-6">
            <PageTitle title={`Settings | ${props.pageTitle}`} />
            <div
                className={clsx(
                    "mx-auto flex w-full flex-col gap-6",
                    !props.alwaysFullWidth && "md:max-w-2xl",
                    props.alwaysFullWidth && "hidden sm:block"
                )}
            >
                {props.children}
            </div>
            {props.alwaysFullWidth && (
                <p className="mt-40 text-center text-sm text-gray-500 sm:hidden">
                    Please switch to desktop to access this page
                </p>
            )}
        </div>
    )
}
