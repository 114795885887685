import _ from "lodash"
import { useIntegrationApp } from "@integration-app/react"
import { useQuery } from "@tanstack/react-query"
import { useCallback, useEffect, useMemo, useState } from "react"
import { queries } from "../../../api/queries"
import { StepBubble } from "./StepBubble"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons"
import Select, { SingleValue } from "react-select"
import { LoadingPulse } from "../../common/LoadingPulse"
import {
    CrmFieldSchema,
    CrmFieldsSchemaResponse,
} from "../../../types/Settings"
import clsx from "clsx"
import { IChecklistOption, ICrmFieldType } from "../../crm/types/Crm"
import { SecondaryButton } from "../../common/Buttons"
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons"
import { faInfoCircle, faXmark } from "@fortawesome/free-solid-svg-icons"
import { getFieldType } from "../../../utils/getFieldTypeFromCrmSchema"
import { useLocation } from "react-router-dom"
import { Tooltip } from "react-tooltip"

type CrmField = CrmFieldSchema & {
    name: string
}

export function OutputConfigurationSection(props: {
    selectedTable: string | undefined
    selectedField: string | undefined
    selectedFieldType: ICrmFieldType | undefined
    checklistOptions?: IChecklistOption[]
    onCrmFieldSelect: (
        table: string | undefined,
        field: string | undefined
    ) => void
    onTypeSelect: (fieldType: ICrmFieldType | undefined) => void
    onChecklistOptionsChange: (options: IChecklistOption[]) => void
}) {
    const { state } = useLocation()
    const fromPage = state?.from as string | undefined
    // If we are coming from the company page, we default to pushing to the CRM
    const defaultToCrmOption = fromPage === "company"

    const crmClient = useIntegrationApp()
    const { data: availableCrmType, isPending: isPendingCrmType } = useQuery(
        queries.crm.availableCrmType(crmClient)
    )

    // We load the schema here but pass it all down to a different component
    // so that it starts loading early, but the display logic is in the
    // CrmFieldSelect component
    const { data: crmSchema, isPending: isPendingCrmSchema } = useQuery(
        queries.customInsights.crmSchema()
    )

    const [pushToCrm, setPushToCrm] = useState(defaultToCrmOption)

    const hasCrmConnected = useMemo(() => {
        return !!availableCrmType
    }, [availableCrmType])

    useEffect(() => {
        if (props.selectedTable && props.selectedField) {
            setPushToCrm(true)
        }
    }, [props, setPushToCrm])

    const handlePushToCrmChange = useCallback(
        (pushToCrm: boolean) => {
            setPushToCrm(pushToCrm)
            props.onTypeSelect(undefined)
            if (!pushToCrm) {
                props.onCrmFieldSelect(undefined, undefined)
                props.onChecklistOptionsChange([])
            }
        },
        [props, setPushToCrm]
    )

    function onCrmFieldSelect(
        table: string | undefined,
        field: CrmField | undefined
    ) {
        // Set field
        props.onCrmFieldSelect(table, field?.name)

        if (field) {
            // Set field type
            const fieldType = getFieldType(field)
            if (fieldType) {
                props.onTypeSelect(fieldType)
            }

            // Set checklist options
            if (fieldType === ICrmFieldType.MultiChecklist) {
                const options = field.items?.referenceRecords || []
                props.onChecklistOptionsChange(options)
            } else if (fieldType === ICrmFieldType.Checklist) {
                const options = field.referenceRecords || []
                props.onChecklistOptionsChange(options)
            } else {
                props.onChecklistOptionsChange([])
            }
        }
    }

    return (
        <section id="configuration-section" className="mb-8 space-y-4">
            <div className="space-y-5">
                <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-3">
                        <StepBubble step={3} />
                        <h1 className="text-2xl font-bold">Output</h1>
                    </div>

                    <h2 className="text-lg font-bold">
                        Would you like to push the information to your CRM?
                    </h2>
                    <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2">
                            <input
                                type="radio"
                                id="crm-no"
                                name="crm-push"
                                className="h-4 w-4"
                                checked={!pushToCrm}
                                onChange={() => handlePushToCrmChange(false)}
                            />
                            <label htmlFor="crm-no">No</label>
                        </div>
                        <div className="flex items-center gap-2">
                            <input
                                type="radio"
                                id="crm-yes"
                                name="crm-push"
                                className="h-4 w-4"
                                checked={pushToCrm}
                                onChange={() => handlePushToCrmChange(true)}
                                disabled={!hasCrmConnected}
                            />
                            <label
                                htmlFor="crm-yes"
                                className={
                                    !hasCrmConnected ? "text-gray-400" : ""
                                }
                            >
                                Yes
                            </label>
                            {isPendingCrmType && (
                                <span className="text-sm text-gray-500">
                                    Checking CRM connection...
                                </span>
                            )}
                            {!hasCrmConnected && (
                                <span className="text-sm text-gray-500">
                                    You must connect to a CRM first
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="mt-4 flex flex-col gap-4 border-l-2 border-gray-200 pl-4">
                        {pushToCrm && (
                            <CrmSyncSettings
                                schema={crmSchema}
                                isLoading={isPendingCrmSchema}
                                onSelect={onCrmFieldSelect}
                                selectedTable={props.selectedTable}
                                selectedField={props.selectedField}
                            />
                        )}
                        {(!pushToCrm || props.selectedFieldType) && (
                            <AnswerFormatSection
                                selectedFieldType={props.selectedFieldType}
                                checklistOptions={props.checklistOptions}
                                onTypeSelect={props.onTypeSelect}
                                onChecklistOptionsChange={
                                    props.onChecklistOptionsChange
                                }
                                disabled={pushToCrm}
                            />
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}

function CrmSyncSettings(props: {
    schema: CrmFieldsSchemaResponse | undefined
    onSelect: (table: string | undefined, field: CrmField | undefined) => void
    isLoading: boolean
    selectedTable: string | undefined
    selectedField: string | undefined
}) {
    const { state } = useLocation()
    const fromPage = state?.from as string | undefined

    const availableTables = useMemo(() => {
        return props.schema ? Object.keys(props.schema) : []
    }, [props.schema])

    const availableFields = useMemo(() => {
        if (!props.selectedTable || !props.schema) {
            return []
        }
        const fields =
            props.schema[props.selectedTable as keyof CrmFieldsSchemaResponse]

        return Object.entries(fields).map(([name, field]) => ({
            name,
            ...field,
        }))
    }, [props.selectedTable, props.schema])

    const updateMethodInfo = useMemo(() => {
        if (!props.schema || !props.selectedTable || !props.selectedField) {
            return null
        }

        const selectedFieldSchema =
            props.schema[props.selectedTable as keyof CrmFieldsSchemaResponse][
                props.selectedField
            ]
        const fieldType = getFieldType(selectedFieldSchema)

        let updateType: string
        switch (fieldType) {
            case ICrmFieldType.Text:
            case ICrmFieldType.MultiChecklist:
                updateType = "Append"
                break
            case ICrmFieldType.Number:
            default:
                updateType = "Does not overwrite"
                break
        }

        return {
            updateType,
            dataMergeExplanation: getDataMergeExplanation(fieldType),
        }
    }, [props.selectedTable, props.selectedField, props.schema])

    const handleTableChange = useCallback(
        (tableName: string) => {
            props.onSelect(tableName, undefined)
        },
        [props]
    )

    const handleFieldChange = useCallback(
        (field: CrmField) => {
            props.onSelect(props.selectedTable, field)
        },
        [props]
    )

    return (
        <div>
            {props.isLoading ? (
                <LoadingPulse rows={2} height="h-16" />
            ) : (
                <>
                    {fromPage === "company" &&
                        props.selectedTable !== "companies" && (
                            <CompanyInsightsWarning />
                        )}
                    <div className="flex flex-col gap-4">
                        <div className="space-y-1">
                            <label className="font-semibold">CRM object</label>
                            <CrmTableSelect
                                availableTables={availableTables}
                                value={props.selectedTable}
                                onChange={handleTableChange}
                            />
                        </div>
                        <div className="space-y-1">
                            <label className="font-semibold">CRM field</label>
                            <CrmFieldSelect
                                availableFields={availableFields}
                                value={props.selectedField}
                                onChange={handleFieldChange}
                            />
                            <p className="mt-1 text-xs text-gray-500">
                                Please select an existing field. We will use the
                                configuration of the field to determine the
                                output
                            </p>
                        </div>
                    </div>
                    {updateMethodInfo && (
                        <div className="mt-4 space-y-1">
                            <label className="font-semibold">
                                Update method
                            </label>
                            <div className="flex items-center gap-2">
                                <div className="rounded border border-gray-200 p-2 text-gray-700">
                                    {updateMethodInfo.updateType}
                                </div>
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    className="h-4 w-4 cursor-help text-gray-400"
                                    data-tooltip-id="tooltip-explanation"
                                    data-tooltip-content={
                                        updateMethodInfo.dataMergeExplanation
                                    }
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

function getDataMergeExplanation(
    fieldType?: ICrmFieldType | null
): string | null {
    if (!fieldType) return null

    const headerExplanation =
        "After each call is processed, when syncing to your CRM, if the field already contains data, "

    switch (fieldType) {
        case ICrmFieldType.Text:
            return (
                headerExplanation +
                "we'll add the new information on a new line, preserving all existing text."
            )
        case ICrmFieldType.MultiChecklist:
            return (
                headerExplanation +
                "we'll add any new detected options while keeping all previously selected ones."
            )
        case ICrmFieldType.Number:
            return (
                headerExplanation +
                "we'll keep the existing value to prevent unintended changes."
            )
        default:
            return (
                headerExplanation + "we'll keep the existing value unchanged."
            )
    }
}

function CrmTableSelect(props: {
    availableTables: string[]
    value: string | undefined
    onChange: (tableName: string) => void
}) {
    const options = useMemo(
        () =>
            props.availableTables.map((key) => ({
                value: key,
                label: _.startCase(key),
            })),
        [props]
    )

    const selectedOption = useMemo(
        () => options.find((opt) => opt.value === props.value) || null,
        [options, props]
    )

    const handleChange = useCallback(
        (option: SingleValue<{ value: string; label: string }>) => {
            props.onChange(option?.value as string)
        },
        [props]
    )

    return (
        <Select
            className="w-full"
            options={options}
            placeholder="Type to search your CRM"
            value={selectedOption}
            onChange={handleChange}
            menuPlacement="top"
            aria-label="Select CRM table"
            isSearchable
            styles={{
                control: (base) => ({
                    ...base,
                    minHeight: "40px",
                    borderColor: "#e5e7eb",
                }),
            }}
        />
    )
}

function CrmFieldSelect(props: {
    availableFields: CrmField[]
    value: string | undefined
    onChange: (field: CrmField) => void
}) {
    const options = useMemo(
        () =>
            props.availableFields.map((field) => ({
                value: field.name,
                label: field.title || field.name,
            })),
        [props]
    )

    const selectedOption = useMemo(() => {
        const option = options.find((opt) => opt.value === props.value)
        if (option) {
            return option
        }
        return null
    }, [options, props])

    const handleChange = useCallback(
        (option: SingleValue<{ value: string; label: string }>) => {
            const field = props.availableFields.find(
                (f) => f.name === option?.value
            )
            if (field) {
                props.onChange(field)
            }
        },
        [props]
    )

    return (
        <Select
            className="w-full"
            options={options}
            placeholder="Type to search your CRM"
            value={selectedOption}
            onChange={handleChange}
            menuPlacement="top"
            aria-label="Select CRM field"
            isSearchable
            styles={{
                control: (base) => ({
                    ...base,
                    minHeight: "40px",
                    borderColor: "#e5e7eb",
                }),
            }}
        />
    )
}

function AnswerFormatSection(props: {
    selectedFieldType: ICrmFieldType | undefined
    onTypeSelect: (fieldType: ICrmFieldType) => void
    checklistOptions?: IChecklistOption[]
    onChecklistOptionsChange: (options: IChecklistOption[]) => void
    disabled?: boolean
}) {
    const { state } = useLocation()
    const fromPage = state?.from as string | undefined

    const isChecklist =
        props.selectedFieldType !== undefined &&
        [ICrmFieldType.Checklist, ICrmFieldType.MultiChecklist].includes(
            props.selectedFieldType
        )

    return (
        <div data-tooltip-id="disabled-explanation">
            {fromPage === "company" && <CompanyInsightsWarning />}
            <h2 className="mb-3 font-semibold">Answer format</h2>
            <div className="mb-3 flex flex-row gap-4">
                <AnswerFormatOption
                    title="Text"
                    description="A free text answer"
                    selected={props.selectedFieldType === ICrmFieldType.Text}
                    onClick={() => props.onTypeSelect(ICrmFieldType.Text)}
                    disabled={props.disabled}
                />
                <AnswerFormatOption
                    title="Number"
                    description="A numerical answer"
                    selected={props.selectedFieldType === ICrmFieldType.Number}
                    onClick={() => props.onTypeSelect(ICrmFieldType.Number)}
                    disabled={props.disabled}
                />
                <AnswerFormatOption
                    title="Multi-option list"
                    description="Answer(s) from a predefined list"
                    selected={isChecklist}
                    onClick={() => {
                        props.onTypeSelect(ICrmFieldType.MultiChecklist)
                    }}
                    disabled={props.disabled}
                />
            </div>

            {isChecklist && (
                <div className="mt-4 border-l-2 border-gray-200 pl-4">
                    <ChecklistSection
                        fieldType={props.selectedFieldType!}
                        options={props.checklistOptions}
                        onTypeSelect={props.onTypeSelect}
                        onOptionsChange={props.onChecklistOptionsChange}
                        disabled={props.disabled}
                    />
                </div>
            )}

            <Tooltip
                id="disabled-explanation"
                float={true}
                delayShow={200}
                className="z-50 flex flex-col items-center"
                style={{
                    backgroundColor: "#111827",
                    borderRadius: "8px",
                }}
            >
                {props.disabled && (
                    <>
                        <div>These details are pulled from your CRM</div>
                        <div>and cannot be changed within Glyphic</div>
                    </>
                )}
            </Tooltip>
        </div>
    )
}

function CompanyInsightsWarning() {
    return (
        <div className="my-2 rounded-lg bg-red-100 p-2 text-sm font-semibold">
            Insights not linked to a company object in the CRM won't appear in
            the company view.
        </div>
    )
}

function ChecklistSection(props: {
    fieldType: ICrmFieldType
    options?: IChecklistOption[]
    onOptionsChange: (options: IChecklistOption[]) => void
    onTypeSelect: (
        type: ICrmFieldType.Checklist | ICrmFieldType.MultiChecklist
    ) => void
    disabled?: boolean
}) {
    const options = useMemo(
        () =>
            props.options && props.options.length > 0
                ? props.options
                : [
                      {
                          id: "",
                          name: "",
                      },
                      {
                          id: "",
                          name: "",
                      },
                  ],
        [props.options]
    )

    const addOption = useCallback(() => {
        props.onOptionsChange([
            ...options,
            {
                id: "",
                name: "",
            },
        ])
    }, [options, props])

    const removeOption = useCallback(
        (index: number) => {
            const newOptions = options.filter((_, i) => i !== index)
            props.onOptionsChange(newOptions)
        },
        [options, props]
    )

    const handleOptionChange = useCallback(
        (index: number, value: string) => {
            const newOptions = [...options]
            newOptions[index] = {
                id: value,
                name: value,
            }
            props.onOptionsChange(newOptions)
        },
        [options, props]
    )

    const handleCheckboxChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            props.onTypeSelect(
                e.target.checked
                    ? ICrmFieldType.Checklist
                    : ICrmFieldType.MultiChecklist
            )
        },
        [props]
    )

    return (
        <div className="space-y-4">
            <h2 className="mb-4 font-semibold">Options for insight</h2>
            <p className="mb-4 text-sm text-gray-600">
                List the possible answers for this insight. The AI will only
                attempt to match to the options.
            </p>

            <div className="space-y-3">
                {options.map((option, index) => (
                    <div key={index} className="flex items-center gap-2">
                        <input
                            type="text"
                            value={option.name}
                            onChange={(e) =>
                                handleOptionChange(index, e.target.value)
                            }
                            placeholder={`Option ${index + 1}`}
                            className="w-full rounded-lg border bg-white p-3 shadow-sm outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
                            disabled={props.disabled}
                        />
                        {!props.disabled && (
                            <FontAwesomeIcon
                                icon={faXmark}
                                className="h-4 w-4 cursor-pointer p-2 text-gray-500 transition-colors hover:text-gray-700"
                                onClick={() => removeOption(index)}
                            />
                        )}
                    </div>
                ))}
            </div>

            {!props.disabled && (
                <SecondaryButton
                    onClick={addOption}
                    className="flex items-center gap-2"
                >
                    <FontAwesomeIcon icon={faPlusCircle} className="h-4 w-4" />
                    Add another option
                </SecondaryButton>
            )}

            <div className="mt-4 flex items-center gap-2">
                <input
                    type="checkbox"
                    id="singleOption"
                    checked={props.fieldType === ICrmFieldType.Checklist}
                    onChange={handleCheckboxChange}
                    disabled={props.disabled}
                    className="h-4 w-4 rounded text-blue-600 focus:ring-blue-500"
                />
                <label htmlFor="singleOption" className="text-gray-700">
                    Only pick one option per call
                </label>
            </div>
        </div>
    )
}

function AnswerFormatOption(props: {
    title: string
    description: string
    selected: boolean
    onClick: () => void
    disabled?: boolean
}) {
    return (
        <button
            className={clsx(
                "rounded-lg border-2 p-4",
                props.selected ? "border-gray-500" : "border-gray-300",
                !props.disabled && "hover:bg-gray-100"
            )}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            <div className="mb-2 flex items-center justify-between font-bold">
                {props.title}
                {props.selected && (
                    <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="h-4 w-4 text-green-500"
                        data-tooltip-content={"Free text field"}
                        data-tooltip-id="tooltip-explanation"
                    />
                )}
            </div>
            <div className="text-left text-sm text-gray-500">
                {props.description}
            </div>
        </button>
    )
}
