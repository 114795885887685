import { faPen, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useMemo, useState } from "react"
import validator from "validator"
import { CallStatus, ICallPreview, IParticipant } from "../../types/Call"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { Modal } from "../common/Modal"
import { UserCircle } from "../UserCircle"
import { getHeapInstance } from "../../utils/heap"
import clsx from "clsx"

interface EditAttendeeViewProps {
    call: ICallPreview
    onChange: (newParties: IParticipant[]) => void
}

export function EditAttendeeView(props: EditAttendeeViewProps) {
    const [showAttendeeEditor, setShowAttendeeEditor] = useState(false)

    const handleEditAttendeeButtonClick = () => {
        if (props.call.status !== CallStatus.Completed) {
            return
        }

        setShowAttendeeEditor((prev) => !prev)
        getHeapInstance()?.track("call-attendee-edit-button-clicked", {
            callId: props.call.id,
            parties: props.call.parties, // So we can study what we were missing
        })
    }

    if (showAttendeeEditor) {
        return (
            <EditAttendeeModal
                onClose={() => setShowAttendeeEditor(false)}
                {...props}
            />
        )
    }

    const hasFinishedProcessing = props.call.status === CallStatus.Completed

    return (
        <button
            className={clsx(
                "hidden w-fit flex-shrink-0 md:block",
                hasFinishedProcessing ? "" : "text-gray-300"
            )}
            onClick={handleEditAttendeeButtonClick}
            data-tooltip-id="tooltip-explanation"
            data-tooltip-content={
                !hasFinishedProcessing
                    ? "Call has not finished processing"
                    : "Edit call participants"
            }
            disabled={!hasFinishedProcessing}
        >
            <FontAwesomeIcon icon={faPen} />
        </button>
    )
}

interface EditAttendeeModalProps {
    onClose: () => void
}

function EditAttendeeModal(
    props: EditAttendeeViewProps & EditAttendeeModalProps
) {
    const parties = props.call.parties
    const [updatedAttendees, setUpdatedAttendees] = useState(parties)

    useEffect(() => {
        setUpdatedAttendees(parties)
    }, [parties])

    const speakerPartyIds = useMemo(() => {
        if (!props.call.transcript_turns) {
            return []
        }
        return props.call.transcript_turns.map((turn) => turn.party_id)
    }, [props.call.transcript_turns])

    const handleAttendeeChange = (updatedAttendee: IParticipant) => {
        const attendees = updatedAttendees.map((party) =>
            party.id === updatedAttendee.id ? updatedAttendee : party
        )
        setUpdatedAttendees(attendees)
    }

    const handleSave = () => {
        props.onChange(updatedAttendees)
        props.onClose()
    }

    // This is used to show an error message next to the save button to explain
    // why it's disabled.
    const areAllAttendeesValid = useMemo(() => {
        return updatedAttendees.every((attendee) =>
            isEmailValid(attendee.email)
        )
    }, [updatedAttendees])

    const handleAddNewAttendee = () => {
        const maxId = Math.max(
            ...updatedAttendees.map((attendee) => attendee.id),
            0
        )
        const newAttendee = {
            id: maxId + 1,
            name: "New participant",
            email: "",
        }
        setUpdatedAttendees([...updatedAttendees, newAttendee])
    }

    const handleRemoveAttendee = (attendeeId: number) => {
        setUpdatedAttendees(
            updatedAttendees.filter((attendee) => attendee.id !== attendeeId)
        )
    }

    return (
        <Modal isOpen={true} onClose={props.onClose}>
            <div className="flex max-h-screen max-w-5xl flex-col gap-2 rounded-lg border bg-gray-50 p-4">
                <div className="flex flex-col p-4">
                    <h2 className="text-lg font-semibold">
                        Edit call participants
                    </h2>
                    <h4 className="text-gray-500">
                        Change the name or email of any call participant.
                    </h4>
                    <h4 className="text-gray-500">
                        Changes to the email will associate this call to the
                        right company.
                    </h4>
                </div>
                <div className="w-full p-4">
                    <table className="block max-h-96 w-full overflow-y-auto">
                        <tr>
                            <th className="w-fit" />
                            <th className="w-1/2">Name</th>
                            <th className="w-1/2">Email</th>
                            <th className="w-fit" />
                        </tr>
                        {updatedAttendees.map((party) => {
                            // Speakers can't be removed
                            const canRemove = !speakerPartyIds.includes(
                                party.id
                            )
                            return (
                                <AttendeeEditor
                                    key={party.id}
                                    attendee={party}
                                    onChange={handleAttendeeChange}
                                    onRemove={
                                        canRemove
                                            ? () =>
                                                  handleRemoveAttendee(party.id)
                                            : undefined
                                    }
                                />
                            )
                        })}
                        <tr>
                            <td />
                            <td className="p-2">
                                <SecondaryButton
                                    className="flex w-full flex-row items-center justify-between gap-2"
                                    onClick={handleAddNewAttendee}
                                >
                                    <span>Add new participant</span>
                                    <FontAwesomeIcon icon={faPlus} />
                                </SecondaryButton>
                            </td>
                        </tr>
                    </table>
                </div>
                <div className="flex flex-row items-center justify-end gap-2">
                    {!areAllAttendeesValid && (
                        <h4 className="text-red-500">
                            Some emails are invalid
                        </h4>
                    )}
                    <PrimaryButton
                        className="w-fit"
                        onClick={handleSave}
                        disabled={!areAllAttendeesValid}
                    >
                        Save
                    </PrimaryButton>
                </div>
            </div>
        </Modal>
    )
}

function AttendeeEditor({
    attendee,
    onChange,
    onRemove,
}: {
    attendee: IParticipant
    onChange: (updatedAttendee: IParticipant) => void
    onRemove?: () => void
}) {
    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        field: keyof IParticipant
    ) => {
        const updatedAttendee = { ...attendee, [field]: e.target.value }
        onChange(updatedAttendee)
    }

    const extraEmailClass = isEmailValid(attendee.email) ? "" : "border-red-500"

    const tooltipContent = onRemove
        ? "Delete participant"
        : "Speakers can't be deleted"

    return (
        <tr>
            <td className="py-1">
                <UserCircle user={attendee} size="40px" />
            </td>
            <td className="px-2 text-base font-semibold">
                <input
                    type="text"
                    value={attendee.name ?? ""}
                    className="w-full rounded border p-2 focus:outline-none"
                    onChange={(e) => handleInputChange(e, "name")}
                />
            </td>
            <td className="px-2 text-base font-semibold">
                <input
                    type="email"
                    value={attendee.email ?? ""}
                    className={
                        "w-full rounded border p-2 focus:outline-none " +
                        extraEmailClass
                    }
                    onChange={(e) => handleInputChange(e, "email")}
                />
            </td>
            <td className="px-2">
                <button
                    className={clsx(
                        onRemove
                            ? "cursor-pointer transition-colors hover:text-red-600"
                            : "cursor-not-allowed text-gray-400"
                    )}
                    onClick={onRemove}
                    disabled={!onRemove}
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content={tooltipContent}
                >
                    <FontAwesomeIcon icon={faXmark} />
                </button>
            </td>
        </tr>
    )
}

function isEmailValid(email?: string | null) {
    return !email || validator.isEmail(email)
}
