import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar } from "@fortawesome/free-regular-svg-icons"
import { faStopwatch } from "@fortawesome/free-solid-svg-icons"

import { ICallPreview, IParticipant, MediaProvider } from "../../types/Call"
import { formatDuration, getDetailedDateTime } from "../../utils/datetime"
import { getParticipantDisplayName } from "../../utils/getParticipantDisplayName"
import { UserCircle } from "../UserCircle"
import { CompanyList } from "../calls-list/CompanyList"
import { SimpleCard } from "../common/SimpleCard"
import { CrmRecords } from "./CrmRecords"
import { FrigadeCallToCompanyTour } from "../Frigade"
import { useUser } from "../../providers/UserProvider"
import { EditAttendeeView } from "./EditCallAttendees"
import { CallTags } from "./CallTags"

export function CallDetails(props: {
    call: ICallPreview
    showTags: boolean
    showCrmRecords?: boolean
    onChange?: (newParties: IParticipant[]) => void
}) {
    const user = useUser()
    const canEditParticipants = user && props.onChange && props.call.can_view

    return (
        <SimpleCard className="p-4">
            <div className="w-full space-y-3">
                <CallInfo call={props.call} />
                <div className="flex w-full flex-row items-center justify-between gap-2">
                    <AttendeeList attendees={props.call.parties} />
                    {canEditParticipants && props.call.parties.length > 0 && (
                        <EditAttendeeView
                            call={props.call}
                            onChange={props.onChange!}
                        />
                    )}
                </div>
                {props.call.summary && (
                    <CallSummary summary={props.call.summary} />
                )}
                {props.showCrmRecords && <CrmRecords callId={props.call.id} />}
                {props.showTags && (
                    <CallTags tags={props.call.tags} callId={props.call.id} />
                )}
            </div>
        </SimpleCard>
    )
}

function CallInfo(props: { call: ICallPreview }) {
    const dateTime = getDetailedDateTime(props.call.start_time)
    const duration = props.call.duration
        ? formatDuration(props.call.duration)
        : ""
    const mediaProviderString = mediaProviderName(props.call.media_provider)

    return (
        <div className="flex gap-4 text-base">
            <div className="space-x-2">
                <FontAwesomeIcon icon={faCalendar} />
                <span>{dateTime}</span>
            </div>
            {duration && (
                <div className="flex-none space-x-2">
                    <FontAwesomeIcon icon={faStopwatch} />
                    <span>{duration}</span>
                </div>
            )}
            {mediaProviderString && (
                <div>
                    <span>From </span>
                    <span className="font-bold">{mediaProviderString}</span>
                </div>
            )}
            <div className="ml-auto">
                {!!props.call.companies?.length && (
                    <CompanyList
                        companies={props.call.companies || []}
                        className="justify-end"
                    />
                )}
            </div>
            {!!props.call.companies?.length && (
                <FrigadeCallToCompanyTour
                    companyDomain={props.call.companies[0].domain}
                />
            )}
        </div>
    )
}

function mediaProviderName(mediaProvider: string): string | undefined {
    switch (mediaProvider) {
        case MediaProvider.Zoom:
            return "Zoom"
        case MediaProvider.Gong:
            return "Gong"
        case MediaProvider.Recall:
            return "Glyphic Copilot"
        case MediaProvider.Hubspot:
            return "Hubspot"
        case MediaProvider.Aircall:
            return "Aircall"
        case MediaProvider.SalesforceDialer:
            return "Salesforce Sales Dialer"
        case MediaProvider.Manual:
            return "Uploaded File"
        case MediaProvider.Chorus:
            return "Chorus"
        default:
            return undefined
    }
}

function AttendeeList({ attendees }: { attendees: IParticipant[] }) {
    return (
        <div className="scrollbar-hide flex items-center gap-4 overflow-x-scroll py-2.5 text-base">
            {attendees.map((attendee) => (
                <Attendee key={attendee.id} attendee={attendee} />
            ))}
        </div>
    )
}

function Attendee(props: { attendee: IParticipant }) {
    const attendee = props.attendee
    const name = getParticipantDisplayName(attendee)

    return (
        <div className="flex flex-row items-center space-x-2">
            <UserCircle user={props.attendee} size="40px" />
            <div>
                <div className="text-base font-semibold">{name}</div>
                <div className="text-gray-400 ">{attendee.email ?? ""}</div>
            </div>
        </div>
    )
}

function CallSummary(props: { summary: string }) {
    return (
        <div className="text-base">
            <span className="text-base font-bold">AI Summary 🪄: </span>
            <span>{props.summary}</span>
        </div>
    )
}
