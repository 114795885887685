import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { InsightValueDealOutcomes } from "../../types/Insights"
import { useAnimationDelay } from "../../utils/animationDelay"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { useId, useMemo, useState } from "react"
import { Flipped, Flipper } from "react-flip-toolkit"
import clsx from "clsx"
import _ from "lodash"
import { queries } from "../../api/queries"
import { useQueryClient } from "@tanstack/react-query"
import { ICRMDeal } from "../../types/Deal"
import { ToggleExpandedViewButton } from "./line-chart/ToggleExpandedViewButton"
import { DealsTooltip } from "./common/DealsTooltip"

interface SortConfig {
    key: "won" | "lost" | "pct"
    direction: "asc" | "desc"
}

export function DealOutcomesChart(props: {
    insightId: string
    insightName: string
    data: InsightValueDealOutcomes[]
    sinceDate: string
    userIds: string[]
}) {
    const [sortConfig, setSortConfig] = useState<SortConfig>({
        key: "won",
        direction: "desc",
    })

    const dataWithPct = useMemo(() => {
        return props.data.map((d) => ({
            ...d,
            // Check that there are deals to avoid division by zero
            pct:
                d.won + d.lost > 0
                    ? Math.round((d.won / (d.won + d.lost)) * 100)
                    : 0,
        }))
    }, [props.data])

    const maxValue = useMemo(
        () => Math.max(...props.data.flatMap((d) => [d.won, d.lost])),
        [props.data]
    )

    const sortedData = useMemo(
        () => _.orderBy(dataWithPct, [sortConfig.key], [sortConfig.direction]),
        [dataWithPct, sortConfig]
    )

    const toggleSort = (key: SortConfig["key"]) => {
        if (sortConfig.key === key) {
            setSortConfig({
                key,
                direction: sortConfig.direction === "asc" ? "desc" : "asc",
            })
        } else {
            setSortConfig({ key, direction: "desc" })
        }
    }

    const maxRowsToShow = 8
    const isTruncated = sortedData.length > maxRowsToShow
    const [showAll, setShowAll] = useState(false)
    const sortedDataToShow = showAll
        ? sortedData
        : sortedData.slice(0, maxRowsToShow)

    return (
        <div className="w-full rounded-lg bg-gray-50 p-6 pr-12">
            <h2 className="mb-8 text-lg">
                How <b>{props.insightName}</b> correlates with{" "}
                <b>deal outcome</b>
            </h2>
            <Header
                insightName={props.insightName}
                toggleSort={toggleSort}
                sortConfig={sortConfig}
            />
            <Flipper
                flipKey={sortedData.map((d) => d.value).join("")}
                className="space-y-4"
            >
                {sortedDataToShow.map((d) => (
                    <Flipped key={d.value} flipId={d.value}>
                        <div className="flex flex-row gap-4" key={d.value}>
                            <span className="w-1/2 text-sm text-gray-600">
                                {d.value}
                            </span>
                            <div className="flex w-1/2 items-center justify-center text-sm text-gray-600">
                                {d.pct}%
                            </div>
                            <ChartBar
                                count={d.won}
                                maxCount={maxValue}
                                isLeft={true}
                                isWon={true}
                                dealIds={d.won_deal_ids}
                            />
                            <ChartBar
                                count={d.lost}
                                maxCount={maxValue}
                                isLeft={false}
                                isWon={false}
                                dealIds={d.lost_deal_ids}
                            />
                        </div>
                    </Flipped>
                ))}
            </Flipper>
            {isTruncated && (
                <div className="mt-4 flex w-full">
                    <ToggleExpandedViewButton
                        viewAllOptions={showAll}
                        setViewAllOptions={setShowAll}
                    />
                </div>
            )}
        </div>
    )
}

function Header(props: {
    insightName: string
    toggleSort: (key: SortConfig["key"]) => void
    sortConfig: SortConfig
}) {
    return (
        <div className="mb-4 flex flex-row gap-4">
            <span className="w-1/2 text-sm font-bold text-gray-800">
                {props.insightName}
            </span>
            <SortableColumn
                keyValue="pct"
                toggleSort={props.toggleSort}
                sortConfig={props.sortConfig}
                left={true}
                label="Win %"
                centerAlign={true}
            />
            <SortableColumn
                keyValue="won"
                toggleSort={props.toggleSort}
                sortConfig={props.sortConfig}
                left={true}
                label="Deals won"
                centerAlign={false}
            />
            <SortableColumn
                keyValue="lost"
                toggleSort={props.toggleSort}
                sortConfig={props.sortConfig}
                left={false}
                label="Deals lost"
                centerAlign={false}
            />
        </div>
    )
}

function SortableColumn({
    keyValue,
    toggleSort,
    sortConfig,
    left,
    label,
    centerAlign,
}: {
    keyValue: SortConfig["key"]
    toggleSort: (key: SortConfig["key"]) => void
    sortConfig: SortConfig
    left: boolean
    label: string
    centerAlign: boolean
}) {
    return (
        <div
            className={clsx(
                "flex w-1/2 cursor-pointer items-center gap-2",
                left && "flex-row-reverse",
                centerAlign && "justify-center"
            )}
            onClick={() => toggleSort(keyValue)}
        >
            <FontAwesomeIcon
                icon={faChevronDown}
                className={clsx(
                    "h-3 w-3 transition-transform hover:opacity-100",
                    sortConfig.key !== keyValue && "opacity-20",
                    sortConfig.key === keyValue &&
                        sortConfig.direction === "asc" &&
                        "rotate-180"
                )}
            />
            <span className="text-sm font-bold text-gray-600">{label}</span>
        </div>
    )
}

function ChartBar({
    count,
    maxCount,
    isLeft,
    isWon,
    dealIds,
}: {
    count: number
    maxCount: number
    isLeft: boolean
    isWon: boolean
    dealIds: string[]
}) {
    const isAnimationTriggered = useAnimationDelay()
    const widthPct = Math.min((count / maxCount) * 100, 100)

    const queryClient = useQueryClient()
    const [deals, setDeals] = useState<ICRMDeal[]>([])
    const [dealsIsPending, setDealsIsPending] = useState(false)

    const handleMouseEnter = async () => {
        if (count > 0 && !deals.length) {
            setDealsIsPending(true)
            const data = await queryClient.fetchQuery(
                queries.deals.getByIds(dealIds)
            )
            setDeals(data)
            setDealsIsPending(false)
        }
    }

    const tooltipId = useId()

    return (
        <>
            <div
                className={clsx(
                    "relative flex h-6 w-1/2 flex-grow items-center",
                    isLeft && "flex-row-reverse"
                )}
                onMouseEnter={handleMouseEnter}
                data-tooltip-id={tooltipId}
            >
                <div
                    className={clsx(
                        "absolute top-0 h-6 rounded-md transition-all duration-500 ease-in-out",
                        isWon
                            ? "bg-green-500 hover:bg-green-400"
                            : "bg-red-500 hover:bg-red-400",
                        isLeft ? "right-0" : "left-0"
                    )}
                    style={{
                        width: isAnimationTriggered ? `${widthPct}%` : "0%",
                    }}
                >
                    <span
                        className={`absolute top-1/2 -translate-y-1/2 transform ${
                            isLeft ? "right-full mr-2" : "left-full ml-2"
                        }`}
                    >
                        <span className="text-sm text-gray-600">{count}</span>
                    </span>
                </div>
                {count > 0 && (
                    <DealsTooltip
                        tooltipId={tooltipId}
                        deals={deals}
                        pendingRowCount={dealsIsPending ? count : 0}
                        place="right"
                    />
                )}
            </div>
        </>
    )
}
