import axios from "axios"
import { ICRMDeal, ICrmDealUpdate } from "../../types/Deal"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../../components/common/Notifcations"
import { getHeapInstance } from "../../utils/heap"

// this uses react-query's opmitisic update pattern:
// https://tanstack.com/query/latest/docs/framework/react/guides/optimistic-updates#via-the-cache

export function useUpdateDeal(queryKey: any[] | readonly any[]) {
    const { addNotification } = useNotification()
    const queryClient = useQueryClient()

    const updateDealData = (dealUpdate: ICrmDealUpdate) => {
        queryClient.setQueryData(
            queryKey,
            (oldDealListData: ICRMDeal[] | undefined) => {
                if (!oldDealListData) {
                    return undefined
                }
                return oldDealListData.map((deal) => {
                    if (deal.id === dealUpdate.id) {
                        return { ...deal, ...dealUpdate }
                    }
                    return deal
                })
            }
        )
    }

    return useMutation({
        mutationFn: async (dealUpdate: ICrmDealUpdate) => {
            await axios.patch(
                `${process.env.REACT_APP_API_DOMAIN}/deals/crm/${dealUpdate.id}`,
                dealUpdate
            )
            getHeapInstance()?.track("deal-value-updated", {
                dealId: dealUpdate.id,
            })

            return dealUpdate
        },
        onMutate: async (dealUpdate: ICrmDealUpdate) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey })

            // Snapshot the previous value
            const previousDeals = queryClient.getQueryData<ICRMDeal[]>(queryKey)

            // Optimistically update the cache
            updateDealData(dealUpdate)

            // Return the previous value to roll back if needed
            return { previousDeals }
        },
        onError: (error, dealUpdate, context: any) => {
            // Roll back the optimistic update if the mutation fails
            if (context?.previousDeals) {
                queryClient.setQueryData(queryKey, context.previousDeals)
            }
            let errorMessage = "An error occurred while updating the deal."
            if (axios.isAxiosError(error)) {
                errorMessage =
                    error.response?.data?.detail?.message || errorMessage
            }
            addNotification(
                "Failed to update deal",
                errorMessage,
                NotificationType.Error
            )
        },
        onSuccess: () => {
            addNotification(
                "Deal updated successfully",
                "",
                NotificationType.Success
            )
        },
        onSettled: () => {
            // Always refetch after error or success
            queryClient.invalidateQueries({ queryKey })
        },
    })
}
