// Util methods for Pylon

const APP_ID = "16c5cfbd-6609-438b-8c1b-dfaf687c1ea0"

enum TicketFormSlugs {
    PRODUCT_FEEDBACK = "product-feedback",
}

export enum KnowledgeBaseArticleSlugs {
    CUSTOM_INSIGHTS_PROMPTS = "6218465811",
    STRATEGIC_INSIGHTS = "1113594798",
}

export function initPylon(userName: string, userEmail: string) {
    // @ts-ignore
    window.pylon = {
        chat_settings: {
            app_id: APP_ID,
            email: userEmail,
            name: userName,

            // (Optional) If you have the user's profile image available through something like Auth0
            // avatar_url: user.avatarUrl,

            // (Optional) If you are using Pylon's identity verification
            // email_hash: "USER_EMAIL_HASH",
        },
    }
}

export function showPylon() {
    // @ts-ignore
    window.Pylon("show")
}

export async function submitPylonFeedback(subject: string) {
    // @ts-ignore
    window.Pylon("setTicketFormFields", {
        subject: subject,
    })
    // @ts-ignore
    window.Pylon("showTicketForm", TicketFormSlugs.PRODUCT_FEEDBACK)
    //

    // Subject form needs to be reset to avoid being shown on newly created tickets
    await new Promise((resolve) => setTimeout(resolve, 1000))
    // @ts-ignore
    window.Pylon("setTicketFormFields", {
        subject: "",
    })
}

export async function showKnowledgeBaseArticle(
    articleId: KnowledgeBaseArticleSlugs
) {
    // @ts-ignore
    window.Pylon("showKnowledgeBaseArticle", articleId)
}
