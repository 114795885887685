import { useIntegrations } from "../../../hooks/useIntegrations"

import { InsightsSettings } from "../insights/InsightsSettings"
import { BaseSettingsPage } from "./BaseSettingsPage"

export function InsightsPage() {
    const { orgName } = useIntegrations()

    return (
        <BaseSettingsPage pageTitle={"Insights"} alwaysFullWidth>
            <InsightsSettings orgName={orgName} />
        </BaseSettingsPage>
    )
}
