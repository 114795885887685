// Formally known as SuperFilter

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBarsFilter } from "@fortawesome/pro-regular-svg-icons"
import { useEffect, useRef, useState } from "react"

interface Filter {
    icon: React.ReactNode
    name: string
    component: React.ReactNode
    defaultShown?: boolean
    onAdd?: () => void
    onRemove: () => void
}

interface CollapsableFiltersProps {
    filters: Filter[]
}

export function CollapsableFilters(props: CollapsableFiltersProps) {
    const [activeFilters, setActiveFilters] = useState<string[]>(
        props.filters
            .filter((filter) => filter.defaultShown)
            .map((filter) => filter.name)
    )
    const [showFilterMenu, setShowFilterMenu] = useState(false)

    // Add ref for the dropdown container
    const dropdownRef = useRef<HTMLDivElement>(null)

    // Add click outside handler
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setShowFilterMenu(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    return (
        <div className="flex flex-row items-center gap-2">
            {activeFilters
                .map((filter) => props.filters.find((f) => f.name === filter))
                .map((filter) => {
                    if (filter === undefined) return null
                    return (
                        <div
                            key={filter.name}
                            className="flex items-center gap-2 rounded-lg border border-gray-300 px-2"
                            data-filter-name={filter.name}
                        >
                            {filter.component}
                            <button
                                className="p-1 text-gray-400 hover:text-gray-600"
                                onClick={() => {
                                    setActiveFilters(
                                        activeFilters.filter(
                                            (name) => name !== filter.name
                                        )
                                    )
                                    filter.onRemove?.()
                                }}
                            >
                                ✕
                            </button>
                        </div>
                    )
                })}

            <div className="relative" ref={dropdownRef}>
                <button
                    className="flex items-center gap-2 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                    onClick={() => setShowFilterMenu(!showFilterMenu)}
                >
                    <FontAwesomeIcon className="h-5 w-5" icon={faBarsFilter} />{" "}
                    Filter
                </button>

                {showFilterMenu && (
                    <div className="absolute left-0 top-full z-10 mt-1 w-fit rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="py-1" role="menu">
                            {props.filters.map((filter) => (
                                <FilterButton
                                    key={filter.name}
                                    filter={filter}
                                    isActive={activeFilters.includes(
                                        filter.name
                                    )}
                                    onClick={() => {
                                        setActiveFilters([
                                            ...activeFilters,
                                            filter.name,
                                        ])
                                        filter.onAdd?.()
                                        setShowFilterMenu(false)
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

interface FilterButtonProps {
    filter: Filter
    isActive: boolean
    onClick: () => void
}

function FilterButton(props: FilterButtonProps) {
    const colours = props.isActive
        ? "text-gray-400"
        : "text-gray-700 hover:bg-gray-100"
    return (
        <button
            disabled={props.isActive}
            className={`block flex w-full flex-nowrap items-center gap-1 whitespace-nowrap px-4 py-2 text-left text-sm ${colours}`}
            onClick={() => props.onClick()}
        >
            {props.filter.icon} {props.filter.name}
        </button>
    )
}
