import { faThumbsUp as regularThumbsUp } from "@fortawesome/free-regular-svg-icons"
import { faThumbsUp as solidThumbsUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useCallback, useState } from "react"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { AnswerFeedback } from "./types/QuestionTypes"
import { getHeapInstance } from "../../utils/heap"

export function ResponseFeedback(props: {
    submitFeedback: (feedback: AnswerFeedback) => Promise<void>
}) {
    const { addNotification } = useNotification()
    const [feedback, setFeedback] = useState<AnswerFeedback | null>(null)

    const submitFeedback = useCallback(
        async (feedback: AnswerFeedback) => {
            try {
                setFeedback(null)
                props.submitFeedback(feedback)
                getHeapInstance()?.track("askglyphic-feedback", {
                    feedback: feedback.positive ? "positive" : "negative",
                })
                setFeedback(feedback)

                addNotification(
                    "Thanks for your feedback!",
                    "We'll use it to improve our answers",
                    NotificationType.Success
                )
            } catch (error) {
                console.error(error)
                addNotification(
                    "Failed to submit feedback!",
                    "Please try again later",
                    NotificationType.Error
                )
            }
        },
        [props, addNotification]
    )

    return (
        <>
            <div className="flex flex-row gap-4 text-gray-500">
                <button
                    className="icon-solid hover:text-gray-800"
                    onClick={() => submitFeedback({ positive: true })}
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content="Good response"
                >
                    <FontAwesomeIcon
                        icon={
                            feedback?.positive ? solidThumbsUp : regularThumbsUp
                        }
                    />
                </button>
                <button
                    className="rotate-180 hover:text-gray-800"
                    onClick={() => submitFeedback({ positive: false })}
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content="Bad response"
                >
                    <FontAwesomeIcon
                        icon={
                            // We don't want to highlight if feedback is null
                            feedback?.positive === false
                                ? solidThumbsUp
                                : regularThumbsUp
                        }
                    />
                </button>
            </div>
        </>
    )
}
