import { UseQueryResult } from "@tanstack/react-query"
import { ILossReasons, InsightsResponse } from "../../types/Insights"
import { useEffect, useMemo, useState } from "react"
import { LoadingPulse } from "../common/LoadingPulse"
import { canShowLossReasons, LOSS_REASONS_NAV_ITEM } from "./LossReasons"
import clsx from "clsx"
import _ from "lodash"
import {
    canShowSummaryCharts,
    LOSING_FORMULA_NAV_ITEM,
    WINNING_FORMULA_NAV_ITEM,
} from "./summary-charts/DealPatternsCharts"
import { MOST_MENTIONED_NAV_ITEM } from "./summary-charts/MostMentionsChart"
import { IStrategicInsightNavigationItem } from "./utils/navigation"
import { SimpleCard } from "../common/SimpleCard"

export function Contents(props: {
    lossReasonsQueryResult: UseQueryResult<ILossReasons, Error>
    insightsQueryResult: UseQueryResult<InsightsResponse, Error>
}) {
    const [activeInsightId, setActiveInsightId] = useState<string>("")
    const [highlighterPosition, setHighlighterPosition] = useState<number>(0)
    const [highlighterHeight, setHighlighterHeight] = useState<number>(20)

    // Get loading state and data results from queries
    const { data: lossReasonsResults, isPending: lossReasonsIsPending } =
        props.lossReasonsQueryResult
    const { data: insightsResults, isPending: insightsIsPending } =
        props.insightsQueryResult

    // Create navigation items from all available insights
    const items = useMemo(() => {
        const items: IStrategicInsightNavigationItem[] = []
        if (canShowLossReasons(lossReasonsResults)) {
            items.push(LOSS_REASONS_NAV_ITEM)
        }
        if (canShowSummaryCharts(insightsResults)) {
            items.push(MOST_MENTIONED_NAV_ITEM)
            items.push(WINNING_FORMULA_NAV_ITEM)
            items.push(LOSING_FORMULA_NAV_ITEM)
        }
        items.push(
            ...(insightsResults?.insights || []).map((insight) => ({
                id: insight.id,
                displayName: insight.displayName,
                groupName: insight.groupName,
                isSummary: false,
                noDataAvailable: !insight.lineChart && !insight.dealOutcomes,
            }))
        )
        return items
    }, [lossReasonsResults, insightsResults])

    // Show highlighter when all data is loaded
    const showHighlighter = useMemo(() => {
        return !lossReasonsIsPending && !insightsIsPending && items.length > 0
    }, [lossReasonsIsPending, insightsIsPending, items])

    // Set up intersection observer to track which insight component is currently in view
    useEffect(() => {
        // Keep track of all currently intersecting elements
        const visibleElements = new Set<string>()

        const handleIntersection = (entries: IntersectionObserverEntry[]) => {
            // Update our set of visible elements
            entries.forEach((entry) => {
                entry.isIntersecting
                    ? visibleElements.add(entry.target.id)
                    : visibleElements.delete(entry.target.id)
            })

            if (visibleElements.size === 0) return

            // Find the topmost visible element
            const topmost = _.minBy(
                Array.from(visibleElements),
                (id) => document.getElementById(id)?.getBoundingClientRect().top
            )

            if (topmost) setActiveInsightId(topmost)
        }

        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.2, // Trigger intersection callback when 20% of element is visible
        })

        // Observe all insight sections
        items.forEach((item) => {
            const element = document.getElementById(item.id)
            if (element) observer.observe(element)
        })

        return () => observer.disconnect()
    }, [items])

    // Update highlighter position & height whenever active insight changes
    useEffect(() => {
        if (!activeInsightId) return

        // Find and position the highlight marker next to the active nav item
        const navItem = document.querySelector(
            `[nav-insight-id="${activeInsightId}"]`
        )
        if (!navItem) return

        // Calculate the top position of the nav item
        const container = document.getElementById("contents")!
        const navItemRect = navItem.getBoundingClientRect()
        const navListRect = container.getBoundingClientRect()
        const topPosition = navItemRect.top - navListRect.top

        setHighlighterPosition(topPosition)
        setHighlighterHeight(navItemRect.height)
    }, [activeInsightId])

    // Partition items into summary and non-summary sections
    const [summaryItems, insightItems] = _.partition(
        items,
        (item) => item.isSummary
    )
    // Group insight items by group name
    const insightItemsByGroup = _.groupBy(insightItems, "groupName")

    return (
        <SimpleCard className="h-full">
            <nav className="h-full w-full space-y-2 divide-y-[1px] divide-gray-200 overflow-y-scroll p-4 pt-1">
                <div className="relative space-y-2" id="contents">
                    <HighlightMarker
                        position={highlighterPosition}
                        height={highlighterHeight}
                        hidden={!showHighlighter}
                    />

                    {/* Display summary items, common to everyone */}
                    <h3 className="font-bold">Summary</h3>
                    {summaryItems.map((item) => (
                        <NavigationItem item={item} key={item.id} />
                    ))}
                    {lossReasonsIsPending && (
                        <LoadingPulse rows={1} height="h-4" />
                    )}
                    {insightsIsPending && (
                        <LoadingPulse rows={3} height="h-4" />
                    )}

                    {/* Display insight items, unique to each org */}
                    <div className="my-2 h-[1px] bg-gray-200" />
                    <h3 className="font-bold">Your Insights</h3>

                    {Object.entries(insightItemsByGroup).map(
                        ([groupName, items]) => (
                            <NavigationItemGroup
                                groupName={groupName}
                                items={items}
                                key={groupName}
                            />
                        )
                    )}

                    {insightsIsPending && (
                        <LoadingPulse rows={4} height="h-4" />
                    )}
                </div>
            </nav>
        </SimpleCard>
    )
}

function HighlightMarker(props: {
    position: number
    height: number
    hidden: boolean
}) {
    return (
        <div
            className={clsx(
                "absolute left-[-8px] w-1 rounded-full bg-sun transition-all duration-300 ease-in-out",
                props.hidden && "hidden"
            )}
            style={{
                transform: `translateY(${props.position}px)`,
                height: `${props.height}px`,
            }}
        />
    )
}

function NavigationItemGroup(props: {
    groupName: string
    items: IStrategicInsightNavigationItem[]
}) {
    return (
        <div className="space-y-2 pb-2">
            <div className="flex gap-2">
                <div className="flex w-2 flex-shrink-0 flex-col items-center gap-2">
                    <div className="mt-2 h-1 w-1 rounded-full bg-gray-200" />
                    <div className="w-[1px] flex-grow bg-gray-200" />
                </div>
                <div className="space-y-2">
                    <h3 className="text-sm font-bold">{props.groupName}</h3>
                    {props.items.map((item) => (
                        <NavigationItem item={item} key={item.id} />
                    ))}
                </div>
            </div>
        </div>
    )
}

function NavigationItem(props: { item: IStrategicInsightNavigationItem }) {
    return (
        <li
            key={props.item.id}
            className="flex items-start gap-2"
            nav-insight-id={props.item.id}
        >
            <a
                href={`#${props.item.id}`}
                className={clsx(
                    "text-sm",
                    "hover:underline",
                    props.item.noDataAvailable &&
                        "text-gray-400 transition-colors hover:text-gray-800"
                )}
            >
                {props.item.displayName}
            </a>
        </li>
    )
}
