import { useCallback } from "react"
import { DateTime } from "luxon"
import { NavigateLink } from "../../common/NavigateLink"
import { RecordingIcon } from "../../../assets/icons/RecordingIcon"
import { Task } from "../types/Task"
import { UpdateTaskMutation } from "../../../api/mutations/tasks"
import { getFormattedDateTime, getFormattedDate } from "../../../utils/datetime"
import { getHeapInstance } from "../../../utils/heap"
import { TaskDueDateEditor } from "./TaskDueDate"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons"

interface ExpandedTaskViewProps {
    task: Task
    updateTask?: UpdateTaskMutation
}

export function ExpandedTaskView(props: ExpandedTaskViewProps) {
    const formattedCreatedAt = props.task.created_at_utc
        ? getFormattedDateTime(props.task.created_at_utc)
        : null
    const formattedDueDate = props.task.due_date
        ? getFormattedDate(DateTime.fromISO(props.task.due_date))
        : null

    const handleCallLinkClick = useCallback(() => {
        if (!props.task.call_id) return

        getHeapInstance()?.track("task-call-link-clicked", {
            task_id: props.task.id,
            call_id: props.task.call_id,
        })
    }, [props.task.id, props.task.call_id])

    return (
        <div>
            <div className="mb-4 mt-2 flex flex-col gap-4 pl-9 md:flex-row">
                {/* Left column */}
                <div className="flex flex-1 flex-col gap-2">
                    {props.task.call_id && props.task.call_title && (
                        <NavigateLink
                            href={`/calls/${props.task.call_id}`}
                            className="flex w-fit items-center gap-2 border-b border-transparent text-sm text-gray-500 transition-all duration-200 hover:border-blue-700"
                            onClick={handleCallLinkClick}
                        >
                            <RecordingIcon />
                            <span
                                className="max-w-[300px] truncate text-left text-blue-700"
                                data-tooltip-id="tooltip-explanation"
                                data-tooltip-content="View call"
                            >
                                {props.task.call_title}
                            </span>
                        </NavigateLink>
                    )}
                    {formattedCreatedAt && (
                        <div className="flex flex-col items-start justify-start text-sm text-gray-600">
                            <Caption label="Created" />
                            <div>{formattedCreatedAt}</div>
                        </div>
                    )}
                </div>

                {/* Right column */}
                <div className="flex h-fit flex-1 flex-row gap-4 text-sm md:justify-end">
                    <div className="flex flex-col gap-1">
                        <Caption label="Assignee" />
                        <div className="leading-none text-gray-600">
                            {props.task.assignee?.name ||
                                props.task.assignee?.email ||
                                "Unassigned"}
                            {/* TODO: Add an edit button! */}
                        </div>
                    </div>
                    <div className="flex flex-col gap-1">
                        <Caption label="Due" />
                        <div className="leading-none text-gray-600 hover:underline">
                            <TaskDueDateEditor
                                taskId={props.task.id}
                                date={props.task.due_date}
                                updateTask={props.updateTask}
                                permanentEditIcon
                            >
                                {formattedDueDate || <Schedule />}
                            </TaskDueDateEditor>
                        </div>
                    </div>
                </div>
            </div>
            {props.task.context && (
                <div className="flex items-start gap-2 pl-9 text-sm text-gray-600">
                    <FontAwesomeIcon icon={faInfoCircle} className="mt-[2px]" />
                    {props.task.context}
                </div>
            )}
        </div>
    )
}

function Schedule() {
    return <span className="text-sm text-gray-500">click to schedule</span>
}

function Caption({ label }: { label: string }) {
    return (
        <span className="text-left text-sm font-medium leading-none text-gray-600">
            {label}:
        </span>
    )
}
