import { useMemo, useState } from "react"
import axios from "axios"
import _ from "lodash"
import ReactDOMServer from "react-dom/server"

import colors from "tailwindcss/colors"
import { ReactComponent as SendIcon } from "../../assets/icons/send-03.svg"
import { Card } from "./Card"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import Badge from "../common/Badge"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import {
    IOrganizationUser,
    IOrgUserCalendarInfo,
} from "../../types/Organization"
import { UserRole, formatRole } from "../../types/User"
import Select, { components } from "react-select"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { DeleteUserButton } from "./DeleteUserButton"
import { queries } from "../../api/queries"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faCircleCheck,
    faCircleXmark,
    faTrashCan,
} from "@fortawesome/free-solid-svg-icons"
import {
    faCircleCalendar,
    faCircleVideo,
} from "@fortawesome/pro-solid-svg-icons"
import { LoadingPulse } from "../common/LoadingPulse"
import clsx from "clsx"
import { canUseFeature, FeatureFlags } from "../../utils/FeatureFlags"
import { useUser } from "../../providers/UserProvider"
import { hasPermission } from "../../utils/Permissions"
import { Permission } from "../../types/Permission"

export function InvitationSettings(props: { orgName: string | undefined }) {
    const user = useUser()
    const canEditOrg = hasPermission(user, Permission.VIEW_EDIT_ORG_SETTINGS)

    const showRestrictedRole = canUseFeature(
        FeatureFlags.ShowRestrictedUserRole,
        user?.organizationId
    )
    const availableRoles = showRestrictedRole
        ? [UserRole.ADMIN, UserRole.USER, UserRole.VIEWER, UserRole.RESTRICTED]
        : [UserRole.ADMIN, UserRole.USER, UserRole.VIEWER]

    const queryClient = useQueryClient()
    const { addNotification } = useNotification()
    const listUsers = queries.users.list({
        include_invites: true,
        include_calendar_info: true,
    })
    const { data, isPending } = useQuery(listUsers)
    const users = useMemo(() => {
        return _.sortBy(
            data || [],
            ["-has_accepted_invite", "name", "email"],
            "asc"
        ) as IOrganizationUser[]
    }, [data])

    const inviteEmail = useMutation({
        mutationFn: async (variables: { email: string; role: UserRole }) => {
            return await axios.post(
                `${process.env.REACT_APP_API_DOMAIN}/invite`,
                {
                    email: variables.email.trim(),
                    role: variables.role,
                }
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries(listUsers)
            addNotification(
                "Access granted!",
                "The user has been sent an email inviting them to join your workspace.",
                NotificationType.Success
            )
        },
        onError: (error) => {
            addNotification(
                "Failed to invite email!",
                "Please check the correct email was entered.",
                NotificationType.Error
            )
        },
    })

    const setUserRole = useMutation({
        mutationFn: async (variables: { userId: string; role: UserRole }) => {
            return await axios.patch(
                `${process.env.REACT_APP_API_DOMAIN}/organization/users/${variables.userId}/role`,
                { role: variables.role }
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries(listUsers)
            addNotification(
                "Successfully updated user role",
                "",
                NotificationType.Success
            )
        },
        onError: (error) => {
            addNotification(
                "Failed to update user role",
                `${error}`,
                NotificationType.Error
            )
        },
    })

    const setUserManagerId = useMutation({
        mutationFn: async (variables: {
            userId: string
            managerId: string
        }) => {
            return await axios.patch(
                `${process.env.REACT_APP_API_DOMAIN}/organization/users/${variables.userId}/manager`,
                { manager_id: variables.managerId || null }
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries(listUsers)
            addNotification(
                "Successfully updated user manager",
                "",
                NotificationType.Success
            )
        },
        onError: (error) => {
            addNotification(
                "Failed to update user manager",
                "Cannot set as a manager as it would create a loop in the " +
                    "company's management structure.",
                NotificationType.Error
            )
        },
    })

    const deleteUser = useMutation({
        mutationFn: async (variables: { userId: string }) => {
            return await axios.delete(
                `${process.env.REACT_APP_API_DOMAIN}/organization/users/${variables.userId}`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries(listUsers)
            addNotification(
                "Successfully deleted user",
                "",
                NotificationType.Success
            )
        },
        onError: (error) => {
            addNotification(
                "Failed to delete user",
                `${error}`,
                NotificationType.Error
            )
        },
    })

    const deleteInvite = useMutation({
        mutationFn: async (variables: { inviteId: string }) => {
            return await axios.delete(
                `${process.env.REACT_APP_API_DOMAIN}/invite/${variables.inviteId}`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries(listUsers)
            addNotification(
                "Successfully deleted invite",
                "",
                NotificationType.Success
            )
        },
        onError: (error) => {
            addNotification(
                "Failed to delete invite",
                `${error}`,
                NotificationType.Error
            )
        },
    })

    const resendInvite = useMutation({
        mutationFn: async (variables: { inviteId: string }) => {
            return await axios.post(
                `${process.env.REACT_APP_API_DOMAIN}/invite/resend/${variables.inviteId}`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries(listUsers)
            addNotification(
                "Invite resent!",
                "The user has been sent another email inviting them to join your workspace.",
                NotificationType.Success
            )
        },
        onError: (error) => {
            if (axios.isAxiosError(error) && error.response?.status === 429) {
                addNotification(
                    "Please wait before resending",
                    "You can only resend an invite every 5 minutes. Please try again later.",
                    NotificationType.Warning
                )
            } else {
                addNotification(
                    "Failed to resend invite!",
                    "An error occurred while trying to resend the invitation.",
                    NotificationType.Error
                )
            }
        },
    })

    return (
        <Card
            title="Workspace Management"
            label="Invite your team to your Glyphic workspace."
        >
            <div className="grid grid-cols-3 gap-12">
                <div className="col-span-2">
                    {canEditOrg ? (
                        <InviteNewUsers
                            orgName={props.orgName}
                            availableRoles={availableRoles}
                            inviteEmail={(email, role) =>
                                inviteEmail.mutate({
                                    email: email,
                                    role: role,
                                })
                            }
                        />
                    ) : (
                        <div className="flex h-full items-center justify-center text-center text-lg text-gray-500">
                            You must be an admin to invite users and edit
                            current users
                        </div>
                    )}
                </div>
                <UserCount availableRoles={availableRoles} users={users} />
            </div>
            {isPending ? (
                <LoadingPulse rows={8} height="h-10" />
            ) : (
                <UserList
                    availableRoles={availableRoles}
                    users={users}
                    setUserRole={(userId, role) =>
                        setUserRole.mutate({
                            userId: userId,
                            role: role,
                        })
                    }
                    setUserManager={(userId, managerId) =>
                        setUserManagerId.mutate({
                            userId: userId,
                            managerId: managerId,
                        })
                    }
                    deleteUser={(userId) => deleteUser.mutate({ userId })}
                    deleteInvite={(inviteId) =>
                        deleteInvite.mutate({ inviteId })
                    }
                    resendInvite={(inviteId) =>
                        resendInvite.mutate({ inviteId })
                    }
                />
            )}
        </Card>
    )
}

function InviteNewUsers(props: {
    orgName: string | undefined
    availableRoles: UserRole[]
    inviteEmail: (email: string, role: UserRole) => void
}) {
    const [email, setEmail] = useState<string>("")
    const [role, setRole] = useState<UserRole>(UserRole.USER)

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
            await props.inviteEmail(email, role)
            setEmail("")
        } catch (error) {
            console.log(error)
        }
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
    }

    const orgName = props.orgName || "Organization"

    return (
        <form
            onSubmit={handleSubmit}
            className="flex w-full flex-wrap justify-start gap-2"
        >
            <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                className="flex-grow rounded-md border border-gray-300 bg-gray-50 px-4 py-2 text-base"
                placeholder="Enter email"
                required
            />
            <div className="flex flex-nowrap items-center justify-stretch gap-2">
                <RoleSelect
                    availableRoles={props.availableRoles}
                    role={role}
                    onChange={setRole}
                />

                <PrimaryButton className="flex w-full flex-nowrap items-center gap-2">
                    <SendIcon />
                    <span className="text-xs md:text-sm">
                        Invite to {orgName}
                    </span>
                </PrimaryButton>
            </div>
        </form>
    )
}

function UserCount(props: {
    availableRoles: UserRole[]
    users: IOrganizationUser[]
}) {
    const acceptedUsers = props.users.filter((user) => user.has_accepted_invite)
    const pendingUsersCount = props.users.filter(
        (user) => !user.has_accepted_invite
    ).length
    const roleCount = _.countBy(acceptedUsers, "role")
    const maxCount = Math.max(...Object.values(roleCount), pendingUsersCount)

    return (
        <div className="w-full space-y-2 text-sm">
            {props.availableRoles.map((role) => {
                return (
                    <UserCountBar
                        key={role}
                        count={roleCount[role] || 0}
                        maxCount={maxCount}
                        label={formatRole(role)}
                        colour="bg-green-400"
                    />
                )
            })}
            <UserCountBar
                count={pendingUsersCount}
                maxCount={maxCount}
                label="Pending"
                colour="bg-blue-400"
            />
        </div>
    )
}

function UserCountBar(props: {
    count: number
    maxCount: number
    label: string
    colour: string
}) {
    if (props.count === 0) {
        return null
    }
    const percentage =
        props.maxCount > 0 ? (props.count / props.maxCount) * 100 : 0
    return (
        <div className="flex items-center gap-1">
            <div className="w-20 truncate">{props.label}</div>
            <div className="h-4 flex-grow rounded-full bg-gray-200">
                <div
                    className={clsx("h-4 rounded-full", props.colour)}
                    style={{ width: `${percentage}%` }}
                />
            </div>
            <div className="w-8 text-right">{props.count}</div>
        </div>
    )
}

function RoleSelect(props: {
    availableRoles: UserRole[]
    role: UserRole
    onChange: (role: UserRole) => void
}) {
    const user = useUser()
    const canEditOrg = hasPermission(user, Permission.VIEW_EDIT_ORG_SETTINGS)

    // Role descriptions for tooltips
    const roleDescriptions = {
        [UserRole.ADMIN]:
            "Able to record calls and edit anything in the platform.",
        [UserRole.USER]:
            "Able to record calls and edit their calls, cannot edit organization wide settings.",
        [UserRole.VIEWER]: "Can only view calls recorded by others.",
        [UserRole.RESTRICTED]: "Can only record and view their own calls.",
        [UserRole.SUPPORT]: "Can only view calls and edit their own calls.",
    }

    const options = props.availableRoles.map((role) => ({
        value: role,
        label: formatRole(role),
        description: roleDescriptions[role],
    }))
    const defaultOption = options.find((option) => option.value === props.role)

    return (
        <Select
            value={defaultOption}
            options={options}
            onChange={(option) => props.onChange(option?.value as UserRole)}
            className="flex"
            menuPlacement="auto"
            styles={{
                control: (baseStyles) => ({
                    ...baseStyles,
                    width: "130px",
                    backgroundColor: colors.gray[50],
                    borderWidth: "1.5px",
                    borderRadius: "0.375rem", // rounded-md
                    borderColor: colors.gray[300],
                }),
                menu: (baseStyles) => ({
                    ...baseStyles,
                    borderRadius: "0.375rem", // rounded-md
                    backgroundColor: colors.gray[50],
                }),
            }}
            components={{
                Option: ({ children, ...props }) => {
                    const { data } = props
                    return (
                        <div
                            data-tooltip-id="tooltip-explanation"
                            data-tooltip-content={data.description}
                            data-tooltip-place="right"
                        >
                            {/* @ts-ignore - using the default Option component */}
                            <components.Option {...props}>
                                {children}
                            </components.Option>
                        </div>
                    )
                },
            }}
            isDisabled={!canEditOrg}
        />
    )
}

function ManagerSelect(props: {
    currentUserId: string
    users: IOrganizationUser[]
    managerId: string | null
    onChange: (managerId: string) => void
}) {
    const user = useUser()
    const canEditOrg = hasPermission(user, Permission.VIEW_EDIT_ORG_SETTINGS)

    const availableManagers = _.orderBy(
        props.users.filter(
            (user) =>
                user.id !== props.currentUserId && user.has_accepted_invite
        ),
        (user) => user.name
    )

    const managerOptions = availableManagers.map((manager) => ({
        value: manager.id,
        label: manager.name,
    }))
    const noManagerOption = { value: null, label: "No manager" }

    const allOptions = [noManagerOption, ...managerOptions]

    const selectedOption = props.managerId
        ? managerOptions.find((option) => option.value === props.managerId)
        : noManagerOption

    return (
        <Select
            value={selectedOption}
            options={allOptions}
            placeholder="No manager"
            onChange={(option) => props.onChange(option?.value as string)}
            className="flex w-48"
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "inherit",
                    backgroundColor: colors.gray[50],
                    borderWidth: "1.5px",
                    borderRadius: "0.375rem", // rounded-md
                    borderColor: colors.gray[300],
                }),
                menu: (baseStyles) => ({
                    ...baseStyles,
                    borderRadius: "0.375rem", // rounded-md
                    backgroundColor: colors.gray[50],
                }),
            }}
            isDisabled={!canEditOrg}
        />
    )
}

function UserList(props: {
    availableRoles: UserRole[]
    users: IOrganizationUser[]
    setUserRole: (userId: string, role: UserRole) => void
    setUserManager: (userId: string, managerId: string) => void
    deleteUser: (userId: string) => void
    deleteInvite: (inviteId: string) => void
    resendInvite: (inviteId: string) => void
}) {
    const user = useUser()
    const canEditOrg = hasPermission(user, Permission.VIEW_EDIT_ORG_SETTINGS)
    const roleByUserId: Map<string, UserRole> = new Map(
        props.users.map((user) => [user.id, user.role])
    )

    return (
        <div className="w-full overflow-auto rounded-lg border border-gray-300">
            <table className="-z-10 table w-full">
                <thead>
                    <tr className="h-16">
                        <th className="px-4 py-2 text-left">Name</th>
                        <th className="px-4 py-2 text-left">Email</th>
                        <th className="px-4 py-2 text-left">Role</th>
                        <th className="px-4 py-2 text-left">Manager</th>
                        <th className="px-4 py-2 text-left">
                            Calendar Integration
                        </th>
                        <th className="px-4 py-2 text-left">Invite</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-300 border-t border-gray-300 text-sm">
                    {props.users.map((user) => (
                        <tr key={user.email}>
                            <td className="px-4 py-2">{user.name || ""}</td>
                            <td className="px-4 py-2">{user.email}</td>
                            <td className="px-4 py-2">
                                <RoleSelect
                                    availableRoles={props.availableRoles}
                                    role={roleByUserId.get(user.id)!}
                                    onChange={(role) => {
                                        props.setUserRole(user.id, role)
                                    }}
                                />
                            </td>
                            <td className="w-auto px-4 py-2">
                                {user.has_accepted_invite && (
                                    <ManagerSelect
                                        users={props.users}
                                        currentUserId={user.id}
                                        managerId={user.manager_id}
                                        onChange={(managerId: string) => {
                                            props.setUserManager(
                                                user.id,
                                                managerId
                                            )
                                        }}
                                    />
                                )}
                            </td>
                            <td className="px-2 py-2">
                                <CalendarIntegrationsCell
                                    calendarInfo={user.calendar_info}
                                />
                            </td>
                            <td className="px-2 py-2">
                                {user.has_accepted_invite ? (
                                    <AcceptedBadge />
                                ) : (
                                    <PendingBadge />
                                )}
                            </td>
                            <td className="px-2 py-2">
                                {canEditOrg &&
                                    (user.has_accepted_invite ? (
                                        <DeleteUserButton
                                            userName={user.name || user.email}
                                            onDeleteUser={() =>
                                                props.deleteUser(user.id)
                                            }
                                        />
                                    ) : (
                                        <div className="flex gap-2">
                                            <SecondaryButton
                                                onClick={() =>
                                                    props.deleteInvite(user.id)
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTrashCan}
                                                />
                                            </SecondaryButton>
                                            <ResendInviteButton
                                                onResendInvite={() =>
                                                    props.resendInvite(user.id)
                                                }
                                            />
                                        </div>
                                    ))}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

function ResendInviteButton({
    onResendInvite,
}: {
    onResendInvite: () => void
}) {
    return <SecondaryButton onClick={onResendInvite}>Resend</SecondaryButton>
}

function PendingBadge() {
    return (
        <Badge
            className="border border-blue-200 bg-blue-50 text-sm text-blue-800"
            label="Pending"
        />
    )
}

function AcceptedBadge() {
    return (
        <Badge
            className="border border-green-200 bg-green-50 text-sm text-green-800"
            label="Accepted"
        />
    )
}

function CalendarIntegrationsCell(props: {
    calendarInfo: IOrgUserCalendarInfo | null
}) {
    if (!props.calendarInfo) {
        return null
    }

    const connected = props.calendarInfo.connected
    const preferences = props.calendarInfo.preferences

    const tooltipHtml = (
        <div className="flex flex-col divide-y divide-gray-700">
            <div className="flex items-center gap-2 p-1">
                <RecordingIcon calendarInfo={props.calendarInfo} />
                {connected ? "Calendar connected" : "Calendar not connected"}
            </div>
            {connected && (
                <>
                    <div>
                        <RecordingPreferencesSetting
                            enabled={preferences?.record_internal ?? false}
                            label="Record Internal"
                        />
                        <RecordingPreferencesSetting
                            enabled={preferences?.record_external ?? false}
                            label="Record External"
                        />
                    </div>
                    <div>
                        <RecordingPreferencesSetting
                            enabled={preferences?.record_confirmed ?? false}
                            label="Record Confirmed Only"
                        />
                        <RecordingPreferencesSetting
                            enabled={preferences?.record_host ?? false}
                            label="Record Host Only"
                        />
                    </div>
                </>
            )}
        </div>
    )

    return (
        <span
            className="flex items-center justify-center gap-2"
            data-tooltip-id="tooltip-explanation"
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(tooltipHtml)}
        >
            <RecordingIcon calendarInfo={props.calendarInfo} />
        </span>
    )
}

function RecordingIcon({
    calendarInfo,
}: {
    calendarInfo: IOrgUserCalendarInfo
}) {
    if (!calendarInfo.connected) {
        return (
            <FontAwesomeIcon
                icon={faCircleXmark}
                className="text-gray-400"
                size="xl"
            />
        )
    } else if (!calendarInfo.preferences?.record_external) {
        return (
            <FontAwesomeIcon
                icon={faCircleCalendar}
                className="text-yellow-500"
                size="xl"
            />
        )
    } else {
        return (
            <FontAwesomeIcon
                icon={faCircleVideo}
                className="text-green-500"
                size="xl"
            />
        )
    }
}

function RecordingPreferencesSetting(props: {
    enabled: boolean
    label: string
}) {
    return (
        <div className="flex items-center gap-2 p-1">
            {props.enabled ? (
                <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="text-green-500"
                    size="lg"
                />
            ) : (
                <FontAwesomeIcon
                    icon={faCircleXmark}
                    className="text-gray-400"
                    size="lg"
                />
            )}{" "}
            {props.label}
        </div>
    )
}
