import { CitationsMessageBlock } from "../../../types/ThreadTypes"
import { BlockHandler } from "./BaseBlockHandlers"
import { CitationProperties, DocumentType } from "../../../../../types/Citation"

export class CitationsBlockHandler
    implements BlockHandler<CitationsMessageBlock>
{
    onCreated(): CitationsMessageBlock {
        return {
            type: "citation",
            content: "",
            citation: undefined,
            closed: false,
        }
    }

    onContent(
        block: CitationsMessageBlock,
        content: string
    ): CitationsMessageBlock {
        const fullContent = (block.content + content).trim()

        try {
            const parsedData = JSON.parse(fullContent)

            const citation: CitationProperties = {
                type: parsedData.type || DocumentType.COMPANY_NOTES,
                call_id: parsedData.call_id || undefined,
                company_id: parsedData.company_id || undefined,
                title: parsedData.title || undefined,
                cited_text: parsedData.cited_text || undefined,
            }
            return { ...block, content: fullContent, citation }
        } catch (error) {
            console.error("Error parsing citation:", fullContent, content)
            throw error
        }
    }

    onClosed(block: CitationsMessageBlock): CitationsMessageBlock {
        return { ...block, closed: true }
    }
}
