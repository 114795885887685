import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import LoadingSpinner from "../common/LoadingSpinner"
import {
    IActivityDateRange,
    IActivityStatsResponse,
    IActivityUserInfo,
} from "../../types/activity"
import { UserCircle } from "../UserCircle"
import { useEffect, useState } from "react"
import _ from "lodash"
import { IParticipant } from "../../types/Call"
import { getSimpleDate } from "../../utils/datetime"
import {
    ACTIVITY_DATE_TAB_CACHE_KEY,
    ACTIVITY_USER_TAB_CACHE_KEY,
    ActivityFilters,
    calculateActivitySinceDate,
    showMyTeamFromTab,
} from "./ActivityFilters"
import { Flipper, Flipped } from "react-flip-toolkit"
import { useAnimationDelay } from "../../utils/animationDelay"
import clsx from "clsx"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { getCacheValue, setCacheValue } from "../../utils/localStorageCache"
import { displaySecondsAsHours } from "../../utils/datetime"

const INTERNAL_TOGGLE_KEY = "activity-filters-include-internal-calls"

export function ActivityPage() {
    const [sinceDate, setSinceDate] = useState<Date | null>(
        calculateActivitySinceDate(
            getCacheValue(ACTIVITY_DATE_TAB_CACHE_KEY, 0)
        )
    )
    const [showMyTeam, setShowMyTeam] = useState<boolean>(
        showMyTeamFromTab(getCacheValue(ACTIVITY_USER_TAB_CACHE_KEY, 0))
    )
    const [includeInternalCalls, setIncludeInternalCalls] = useState(
        getCacheValue(INTERNAL_TOGGLE_KEY, false)
    )

    const { data: activityStats } = useQuery<IActivityStatsResponse>({
        queryKey: [
            "activity-dashboard",
            sinceDate,
            includeInternalCalls,
            showMyTeam,
        ],
        queryFn: async () => {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/activity/`,
                {
                    params: {
                        since: sinceDate,
                        include_internal_calls: includeInternalCalls,
                        only_my_team: showMyTeam,
                    },
                }
            )
            return data
        },
    })

    return (
        <div className="mx-auto my-6 max-w-7xl space-y-4 p-6">
            <div className="space-y-4">
                <h1 className="text-2xl font-bold">Activity</h1>
                <ActivityFilters
                    setSinceDate={setSinceDate}
                    setShowMyTeam={setShowMyTeam}
                    includeInternalCalls={includeInternalCalls}
                    setIncludeInternalCalls={(newValue) => {
                        setIncludeInternalCalls(newValue)
                        setCacheValue(INTERNAL_TOGGLE_KEY, newValue)
                    }}
                />
            </div>
            <ActivityCharts activityStats={activityStats} />
        </div>
    )
}

interface SortingOptions {
    key: keyof IActivityUserInfo["stats"]
    direction: "desc" | "asc"
}

function ActivityCharts(props: { activityStats?: IActivityStatsResponse }) {
    const [activityStats, setActivityStats] = useState<IActivityStatsResponse>()
    const [sorting, setSorting] = useState<SortingOptions>({
        key: "num_calls",
        direction: "desc",
    })

    useEffect(() => {
        if (props.activityStats) {
            setActivityStats(props.activityStats)
        }
    }, [props.activityStats])

    if (!activityStats) {
        return (
            <div>
                <LoadingSpinner />
            </div>
        )
    }
    const users = activityStats.users

    const userActivity = _.orderBy(
        users,
        (user) => user.stats[sorting.key as keyof IActivityUserInfo["stats"]],
        sorting.direction
    )

    const [usersWithTalkTime, usersWithoutTalkTime] = _.partition(
        userActivity,
        (user) => user.stats.median_talk_time_percentage > 0
    )

    const maxDuration = _.max(
        usersWithTalkTime.map((user) => user.stats.total_call_duration_seconds)
    )
    const maxCalls = _.max(
        usersWithTalkTime.map((user) => user.stats.num_calls)
    )
    const maxTalkSpeed = _.max(
        usersWithTalkTime.map(
            (user) => user.stats.median_talk_speed_in_words_per_minute
        )
    )

    const getMissingUsersMessage = (
        usersWithoutTalkTime: IActivityUserInfo[]
    ): string => {
        if (usersWithoutTalkTime.length === 0) {
            return ""
        }
        if (usersWithoutTalkTime.length === 1) {
            return `${usersWithoutTalkTime[0].name} has not participated in any calls during this time period.`
        }
        const names = usersWithoutTalkTime
            .map((user, index) => {
                return index === usersWithoutTalkTime.length - 1
                    ? `and ${user.name}`
                    : user.name
            })
            .join(", ")

        return `${names} have not participated in any calls during this time period.`
    }

    const updateSorting = (key: keyof IActivityUserInfo["stats"]) => {
        if (sorting.key === key) {
            setSorting({
                key,
                direction: sorting.direction === "asc" ? "desc" : "asc",
            })
        } else {
            setSorting({ key, direction: "desc" })
        }
    }

    return (
        <div className="overflow-auto rounded-lg border bg-white p-5">
            <h2 className="text-[22px] font-bold">
                {dateRangeToStr(activityStats.date_range)}
            </h2>
            {usersWithTalkTime.length === 0 && (
                <div className="mt-8 text-sm">
                    No one has participated in any calls during this time
                    period.
                </div>
            )}
            {usersWithTalkTime.length > 0 && (
                <Flipper
                    flipKey={usersWithTalkTime
                        .map((user) => user.user_id)
                        .join("")}
                >
                    <table className="w-full border-separate border-spacing-y-4">
                        <thead className="text-left text-lg">
                            <tr>
                                <th className="w-72">Person</th>
                                <StatHeaderCell
                                    label="Calls"
                                    isSorted={
                                        sorting.key === "num_calls"
                                            ? sorting.direction
                                            : null
                                    }
                                    onClick={() => updateSorting("num_calls")}
                                />
                                <StatHeaderCell
                                    label="Total call time"
                                    isSorted={
                                        sorting.key ===
                                        "total_call_duration_seconds"
                                            ? sorting.direction
                                            : null
                                    }
                                    onClick={() =>
                                        updateSorting(
                                            "total_call_duration_seconds"
                                        )
                                    }
                                />
                                <StatHeaderCell
                                    label="Talk:Listen ratio"
                                    isSorted={
                                        sorting.key ===
                                        "median_talk_time_percentage"
                                            ? sorting.direction
                                            : null
                                    }
                                    onClick={() =>
                                        updateSorting(
                                            "median_talk_time_percentage"
                                        )
                                    }
                                />
                                <StatHeaderCell
                                    label="Talk speed (words /m)"
                                    isSorted={
                                        sorting.key ===
                                        "median_talk_speed_in_words_per_minute"
                                            ? sorting.direction
                                            : null
                                    }
                                    onClick={() =>
                                        updateSorting(
                                            "median_talk_speed_in_words_per_minute"
                                        )
                                    }
                                />
                            </tr>
                        </thead>
                        <tbody>
                            {usersWithTalkTime.map((user) => (
                                <Flipped
                                    key={user.user_id}
                                    flipId={user.user_id}
                                >
                                    <tr key={user.user_id}>
                                        <td>
                                            <UserInfo user={user} />
                                        </td>
                                        <td>
                                            <LabeledChartBar
                                                value={user.stats.num_calls}
                                                maxValue={maxCalls!}
                                                color="bg-papyrus"
                                            />
                                        </td>
                                        <td>
                                            <LabeledChartBar
                                                value={
                                                    user.stats
                                                        .total_call_duration_seconds
                                                }
                                                label={displaySecondsAsHours(
                                                    user.stats
                                                        .total_call_duration_seconds
                                                )}
                                                maxValue={maxDuration!}
                                                color="bg-sky-500"
                                            />
                                        </td>
                                        <td>
                                            <LabeledMeter
                                                value={
                                                    user.stats
                                                        .median_talk_time_percentage
                                                }
                                            />
                                        </td>
                                        <td>
                                            <LabeledChartBar
                                                value={
                                                    user.stats
                                                        .median_talk_speed_in_words_per_minute
                                                }
                                                maxValue={maxTalkSpeed!}
                                                color="bg-yellow-500"
                                            />
                                        </td>
                                    </tr>
                                </Flipped>
                            ))}
                        </tbody>
                    </table>
                </Flipper>
            )}
            {usersWithTalkTime.length > 0 &&
                usersWithoutTalkTime.length > 0 && (
                    <div className="mt-10 text-sm">
                        {getMissingUsersMessage(usersWithoutTalkTime)}
                    </div>
                )}
        </div>
    )
}

function StatHeaderCell(props: {
    label: string
    isSorted: string | null
    onClick: () => void
}) {
    const [hovered, setHovered] = useState(false)
    return (
        <th
            className="w-72 cursor-pointer items-center space-x-2"
            onClick={props.onClick}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <span>{props.label}</span>
            {(hovered || props.isSorted) && (
                <FontAwesomeIcon
                    icon={faChevronUp}
                    className={clsx(
                        "w-6 text-gray-600",
                        props.isSorted === "desc" && "rotate-180"
                    )}
                />
            )}
        </th>
    )
}

function UserInfo(props: { user: IActivityUserInfo }) {
    return (
        <div className="flex space-x-2">
            <UserCircle
                user={activityUserInfoToParticipant(props.user)}
                size="40px"
            />
            <div className="flex flex-col">
                <span className="font-bold">{props.user.name}</span>
                <span className="text-sm text-gray-600">
                    {props.user.email}
                </span>
            </div>
        </div>
    )
}

function LabeledMeter(props: { value: number }) {
    const displayValue = Math.round(props.value)
    return (
        <div className="mr-4 flex items-center space-x-4">
            <span className="w-8 font-bold">{displayValue}%</span>
            <div className="w-32">
                <Meter pct={props.value} />
            </div>
        </div>
    )
}

function LabeledChartBar(props: {
    value: number
    maxValue: number
    color: string
    label?: string
}) {
    const label = props.label || Math.round(props.value)
    const widthPct = Math.min((props.value / props.maxValue) * 100, 100)
    return (
        <div className="mr-4 flex items-center space-x-4">
            <span className="w-8 font-bold">{label}</span>
            <div className="w-32">
                <ChartBar widthPct={widthPct} color={props.color} />
            </div>
        </div>
    )
}

function dateRangeToStr(range: IActivityDateRange) {
    return `${getSimpleDate(range.start)} - ${getSimpleDate(range.end)}`
}

function activityUserInfoToParticipant(user: IActivityUserInfo): IParticipant {
    // This conversion is needed as <UserCircle /> currently expects a participant object
    return {
        id: Number(user.user_id),
        name: user.name,
        email: user.email,
        profile: { photo_url: user.photo_url || undefined },
    }
}

const ChartBar = (props: { widthPct: number; color: string }) => {
    // Animate the bar appearing a brief delay after the page loads
    const isAnimationTriggered = useAnimationDelay()
    return (
        <div
            className={`${props.color} h-4 rounded-lg transition-width duration-500 ease-in-out`}
            style={{
                width: isAnimationTriggered ? `${props.widthPct}%` : "0%",
            }}
        ></div>
    )
}

export function Meter(props: { pct: number }) {
    const isAnimationTriggered = useAnimationDelay()
    return (
        <div className="h-4 overflow-hidden rounded-lg bg-violet-700">
            <div
                className={clsx(
                    "h-4 rounded-lg bg-violet-400 transition-width duration-500 ease-in-out",
                    props.pct < 100 ? "rounded-r-none" : ""
                )}
                style={{
                    width: isAnimationTriggered ? `${props.pct}%` : "0%",
                }}
            ></div>
        </div>
    )
}
