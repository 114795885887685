import { CopyIcon } from "../../assets/icons/CopyIcon"
import { useNotification } from "../../providers/NotificationProvider"
import { SecondaryButton } from "./Buttons"
import { NotificationType } from "./Notifcations"

export function CopyButton(props: { label?: string; content: string }) {
    const { addNotification } = useNotification()

    async function copy() {
        if (!props.content) {
            addNotification(
                "Could not copy to clipboard",
                "text is undefined",
                NotificationType.Error
            )
            return
        }
        await navigator.clipboard.writeText(props.content)
        addNotification("Copied to clipboard!", "", NotificationType.Success)
    }

    if (!props.label) {
        return (
            <button onClick={copy} title="Copy to clipboard">
                <CopyIcon className="text-gray-600" />
            </button>
        )
    }

    return (
        <SecondaryButton
            className="flex flex-shrink-0 flex-row items-center gap-2 overflow-hidden"
            onClick={copy}
        >
            {props.label}
            <CopyIcon className="text-gray-600" />
        </SecondaryButton>
    )
}
