import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ColoredLabelSquare } from "../common/ColoredLabelSquare"
import { SelectionState } from "./SelectionState"
import clsx from "clsx"

import { useNavigate } from "react-router-dom"
import { parseInsightToUrl } from "../../calls-list/CallsList"
import { InsightResult } from "../../../types/Insights"
import { useSearchModal } from "../../../providers/SearchProvider"
import { faSparkles } from "@fortawesome/pro-regular-svg-icons"
import { RecordingIcon } from "../../../assets/icons/RecordingIcon"

export function Legend(props: {
    insight: InsightResult
    colors: string[]
    totalCounts: [string, number][]
    selectionState: SelectionState
    toggleSelectedOption: (option: string) => void
    setHoveredOption: (option: string | null) => void
    isOptionSelected: (option: string, state: SelectionState) => boolean
}) {
    const navigate = useNavigate()
    const insightName = props.insight.displayName

    const { openSearchModal } = useSearchModal()

    return (
        <div className="flex w-full flex-col text-sm">
            <div className="flex w-full flex-row justify-between">
                <span className="mb-3 font-bold text-gray-800">
                    {insightName}
                </span>
                <div>
                    <span className="mb-3 pr-8 font-bold text-gray-800">
                        Total
                    </span>
                </div>
            </div>
            {props.totalCounts.map(([option, total], index) => (
                <div
                    key={option}
                    className="group my-2 flex w-full flex-row items-center justify-between gap-2"
                    onMouseEnter={() => props.setHoveredOption(option)}
                    onMouseLeave={() => props.setHoveredOption(null)}
                >
                    <button
                        className={clsx(
                            "flex w-full items-center gap-2 overflow-hidden text-gray-600 hover:font-semibold",
                            !props.isOptionSelected(
                                option,
                                props.selectionState
                            ) && "opacity-50 hover:opacity-100"
                        )}
                        onClick={() => props.toggleSelectedOption(option)}
                    >
                        <ColoredLabelSquare
                            color={props.colors[index % props.colors.length]}
                            // We use the props.selectionState.selected
                            // directly so that we don't count with the
                            // hovered state.
                            isSelected={props.selectionState.selected.has(
                                option
                            )}
                        />
                        <span
                            className="flex-1 truncate text-left"
                            data-tooltip-id="tooltip-explanation"
                            data-tooltip-content={option}
                        >
                            {option}
                        </span>
                    </button>
                    <div
                        className={clsx(
                            "w-fit min-w-0",
                            !props.isOptionSelected(
                                option,
                                props.selectionState
                            ) && "opacity-50"
                        )}
                    >
                        <div className="mr-2 whitespace-nowrap">
                            <span className="font-bold text-gray-600">
                                {total}
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-row gap-1">
                        <LegendActionButton
                            icon={<RecordingIcon className="h-3 w-3" />}
                            onClick={() => {
                                navigate(
                                    `/calls?insight=${parseInsightToUrl({
                                        insightName: props.insight.name,
                                        values: [option],
                                    })}`
                                )
                            }}
                            tooltip={`<div style="text-align: center;">View calls where <b>${insightName}</b> is<br/><b>${option}</b></div>`}
                        />
                        <LegendActionButton
                            icon={
                                <FontAwesomeIcon
                                    className="text-xs transition-colors"
                                    icon={faSparkles}
                                    size="sm"
                                />
                            }
                            onClick={() => {
                                openSearchModal({
                                    insights: [
                                        {
                                            field_name: props.insight.name,
                                            values: [option],
                                        },
                                    ],
                                })
                            }}
                            tooltip={`<div style="text-align: center;">Ask Glyphic about calls where <b>${insightName}</b> is<br/><b>${option}</b></div>`}
                        />
                    </div>
                </div>
            ))}
        </div>
    )
}

function LegendActionButton({
    icon,
    onClick,
    tooltip,
}: {
    icon: JSX.Element
    onClick: () => void
    tooltip: string
}) {
    return (
        <button
            className="rounded-md  bg-gray-200 px-1 text-gray-600 hover:bg-gray-400 hover:text-gray-800"
            data-tooltip-id="tooltip-explanation"
            data-tooltip-html={tooltip}
            onClick={onClick}
        >
            {icon}
        </button>
    )
}
