interface TimestampProps {
    timestamp: string
    onClick: (timestamp: string) => void
}

export function Timestamp({ timestamp, onClick }: TimestampProps) {
    return (
        <div
            className="mr-2 flex-none cursor-pointer rounded-md px-2 py-1 text-slate-400 hover:bg-gray-100"
            onClick={() => onClick(timestamp)}
        >
            {timestamp}
        </div>
    )
}
