import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react"
import { SearchModal } from "../components/global-search/Search"
import { useUser } from "./UserProvider"
import { hasPermission } from "../utils/Permissions"
import { Permission } from "../types/Permission"
import { useLocation } from "react-router-dom"
import { ISearchFilters } from "../types/search"

export interface SearchModalContextType {
    openSearchModal: (initialFilters?: ISearchFilters) => void
}

export const SearchModalContext = createContext<
    SearchModalContextType | undefined
>(undefined)

// SearchModalProvider allows other components to open the search modal
export const SearchModalProvider = ({ children }: { children: ReactNode }) => {
    const location = useLocation()
    const user = useUser()
    const canViewOthersCalls =
        user && hasPermission(user, Permission.VIEW_OTHERS_CALLS)
    const [showSearch, setShowSearch] = useState(false)
    const [initialFilters, setInitialFilters] = useState<ISearchFilters>()

    const openSearchModal = (initialFilters?: ISearchFilters) => {
        setShowSearch(true)
        setInitialFilters(initialFilters)
    }

    const closeSearchModal = () => setShowSearch(false)

    // Listen for keyboard shortcuts to open and close the search modal
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            // cmd-k or ctrl-k to open the search modal
            if (event.key === "k" && (event.ctrlKey || event.metaKey)) {
                setShowSearch((prev) => !prev)
            }
            if (event.key === "Escape") {
                setShowSearch(false)
            }
        }
        document.addEventListener("keydown", handleKeyDown)
        return () => {
            document.removeEventListener("keydown", handleKeyDown)
        }
    }, [])

    useEffect(() => {
        // Close the search modal when the user navigates to a new page
        closeSearchModal()
    }, [location.pathname])

    // To keep things simple
    // we just completely remove the search modal
    // if the user doesn't have permission to view other people's calls
    if (!canViewOthersCalls) {
        return <>{children}</>
    }

    return (
        <SearchModalContext.Provider value={{ openSearchModal }}>
            {children}
            <SearchModal
                isOpen={showSearch}
                onClose={closeSearchModal}
                initialFilters={initialFilters}
            />
        </SearchModalContext.Provider>
    )
}

export const useSearchModal = () => {
    const context = useContext(SearchModalContext)
    if (context === undefined) {
        throw new Error(
            "useSearchModal must be used within a SearchModalProvider"
        )
    }
    return context
}
