import {
    MessageBlock,
    isCitationMessageBlock,
    isTextMessageBlock,
} from "../types/ThreadTypes"
import { getCitationUrl } from "../CitationCard"

export function getFullTextFromBlocks(blocks: MessageBlock[]): string {
    if (blocks.length === 0) {
        return ""
    }

    // First, collect all text content
    const textContent = blocks
        .filter(isTextMessageBlock)
        .map((block) => block.content)
        .join("")

    // Then, collect all citations
    const citations = blocks
        .filter(isCitationMessageBlock)
        .filter((block) => block.citation)
        .map((block) => {
            const title = block.citation?.title || "Citation"
            const citedText = block.citation?.cited_text || ""
            const relativeUrl = block.citation
                ? getCitationUrl(block.citation)
                : null

            if (relativeUrl) {
                const baseUrl = process.env.REACT_APP_WEBSITE_DOMAIN ?? ""
                const fullUrl = `${baseUrl}${relativeUrl}`
                return `${title}\n${fullUrl}\n${citedText}`
            } else {
                return `${title}\n${citedText}`
            }
        })

    // If there are citations, append them with a separator
    if (citations.length > 0) {
        return `${textContent}\n\n--- Citations ---\n${citations.join(
            "\n\n---\n"
        )}`
    }

    // Otherwise just return the text content
    return textContent
}
