import { Navigate, useLocation, useSearchParams } from "react-router-dom"
import { callbackParams } from "./page/OrganisationalPage"

export function RootSettingsRedirect() {
    const location = useLocation()

    const [searchParams] = useSearchParams()

    const shouldRedirectToOrg = callbackParams.some((param) =>
        searchParams.get(param)
    )

    const redirectTo = shouldRedirectToOrg
        ? `/settings/organizational${location.search}`
        : `/settings/personal${location.search}`

    return <Navigate to={redirectTo} state={{ from: location }} replace />
}
