import { DateTime, Duration } from "luxon"

export function getLocalDateTime(isoDateTime: string): DateTime {
    // If no timezone offset is present default to UTC
    return DateTime.fromISO(isoDateTime, { zone: "UTC" }).toLocal()
}

export function localToUTC(isoDateTime: string): string | null {
    const localDateTime = DateTime.fromISO(isoDateTime, { zone: "local" })
    return localDateTime.toUTC().toISO()
}

export function getDetailedDateTime(isoDateTime: string): string {
    const callDateTime = getLocalDateTime(isoDateTime)
    return callDateTime.toLocaleString(DateTime.DATETIME_FULL)
}

export function getSimpleDate(isoDateTime: string): string {
    const callDateTime = getLocalDateTime(isoDateTime)
    return callDateTime.toLocaleString(DateTime.DATE_MED)
}

export function getSimpleTime(isoDateTime: string): string {
    const callDateTime = getLocalDateTime(isoDateTime)
    return callDateTime.toLocaleString(DateTime.TIME_SIMPLE)
}

export function getSimpleDateWithoutYear(isoDateTime: string): string {
    const callDateTime = getLocalDateTime(isoDateTime)
    return callDateTime.toFormat("d MMM") // Example format: "13 Aug"
}

export function getSimpleDateWithYearIfDifferent(isoDateTime: string): string {
    // Returns the date in the format "13 Aug" if the year is the same as the current year,
    // otherwise it returns the date in the format "13 Aug 2024"
    const currentYear = DateTime.local().year
    if (getLocalDateTime(isoDateTime).year === currentYear) {
        return getSimpleDateWithoutYear(isoDateTime)
    }
    return getSimpleDate(isoDateTime)
}

function getDaysUntilNow(isoDateTime: string): number {
    const callDateTime = getLocalDateTime(isoDateTime)
    const currentDate = DateTime.local().startOf("day")
    return callDateTime.startOf("day").diff(currentDate, "days").days
}

export function getFormattedDateTime(
    isoDateTime: string,
    showTimeForDistantDates: boolean = false
): string {
    // Returns a formatted date
    // If showTimeForDistantDates is true then time is always appended,
    // otherwise it is only appended for close dates (today, tomorrow, yesterday)
    const callDateTime = getLocalDateTime(isoDateTime)
    const daysUntilNow = getDaysUntilNow(isoDateTime)

    const time = callDateTime.toLocaleString(DateTime.TIME_SIMPLE)
    const date = getFormattedDate(callDateTime)
    const isCloseDate = Math.abs(daysUntilNow) <= 1

    // For close dates (today, yesterday, tomorrow), always append time
    if (isCloseDate) {
        return `${date}, ${time}`
    }

    // For distant dates, only append time if showTimeForDistantDates is true
    if (showTimeForDistantDates) {
        return `${date}, ${time}`
    }

    return date
}

export function getFormattedDate(
    date: DateTime,
    distantDateFormat: Intl.DateTimeFormatOptions = DateTime.DATE_MED_WITH_WEEKDAY
): string {
    // Returns a formatted date without time, using relative terms for close dates
    const daysUntilNow = getDaysUntilNow(date.toString())

    if (daysUntilNow === 0) {
        return "Today"
    } else if (daysUntilNow === -1) {
        return "Yesterday"
    } else if (daysUntilNow === 1) {
        return "Tomorrow"
    }

    return date.toLocaleString(distantDateFormat)
}

export function formatDuration(seconds: number): string {
    const duration = Duration.fromObject({ seconds })
    // If duration is 0, rescale() won't work
    if (seconds === 0) return duration.toHuman({ unitDisplay: "narrow" })
    return duration.rescale().toHuman({ unitDisplay: "narrow" })
}

export function timestampToSeconds(timestamp: string): number {
    // Formats timestamp of the format "mm:ss" into seconds
    const [minutes, seconds] = timestamp.split(":").map((num) => parseInt(num))
    if (isNaN(minutes) || isNaN(seconds)) {
        throw new Error("Invalid timestamp format")
    }
    return minutes * 60 + seconds
}

export function secondsToTimestamp(seconds: number): string {
    // Formats seconds into a timestamp of the format "mm:ss"
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = Math.round(seconds % 60)
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`
}

export function displaySecondsAsHours(seconds: number): string {
    const hours = Math.round(seconds / 3600)
    if (hours === 0) {
        return `<1h`
    }
    return `${hours}h`
}

export function convertDateToISODateString(dateString: string): string {
    return new Date(dateString + "T00:00:00.000Z").toISOString()
}

export function getStartOfWeek(date: string) {
    const dateTime = DateTime.fromISO(date)
    const startOfWeek = dateTime.startOf("week")
    return `${startOfWeek.toFormat("d LLL")}`
}

export function getMonth(date: string) {
    const dateTime = DateTime.fromISO(date)
    return `${dateTime.toFormat("LLL")}`
}

export function getRemainingDays(date: string) {
    const dateTime = DateTime.fromISO(date)
    const currentDate = DateTime.local().startOf("day")
    const diffInDays = dateTime.startOf("day").diff(currentDate, "days").days
    return diffInDays
}

export function getRelativeToNow(date: string): string | null {
    const dateTime = getLocalDateTime(date)
    let relative = dateTime.toRelative()

    if (relative === null) {
        return null
    }

    // Luxon supports toRelative({style: "narrow"}) but it's not short enough.
    // So we abbreviate the units ourselves.

    const abbreviations = {
        "years?": "y", // matches "year" or "years"
        "months?": "mo", // matches "month" or "months"
        "weeks?": "w", // matches "week" or "weeks"
        "days?": "d", // matches "day" or "days"
        "hours?": "h", // matches "hour" or "hours"
        "minutes?": "m", // matches "minute" or "minutes"
        "seconds?": "s", // matches "second" or "seconds"
    }

    // Replace each full unit with its abbreviation.
    for (const [fullUnit, abbreviation] of Object.entries(abbreviations)) {
        const regex = new RegExp(`\\b ${fullUnit}\\b`, "gi")
        relative = relative.replace(regex, abbreviation)
    }

    return relative
}
