import { useIntegrations } from "../../../hooks/useIntegrations"

import { NotificationPreferences } from "../NotificationPreferences"

import { BaseSettingsPage } from "./BaseSettingsPage"

export function NotificationsPage() {
    const { hasSlack } = useIntegrations()

    return (
        <BaseSettingsPage pageTitle={"Notifications"}>
            <NotificationPreferences slackEnabled={hasSlack} />
        </BaseSettingsPage>
    )
}
