interface IUpcomingCallMetadata {
    is_private: boolean
}

export interface IUpcomingCall {
    id: string
    metadata: IUpcomingCallMetadata
    title: string
    start_time: string
    end_time: string
    will_record: boolean
    will_record_reason: string
    is_external: boolean
    ical_uid: string | null
    meeting_url: string | null
    prep_sheet_id: string | null
    prep_sheet_url: string | null
    has_bot: boolean
}

/**
 * This is a constant that is used to check if a call is recordable or not
 * Recall API does not override will_record for meetings who have this value as their will_record_reason.
 * It seems that it applies to meetings that are missing a meeting URL.
 */
export const RECALL_API_MISSING_INFORMATION_WILL_RECORD_REASON =
    "This meeting does not have sufficient information to be recorded."
