import { useMutation, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import { NotificationType } from "../../components/common/Notifcations"
import { useNotification } from "../../providers/NotificationProvider"
import { ICallComment, IEmojiReaction } from "../../types/Comment"
import { queries } from "../queries"

interface ICreateArgs {
    callId: string
    content: string
    call_timestamp: string
    parent_id?: string
}

export function useAddComment() {
    const { addNotification } = useNotification()
    const queryClient = useQueryClient()

    const addNewCommentToCache = (
        newComment: ICallComment,
        callId: string,
        parentId?: string
    ) => {
        const queryKey = queries.comments.byCallId(callId)

        if (parentId === undefined) {
            // If the comment is a top-level comment, add it to the list of comments
            queryClient.setQueryData(
                queryKey.queryKey,
                (old: ICallComment[]) => [...old, newComment]
            )
        } else {
            // If the comment is a reply, add it to the list of replies for the parent comment
            queryClient.setQueryData(
                queryKey.queryKey,
                (old: ICallComment[]) => {
                    return old.map((comment) => {
                        if (comment.id === parentId) {
                            return {
                                ...comment,
                                replies: [...comment.replies, newComment],
                            }
                        }
                        return comment
                    })
                }
            )
        }
    }

    const { mutateAsync, isPending } = useMutation({
        mutationFn: async ({ callId, ...comment }: ICreateArgs) => {
            const response = await axios.post(
                `${process.env.REACT_APP_API_DOMAIN}/comments/call/${callId}`,
                comment
            )
            return {
                newComment: response.data as ICallComment,
                callId,
                parentId: comment.parent_id,
            }
        },

        onSuccess: ({ newComment, callId, parentId }) => {
            const queryKey = queries.comments.byCallId(callId)

            addNewCommentToCache(newComment, callId, parentId)
            queryClient.invalidateQueries(queryKey)
        },
        onError: () => {
            addNotification(
                "Failed to post comment",
                "",
                NotificationType.Error
            )
        },
    })

    return {
        mutateAsync,
        isPending,
    }
}

interface IUpdateArgs {
    callId: string
    commentId: string
    content: string
}

export function useUpdateComment() {
    const { addNotification } = useNotification()
    const queryClient = useQueryClient()

    const { mutateAsync, isPending } = useMutation({
        mutationFn: async ({ callId, commentId, content }: IUpdateArgs) => {
            await axios.put(
                `${process.env.REACT_APP_API_DOMAIN}/comments/${commentId}`,
                { content }
            )
            return callId
        },

        onSuccess: (callId) => {
            queryClient.invalidateQueries(queries.comments.byCallId(callId))
        },
        onError: () => {
            addNotification(
                "Failed to update comment",
                "",
                NotificationType.Error
            )
        },
    })

    return {
        mutateAsync,
        isPending,
    }
}

export function useDeleteComment() {
    const { addNotification } = useNotification()
    const queryClient = useQueryClient()

    const { mutateAsync, isPending } = useMutation({
        mutationFn: async ({
            callId,
            commentId,
        }: {
            callId: string
            commentId: string
        }) => {
            await axios.delete(
                `${process.env.REACT_APP_API_DOMAIN}/comments/${commentId}`
            )
            return callId
        },

        onSuccess: (callId) => {
            addNotification("Comment deleted", "", NotificationType.Success)
            queryClient.invalidateQueries(queries.comments.byCallId(callId))
        },
        onError: () => {
            addNotification(
                "Failed to delete comment",
                "",
                NotificationType.Error
            )
        },
    })

    return {
        mutateAsync,
        isPending,
    }
}

export function useUpdateCommentReaction() {
    const queryClient = useQueryClient()

    const { mutateAsync } = useMutation({
        mutationFn: async ({
            callId,
            commentId,
            emoji_reactions,
        }: {
            callId: string
            commentId: string
            emoji_reactions: IEmojiReaction[]
        }) => {
            await axios.put(
                `${process.env.REACT_APP_API_DOMAIN}/comments/${commentId}/reactions`,
                { emoji_reactions }
            )
            return callId
        },

        onSuccess: (callId) => {
            queryClient.invalidateQueries(queries.comments.byCallId(callId))
        },
    })

    return {
        mutateAsync,
    }
}
