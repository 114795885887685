export enum DocumentType {
    CALL_SUMMARY = "call summary",
    CALL_TRANSCRIPT = "call transcript",
    COMPANY_NOTES = "company notes",
}

export interface CitationProperties {
    type: DocumentType
    call_id?: string
    company_id?: string
    title?: string
    cited_text?: string
}
