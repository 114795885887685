import { createQueryKeys } from "@lukemorales/query-key-factory"
import axios from "axios"
import { IOrganizationUser } from "../../types/Organization"

export const userQueries = createQueryKeys("users", {
    list: (params?: {
        include_invites?: boolean
        include_calendar_info?: boolean
    }) => ({
        queryKey: ["organization/users", params],
        queryFn: async () => {
            const url = `${process.env.REACT_APP_API_DOMAIN}/organization/users`
            const response = await axios.get(url, {
                params,
            })
            return response.data.users as IOrganizationUser[]
        },
    }),
})
