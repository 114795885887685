import { SettingsSection } from "./Card"
import { useQuery } from "@tanstack/react-query"
import { TextArea } from "./TextArea"
import { useUpdateProductDescription } from "../../api/mutations/product-desc"
import { queries } from "../../api/queries"

export function ProductDescription(props: { orgName?: string }) {
    const organizationName = props.orgName || "Organization"

    const { data } = useQuery(queries.organization.productDescription())

    const { mutate, isPending } = useUpdateProductDescription()

    return (
        <SettingsSection
            title={`${organizationName}'s product description`}
            description="This description is used to help Glyphic understand your product and how to best serve you."
        >
            <TextArea
                value={data || ""}
                placeholder={"Enter your product description here..."}
                onSave={mutate}
                isPending={isPending}
            />
        </SettingsSection>
    )
}
