import { useEffect, useMemo, useState } from "react"
import { useUser } from "../../providers/UserProvider"
import { ICall } from "../../types/Call"
import LoadingSpinner from "../common/LoadingSpinner"
import CallDetailsTable from "./CallDetailsTable"
import FilterInput from "./FilterInput"
import { FilterTerm } from "./types/FilterTerm"
import { extractDomain } from "./utils/callInfo"
import { fetchCalls } from "./utils/fetchCalls"
import { TabHead } from "../common/Tabs"
import { useQuery } from "@tanstack/react-query"
import { ErrorPage } from "../common/ErrorPage"
import { getCacheValue, setCacheValue } from "../../utils/localStorageCache"
import { useSearchParam } from "../common/hooks/useSearchParam"
import { PageNavigator } from "./PageNavigator"
import { IPaginatedResponse } from "../../types/pagination"
import { Modal } from "../common/Modal"
import { CallUploadForm } from "./call-upload/CallUploadForm"
import { useNavigate } from "react-router-dom"
import { SecondaryButton } from "../common/Buttons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faCirclePlus,
    faHouseCircleXmark,
    faCircleCheck,
} from "@fortawesome/pro-regular-svg-icons"
import { faTag } from "@fortawesome/free-solid-svg-icons"
import { TagFilter } from "./TagFilter"
import { Permission } from "../../types/Permission"
import { hasPermission } from "../../utils/Permissions"
import { CustomInsightsFilter, IInsightFilter } from "./CustomInsightsFilter"
import { CollapsableFilters } from "../common/CollapsableFilters"
import { faLightbulb } from "@fortawesome/pro-solid-svg-icons"

enum Tabs {
    YourCalls = "Your Calls",
    TeamCalls = "Team Calls",
    AllCalls = "All Calls",
}
interface Tab {
    type: Tabs
    label: string
}

const TAB_CACHE_KEY = "calls-list-tab-index"
const INTERNAL_TOGGLE_KEY = "calls-list-internal-toggle"
const INCOMPLETE_TOGGLE_KEY = "calls-list-incomplete-toggle"

interface ITabViewProps {
    fetchEndpoint: string
    filterTerms: FilterTerm[]
    tags: string[]
    insight?: IInsightFilter
    currentDomain: string | undefined
    includeInternalCalls: boolean
    includeIncompleteCalls: boolean
}

export function CallsList() {
    const navigate = useNavigate()
    const [tabs, setTabs] = useState<Tab[]>([])
    const [activeTab, setActiveTab] = useState(getCacheValue(TAB_CACHE_KEY, 0))
    const [showCallUploadModal, setShowCallUploadModal] = useState(false)
    const currentUser = useUser()
    const canViewOthersCalls =
        currentUser && hasPermission(currentUser, Permission.VIEW_OTHERS_CALLS)
    const currentDomain = extractDomain(currentUser?.email)

    const [filterUrlParam, setFilterUrlParam] = useSearchParam("filter")
    const [tagsUrlParam, setTagsUrlParam] = useSearchParam("tags")
    const [insightUrlParam, setInsightUrlParam] = useSearchParam("insight")

    const urlFilterTerms = useMemo(
        () =>
            filterUrlParam
                ?.split(",")
                .filter((term) => !!term)
                .map(
                    (term): FilterTerm => ({
                        value: term,
                        accepted: true,
                    })
                ) ?? [],
        [filterUrlParam]
    )
    const selectedTags = useMemo(
        () => tagsUrlParam?.split(",").filter((tag) => !!tag) ?? [],
        [tagsUrlParam]
    )
    const selectedInsight = useMemo(
        () =>
            insightUrlParam ? parseUrlToInsight(insightUrlParam) : undefined,
        [insightUrlParam]
    )

    // We need a separate state from the urlFilterTerms so we can represent the
    // `accepted` state of the filter terms in the UI.
    const [filterTerms, setFilterTerms] = useState<FilterTerm[]>(
        urlFilterTerms ?? []
    )
    const [includeInternalCalls, setIncludeInternalCalls] = useState(
        getCacheValue(INTERNAL_TOGGLE_KEY, true)
    )
    const [includeIncompleteCalls, setIncludeIncompleteCalls] = useState(
        getCacheValue(INCOMPLETE_TOGGLE_KEY, true)
    )

    useEffect(() => {
        const availableTabs = []
        if (canViewOthersCalls) {
            availableTabs.push({
                type: Tabs.AllCalls,
                label: Tabs.AllCalls.toString(),
            })
            availableTabs.push({
                type: Tabs.TeamCalls,
                label: Tabs.TeamCalls.toString(),
            })
        }

        availableTabs.push({
            type: Tabs.YourCalls,
            label: Tabs.YourCalls.toString(),
        })

        if (activeTab >= availableTabs.length) {
            setActiveTab(0)
        }

        setTabs(availableTabs)
    }, [canViewOthersCalls, activeTab])

    useEffect(() => {
        if (!filterUrlParam && filterTerms.length > 0) {
            // This is here to track the case when the user types in the filter
            // and then navigates to "Calls" tab again, which should clear the
            // filter.
            // We cannot yet always follow the state of the URL, because there
            // is no way to represent the accepted state.
            // The accepted state decides when a term should be its own tag
            // in the filter input (with the X to clear).
            // We could use the ',' at the end to represent the accepted state,
            // but it would complicate the logic!
            setFilterTerms([])
        }
    }, [filterUrlParam, filterTerms])

    const handleGlobalFilterChange = (newFilterTerms: FilterTerm[]) => {
        setFilterTerms(newFilterTerms)
        setFilterUrlParam(newFilterTerms.map((term) => term.value).join(","))
    }

    const handleTagChange = (newTags: string[]) => {
        setTagsUrlParam(newTags.join(","))
    }

    const handleInsightFilterChange = (
        newInsight?: IInsightFilter | undefined
    ) => {
        setInsightUrlParam(parseInsightToUrl(newInsight))
    }

    const handleIncludeInternalCallsChange = (value: boolean) => {
        setIncludeInternalCalls(value)
        setCacheValue(INTERNAL_TOGGLE_KEY, value)
    }

    const handleIncompleteCallsChange = (value: boolean) => {
        setIncludeIncompleteCalls(value)
        setCacheValue(INCOMPLETE_TOGGLE_KEY, value)
    }

    const canUploadCalls =
        currentUser && hasPermission(currentUser, Permission.RECORD_CALLS)

    return (
        <section className="space-y-4 px-8 py-6">
            <Modal
                isOpen={showCallUploadModal}
                onClose={() => setShowCallUploadModal(false)}
            >
                <CallUploadForm
                    onSuccess={(callId: string) => {
                        setShowCallUploadModal(false)
                        navigate(`/calls/${callId}`)
                    }}
                />
            </Modal>
            <div className="flex w-full flex-col gap-4 rounded-lg">
                <div className="flex items-center justify-between gap-2">
                    <div className="w-auto">
                        <TabHead
                            tabs={tabs}
                            activeTab={activeTab}
                            onTabChange={(index: number) => {
                                setActiveTab(index)
                                setCacheValue(TAB_CACHE_KEY, index)
                            }}
                        />
                    </div>
                    {canUploadCalls && (
                        <div>
                            <SecondaryButton
                                className="flex items-center gap-2"
                                onClick={() => setShowCallUploadModal(true)}
                            >
                                <FontAwesomeIcon
                                    icon={faCirclePlus}
                                    className="h-5 w-5"
                                />
                                <span>Upload call</span>
                            </SecondaryButton>
                        </div>
                    )}
                </div>
                <div className="flex flex-wrap items-center gap-4">
                    <div className="w-full md:w-[400px] xl:w-[500px]">
                        <FilterInput
                            filterTerms={filterTerms}
                            placeholder="Filter by call title, participants, date..."
                            onFilterChange={handleGlobalFilterChange}
                            className="w-full bg-white text-base"
                        />
                    </div>
                    <CollapsableFilters
                        filters={[
                            {
                                icon: <FontAwesomeIcon icon={faTag} />,
                                name: "Tags",
                                defaultShown: selectedTags.length > 0,
                                onRemove: () => {
                                    handleTagChange([])
                                },
                                component: (
                                    <TagFilter
                                        onTagChange={handleTagChange}
                                        selectedTags={selectedTags}
                                        defaultMenuIsOpen={
                                            selectedTags.length === 0
                                        }
                                    />
                                ),
                            },
                            {
                                icon: <FontAwesomeIcon icon={faLightbulb} />,
                                name: "Insights",
                                defaultShown: selectedInsight !== undefined,
                                onRemove: () => {
                                    handleInsightFilterChange(undefined)
                                },
                                component: (
                                    <CustomInsightsFilter
                                        onInsightChange={
                                            handleInsightFilterChange
                                        }
                                        selectedInsight={selectedInsight}
                                        defaultMenuIsOpen={
                                            selectedInsight === undefined
                                        }
                                    />
                                ),
                            },
                            {
                                icon: (
                                    <FontAwesomeIcon
                                        icon={faHouseCircleXmark}
                                    />
                                ),
                                name: "External only",
                                defaultShown: !includeInternalCalls,
                                onAdd: () => {
                                    handleIncludeInternalCallsChange(false)
                                },
                                onRemove: () => {
                                    handleIncludeInternalCallsChange(true)
                                },
                                component: (
                                    <div className="flex items-center gap-2 whitespace-nowrap p-2 px-3 text-gray-600">
                                        <FontAwesomeIcon
                                            icon={faHouseCircleXmark}
                                        />{" "}
                                        External only
                                    </div>
                                ),
                            },
                            {
                                icon: <FontAwesomeIcon icon={faCircleCheck} />,
                                name: "Completed only",
                                defaultShown: !includeIncompleteCalls,
                                onAdd: () => {
                                    handleIncompleteCallsChange(false)
                                },
                                onRemove: () => {
                                    handleIncompleteCallsChange(true)
                                },
                                component: (
                                    <div className="flex items-center gap-2 whitespace-nowrap p-2 px-3 text-gray-600">
                                        <FontAwesomeIcon icon={faCircleCheck} />{" "}
                                        Completed only
                                    </div>
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
            <div className="space-y-4">
                {currentUser === undefined || tabs.length === 0 ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        {tabs[activeTab].type === Tabs.YourCalls && (
                            <TabView
                                fetchEndpoint="/calls/user/"
                                filterTerms={filterTerms}
                                tags={selectedTags}
                                insight={selectedInsight}
                                currentDomain={currentDomain}
                                includeInternalCalls={includeInternalCalls}
                                includeIncompleteCalls={includeIncompleteCalls}
                            />
                        )}
                        {tabs[activeTab].type === Tabs.TeamCalls && (
                            <TabView
                                fetchEndpoint="/calls/team/"
                                filterTerms={filterTerms}
                                tags={selectedTags}
                                insight={selectedInsight}
                                currentDomain={currentDomain}
                                includeInternalCalls={includeInternalCalls}
                                includeIncompleteCalls={includeIncompleteCalls}
                            />
                        )}
                        {tabs[activeTab].type === Tabs.AllCalls && (
                            <TabView
                                fetchEndpoint="/calls/"
                                filterTerms={filterTerms}
                                tags={selectedTags}
                                insight={selectedInsight}
                                currentDomain={currentDomain}
                                includeInternalCalls={includeInternalCalls}
                                includeIncompleteCalls={includeIncompleteCalls}
                            />
                        )}
                    </>
                )}
            </div>
        </section>
    )
}

export function TabView({
    fetchEndpoint,
    filterTerms,
    tags,
    insight,
    currentDomain,
    includeInternalCalls,
    includeIncompleteCalls,
}: ITabViewProps) {
    const [currentPage, setCurrentPage] = useState(0)
    // Currently if there is any filters we disable pagination and load all calls
    // so that we can filter them on the client side.
    const usePagination = filterTerms.length === 0

    const queryKey = usePagination
        ? [
              fetchEndpoint,
              includeInternalCalls,
              includeIncompleteCalls,
              currentPage,
              tags,
              insight,
          ]
        : [
              fetchEndpoint,
              includeInternalCalls,
              includeIncompleteCalls,
              tags,
              insight,
          ]
    const {
        data: callResponse,
        isError,
        isPending,
    } = useQuery<IPaginatedResponse<ICall>>({
        queryKey: queryKey,
        queryFn: () =>
            fetchCalls(
                fetchEndpoint,
                includeInternalCalls,
                includeIncompleteCalls,
                usePagination,
                currentPage,
                tags,
                insight
            ),
        refetchInterval: 60000, // 60 seconds
    })

    if (isPending) {
        return <LoadingSpinner />
    }

    if (isError) {
        return <ErrorPage error={{ message: "Failed to load calls" }} />
    }

    const calls = callResponse.records
    const pageCount = callResponse.pagination.page_count
    const pageSize = callResponse.pagination.page_size

    return (
        <div>
            <CallDetailsTable
                calls={calls}
                filterTerms={filterTerms}
                currentDomain={currentDomain}
                pageSize={pageSize}
            />
            <PageNavigator
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
            />
        </div>
    )
}

export function parseInsightToUrl(
    insight: IInsightFilter | undefined
): string | undefined {
    if (insight === undefined) return undefined
    return `${insight.insightName}:${insight.values.join(",")}`
}

function parseUrlToInsight(url: string): IInsightFilter {
    const [insightName, values] = url.split(":")
    return { insightName, values: values.split(",") }
}
