import { useState } from "react"

import { isSuperOrg } from "../../utils/FeatureFlags"
import { ErrorPage } from "../common/ErrorPage"
import { useUser } from "../../providers/UserProvider"
import { CallPrep } from "./CallPrep"
import axios from "axios"
import { ICallPrepData } from "../../types/CallPrep"
import LoadingSpinner from "../common/LoadingSpinner"

//TODO This is a temporary page to test the CallPrep component. We should remove it at some point.
export function CallPrepTempPage() {
    const [email, setEmail] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const [callPrepSheet, setCallPrepSheet] = useState<
        ICallPrepData | undefined
    >()
    const user = useUser()

    if (!isSuperOrg(user?.organizationId)) {
        return <ErrorPage error={{ code: 404 }} />
    }

    async function loadCallPrep() {
        setLoading(true)
        setCallPrepSheet(undefined)
        setCallPrepSheet(await getCallPrepData(email))
        setLoading(false)
    }

    return (
        <div className="p-8">
            <input
                className="border"
                type="text"
                placeholder="Input email"
                value={email}
                onChange={(e) => {
                    setEmail(e.target.value)
                }}
            ></input>
            <button className="border" onClick={loadCallPrep}>
                Click me
            </button>
            {loading && <LoadingSpinner />}
            {callPrepSheet && <CallPrep sheet={callPrepSheet} />}
        </div>
    )
}

async function getCallPrepData(email: string): Promise<ICallPrepData> {
    const response = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/call_prep/test/${email}`
    )
    return response.data as ICallPrepData
}
