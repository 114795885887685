import { createQueryKeys } from "@lukemorales/query-key-factory"
import axios from "axios"
import {
    ICrmDealPipeline,
    ICrmDealStage,
    ICrmUser,
} from "../../components/crm/types/Crm"
import { IntegrationAppClient } from "@integration-app/react"
import { findValidCrmType } from "../../components/crm/utils/integration"

export const crmQueries = createQueryKeys("crm", {
    availableCrmType: (appClient: IntegrationAppClient) => ({
        queryKey: ["crm/available_crm_type"],
        queryFn: async () => {
            const connections = await appClient.connections.find()
            return findValidCrmType(connections.items)
        },
    }),
    users: () => ({
        queryKey: ["crm/users"],
        queryFn: async () => {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/crm/users`
            )
            return data as ICrmUser[]
        },
    }),
    dealStages: () => ({
        queryKey: ["crm/deal_stages"],
        queryFn: async () => {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/crm/deal_stages`
            )
            return data as ICrmDealStage[]
        },
    }),
    dealPipelines: (enabledOnly: boolean) => ({
        queryKey: ["crm/deal_pipelines", { enabledOnly }],
        queryFn: async () => {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/crm/deal_pipelines`,
                {
                    params: {
                        enabled_only: enabledOnly,
                    },
                }
            )
            return data as ICrmDealPipeline[]
        },
    }),
})
