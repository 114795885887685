import { useState, useMemo } from "react"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserSlash } from "@fortawesome/pro-solid-svg-icons"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@radix-ui/react-dropdown-menu"
import clsx from "clsx"
import { IParticipant } from "../../../types/Call"
import { TaskAssignee } from "../types/Task"
import { IUserCircleUser, UserCircle } from "../../UserCircle"
import { faPencil } from "@fortawesome/pro-solid-svg-icons"
import { queries } from "../../../api/queries"
import { taskQueries } from "../../../api/queries/tasks"
import { UpdateTaskMutation } from "../../../api/mutations/tasks"
import { useNotification } from "../../../providers/NotificationProvider"
import { NotificationType } from "../../../components/common/Notifcations"
import { getHeapInstance } from "../../../utils/heap"

interface AssigneeSelectorProps {
    currentAssignee: TaskAssignee | null
    participants: IParticipant[]
    taskId: string
    callId: string
    updateTask: UpdateTaskMutation | undefined
}

export function AssigneeSelector({
    currentAssignee,
    participants,
    taskId,
    callId,
    updateTask,
}: AssigneeSelectorProps) {
    const [isOpen, setIsOpen] = useState(false)
    const queryClient = useQueryClient()
    const { addNotification } = useNotification()
    const canUpdateTask = !!updateTask

    const handleAssigneeChange = (assignee: TaskAssignee | null) => {
        if (!canUpdateTask) {
            return
        }
        if (isAssigneeEqual(assignee, currentAssignee)) {
            setIsOpen(false)
            return
        }

        getHeapInstance()?.track("task-assignee-changed", {
            task_id: taskId,
            assignee,
        })

        updateTask(
            {
                taskId: taskId,
                updates: { assignee },
            },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({
                        queryKey: taskQueries.byCallId(callId).queryKey,
                    })
                    setIsOpen(false)
                },
                onError: () => {
                    addNotification(
                        "Failed to update task assignee",
                        "Please try again.",
                        NotificationType.Error
                    )
                },
            }
        )
    }

    const { data: orgUsers } = useQuery(queries.users.list())
    const userIdByEmail = useMemo(() => {
        return orgUsers?.reduce((acc, user) => {
            acc[user.email] = user.id
            return acc
        }, {} as Record<string, string>)
    }, [orgUsers])

    const options = [
        null,
        ...participants.map((p) => ({
            email: p.email || null,
            name: p.name || null,
            user_id: (p.email && userIdByEmail?.[p.email]) || null,
            profile: p.profile,
        })),
    ]

    return (
        <DropdownMenu
            open={isOpen}
            onOpenChange={(open) => {
                if (canUpdateTask) {
                    setIsOpen(open)
                }
            }}
        >
            <DropdownMenuTrigger asChild disabled={!canUpdateTask}>
                <button
                    className="group relative inline-block flex items-center justify-center"
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content={
                        currentAssignee?.name ||
                        currentAssignee?.email ||
                        "Unassigned"
                    }
                >
                    {canUpdateTask && (
                        <FontAwesomeIcon
                            icon={faPencil}
                            className="hidden h-4 w-4 rounded-full border border-gray-300 bg-gray-100 p-1 text-xs
            text-gray-500 opacity-0 transition-opacity group-hover:flex group-hover:opacity-100"
                        />
                    )}
                    <div
                        className={clsx(
                            "h-[24px] w-[24px] items-center justify-center",
                            canUpdateTask && "group-hover:hidden"
                        )}
                    >
                        <AssigneeIcon
                            assignee={getTaskAssigneeWithPhoto(
                                currentAssignee,
                                participants
                            )}
                        />
                    </div>
                </button>
            </DropdownMenuTrigger>

            <DropdownMenuContent
                className="z-50 w-64 rounded-lg border border-gray-200 bg-white py-1 shadow-lg"
                align="start"
                sideOffset={5}
            >
                {options.map((option, index) => {
                    const isSelected = isAssigneeEqual(option, currentAssignee)

                    return (
                        <div
                            key={index}
                            className={clsx(
                                "flex cursor-pointer items-center gap-2 px-4 py-2 hover:bg-gray-100",
                                isSelected && "bg-gray-200"
                            )}
                            onClick={() => handleAssigneeChange(option)}
                        >
                            <AssigneeIcon assignee={option} />
                            <span>
                                {option
                                    ? option.name || option.email
                                    : "Unassigned"}
                            </span>
                        </div>
                    )
                })}
            </DropdownMenuContent>
        </DropdownMenu>
    )
}

function isAssigneeEqual(a: TaskAssignee | null, b: TaskAssignee | null) {
    if (a === null && b === null) return true
    if (a === null || b === null) return false
    return a.email === b.email && a.name === b.name && a.user_id === b.user_id
}

function getTaskAssigneeWithPhoto(
    assignee: TaskAssignee | null,
    participants: IParticipant[] | null
): IUserCircleUser | null {
    if (!assignee) {
        return null
    }
    const participant = participants?.find(
        (participant) => participant.email === assignee.email
    )
    return {
        name: assignee.name,
        email: assignee.email,
        profile: {
            photo_url: participant?.profile?.photo_url,
        },
    }
}

function AssigneeIcon({ assignee }: { assignee: IUserCircleUser | null }) {
    if (!assignee) {
        return (
            <span className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-gray-100 text-xs text-gray-300">
                <FontAwesomeIcon icon={faUserSlash} size="xs" />
            </span>
        )
    }
    return <UserCircle user={assignee} size="24px" />
}
