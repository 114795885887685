import { useQuery } from "@tanstack/react-query"
import { useMemo } from "react"
import { useUpdateTask } from "../../api/mutations/tasks"
import { taskQueries } from "../../api/queries/tasks"
import { categorizeTasks } from "../../utils/taskCategories"
import { CallTaskItem } from "../tasks/items/CallTaskItem"
import { CallTask } from "../tasks/types/Task"
import { TaskListSectionView } from "./TaskListSectionView"

export function SectionTasks({ companyDomain }: { companyDomain: string }) {
    const query = taskQueries.byCompanyDomain(companyDomain)
    const { data: tasks = [], isLoading } = useQuery(query)

    const { mutate: updateTaskMutation, isPending: isUpdating } = useUpdateTask(
        query.queryKey
    )

    const categorizedTasks = useMemo(() => categorizeTasks(tasks), [tasks])
    const { dueToday, overdue, upcoming, unscheduled } = categorizedTasks

    return (
        <TaskListSectionView<CallTask>
            title="Tasks"
            dueTodayTasks={dueToday}
            upcomingTasks={upcoming}
            overdueTasks={overdue}
            unscheduledTasks={unscheduled}
            titleStyle="inline"
            renderTask={(props) => <CallTaskItem {...props} />}
            isLoading={isLoading}
            isUpdating={isUpdating}
            updateTask={updateTaskMutation}
        />
    )
}
