import axios from "axios"
import React, { useEffect } from "react"
import zoomLogo from "../../assets/zoom-square.png"
import { IntegrationSetting } from "./IntegrationSetting"
import { navigateToExternal } from "./oauth_utils"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { transformSnakeKeysToCamelCase } from "../../utils/transformSnakeKeysToCamelCase"
import { IUser } from "../../types/User"

export function ZoomMeetingSDKSettings() {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const { addNotification } = useNotification()
    const [hasZoomAuthToken, setHasZoomAuthToken] =
        React.useState<boolean>(false)
    const redirectUri = `${process.env.REACT_APP_WEBSITE_DOMAIN}/settings?zoomSDKCallback=true`

    // Fetch preferences from a fresh user object, settings inside useUser context could be stale
    useEffect(() => {
        async function getUser() {
            const response = await axios.get<IUser>(
                `${process.env.REACT_APP_API_DOMAIN}/user`
            )
            const user = transformSnakeKeysToCamelCase(response.data)
            if (!!user.recordingPreferences?.zoom_meeting_sdk_oauth_id) {
                setHasZoomAuthToken(true)
            }
        }
        getUser()
    }, [])

    useEffect(() => {
        async function generateTokenFromCallback() {
            if (searchParams.get("zoomSDKCallback") === "true") {
                // If we're loading settings page from Zoom OAuth callback,
                // send req via Maverick backend to Recall to record the token
                const zoomAuthCode = searchParams.get("code")!
                if (zoomAuthCode) {
                    try {
                        await saveZoomMeetingOAuthToken(
                            zoomAuthCode,
                            redirectUri
                        )
                        addNotification(
                            "Saved Zoom authentication",
                            "",
                            NotificationType.Success
                        )
                        setHasZoomAuthToken(true)
                    } catch (error) {
                        addNotification(
                            "Failed to save Zoom authentication",
                            "",
                            NotificationType.Error
                        )
                    }
                }
                // If there is no zoomAuthCode passed in the callback, the user has likely denied granting access
                navigate("/settings")
            }
        }
        generateTokenFromCallback()
    }, [searchParams, navigate, redirectUri, addNotification])

    return (
        <div className="space-y-2">
            <IntegrationSetting
                name="Authorize Zoom recordings"
                logo={zoomLogo}
                onEnable={async () => navigateToExternal(zoomUrl(redirectUri))}
                onDisable={async () => {
                    const success = await deleteZoomMeetingOAuthToken(
                        addNotification
                    )
                    if (success) {
                        setHasZoomAuthToken(false)
                    }
                }}
                authorized={hasZoomAuthToken}
            />
            <p className="text-xs text-gray-600">
                Use this option to automatically grant recording permission to
                all Zoom meetings that you host.{" "}
                <a
                    className="underline"
                    href="https://glyphic.releasenotes.io/release/1lFe8-new-zoom-recording-permission-requests"
                >
                    Learn why
                </a>
                .
            </p>
        </div>
    )
}

function zoomUrl(redirectUri: string) {
    const zoomUrlPrefix = "https://zoom.us/oauth/authorize?"

    let url = new URL(zoomUrlPrefix)
    url.searchParams.set(
        "client_id",
        process.env.REACT_APP_ZOOM_MEETING_SDK_APP_ID!
    )
    url.searchParams.set("response_type", "code")
    url.searchParams.set("redirect_uri", redirectUri)

    return url.toString()
}

async function saveZoomMeetingOAuthToken(
    oauthCode: string,
    redirectUri: string
): Promise<void> {
    await axios.put(
        `${process.env.REACT_APP_API_DOMAIN}/user/recording_preferences/zoom_meeting_sdk_oauth_token/`,
        { oauth_code: oauthCode, redirect_uri: redirectUri }
    )
}

async function deleteZoomMeetingOAuthToken(
    addNotification: (
        highlight: string,
        message: string,
        type: NotificationType
    ) => void
): Promise<boolean> {
    try {
        await axios.delete(
            `${process.env.REACT_APP_API_DOMAIN}/user/recording_preferences/zoom_meeting_sdk_oauth_token/`
        )
        addNotification(
            "Zoom authentication removed successfully",
            "",
            NotificationType.Success
        )
        return true
    } catch (error) {
        addNotification(
            "Failed to remove Zoom authentication",
            "",
            NotificationType.Error
        )
        return false
    }
}
