import { useMemo, useState } from "react"
import { GLOBAL_QUESTION_SUGGESTIONS } from "../question-answering/question-suggestions"
import { Thread } from "../question-answering/types/ThreadTypes"
import _ from "lodash"
import { getFormattedDateTime } from "../../utils/datetime"
import { useQuery } from "@tanstack/react-query"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faClockRotateLeft,
    faMagicWandSparkles,
} from "@fortawesome/pro-solid-svg-icons"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import clsx from "clsx"
import { getFullTextFromBlocks } from "../question-answering/utils/getFullTextFromBlocks"
import { useThread } from "../question-answering/hooks/useThread"

const NUMBER_OF_SUGGESTIONS = 3

interface SearchEmptyProps {
    selectThread: (thread: Thread) => void
    startThread: (message: string) => void
}

export function SearchEmptyState(props: SearchEmptyProps) {
    return (
        <div className="space-y-8">
            <Suggestions startThread={props.startThread} />
            <ThreadHistory selectThread={props.selectThread} />
        </div>
    )
}

function Suggestions(props: { startThread: (message: string) => void }) {
    const suggestions = useMemo(() => {
        return _.sampleSize(GLOBAL_QUESTION_SUGGESTIONS, NUMBER_OF_SUGGESTIONS)
    }, [])

    return (
        <div className="space-y-2">
            <div className="flex flex-row flex-nowrap items-center space-x-2">
                <FontAwesomeIcon
                    icon={faMagicWandSparkles}
                    className="text-blue-500"
                />
                <span className="text-sm">Try asking</span>
            </div>
            <div className="grid animate-fadeInUp grid-cols-1 gap-4 sm:grid-cols-3">
                {suggestions.map((question) => (
                    <SuggestedQuestion
                        key={question}
                        question={question}
                        setQuestion={(question) => props.startThread(question)}
                    />
                ))}
            </div>
        </div>
    )
}

function ThreadHistory(props: { selectThread: (thread: Thread) => void }) {
    const [expandHistory, setExpandHistory] = useState(false)
    const { getThreads } = useThread()

    const { data: allThreads = [] } = useQuery({
        queryKey: ["organization/askglyphic/threads"],
        queryFn: getThreads,
        initialData: [],
    })
    const threads = useMemo(() => {
        return allThreads
            .sort((a, b) => b.created_at_utc.localeCompare(a.created_at_utc))
            .filter((t) => t.turns.length > 0)
    }, [allThreads])

    if (threads.length === 0) return null

    return (
        <div className="space-y-4">
            <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={faClockRotateLeft} />
                <span className="text-sm font-semibold text-gray-800">
                    Previously asked questions
                </span>
                <FontAwesomeIcon
                    icon={faChevronDown}
                    onClick={() => setExpandHistory(!expandHistory)}
                    className={clsx(
                        "cursor-pointer text-gray-600 transition-colors hover:text-gray-800",
                        expandHistory ? "rotate-180" : ""
                    )}
                />
            </div>
            {expandHistory && (
                <div className="space-y-3">
                    {threads.map((thread) => (
                        <PastThread
                            key={thread.id}
                            thread={thread}
                            selectThread={props.selectThread}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

function SuggestedQuestion({
    question,
    setQuestion,
}: {
    question: string
    setQuestion: (question: string) => void
}) {
    return (
        <div
            className="cursor-pointer rounded-lg border border-blue-100 bg-blue-50 p-4 text-sm font-semibold hover:bg-blue-100"
            onClick={() => setQuestion(question)}
        >
            <p className="text-center font-medium text-gray-800">{question}</p>
        </div>
    )
}

function PastThread(props: {
    thread: Thread
    selectThread: (thread: Thread) => void
}) {
    const message = getFullTextFromBlocks(props.thread.turns[0]?.blocks)
    return (
        <div
            key={props.thread.id}
            onClick={() => props.selectThread(props.thread)}
            className="animate-fadeInUp cursor-pointer rounded-lg border border-gray-200 bg-white p-4 text-sm shadow-sm transition-colors hover:bg-gray-50"
        >
            <div className="flex items-center justify-between">
                <p className="flex-grow text-gray-800">{message}</p>
                <span className="ml-2 whitespace-nowrap text-xs text-gray-500">
                    {getFormattedDateTime(props.thread.created_at_utc)}
                </span>
            </div>
        </div>
    )
}
