import { IParticipant } from "../types/Call"
import { getParticipantDisplayName } from "../utils/getParticipantDisplayName"
import { ChristmasHat, chrismtastColor, isChristmas } from "../utils/christmas"
import { stringToColour } from "../utils/stringToColour"

export type IUserCircleUser = Pick<IParticipant, "name" | "email" | "profile">

export function UserCircle(props: { user: IUserCircleUser; size: string }) {
    if (props.user.profile?.photo_url) {
        return (
            <UserPhoto
                imageUrl={props.user.profile.photo_url}
                size={props.size}
            />
        )
    } else {
        const name = getParticipantDisplayName(props.user)
        return <UserInitialsCircle name={name} size={props.size} />
    }
}

function UserPhoto(props: { imageUrl: string; size: string }) {
    return (
        <div className="relative">
            <img
                src={props.imageUrl}
                style={{
                    width: props.size,
                    height: props.size,
                    minWidth: props.size,
                    minHeight: props.size,
                }}
                className="rounded-full object-cover"
                alt="person"
            />
            {isChristmas() && <ChristmasHat keyStr={props.imageUrl} />}
        </div>
    )
}

export function UserInitialsCircle(props: { name: string; size: string }) {
    const initial = props.name.charAt(0).toUpperCase()
    const colour = isChristmas()
        ? chrismtastColor(props.name)
        : stringToColour(props.name)[400]
    const font = parseInt(props.size) >= 50 ? "text-lg" : "font-bold text-sm"
    return (
        <span
            className="flex items-center justify-center rounded-full"
            style={{
                backgroundColor: colour, // Use inline style as tailwind will optimize out dynamically generated styles
                width: props.size,
                height: props.size,
                minWidth: props.size,
                minHeight: props.size,
            }}
        >
            <span className={`text-white ${font}`}>{initial}</span>
        </span>
    )
}
