import { useUser } from "../../providers/UserProvider"
import { UserInitialsCircle } from "../UserCircle"
import { AnswerFeedback } from "./types/QuestionTypes"
import { useCallback, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faChevronRight,
    faChevronDown,
} from "@fortawesome/free-solid-svg-icons"
import {
    CitationsMessageBlock,
    DisplayMessageBlock,
    isCitationMessageBlock,
} from "./types/ThreadTypes"
import { AskGlyphicAvi } from "../../assets/AskGlyphicAvi"
import { getFullTextFromBlocks } from "./utils/getFullTextFromBlocks"
import { CitationPill, createDisplayBlockPairs } from "./Citation"
import { CitationCard } from "./CitationCard"
import { MessageFooter } from "./MessageFooter"
import { faCircle } from "@fortawesome/pro-solid-svg-icons"

interface UserMessageProps {
    message: string
}

export function UserMessage({ message }: UserMessageProps) {
    const user = useUser()
    const userName = user?.name || ""

    return (
        <div className="group flex animate-fadeInUp flex-row items-start gap-2">
            <UserInitialsCircle name={userName} size="24px" />
            <div className="whitespace-pre-wrap">{message.trim()}</div>
        </div>
    )
}

interface AiMessageProps {
    messageBlocks: DisplayMessageBlock[]
    isComplete: boolean
    isLast: boolean
    submitFeedback: (feedback: AnswerFeedback) => Promise<void>
    isReadOnly?: boolean
    sendEmail: (message: string) => Promise<void>
}

export function AiMessage(props: AiMessageProps) {
    const displayBlockPairs = createDisplayBlockPairs(props.messageBlocks)

    const citationBlocks = props.messageBlocks
        .filter(isCitationMessageBlock)
        .filter((block) => !!block.citation)
    const fullContent = getFullTextFromBlocks(props.messageBlocks)

    const renderCitationsForBlock = useCallback(
        (citations: CitationsMessageBlock[], blockIndex: number) => {
            if (citations.length === 0) return null

            return citations.map((citation) => (
                <span
                    key={`citation-${citation.citation?.title}-${blockIndex}`}
                    className="mx-1 inline-block align-middle"
                >
                    <CitationPill citation={citation.citation!} />
                </span>
            ))
        },
        []
    )

    return (
        <div className="flex flex-row items-start gap-2">
            <AskGlyphicAvi className="h-6 w-6 flex-shrink-0" />
            <div className="flex w-full flex-col items-start gap-1">
                {displayBlockPairs.map((pair, index) => (
                    <div
                        key={`pair-${index}`}
                        className="mb-3 whitespace-pre-wrap"
                    >
                        {pair.textBlock.content.trim()}
                        {renderCitationsForBlock(pair.citations, index)}
                    </div>
                ))}
                {citationBlocks.length > 0 && (
                    <CitationsContainer citations={citationBlocks} />
                )}
                {displayBlockPairs.length === 0 && <WaitingPulse />}
                <MessageFooter
                    isComplete={props.isComplete}
                    isLast={props.isLast}
                    isReadOnly={props.isReadOnly}
                    submitFeedback={props.submitFeedback}
                    fullContent={fullContent}
                    sendEmail={props.sendEmail}
                />
            </div>
        </div>
    )
}

function WaitingPulse() {
    return (
        <FontAwesomeIcon
            icon={faCircle}
            className="fa-beat-fade mt-1 text-sm text-gray-600"
            style={{ "--fa-beat-fade-opacity": 0.1 } as any}
        />
    )
}

interface CitationsContainerProps {
    citations: CitationsMessageBlock[]
}

function CitationsContainer(props: CitationsContainerProps) {
    const [isCollapsed, setIsCollapsed] = useState(true)

    const toggleCollapse = useCallback(() => {
        setIsCollapsed(!isCollapsed)
    }, [isCollapsed])

    return (
        <div className="mt-2">
            <div
                className="mb-2 flex cursor-pointer items-center gap-2"
                onClick={toggleCollapse}
            >
                <FontAwesomeIcon
                    icon={isCollapsed ? faChevronRight : faChevronDown}
                    className="text-gray-500"
                />
                <span className="text-sm font-medium">
                    {isCollapsed ? "Show" : "Hide"} All Sources (
                    {props.citations.length})
                </span>
            </div>

            {!isCollapsed && (
                <div className="grid grid-cols-1 gap-2">
                    {props.citations.map((block) => (
                        <CitationCard
                            key={block.citation!.title}
                            citation={block.citation!}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}
