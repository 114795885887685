import { useQuery } from "@tanstack/react-query"
import { ICall } from "../../types/Call"
import { fetchCalls } from "../calls-list/utils/fetchCalls"
import { LoadingPulse } from "../common/LoadingPulse"
import { getFormattedDateTime } from "../../utils/datetime"
import { CallPageParticipantsList } from "../calls-list/ParticipantsList"
import { Link, useNavigate } from "react-router-dom"
import { SecondaryButton } from "../common/Buttons"
import { RecordingIcon } from "../../assets/icons/RecordingIcon"
import { CompanyLogo } from "../company-view/CompanyLogo"
import { LightEmptyArea } from "./EmptyArea"
import { useIntegrations } from "../../hooks/useIntegrations"
import { RecordNewMeetingButton } from "../schedule/Schedule"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog } from "@fortawesome/free-solid-svg-icons"

export function PastCalls() {
    const { hasCalendar, isPending: isIntegrationsPending } = useIntegrations()
    const limit = 3

    const usePagination = true
    const callsPage = 0 // fetch the most recent
    const includeInternalCalls = true
    const includeIncompleteCalls = false
    const {
        data: calls,
        isError,
        isPending: isCallsPending,
    } = useQuery<ICall[]>({
        queryKey: [
            "calls/user",
            includeInternalCalls,
            includeIncompleteCalls,
            callsPage,
        ],
        queryFn: async () => {
            return (
                await fetchCalls(
                    `/calls/user`,
                    includeInternalCalls,
                    includeIncompleteCalls,
                    usePagination,
                    callsPage
                )
            ).records
        },
    })

    if (isError) {
        return null
    }

    if (isCallsPending || isIntegrationsPending) {
        return (
            <div>
                <Header />
                <div className="space-y-2">
                    {Array.from({ length: limit }, (_, index) => (
                        <div
                            key={index}
                            className="rounded-lg bg-white p-4 shadow-md shadow-gray-200"
                        >
                            <LoadingPulse rows={2} />
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    if (calls?.length === 0) {
        return (
            <div>
                <Header />
                <LightEmptyArea>
                    <div className="flex flex-col items-center gap-2">
                        <span>You haven't had any calls yet 😕</span>
                        <RecordNewMeetingButton />
                        {!hasCalendar && (
                            <div className="flex flex-col items-center gap-2 xl:flex-row">
                                <span className="text-center">
                                    Or to automatically record your scheduled
                                    calls:
                                </span>
                                <Link to="/settings/personal">
                                    <SecondaryButton className="flex items-center gap-2">
                                        <FontAwesomeIcon icon={faCog} />
                                        <span>Connect your calendar</span>
                                    </SecondaryButton>
                                </Link>
                            </div>
                        )}
                    </div>
                </LightEmptyArea>
            </div>
        )
    }

    return (
        <div>
            <Header />
            <div className="space-y-2">
                {calls.slice(0, limit).map((call) => (
                    <PastCall key={call.id} call={call} />
                ))}
            </div>
        </div>
    )
}

function Header() {
    return (
        <div className="mb-3 flex items-center justify-between">
            <h3 className="text-lg font-bold">Recent calls</h3>
            <SecondaryButton>
                <Link
                    to="/calls"
                    className="flex items-center space-x-2 whitespace-nowrap"
                >
                    <RecordingIcon />
                    <span>View all calls</span>
                </Link>
            </SecondaryButton>
        </div>
    )
}

function PastCall(props: { call: ICall }) {
    const navigate = useNavigate()
    const goToCall = () => {
        navigate(`/calls/${props.call.id}`)
    }
    return (
        <div
            className="space-y-1 rounded-lg bg-white p-4 shadow-md shadow-gray-200 hover:cursor-pointer hover:bg-gray-100"
            onClick={goToCall}
        >
            <div className="font-semibold">{props.call.title}</div>
            <div className="flex space-x-12">
                <div>{getFormattedDateTime(props.call.start_time)}</div>
                <div className="flex h-8 items-center space-x-2">
                    {props.call.companies.map((company) => (
                        <Link
                            to={`/companies/${company.domain}`}
                            data-tooltip-content={company.name}
                            key={company.id}
                            data-tooltip-id="tooltip-explanation"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <CompanyLogo
                                className="h-6 w-6 text-xs"
                                image_url={company.image_url}
                            />
                        </Link>
                    ))}
                    <CallPageParticipantsList parties={props.call.parties} />
                </div>
            </div>
        </div>
    )
}
