import {
    faChevronDown,
    faClockRotateLeft,
    faPlus,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import clsx from "clsx"
import { useEffect, useRef, useState } from "react"
import { Thread } from "./types/ThreadTypes"
import { getHeapInstance } from "../../utils/heap"
import { getFullTextFromBlocks } from "./utils/getFullTextFromBlocks"

interface AskGlyphicHeaderProps {
    pastThreads: Thread[]
    startNewThread: () => void
    selectPastThread: (thread: Thread) => void
    hasOpenThread: boolean
    threadExpanded: boolean
    expandCollapseThread: () => void
    receivingResponse: boolean
}

export function AskGlyphicHeader({
    pastThreads,
    startNewThread,
    selectPastThread,
    hasOpenThread,
    threadExpanded,
    expandCollapseThread,
    receivingResponse,
}: AskGlyphicHeaderProps) {
    return (
        <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center space-x-1">
                <p className="text-xl font-bold">Ask Glyphic</p>
                {hasOpenThread && (
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        className={clsx(
                            "h-4 w-4 cursor-pointer text-gray-600 transition-transform duration-300 hover:text-gray-800",
                            !threadExpanded && "-rotate-90"
                        )}
                        onClick={expandCollapseThread}
                    />
                )}
            </div>
            <div className="flex flex-row items-center gap-2">
                {pastThreads.length > 0 && (
                    <AskGlyphicHistory
                        pastThreads={pastThreads}
                        selectPastThread={selectPastThread}
                        disabled={receivingResponse}
                    />
                )}
                <button
                    onClick={() => startNewThread()}
                    disabled={receivingResponse}
                    className="text-gray-600 hover:text-gray-800 disabled:opacity-50"
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content="New Chat"
                >
                    <FontAwesomeIcon icon={faPlus} className="h-4 w-4" />
                </button>
            </div>
        </div>
    )
}

function AskGlyphicHistory(props: {
    pastThreads: Thread[]
    selectPastThread: (thread: Thread) => void
    disabled?: boolean
}) {
    const [showHistory, setShowHistory] = useState(false)
    const hideTimer = useRef<NodeJS.Timeout | null>(null)

    const show = () => {
        if (hideTimer.current) {
            clearTimeout(hideTimer.current)
            hideTimer.current = null
        }
        setShowHistory(true)
    }

    const hide = () => {
        hideTimer.current = setTimeout(() => {
            setShowHistory(false)
        }, 300)
    }

    useEffect(() => {
        return () => {
            if (hideTimer.current) {
                clearTimeout(hideTimer.current)
            }
        }
    }, [])

    return (
        <div className="relative" onMouseEnter={show} onMouseLeave={hide}>
            <button
                disabled={props.disabled}
                className="relative text-gray-600 hover:text-gray-800 disabled:opacity-50"
            >
                <FontAwesomeIcon icon={faClockRotateLeft} className="h-4 w-4" />
            </button>
            {showHistory && (
                <div className="absolute right-0 top-5 z-10 flex w-96 flex-col space-y-2 rounded-md border bg-white p-3 shadow-lg">
                    <div className="text-xs text-gray-600">Past chats</div>
                    {props.pastThreads
                        .filter((t) => t.turns.length > 0)
                        .sort((a, b) =>
                            a.created_at_utc > b.created_at_utc ? -1 : 1
                        )
                        .map((thread, index) => {
                            const message = getFullTextFromBlocks(
                                thread.turns[0]?.blocks
                            )
                            return (
                                <button
                                    disabled={props.disabled}
                                    key={index}
                                    className="flex items-center justify-between rounded-md px-1 py-2 text-left text-xs font-semibold hover:bg-gray-100 disabled:bg-inherit disabled:opacity-50"
                                    onClick={() => {
                                        props.selectPastThread(thread)
                                        getHeapInstance()?.track(
                                            "selected-history-item",
                                            {
                                                threadId: thread.id,
                                                startingMessage: message,
                                            }
                                        )
                                        setShowHistory(false)
                                    }}
                                >
                                    {message}
                                </button>
                            )
                        })}
                </div>
            )}
        </div>
    )
}
