// Utils for the ReactPlayer used by both the call page and shared/calls page

import { MutableRefObject } from "react"
import { ITranscriptTurn } from "../../../types/Call"
import { timestampToSeconds } from "../../../utils/datetime"
import ReactPlayer from "react-player"

function isTimestampInTurns(
    turn: ITranscriptTurn,
    nextTurn: ITranscriptTurn,
    currentTimestampSeconds: number
) {
    let turnTimestamp = timestampToSeconds(turn.timestamp)
    let nextTurnTimestamp = timestampToSeconds(nextTurn.timestamp)
    if (turnTimestamp === currentTimestampSeconds) {
        return true
    } else {
        return (
            turnTimestamp < currentTimestampSeconds &&
            currentTimestampSeconds < nextTurnTimestamp
        )
    }
}

export function getTurnAtTimestamp(
    transcript_turns: ITranscriptTurn[] | null,
    currentTimestampSeconds: number
): ITranscriptTurn | null {
    if (currentTimestampSeconds === 0 || !transcript_turns) {
        return null
    }

    for (let i = 0; i < transcript_turns.length - 1; i++) {
        const turn = transcript_turns[i]
        const nextTurn = transcript_turns[i + 1]

        // Note we need to use timestampToSeconds to compare string timestamps
        // to the playbackSeconds number
        if (isTimestampInTurns(turn, nextTurn, currentTimestampSeconds)) {
            return turn
        }
    }
    return null
}

export function goToTimestamp(playerRef: MutableRefObject<ReactPlayer | null>) {
    return (timestamp: string) => {
        if (!playerRef.current) {
            console.log("playerRef.current is null")
            return
        }
        const timestampSeconds = timestampToSeconds(timestamp)
        console.log("seeking to", timestampSeconds)
        playerRef.current.seekTo(timestampSeconds)
    }
}

export function goToFirstTimestamp(
    playerRef: MutableRefObject<ReactPlayer | null>,
    transcriptTurns?: ITranscriptTurn[] | null
) {
    if (!transcriptTurns?.length) return
    const firstTurn = transcriptTurns[0]
    goToTimestamp(playerRef)(firstTurn.timestamp)
}
