import { useQuery } from "@tanstack/react-query"
import { taskQueries } from "../../api/queries/tasks"
import { useUpdateTask } from "../../api/mutations/tasks"
import { TaskListView } from "../tasks/TaskListView"
import { categorizeTasks } from "../../utils/taskCategories"
import { useMemo } from "react"
import { TaskItem, TaskItemProps } from "../tasks/items/TaskItem"

export function Tasks() {
    const query = taskQueries.list()
    const { data: tasks = [], isLoading } = useQuery(query)

    const { mutate: updateTaskMutation, isPending: isUpdating } = useUpdateTask(
        query.queryKey
    )

    // Get today's tasks using the shared categorization logic
    const { dueToday: todayTasks } = useMemo(
        () => categorizeTasks(tasks),
        [tasks]
    )
    const emptyMessage = isLoading
        ? "Loading tasks..."
        : "🎉 No tasks due today"

    return (
        <TaskListView
            title="Today's Tasks"
            tasks={todayTasks}
            isUpdating={isUpdating}
            updateTask={updateTaskMutation}
            emptyMessage={emptyMessage}
            showDueDate={false}
            showProgress={true}
            titleStyle="small"
            renderTask={(props: TaskItemProps) => <TaskItem {...props} />}
        />
    )
}
