import React, { useCallback, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { SessionAuth, signOut } from "supertokens-auth-react/recipe/session"
import logo from "../assets/logomark_sun_logotype_cotton.svg"
import { IntegrationAppSession } from "./IntegrationAppSession"
import { UserProvider, useUser } from "../providers/UserProvider"
import { UserInitialsCircle } from "./UserCircle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faArrowRightFromBracket,
    faGear,
    faCalendarAlt,
    faAnglesRight,
    faLandmark,
    faBell,
    faChartSimple,
    faHandshakeSimple,
    faHouse,
    faCircleQuestion,
    faLineChart,
    faUser,
    faEnvelope,
    faBolt,
    faSitemap,
    faUserGear,
    faTag,
} from "@fortawesome/free-solid-svg-icons"
import { Tooltip } from "react-tooltip"
import { ScreenSize, screenLargerOrEqualTo } from "../utils/screenSize"
import {
    displayReleaseNotesModal,
    displayReleaseNotesModalIfNew,
} from "../utils/releasenotes"
import {
    SearchModalProvider,
    useSearchModal,
} from "../providers/SearchProvider"
import { RecordingIcon } from "../assets/icons/RecordingIcon"
import { FrigadeProvider } from "./Frigade"
import { faListCheck, faSparkles } from "@fortawesome/pro-solid-svg-icons"
import { NewVersionChecker } from "./NewVersionChecker"
import { AnnouncementChecker } from "./AnnouncementChecker"
import { hasPermission } from "../utils/Permissions"
import { Permission } from "../types/Permission"
import { showPylon } from "../utils/pylon"
import { taskQueries } from "../api/queries/tasks"
import { useQuery } from "@tanstack/react-query"
import { HashLink } from "react-router-hash-link"
import { isMacOS } from "../utils/os"
import { CitationTooltip } from "./question-answering/CitationTooltip"
import { PageTitle } from "./PageTitle"
import clsx from "clsx"
import { Logomark } from "../assets/Logomark"
import * as Popover from "@radix-ui/react-popover"

export function WithNavbarLayout(props: {
    children: React.ReactNode
    title: string
}) {
    return (
        <SessionAuth>
            <UserProvider>
                <FrigadeProvider>
                    <IntegrationAppSession>
                        <SearchModalProvider>
                            {/* Tab title */}
                            <PageTitle title={props.title} />
                            <div className="flex h-screen">
                                {/* Adds the content after the navbar with the correct padding */}
                                <ContentWithNavbar>
                                    {props.children}
                                </ContentWithNavbar>
                                <TooltipsContainer />
                                <NewVersionChecker />
                                <AnnouncementChecker />
                            </div>
                        </SearchModalProvider>
                    </IntegrationAppSession>
                </FrigadeProvider>
            </UserProvider>
        </SessionAuth>
    )
}

function TooltipsContainer() {
    return (
        <>
            {/*
            This Tooltip is used by any component that needs to
            provide more explanation to the user.
            We add it here so that's it's unique available to all
            components in this layout.
            Keeping it available to multiple components is good
            practice, see https://github.com/ReactTooltip/react-tooltip#dynamically-generated-anchor-elements
            */}
            <Tooltip
                id="tooltip-explanation"
                className="z-[60] max-w-xl text-left"
                style={{
                    backgroundColor: "#111827",
                    borderRadius: "8px",
                }}
            />
            <Tooltip
                id="memory-updated"
                className="z-[60] max-w-xl"
                style={{
                    backgroundColor: "#111827",
                    borderRadius: "8px",
                }}
                clickable
                place="right"
            >
                <p>Your personal user memory had been updated!</p>
                <p>
                    Update manually{" "}
                    <HashLink
                        target="_blank"
                        to={"/settings/personal#user-memory"}
                        className="underline hover:text-gray-500"
                    >
                        here
                    </HashLink>
                    .
                </p>
            </Tooltip>
            <CitationTooltip />
        </>
    )
}

function ContentWithNavbar(props: { children: React.ReactNode }) {
    const [padding, setPadding] = useState<string>("pl-48")

    return (
        <>
            <Navbar setPadding={setPadding} />
            <main
                className={clsx(
                    "max-w-full flex-grow transition-all ",
                    padding
                )}
            >
                {props.children}
            </main>
        </>
    )
}

function Navbar({ setPadding }: { setPadding: (padding: string) => void }) {
    const user = useUser()

    const canViewOthersCalls = hasPermission(user, Permission.VIEW_OTHERS_CALLS)
    const canViewActivityPage = hasPermission(
        user,
        Permission.VIEW_ACTIVITY_PAGE
    )
    const canEditOrg = hasPermission(user, Permission.VIEW_EDIT_ORG_SETTINGS)

    const location = useLocation()

    // If the primary is expanded
    const [isExpanded, setIsExpanded] = useState<boolean>(
        screenLargerOrEqualTo(ScreenSize.md)
    )

    // If the secondary is shown at all
    const [isSecondaryShown, setIsSecondaryShown] = useState<boolean>(false)

    // If the secondary is expanded
    const [isSecondaryExpanded, setIsSecondaryExpanded] =
        useState<boolean>(true)

    function toggleIsExpanded() {
        setIsExpanded((prev) => !prev)
    }

    const handleResize = useCallback(() => {
        if (isSecondaryShown) {
            // If the secondary is shown, the primary should be collapsed
            setIsExpanded(false)
            setIsSecondaryExpanded(screenLargerOrEqualTo(ScreenSize.md))
            // Adjust the padding of the main content to fit the new size
            if (screenLargerOrEqualTo(ScreenSize.md)) {
                // Padding for collapsed primary navbar + full secondary navbar
                setPadding("pl-60")
            } else {
                // Padding for collapsed primary navbar + collapsed secondary navbar
                setPadding("pl-24")
            }
        } else {
            setIsExpanded(screenLargerOrEqualTo(ScreenSize.md))
            if (screenLargerOrEqualTo(ScreenSize.md)) {
                // Padding for full primary navbar
                setPadding("pl-48")
            } else {
                // Padding for collapsed secondary navbar
                setPadding("pl-12")
            }
        }
    }, [isSecondaryShown, setPadding])

    useEffect(() => {
        handleResize() // Call on mount to set initial state
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [isSecondaryShown, handleResize])

    return (
        <>
            <div
                className={clsx(
                    "fixed z-40 my-auto flex h-full flex-col overflow-y-auto bg-gray-900 py-4 text-base transition-all",
                    isExpanded ? "w-48 px-4" : "w-12 px-2"
                )}
            >
                <div className="flex h-full flex-col justify-between object-scale-down">
                    {/* Top items */}
                    <div className="space-y-1 py-2">
                        <div
                            className={clsx(
                                "flex items-center gap-2 pb-5",
                                !isExpanded && "flex-col"
                            )}
                        >
                            <Logo includeText={isExpanded} />
                            <button
                                className={clsx(
                                    "!ml-0 flex rounded-lg p-2 text-white transition-colors hover:bg-gray-700",
                                    !isSecondaryShown && "md:hidden"
                                )}
                                onClick={toggleIsExpanded}
                            >
                                <FontAwesomeIcon
                                    icon={faAnglesRight}
                                    className={`transition-all ${
                                        isExpanded ? "rotate-180" : ""
                                    }`}
                                />
                            </button>
                        </div>

                        <div className="space-y-2">
                            {canViewOthersCalls && (
                                <Search showLabel={isExpanded} />
                            )}
                            <Home
                                pathname={location.pathname}
                                showLabel={isExpanded}
                            />
                            <Calls
                                pathname={location.pathname}
                                showLabel={isExpanded}
                            />
                            {canViewOthersCalls && (
                                <Deals
                                    pathname={location.pathname}
                                    showLabel={isExpanded}
                                />
                            )}
                            {canViewOthersCalls && (
                                <Companies
                                    pathname={location.pathname}
                                    showLabel={isExpanded}
                                />
                            )}
                            <Schedule
                                pathname={location.pathname}
                                showLabel={isExpanded}
                            />
                            <Tasks
                                pathname={location.pathname}
                                showLabel={isExpanded}
                            />
                            {canViewActivityPage && (
                                <Activity
                                    pathname={location.pathname}
                                    showLabel={isExpanded}
                                />
                            )}
                            {canViewOthersCalls && (
                                <StrategicInsights
                                    pathname={location.pathname}
                                    showLabel={isExpanded}
                                />
                            )}
                        </div>
                    </div>
                    {/* Bottom items */}
                    <div
                        className={clsx(
                            "flex w-full items-center justify-between gap-2",
                            !isExpanded && "flex-col"
                        )}
                    >
                        <UserLabel showLabel={isExpanded} />
                        <Settings
                            pathname={location.pathname}
                            showLabel={isExpanded}
                        />
                    </div>
                </div>
            </div>

            <SecondaryNavbar
                route="/settings"
                setSecondaryShown={setIsSecondaryShown}
                isExpanded={isSecondaryExpanded}
                title="Settings"
            >
                <div className="space-y-2">
                    <SettingsNavbar
                        pathname={location.pathname}
                        showLabel={isSecondaryExpanded}
                        canEditOrg={canEditOrg ?? false}
                    />
                </div>
            </SecondaryNavbar>
        </>
    )
}

function SecondaryNavbar({
    children,
    route,
    setSecondaryShown,
    isExpanded,
    title,
}: {
    children: React.ReactNode
    route: string
    setSecondaryShown: (show: boolean) => void
    isExpanded: boolean
    title: string
}) {
    const location = useLocation()
    const shouldShow = location.pathname.startsWith(route)

    useEffect(() => {
        if (shouldShow) {
            setSecondaryShown(true)
        } else {
            setSecondaryShown(false)
        }
    }, [shouldShow, setSecondaryShown])

    if (!shouldShow) {
        return <></>
    }

    return (
        <div
            className={clsx(
                "fixed left-12 z-20 my-auto flex h-full flex-col overflow-y-auto bg-gray-800 text-base transition-all",
                isExpanded ? "w-48 px-4" : "w-12 px-2"
            )}
        >
            <div className="space-y-6 py-4">
                {isExpanded && (
                    <h3 className="text-lg font-bold text-white">{title}</h3>
                )}
                {children}
            </div>
        </div>
    )
}

function Logo({ includeText }: { includeText: boolean }) {
    return (
        <Link className="flex items-center" to="/home">
            {includeText ? (
                <img src={logo} alt="Logo" className="max-h-[32px]" />
            ) : (
                <Logomark className="h-8 w-8 text-sun" />
            )}
        </Link>
    )
}

function UserLabel(props: { showLabel: boolean }) {
    const user = useUser()
    if (!user) {
        return <></>
    }
    const firstName = user.name.split(" ")[0]

    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <button className="text-md flex items-center gap-1 rounded-lg p-1 font-semibold text-white transition-colors hover:bg-gray-700">
                    <UserInitialsCircle name={user.name} size="24px" />
                    {props.showLabel && (
                        <span className="max-w-[80px] truncate text-white">
                            {firstName}
                        </span>
                    )}
                </button>
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content
                    className="z-50 ml-2 rounded-lg bg-gray-800 p-2 shadow-lg"
                    sideOffset={5}
                    side="top"
                >
                    <div className="flex flex-col gap-2 ">
                        <Updates showLabel={true} />
                        <Help showLabel={true} />
                        <LogOut showLabel={true} />
                    </div>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    )
}

function Home(props: { pathname: string; showLabel: boolean }) {
    return (
        <PageLink
            label="Home"
            icon={<FontAwesomeIcon icon={faHouse} />}
            to="/home"
            selected={props.pathname === "/home"}
            showLabel={props.showLabel}
        />
    )
}

function Tasks(props: { pathname: string; showLabel: boolean }) {
    const { data: taskCount } = useQuery(
        taskQueries.count({ completed: false, due: "today" })
    )

    return (
        <PageLink
            label="Tasks"
            icon={<FontAwesomeIcon icon={faListCheck} />}
            to="/tasks"
            selected={props.pathname.startsWith("/tasks")}
            showLabel={props.showLabel}
            badge={taskCount ? taskCount.toString() : undefined}
        />
    )
}

function Companies(props: { pathname: string; showLabel: boolean }) {
    return (
        <PageLink
            label="Companies"
            icon={<FontAwesomeIcon icon={faLandmark} />}
            to="/companies"
            selected={props.pathname.startsWith("/companies")}
            showLabel={props.showLabel}
        />
    )
}

function Calls(props: { pathname: string; showLabel: boolean }) {
    return (
        <PageLink
            label="Calls"
            icon={<RecordingIcon />}
            to="/calls"
            selected={props.pathname.startsWith("/calls")}
            showLabel={props.showLabel}
        />
    )
}

function Deals(props: { pathname: string; showLabel: boolean }) {
    return (
        <PageLink
            label="Deals"
            icon={<FontAwesomeIcon icon={faHandshakeSimple} />}
            to="/deals"
            selected={props.pathname.startsWith("/deals")}
            showLabel={props.showLabel}
        />
    )
}

function Schedule(props: { pathname: string; showLabel: boolean }) {
    return (
        <PageLink
            label="Schedule"
            icon={<FontAwesomeIcon icon={faCalendarAlt} />}
            to="/schedule"
            selected={props.pathname.startsWith("/schedule")}
            showLabel={props.showLabel}
        />
    )
}

function Activity(props: { pathname: string; showLabel: boolean }) {
    return (
        <PageLink
            label="Activity"
            icon={<FontAwesomeIcon icon={faChartSimple} />}
            to="/activity"
            selected={props.pathname.startsWith("/activity")}
            showLabel={props.showLabel}
        />
    )
}

function Settings(props: { pathname: string; showLabel: boolean }) {
    return (
        <PageLink
            to="/settings/personal"
            icon={<FontAwesomeIcon icon={faGear} size="lg" />}
            label="Settings"
            selected={props.pathname.startsWith("/settings/personal")}
            showLabel={false}
            className="text-sm"
        />
    )
}

function SettingsNavbar(props: {
    pathname: string
    showLabel: boolean
    canEditOrg: boolean
}) {
    return (
        <>
            <PageLink
                to="/settings/personal"
                icon={<FontAwesomeIcon icon={faUser} size="lg" />}
                label="Personal"
                selected={props.pathname.startsWith("/settings/personal")}
                showLabel={props.showLabel}
                className="text-sm"
            />

            <PageLink
                to="/settings/notifications"
                icon={<FontAwesomeIcon icon={faEnvelope} size="lg" />}
                label="Notifications"
                selected={props.pathname.startsWith("/settings/notifications")}
                showLabel={props.showLabel}
                className="text-sm"
            />

            <PageLink
                to="/settings/quick-actions"
                icon={<FontAwesomeIcon icon={faBolt} size="lg" />}
                label="Quick Actions"
                selected={props.pathname.startsWith("/settings/quick-actions")}
                showLabel={props.showLabel}
                className="text-sm"
            />

            <PageLink
                to="/settings/insights"
                icon={<FontAwesomeIcon icon={faLineChart} size="lg" />}
                label="Insights"
                selected={props.pathname.startsWith("/settings/insights")}
                showLabel={props.showLabel}
                className="text-sm"
            />

            <PageLink
                to="/settings/call-tags"
                icon={<FontAwesomeIcon icon={faTag} size="lg" />}
                label="Call Tags"
                selected={props.pathname.startsWith("/settings/call-tags")}
                showLabel={props.showLabel}
                className="text-sm"
            />
            <PageLink
                to="/settings/user-management"
                icon={<FontAwesomeIcon icon={faUserGear} size="lg" />}
                label="Users"
                selected={props.pathname.startsWith(
                    "/settings/user-management"
                )}
                showLabel={props.showLabel}
                className="text-sm"
            />
            <PageLink
                to="/settings/organizational"
                icon={<FontAwesomeIcon icon={faSitemap} size="lg" />}
                label="Organizational"
                selected={props.pathname.startsWith("/settings/organizational")}
                showLabel={props.showLabel}
                className="text-sm"
                disabled={!props.canEditOrg}
                disabledTooltip="Must be an admin to access this page"
            />
        </>
    )
}

function StrategicInsights(props: { pathname: string; showLabel: boolean }) {
    return (
        <PageLink
            to="/insights"
            icon={<FontAwesomeIcon icon={faLineChart} size="lg" />}
            label="Insights"
            selected={props.pathname.startsWith("/insights")}
            showLabel={props.showLabel}
        />
    )
}

function Search(props: { showLabel: boolean }) {
    const { openSearchModal } = useSearchModal()
    const shortcutKey = isMacOS() ? "⌘K" : "ctrl+k"
    return (
        <div
            onClick={() => {
                openSearchModal()
            }}
            className="flex items-center justify-between rounded-lg p-2 font-semibold text-white transition-colors  hover:cursor-pointer hover:bg-gray-700"
        >
            <div className="space-x-2">
                <FontAwesomeIcon icon={faSparkles} size="lg" />
                {props.showLabel && <span>Search</span>}
            </div>
            {props.showLabel && (
                <span className="opacity-50">{shortcutKey}</span>
            )}
        </div>
    )
}

function Updates(props: { showLabel: boolean }) {
    useEffect(() => {
        displayReleaseNotesModalIfNew()
    }, [])

    return (
        <div
            onClick={displayReleaseNotesModal}
            className="rn-badge flex items-center space-x-2 rounded-lg p-2 text-white  transition-colors hover:cursor-pointer hover:bg-gray-700" // rn-badge needed to display unread notifiation
        >
            <FontAwesomeIcon icon={faBell} size="lg" />
            {props.showLabel && <span>Updates</span>}
        </div>
    )
}

function Help(props: { showLabel: boolean }) {
    return (
        <div
            onClick={showPylon}
            className="rn-badge flex items-center space-x-2 rounded-lg p-2 text-white  transition-colors hover:cursor-pointer hover:bg-gray-700" // rn-badge needed to display unread notifiation
        >
            <FontAwesomeIcon icon={faCircleQuestion} size="lg" />
            {props.showLabel && <span>Help</span>}
        </div>
    )
}

function PageLink(props: {
    label: string
    icon: React.ReactNode
    to: string
    selected: boolean
    showLabel: boolean
    badge?: string
    className?: string
    disabled?: boolean
    disabledTooltip?: string
}) {
    let tooltip = ""
    if (props.disabled && props.disabledTooltip) {
        tooltip = props.disabledTooltip
    } else if (!props.showLabel) {
        tooltip = props.label
    }

    return (
        <Link
            to={props.to}
            className={clsx(
                "text-md flex flex-row items-center gap-2 rounded-lg p-2 font-semibold text-white transition-colors hover:bg-gray-700",
                props.className,
                props.disabled && "opacity-50 hover:cursor-not-allowed"
            )}
            onClick={(e) => {
                if (props.disabled) {
                    e.preventDefault()
                }
            }}
            data-tooltip-id="tooltip-explanation"
            data-tooltip-content={tooltip}
            data-tooltip-place="right"
        >
            {props.icon}
            {props.showLabel && (
                <div className="flex w-full justify-between">
                    <span>{props.label}</span>
                    {props.badge && (
                        <span className="flex items-center justify-center rounded-full bg-sun px-2.5 text-xs text-black">
                            {props.badge}
                        </span>
                    )}
                </div>
            )}
        </Link>
    )
}

function LogOut(props: { showLabel: boolean }) {
    const navigate = useNavigate()

    async function logoutClicked() {
        await signOut()
        navigate("/auth")
    }

    return (
        <div
            onClick={logoutClicked}
            className="flex items-center space-x-2 rounded-lg p-2 text-white transition-colors  hover:cursor-pointer hover:bg-red-500"
        >
            <FontAwesomeIcon icon={faArrowRightFromBracket} size="lg" />
            {props.showLabel && <span>Log out</span>}
        </div>
    )
}
