import _ from "lodash"
import { CueType, ICue } from "../../types/CallPrep"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"

export function Cues(props: { cues: ICue[] }) {
    const cuesByType: _.Dictionary<ICue[]> = _.groupBy(props.cues, "type")
    const reminders = cuesByType[CueType.Reminder]
    const questions = cuesByType[CueType.Question]
    const objections = cuesByType[CueType.Objection]

    if (props.cues.length === 0) {
        return (
            <div className="space-y-3 text-gray-500">
                <div>No relevant playbook entries found.</div>
                <div>
                    Please try adding additional information about this
                    contact's persona to your CRM, or extending your playbook to
                    cover this persona.
                </div>
            </div>
        )
    }

    return (
        <div className="space-y-2">
            {reminders && (
                <CueSection
                    heading="Things to remember 📝"
                    cues={reminders}
                    highlightColour="bg-amber-300"
                    reasonColour="text-amber-500"
                />
            )}
            {questions && (
                <CueSection
                    heading="Questions to ask ✅"
                    cues={questions}
                    highlightColour="bg-indigo-300"
                    reasonColour="text-indigo-500"
                />
            )}
            {objections && (
                <CueSection
                    heading="Possible Objections ⛔"
                    cues={objections}
                    highlightColour="bg-rose-300"
                    reasonColour="text-rose-500"
                />
            )}
        </div>
    )
}

function CueSection(props: {
    heading: string
    cues: ICue[]
    highlightColour: string
    reasonColour: string
}) {
    return (
        <div className="space-y-2 rounded-lg border border-gray-300 bg-white p-3">
            <div className="font-bold">{props.heading}</div>
            {props.cues.map((cue) => (
                <Cue
                    key={cue.id}
                    cue={cue}
                    highlightColour={props.highlightColour}
                    reasonColour={props.reasonColour}
                />
            ))}
        </div>
    )
}

function Cue(props: {
    cue: ICue
    highlightColour: string
    reasonColour: string
}) {
    const [showReason, setShowReason] = useState<boolean>(false)
    return (
        <button
            className="flex w-full items-start justify-between space-x-2"
            onClick={() => {
                setShowReason((prev) => !prev)
            }}
        >
            <div className="flex space-x-2">
                <BulletPoint colour={props.highlightColour} />
                <div className="flex flex-col space-y-2 text-left">
                    <div>{props.cue.text}</div>
                    {showReason && (
                        <div className={props.reasonColour}>
                            ✦ {props.cue.reason}
                        </div>
                    )}
                </div>
            </div>
            <FontAwesomeIcon
                icon={faChevronDown}
                className={`text-gray-400 transition-transform ${
                    showReason && "rotate-180"
                }`}
            />
        </button>
    )
}

function BulletPoint(props: { colour: string }) {
    return (
        <div className={`mb-1 mt-1 w-1 flex-none rounded-lg ${props.colour}`} />
    )
}
