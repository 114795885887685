import { ReactNode } from "react"

export function LightEmptyArea({ children }: { children: ReactNode }) {
    return (
        <div className="flex h-40 items-center justify-center rounded-xl bg-gray-100 p-4 text-center text-sm text-gray-600">
            {children}
        </div>
    )
}

export function DarkEmptyArea({ children }: { children: ReactNode }) {
    return (
        <div className="flex h-40 items-center justify-center rounded-xl bg-gray-200 p-4 text-center text-sm text-gray-700">
            {children}
        </div>
    )
}
