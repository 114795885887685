import { useMutation, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import { NotificationType } from "../../components/common/Notifcations"
import { useNotification } from "../../providers/NotificationProvider"

export function useUpdateUserMemory() {
    const { addNotification } = useNotification()
    const queryClient = useQueryClient()

    const { mutate, isPending } = useMutation({
        mutationFn: async (new_memory: string) => {
            await axios.put(`${process.env.REACT_APP_API_DOMAIN}/user/memory`, {
                new_memory,
            })
        },
        onSuccess: () => {
            addNotification("User memory updated", "", NotificationType.Success)
            queryClient.invalidateQueries({ queryKey: ["user"] })
        },
        onError: () => {
            addNotification(
                "Failed to update user memory",
                "",
                NotificationType.Error
            )
        },
    })

    return {
        mutate,
        isPending,
    }
}
