import { useCallback, useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import ReactPlayer from "react-player"

import { ISharedCall, ITranscriptTurn } from "../../types/Call"
import { CallDetails } from "./CallDetails"
import { CallTranscript } from "./CallTranscript"
import LoadingSpinner from "../common/LoadingSpinner"
import { PageTitle } from "../PageTitle"

import logo from "../../assets/logomark_sun_logotype_cotton.svg"
import { getFormattedDateTime } from "../../utils/datetime"
import { getTurnAtTimestamp, goToTimestamp } from "./utils/mediaPlayer"
import assert from "assert"
import { ErrorPage } from "../common/ErrorPage"
import { IAnnotation } from "../../types/Annotation"
import { CallAnalysis, Tab, Tabs, TabsEnum } from "./CallAnalysis"
import AskGlyphic from "../question-answering/AskGlyphic"
import { ResourceType } from "../question-answering/types/ResourceTypes"
import { sendFollowUpEmailForCall } from "../../utils/createEmailLink"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faScissors, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { useQuery } from "@tanstack/react-query"
import { queries } from "../../api/queries"

export function SharedCall() {
    return (
        <div>
            {/* Needs this as we're not using the NavbarLayout */}
            <PageTitle title="Shared Call" />
            <Banner />
            <SharedCallPage />
        </div>
    )
}

function Banner() {
    return (
        <nav className="flex min-h-fit flex-col items-center justify-between gap-4 space-x-4 bg-gray-900 px-6 py-3 text-base text-white md:flex-row md:px-10">
            <div className="flex items-center">
                <a className="flex items-center" href="https://glyphic.ai">
                    <img src={logo} alt="Logo" className="max-h-[32px]" />{" "}
                </a>
            </div>
            <div className="flex w-full flex-row items-center justify-end gap-2 text-center md:gap-4">
                <span className="font-semibold">
                    Find out how Glyphic can take your sales to new heights.
                </span>
                <a
                    className="rounded-lg bg-blue-500 px-6 py-2 font-bold text-white"
                    href="https://glyphic.ai"
                >
                    Learn&nbsp;more
                </a>
            </div>
        </nav>
    )
}

function SharedCallPage() {
    const params = useParams()
    const sharedCallId: string = params.shared_call_id as string
    const playerRef = useRef<ReactPlayer | null>(null)
    console.assert(!!sharedCallId, "Call page should always have a call id!")

    const [tabs, setTabs] = useState<Tab[]>([])
    const [selectedAnnotation, setSelectedAnnotation] = useState<
        IAnnotation | undefined
    >()
    const [playerReady, setPlayerReady] = useState<boolean>(false)

    const {
        data: call,
        error,
        isPending,
    } = useQuery({
        ...queries.sharedCall.byId(sharedCallId),
        refetchInterval: (query) => {
            return query.state.data?.is_snippet &&
                query.state.data?.snippet_status === "in_progress"
                ? 10000 // refetch every 10 seconds while snippet is processing
                : false
        },
    })

    const { data: media, refetch: refetchMedia } = useQuery({
        ...queries.sharedCall.media(sharedCallId),
        enabled: !!call,
        staleTime: Infinity, // Don't refetch media as it will disrupt playback
    })

    useEffect(() => {
        if (call) {
            refetchMedia()
        }
    }, [call, refetchMedia])

    const playerHeight = media?.type === "video" ? "100%" : "50px"

    const selectAnnotation = (annotation: IAnnotation) => {
        setSelectedAnnotation((prevSelectedAnnotation) => {
            if (prevSelectedAnnotation === annotation) {
                return undefined // unselect the annotation
            }
            return annotation
        })
    }

    useEffect(() => {
        function setTabsFromCall(call: ISharedCall) {
            const availableTabs: Tab[] = []
            if (call.share_options?.share_annotations) {
                availableTabs.push(Tabs[TabsEnum.Annotations])
            }
            if (call.share_options?.share_custom_insights) {
                availableTabs.push(Tabs[TabsEnum.CustomInsights])
            }
            if (call.share_options?.share_talk_stats) {
                availableTabs.push(Tabs[TabsEnum.TalkStats])
            }
            setTabs(availableTabs)
        }

        if (call) {
            setTabsFromCall(call)
        }
    }, [call])

    const [highlightedTurns, setHighlightedTurns] = useState<ITranscriptTurn[]>(
        []
    )

    // if link has timestamp, go to that timestamp
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const startTimestamp =
            call?.share_options?.start_time ?? urlParams.get("start_timestamp")
        if (startTimestamp) {
            goToTimestamp(playerRef)(startTimestamp)
        }
    }, [params.timestamp, playerReady, call])

    function onMediaProgress(mediaProgress: {
        played: number
        playedSeconds: number
        loaded: number
        loadedSeconds: number
    }) {
        assert(call)
        const turn = getTurnAtTimestamp(
            call.transcript_turns,
            mediaProgress.playedSeconds
        )
        if (turn !== null) {
            setHighlightedTurns([turn])
        }
    }

    const sendEmail = useCallback(
        async (message: string) => {
            if (!call) return
            await sendFollowUpEmailForCall(message, call)
        },
        [call]
    )

    if (error) {
        return <NoAccessPage error={error} />
    }

    if (isPending) {
        return <LoadingSpinner />
    }

    const callTitle = call?.title ?? "Untitled call"
    const isPendingSnippet =
        call.is_snippet && call.snippet_status === "in_progress"
    const isFailedSnippet = call.is_snippet && call.snippet_status === "failed"

    return (
        <div className="mx-auto grid max-w-7xl divide-x rounded-lg md:h-[calc(100vh-80px)] md:grid-cols-5">
            <PageTitle title={callTitle} />
            <div className="col-span-3 overflow-y-auto pt-12">
                <section className="space-y-10 px-10 pb-6">
                    <div className="space-y-5">
                        <AccessGrantedSection
                            isSnippet={call.is_snippet}
                            sharerName={call.shared_by_name}
                            sharerEmail={call.shared_by_email}
                            expiryDate={call.expiry_date}
                        />
                        <div className="flex justify-between space-x-2">
                            <h1 className="pb-2 text-2xl font-bold">
                                {callTitle}
                            </h1>
                        </div>
                        <CallDetails call={call} showTags={false} />

                        {isPendingSnippet && <SnippetPendingWarning />}
                        {isFailedSnippet && <SnippetFailedWarning />}
                        {media?.signed_url && (
                            <div className="overflow-hidden rounded-lg">
                                <ReactPlayer
                                    url={media.signed_url}
                                    controls={true}
                                    ref={playerRef}
                                    height={playerHeight}
                                    width="100%"
                                    onProgress={onMediaProgress}
                                    onReady={() => setPlayerReady(true)}
                                />
                            </div>
                        )}

                        {call.share_options?.enable_ask_glyphic && (
                            <AskGlyphic
                                resourceId={sharedCallId}
                                type={ResourceType.SharedCalls}
                                sendEmail={sendEmail}
                                isReadOnly={true}
                            />
                        )}
                        <CallAnalysis
                            tabs={tabs}
                            call={call}
                            selectedAnnotation={selectedAnnotation}
                            selectAnnotation={selectAnnotation}
                            playerRef={playerRef}
                        />
                    </div>
                </section>
            </div>

            <div className="col-span-2 flex flex-grow overflow-y-auto rounded-xl px-10 pt-12">
                <CallTranscript
                    transcriptTurns={call.transcript_turns}
                    highlightedTurns={highlightedTurns}
                    parties={call.parties}
                    goToTimestamp={goToTimestamp(playerRef)}
                    followMedia={true}
                />
            </div>
        </div>
    )
}

function NoAccessPage({ error }: { error: Error }) {
    let errorCode = undefined
    let errorMsg = "Failed to load call."

    if (axios.isAxiosError(error)) {
        errorCode = error.response?.status
    }

    if (errorCode === 410) {
        return <NoAccessExpiredPage />
    } else if (errorCode === 404) {
        errorMsg = "Call not found. The link may be invalid."
    }

    return (
        <ErrorPage
            error={{
                code: errorCode,
                message: errorMsg,
            }}
        />
    )
}

function NoAccessExpiredPage() {
    return (
        <div className="flex flex-col items-center justify-center space-y-4 px-20 pt-48">
            <h1 className="text-6xl font-bold text-delta">
                This link has expired.
            </h1>
            <h2 className="text-2xl">
                Please contact the meeting host for access.
            </h2>
        </div>
    )
}

function AccessGrantedSection(props: {
    isSnippet: boolean
    sharerName: string
    sharerEmail: string
    expiryDate: string
}) {
    const formattedDate = getFormattedDateTime(props.expiryDate)
    const shareable = props.isSnippet ? "call snippet" : "call"
    return (
        <div className="rounded-lg bg-blue-50 px-4 py-2">
            <div className="flex items-center space-x-2 font-bold">
                {props.isSnippet && (
                    <FontAwesomeIcon
                        icon={faScissors}
                        className="text-blue-600"
                    />
                )}
                <div>
                    <span>
                        You've been granted access to this {shareable} by{" "}
                    </span>
                    <span className="text-blue-600">{props.sharerName}</span>
                </div>
            </div>
            <div>
                <span>This link will expire on {formattedDate}. </span>
                <a className="underline" href={`mailto:${props.sharerEmail}`}>
                    Contact the host
                </a>
                <span> if you need more time.</span>
            </div>
        </div>
    )
}

function SnippetPendingWarning() {
    return (
        <div className="space-y-4 rounded-lg bg-gray-900 p-3 text-center text-white">
            <div>
                Your call snippet is being generated.
                <br />
                It'll be ready in just a moment.
            </div>
            <FontAwesomeIcon
                className={"h-6 w-6 font-bold"}
                icon={faSpinner}
                spin
            />
        </div>
    )
}

function SnippetFailedWarning() {
    return (
        <div className="rounded-lg bg-gray-900 p-3 text-center text-white">
            <div>
                Something went wrong, and the call snippet couldn't be
                generated.
            </div>
        </div>
    )
}
