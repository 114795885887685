import { CallTags } from "../CallTags"
import { BaseSettingsPage } from "./BaseSettingsPage"

export function CallTagsPage() {
    return (
        <BaseSettingsPage pageTitle={"Call Tags"} alwaysFullWidth>
            <CallTags />
        </BaseSettingsPage>
    )
}
