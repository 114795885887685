import { faFaceSmilePlus } from "@fortawesome/pro-regular-svg-icons"
import EmojiPicker from "emoji-picker-react"
import * as Popover from "@radix-ui/react-popover"
import { useState } from "react"
import { IEmojiReaction } from "../../types/Comment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import clsx from "clsx"
import { IUser } from "../../types/User"

export function EmojiReactionPicker({
    onSelectEmoji,
}: {
    onSelectEmoji: (emoji: string) => void
}) {
    const [showEmojiPicker, setShowEmojiPicker] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className="ml-1 flex items-center gap-1 rounded-full">
            <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
                <Popover.Trigger asChild>
                    <button
                        className="h-8 w-8 rounded-full opacity-0 transition-opacity group-hover:opacity-100"
                        onClick={() => setShowEmojiPicker(true)}
                    >
                        <FontAwesomeIcon
                            icon={faFaceSmilePlus}
                            className="text-gray-700"
                        />
                    </button>
                </Popover.Trigger>

                <Popover.Portal>
                    <Popover.Content side="right">
                        <EmojiPicker
                            onReactionClick={(reaction) => {
                                onSelectEmoji(reaction.emoji)
                                setShowEmojiPicker(false)
                            }}
                            onEmojiClick={(emoji) => {
                                onSelectEmoji(emoji.emoji)
                                setShowEmojiPicker(false)
                            }}
                            open={showEmojiPicker}
                            reactionsDefaultOpen={true}
                            className="shadow-md"
                            style={{
                                // @ts-ignore
                                "--epr-emoji-size": "20px",
                                "--epr-category-label-height": "24px",
                                "--epr-header-padding": "6px",
                            }}
                        />
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        </div>
    )
}

function joinUsers(
    users: { user_id: string; name: string }[],
    currentUser: IUser
) {
    if (users.length === 0) return ""
    if (users.length === 1)
        return users[0].user_id === currentUser.id ? "You" : users[0].name

    const names = users.map((u) =>
        currentUser.id === u.user_id ? "You" : u.name
    )
    const lastUser = names.pop()
    return names.join(", ") + " and " + lastUser
}

function constructTooltipContent(
    users: { user_id: string; name: string }[],
    currentUser: IUser,
    emoji: string
) {
    return `${joinUsers(users, currentUser)} reacted with ${emoji}`
}

export function EmojiReactionDisplay({
    emojis,
    handleEmojiClick,
    user,
}: {
    emojis: IEmojiReaction[]
    handleEmojiClick: (emoji: string) => void
    user: IUser
}) {
    return (
        <div className="mt-1 flex flex-wrap items-center gap-2">
            {emojis.map(({ emoji, count, users }, index) => {
                const tooltipContent = constructTooltipContent(
                    users,
                    user,
                    emoji
                )
                const includesCurrentUser = users.some(
                    (u) => u.user_id === user.id
                )

                return (
                    <div
                        className={clsx(
                            "flex h-6 w-6  cursor-pointer items-center justify-center gap-1 rounded-full px-6",
                            includesCurrentUser
                                ? "border border-blue-500 bg-blue-50 hover:bg-blue-100"
                                : "border border-transparent bg-gray-100 hover:border-gray-500"
                        )}
                        data-tooltip-id="tooltip-explanation"
                        data-tooltip-content={tooltipContent}
                        onClick={() => handleEmojiClick(emoji)}
                        key={`emoji-${index}`}
                    >
                        {emoji}
                        {count > 0 && (
                            <span className="text-xs text-gray-500">
                                {count}
                            </span>
                        )}
                    </div>
                )
            })}
        </div>
    )
}
