import { DateTime } from "luxon"
import _ from "lodash"

// Define minimum required properties for task categorization
export interface TaskLike {
    completed_at_utc: string | null
    due_date: string | null
    created_at_utc: string | null
}

export interface TaskCategories<T extends TaskLike> {
    dueToday: T[]
    overdue: T[]
    upcoming: T[]
    unscheduled: T[]
    completed: T[]
}

function compareDates(dateA?: DateTime | null, dateB?: DateTime | null) {
    if (!dateA) return 1
    if (!dateB) return -1
    return dateA.startOf("day").diff(dateB.startOf("day"), "days").days
}

export function categorizeTasks<T extends TaskLike>(
    tasks: T[]
): TaskCategories<T> {
    const categories: TaskCategories<T> = {
        dueToday: [],
        overdue: [],
        upcoming: [],
        unscheduled: [],
        completed: [],
    }

    if (!tasks) return categories

    const today = DateTime.now().setZone("utc").startOf("day")

    // First categorize all tasks
    tasks.forEach((task) => {
        if (task.completed_at_utc) {
            const completedDate = DateTime.fromISO(task.completed_at_utc, {
                zone: "utc",
            })
            const isCompletedToday = completedDate.startOf("day").equals(today)

            // Add to completed list immediately
            categories.completed.push(task)

            // For tasks completed today, we want to continue to
            // also categorise them in their original lists
            if (!isCompletedToday) {
                return
            }
        }

        if (!task.due_date) {
            categories.unscheduled.push(task)
            return
        }

        const dueDateDiff = compareDates(
            DateTime.fromISO(task.due_date!, { zone: "utc" }),
            today
        )
        if (dueDateDiff === 0) {
            categories.dueToday.push(task)
        } else if (dueDateDiff < 0) {
            categories.overdue.push(task)
        } else {
            categories.upcoming.push(task)
        }
    })

    // Sorting rules for task lists:
    // - Today: Sort by created_at (oldest first) to show oldest tasks first
    // - Overdue: Sort by due_date (newest first) with created_at (oldest first) as tiebreaker
    // - Upcoming: Sort by due_date (oldest first) with created_at (oldest first) as tiebreaker
    // - Completed tasks remain in their original lists if completed today
    // - Tasks completed before today only show in Completed, sorted by completion
    categories.dueToday = _.orderBy(
        categories.dueToday,
        ["created_at_utc"],
        ["asc"]
    )
    categories.overdue = _.orderBy(
        categories.overdue,
        ["due_date", "created_at_utc"],
        ["desc", "asc"]
    )
    categories.upcoming = _.orderBy(
        categories.upcoming,
        ["due_date", "created_at_utc"],
        ["asc", "asc"]
    )
    categories.unscheduled = _.orderBy(
        categories.unscheduled,
        ["created_at_utc"],
        ["asc"]
    )
    categories.completed = _.orderBy(
        categories.completed,
        ["completed_at_utc"],
        ["desc"]
    )

    return categories
}
