import Dropzone, { FileRejection } from "react-dropzone"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFile } from "@fortawesome/free-solid-svg-icons"
import { CircleLoadingSpinner } from "../../common/CircleLoadingSpinner"
import { useFileUpload } from "./UseFileUpload"
import { useNotification } from "../../../providers/NotificationProvider"
import { NotificationType } from "../../common/Notifcations"

const MAX_FILE_SIZE = 1024 * 1024 * 1024 // 1GB in bytes
const MAX_FILE_SIZE_STR = "1GB"

export function RecordingUploader(props: {
    onUpload: (type: "audio" | "video", name: string) => void
}) {
    const { addNotification } = useNotification()
    const { handleDrop, uploadStatus, filename } = useFileUpload(props.onUpload)

    const onFileRejected = (fileRejections: FileRejection[]) => {
        const error = fileRejections[0].errors[0]
        if (error.code === "file-too-large") {
            addNotification(
                "File is too large",
                `"Please upload a file no larger than ${MAX_FILE_SIZE_STR}`,
                NotificationType.Error
            )
        } else {
            addNotification(error.code, error.message, NotificationType.Error)
        }
    }

    return (
        <Dropzone
            onDrop={handleDrop}
            maxFiles={1}
            maxSize={MAX_FILE_SIZE}
            onDropRejected={onFileRejected}
        >
            {({ getRootProps, getInputProps }) => (
                <section>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {uploadStatus === "success" && (
                            <div className="flex cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-blue-500 bg-blue-50 p-4 shadow-md">
                                <FontAwesomeIcon
                                    icon={faFile}
                                    size="3x"
                                    className="mb-2 text-blue-700"
                                />
                                <p className="font-semibold text-blue-800">
                                    {filename}
                                </p>
                            </div>
                        )}

                        {uploadStatus === "pending" && (
                            <div className="flex h-32 flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300">
                                <CircleLoadingSpinner />
                                <p className="mt-2 text-gray-500">
                                    Uploading... This won't take long!
                                </p>
                            </div>
                        )}

                        {uploadStatus === "idle" && (
                            <div className="flex h-32 cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300">
                                <div className="text-center text-gray-400">
                                    <div>
                                        Drag & drop the audio or video file
                                        here, or click to select it.
                                    </div>
                                    <div>{`Max file size: ${MAX_FILE_SIZE_STR}`}</div>
                                </div>
                            </div>
                        )}

                        {uploadStatus === "error" && (
                            <div className="flex h-32 cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-red-500">
                                <p className="font-semibold text-red-700">
                                    Failed to upload file
                                </p>
                            </div>
                        )}
                    </div>
                </section>
            )}
        </Dropzone>
    )
}
