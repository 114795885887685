import { useMemo, useState } from "react"
import { ICallTag } from "../../types/Call"
import { Tag } from "../settings/CallTags"

export function TagsPreview(props: { tags: ICallTag[] }) {
    const [showAllTags, setShowAllTags] = useState(false)

    // Sort and group tags like in the call page
    const sortedTags = useMemo(() => {
        // First sort by group (with ungrouped last), then by name
        return [...props.tags].sort((a, b) => {
            // If both have same group or both are ungrouped/null, sort by name
            if (a.group === b.group || (!a.group && !b.group)) {
                return a.name.localeCompare(b.name)
            }

            // Handle null/undefined groups (treat as "ungrouped")
            if (!a.group) return 1 // a is ungrouped, should come after
            if (!b.group) return -1 // b is ungrouped, should come after

            // Sort by group name
            return a.group.localeCompare(b.group)
        })
    }, [props.tags])

    if (props.tags.length === 0) {
        return null
    }

    if (props.tags.length <= 3) {
        return (
            <div className="flex flex-row gap-2">
                {sortedTags.map((tag) => (
                    <Tag key={tag.id} text={tag.name} groupName={tag.group} />
                ))}
            </div>
        )
    }

    return (
        <div
            className="relative"
            onMouseEnter={() => setShowAllTags(true)}
            onMouseLeave={() => setShowAllTags(false)}
        >
            <div className="flex flex-row gap-2">
                <span className="flex items-center whitespace-nowrap rounded-sm bg-gray-100 px-2 py-1 text-sm">
                    {props.tags.length} tags
                </span>
            </div>
            {showAllTags && (
                <div className="absolute left-0 top-full z-10 mt-1 rounded border border-gray-200 bg-white p-2 shadow-md">
                    <div className="flex flex-row gap-2 overflow-x-auto">
                        {sortedTags.map((tag) => (
                            <Tag
                                key={tag.id}
                                text={tag.name}
                                groupName={tag.group}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
