import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getHeapInstance } from "../../utils/heap"
import {
    AccessLevel,
    QuickAction,
    SavedQuickAction,
} from "./types/QuickActionTypes"
import {
    faBolt,
    faEye,
    faEyeSlash,
    faPencil,
    faPlus,
    faRemove,
} from "@fortawesome/free-solid-svg-icons"
import { useCallback, useEffect, useState } from "react"
import clsx from "clsx"
import { useUser } from "../../providers/UserProvider"
import { ResourceType } from "./types/ResourceTypes"
import { EditQuickActionModal, NewQuickActionModal } from "./QuickActionModals"
import { hasEditPermission } from "./utils/quickActionPermissions"
import { QuickActionDeleteModal } from "./QuickActionDeleteModal"

export function QuickActionsArea({
    type,
    savedQuickActions,
    setQuestion,
    deleteQuickAction,
    editQuickAction,
    toggleQuickActionVisibility,
    createQuickAction,
    disabled,
}: {
    type: ResourceType
    savedQuickActions: SavedQuickAction[]
    setQuestion: (suggestion: string) => void
    deleteQuickAction: (id: string) => void
    editQuickAction: (
        id: string,
        newTitle: string,
        accessLevel: AccessLevel,
        newQuestion: string
    ) => void
    toggleQuickActionVisibility: (id: string) => void
    createQuickAction: (
        title: string,
        accessLevel: AccessLevel,
        question: string
    ) => Promise<void>
    disabled: boolean
}) {
    const [showHiddenQuickActions, setShowHiddenQuickActions] = useState(false)
    const handleSetQuestion = useCallback(
        (suggestion: string) => {
            if (disabled) return
            setShowHiddenQuickActions(false) // Hide hidden after submitting a question
            setQuestion(suggestion)
        },
        [disabled, setQuestion, setShowHiddenQuickActions]
    )

    const hiddenQuickActions = savedQuickActions.filter((q) => q.is_hidden)
    const visibleQuickActions = savedQuickActions.filter((q) => !q.is_hidden)
    const hasHiddenQuickActions = hiddenQuickActions.length > 0

    useEffect(() => {
        if (!hasHiddenQuickActions) {
            // If a user removed the last hidden quick action, make sure see all state is disabled
            setShowHiddenQuickActions(false)
        }
    }, [hasHiddenQuickActions])

    const hasAnyAction = savedQuickActions.length > 0
    if (!hasAnyAction) return null

    return (
        <div id="frigade-quick-actions" className="flex flex-col gap-2">
            <p className="space-x-2 text-sm">
                <FontAwesomeIcon icon={faBolt} className="text-yellow-500" />
                <span>Quick actions</span>
            </p>
            <div className="flex flex-wrap gap-2">
                {visibleQuickActions.map((quickAction) =>
                    disabled ? (
                        <DisabledQuickAction
                            key={quickAction.id}
                            action={quickAction}
                        />
                    ) : (
                        <SavedQuickActionView
                            key={quickAction.id}
                            action={quickAction}
                            deleteQuickAction={deleteQuickAction}
                            editQuickAction={editQuickAction}
                            toggleQuickActionVisibility={
                                toggleQuickActionVisibility
                            }
                            setQuestion={handleSetQuestion}
                        />
                    )
                )}

                {showHiddenQuickActions &&
                    !disabled &&
                    hiddenQuickActions.map((quickAction) => (
                        <SavedQuickActionView
                            key={quickAction.id}
                            action={quickAction}
                            deleteQuickAction={deleteQuickAction}
                            editQuickAction={editQuickAction}
                            toggleQuickActionVisibility={
                                toggleQuickActionVisibility
                            }
                            setQuestion={handleSetQuestion}
                        />
                    ))}
                {hasHiddenQuickActions && (
                    <ShowHideHiddenQuickActionsButton
                        showHiddenQuickActions={showHiddenQuickActions}
                        setShowHiddenQuickActions={setShowHiddenQuickActions}
                        disabled={disabled}
                    />
                )}

                <NewEmptyQuickActionButton
                    disabled={disabled}
                    createQuickAction={createQuickAction}
                    resourceType={type}
                />
            </div>
        </div>
    )
}

function ShowHideHiddenQuickActionsButton(props: {
    showHiddenQuickActions: boolean
    setShowHiddenQuickActions: (show: boolean) => void
    disabled: boolean
}) {
    return (
        <button
            className={clsx(
                "flex items-center rounded-lg p-2 text-sm font-semibold",
                props.disabled
                    ? "cursor-wait border border-gray-200 bg-gray-100 font-semibold"
                    : "border border-gray-100 bg-gray-50 transition-colors hover:bg-gray-100"
            )}
            onClick={() =>
                props.setShowHiddenQuickActions(!props.showHiddenQuickActions)
            }
            data-tooltip-id="tooltip-explanation"
            data-tooltip-content={`${
                props.showHiddenQuickActions ? "Hide" : "Show"
            } your hidden quick actions`}
            disabled={props.disabled}
        >
            {props.showHiddenQuickActions ? "See less" : "See all"}
        </button>
    )
}

function NewEmptyQuickActionButton(props: {
    disabled: boolean
    createQuickAction: (
        title: string,
        accessLevel: AccessLevel,
        question: string
    ) => Promise<void>
    resourceType: ResourceType
}) {
    const [showNewQuickAction, setShowNewQuickAction] = useState(false)
    return (
        <>
            <button
                className={clsx(
                    "flex items-center rounded-lg p-2 text-sm font-semibold",
                    props.disabled
                        ? "cursor-wait border border-gray-200 bg-gray-100 font-semibold"
                        : "border border-gray-100 bg-gray-50 transition-colors hover:bg-gray-100"
                )}
                onClick={() => {
                    setShowNewQuickAction(true)
                    getHeapInstance()?.track("create-blank-quick-action-opened")
                }}
                data-tooltip-id="tooltip-explanation"
                data-tooltip-content="Create a new quick action"
                disabled={props.disabled}
            >
                <FontAwesomeIcon icon={faPlus} />
            </button>

            {showNewQuickAction && (
                <NewQuickActionModal
                    threadId={null}
                    resourceType={props.resourceType}
                    isOpen={showNewQuickAction}
                    onClose={() => setShowNewQuickAction(false)}
                    saveQuickAction={async (title, accessLevel, question) => {
                        await props.createQuickAction(
                            title,
                            accessLevel,
                            question
                        )
                        setShowNewQuickAction(false)
                    }}
                />
            )}
        </>
    )
}

function DisabledQuickAction(props: { action: QuickAction }) {
    return (
        <div className="group flex max-w-full cursor-wait items-center truncate rounded-lg border border-gray-200 bg-gray-100 p-2 text-left text-sm font-semibold">
            {props.action.title || props.action.question}
        </div>
    )
}

function SavedQuickActionView(props: {
    action: SavedQuickAction
    editQuickAction: (
        id: string,
        newTitle: string,
        accessLevel: AccessLevel,
        newQuestion: string
    ) => void
    deleteQuickAction: (id: string) => void
    toggleQuickActionVisibility: (id: string) => void
    setQuestion: (suggestion: string) => void
}) {
    const user = useUser()
    const [isEditing, setIsEditing] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const canEdit = hasEditPermission(user, props.action)
    const isHidden = props.action.is_hidden

    return (
        <>
            <div
                className={clsx(
                    "text-del group relative flex max-w-full items-center rounded-lg border border-amber-100 bg-amber-50 pr-3 font-semibold transition-colors hover:bg-amber-100",
                    isHidden && "opacity-50"
                )}
            >
                <button
                    className="truncate p-2 text-left text-sm "
                    onClick={() => {
                        getHeapInstance()?.track("selected-saved-question", {
                            suggestion: props.action.question,
                        })
                        props.setQuestion(props.action.question)
                    }}
                >
                    {props.action.title || props.action.question}
                </button>
                <div
                    className={clsx(
                        "absolute right-0 ml-2 hidden h-full items-center gap-2 rounded-lg border border-amber-200 bg-amber-50 p-2",
                        canEdit && "group-hover:flex"
                    )}
                >
                    <FontAwesomeIcon
                        icon={faPencil}
                        size="xs"
                        className="cursor-pointer text-gray-700 hover:text-gray-900"
                        data-tooltip-id="tooltip-explanation"
                        data-tooltip-content="Edit"
                        onClick={() => {
                            setIsEditing(true)
                        }}
                    />
                    <FontAwesomeIcon
                        icon={isHidden ? faEye : faEyeSlash}
                        size="xs"
                        className="cursor-pointer text-gray-700 hover:text-gray-900"
                        data-tooltip-id="tooltip-explanation"
                        data-tooltip-content={isHidden ? "Unhide" : "Hide"}
                        onClick={() => {
                            props.toggleQuickActionVisibility(props.action.id)
                        }}
                    />

                    <FontAwesomeIcon
                        icon={faRemove}
                        size="xs"
                        className="cursor-pointer text-gray-700 hover:text-gray-900"
                        data-tooltip-id="tooltip-explanation"
                        data-tooltip-content="Delete"
                        onClick={() => {
                            setShowConfirmationModal(true)
                        }}
                    />
                </div>
            </div>
            <EditQuickActionModal
                isOpen={isEditing}
                title={props.action.title || ""}
                question={props.action.question}
                accessLevel={props.action.access_level}
                onClose={() => setIsEditing(false)}
                saveQuickAction={async (title, accessLevel, question) => {
                    props.editQuickAction?.(
                        props.action.id,
                        title,
                        accessLevel,
                        question
                    )
                    setIsEditing(false)
                }}
            />
            <QuickActionDeleteModal
                isOpen={showConfirmationModal}
                setIsOpen={setShowConfirmationModal}
                deleteQuickAction={props.deleteQuickAction}
                quickAction={props.action}
            />
        </>
    )
}
