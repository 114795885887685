import clsx from "clsx"
import { NavigateLink } from "./NavigateLink"

interface BadgeProps {
    className?: string
    label: string
    image_url?: string | null
    href?: string
}

export default function Badge(props: BadgeProps) {
    return (
        <NavigateLink
            className={clsx(
                "flex h-fit w-fit items-center gap-1 rounded px-2 py-1",
                props.className
            )}
            href={props.href || ""}
        >
            {props.image_url && (
                <img
                    src={props.image_url}
                    alt={`${props.label} logo`}
                    className="h-[18px] w-[18px] rounded-sm object-cover"
                ></img>
            )}
            <span className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                {props.label}
            </span>
        </NavigateLink>
    )
}
