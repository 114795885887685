import { Row, flexRender } from "@tanstack/react-table"
import { ICall } from "../../types/Call"
import { NavigateLink } from "../common/NavigateLink"

interface CallDetailsRowProps {
    row: Row<ICall>
}

export default function CallDetailsRow({ row }: CallDetailsRowProps) {
    const href = `/calls/${row.original.id}`

    return (
        <tr
            className="h-16 rounded-xl border-b bg-white text-base
                leading-normal text-gray-600 shadow-md shadow-gray-100 transition-colors
                ease-out last:border-0 hover:cursor-pointer hover:bg-gray-100"
        >
            {row.getVisibleCells().map((cell) => (
                <td
                    key={cell.id}
                    className="h-16 first:rounded-l-xl last:rounded-r-xl"
                >
                    <NavigateLink
                        href={href}
                        className="flex h-full w-full items-center p-2"
                    >
                        {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                        )}
                    </NavigateLink>
                </td>
            ))}
        </tr>
    )
}
