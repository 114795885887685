import { useEffect, useState } from "react"
import {
    ICallProcessedNotificationType,
    IMentionsPreferences,
    INotificationPreferences,
    ITaskReminderPreferences,
} from "../../types/User"

import { SettingsOption, SettingsSection } from "./Card"
import { useUser } from "../../providers/UserProvider"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import Select, { SingleValue } from "react-select"
import { userTimezoneToUtc } from "../../utils/timezone"

import { useUpdateTaskReminderPreferences } from "../../api/mutations/task-reminder-prefs"
import { useUpdateMentionsPreferences } from "../../api/mutations/mentions-prefs"
import ToggleButton from "../common/ToggleButton"
import { useUpdateNotificationPreferences } from "../../api/mutations/notification-prefs"

export function NotificationPreferences(props: { slackEnabled: boolean }) {
    return (
        <>
            <CallProcessedPreferences />
            <CallPrepPreferences slackEnabled={props.slackEnabled} />
            <TaskReminderPreferences slackEnabled={props.slackEnabled} />
            <MentionsPreferences slackEnabled={props.slackEnabled} />
        </>
    )
}

function CallProcessedPreferences() {
    const user = useUser()

    const [notificationPreferences, setNotificationPreferences] =
        useState<INotificationPreferences>({
            call_processed_emails: ICallProcessedNotificationType.Participant,
            call_prep_emails_mins_before: null,
            call_prep_slack_mins_before: null,
        })

    useEffect(() => {
        if (!user) return
        if (user.notificationPreferences) {
            setNotificationPreferences(user.notificationPreferences)
        }
    }, [user])

    const { mutate } = useUpdateNotificationPreferences()

    const saveNotificationPreferences = (
        updates: Partial<INotificationPreferences>
    ) => {
        const newPrefs = {
            ...notificationPreferences,
            ...updates,
        }
        setNotificationPreferences(newPrefs)
        mutate(newPrefs)
    }

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newOption = event.target.value as ICallProcessedNotificationType
        saveNotificationPreferences({
            call_processed_emails: newOption,
        })
    }

    const isChecked = (option: ICallProcessedNotificationType) => {
        return notificationPreferences.call_processed_emails === option
    }

    return (
        <SettingsSection
            title="Call Summary Emails"
            description="Receive summary emails when calls have been processed by Glyphic"
            id="call-summary"
        >
            <SettingsOption title="Reminder me when I am: ">
                <div className="flex flex-col gap-2">
                    <NotificationRadioOption
                        value={ICallProcessedNotificationType.Participant}
                        checked={isChecked(
                            ICallProcessedNotificationType.Participant
                        )}
                        onChange={handleRadioChange}
                        label="Participant"
                        group="call-processed-email"
                    />
                    <NotificationRadioOption
                        value={ICallProcessedNotificationType.Host}
                        checked={isChecked(ICallProcessedNotificationType.Host)}
                        onChange={handleRadioChange}
                        label="Host"
                        group="call-processed-email"
                    />
                    <NotificationRadioOption
                        value={ICallProcessedNotificationType.None}
                        checked={isChecked(ICallProcessedNotificationType.None)}
                        onChange={handleRadioChange}
                        label="Never"
                        group="call-processed-email"
                    />
                </div>
            </SettingsOption>
        </SettingsSection>
    )
}

function CallPrepPreferences(props: { slackEnabled: boolean }) {
    const user = useUser()

    const [notificationPreferences, setNotificationPreferences] =
        useState<INotificationPreferences>({
            call_processed_emails: ICallProcessedNotificationType.Participant,
            call_prep_emails_mins_before: null,
            call_prep_slack_mins_before: null,
        })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (!user) return
        if (user.notificationPreferences) {
            setNotificationPreferences(user.notificationPreferences)
        }
        setLoading(false)
    }, [user])

    const { mutate, isPending } = useUpdateNotificationPreferences()

    const saveNotificationPreferences = (
        updates: Partial<INotificationPreferences>
    ) => {
        const newPrefs = {
            ...notificationPreferences,
            ...updates,
        }
        setNotificationPreferences(newPrefs)
        mutate(newPrefs)
    }

    const minsBeforeOptions = [
        { value: 15, label: "15 minutes before a call" },
        { value: 30, label: "30 minutes before a call" },
        { value: 60, label: "1 hour before a call" },
        { value: 180, label: "3 hours before a call" },
        { value: 360, label: "6 hours before a call" },
        { value: 480, label: "8 hours before a call" },
    ]

    const handleMediumsChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        let email_pref = notificationPreferences.call_prep_emails_mins_before
        let slack_pref = notificationPreferences.call_prep_slack_mins_before
        const default_val = 15

        if (event.target.checked) {
            if (event.target.value === "email") {
                email_pref = slack_pref || default_val
            } else {
                slack_pref = email_pref || default_val
            }
        } else {
            if (event.target.value === "email") {
                email_pref = null
            } else {
                slack_pref = null
            }
        }
        saveNotificationPreferences({
            call_prep_emails_mins_before: email_pref,
            call_prep_slack_mins_before: slack_pref,
        })
    }

    const handleMinsBeforeChange = (
        newOption: SingleValue<{
            value: number
            label: string
        }>
    ) => {
        if (newOption) {
            const emailMinsBefore =
                notificationPreferences.call_prep_emails_mins_before === null
                    ? null
                    : newOption.value
            const slackMinsBefore =
                notificationPreferences.call_prep_slack_mins_before === null
                    ? null
                    : newOption.value

            saveNotificationPreferences({
                call_prep_emails_mins_before: emailMinsBefore,
                call_prep_slack_mins_before: slackMinsBefore,
            })
        }
    }
    const notificationsTurnedOff =
        notificationPreferences.call_prep_emails_mins_before === null &&
        notificationPreferences.call_prep_slack_mins_before === null

    return (
        <SettingsSection
            title="Call Prep Sheets"
            description="Receive automated prep sheets before your sales and customer success calls, including summaries of past interactions and open questions"
            id="call-prep"
        >
            <SettingsOption title="Email" loading={loading}>
                <NotificationCheckboxOption
                    value={"email"}
                    checked={
                        notificationPreferences.call_prep_emails_mins_before !==
                        null
                    }
                    onChange={handleMediumsChange}
                    label=""
                    loading={isPending}
                />
            </SettingsOption>
            <SettingsOption
                title="Slack"
                loading={loading}
                icon={
                    <SlackNotificationInfo
                        slackEnabled={props.slackEnabled}
                        email={user?.email ?? ""}
                    />
                }
            >
                <NotificationCheckboxOption
                    value={"slack"}
                    checked={
                        notificationPreferences.call_prep_slack_mins_before !==
                        null
                    }
                    onChange={handleMediumsChange}
                    label=""
                    disabled={!props.slackEnabled}
                    loading={isPending}
                />
            </SettingsOption>
            <SettingsOption title="Reminder Period" loading={loading}>
                <Select
                    className="basic-single w-58"
                    classNamePrefix="select"
                    value={
                        minsBeforeOptions.find(
                            (option) =>
                                option.value ===
                                    notificationPreferences.call_prep_emails_mins_before ||
                                option.value ===
                                    notificationPreferences.call_prep_slack_mins_before
                        ) || minsBeforeOptions[0]
                    }
                    isDisabled={notificationsTurnedOff}
                    onChange={handleMinsBeforeChange}
                    options={minsBeforeOptions}
                />
            </SettingsOption>
        </SettingsSection>
    )
}

function TaskReminderPreferences(props: { slackEnabled: boolean }) {
    const user = useUser()

    const [taskReminderPreferences, setTaskReminderPreferences] =
        useState<ITaskReminderPreferences>({
            send_email_reminder: false,
            send_slack_reminder: false,
            enabled_on_weekends: false,
            reminder_time_utc: "09:00",
        })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (!user) return
        if (user.taskReminderPreferences) {
            setTaskReminderPreferences(user.taskReminderPreferences)
        }
        setLoading(false)
    }, [user])

    const { mutate, isPending } = useUpdateTaskReminderPreferences()

    const [reminderTime, setReminderTime] = useState<string>(
        taskReminderPreferences.reminder_time_utc ?? "9:00"
    )

    useEffect(() => {
        if (!taskReminderPreferences.reminder_time_utc) return
        setReminderTime(
            userTimezoneToUtc(
                taskReminderPreferences.reminder_time_utc,
                "HH:mm",
                user
            )
        )
    }, [user, taskReminderPreferences.reminder_time_utc])

    const saveTaskReminderPreferences = (
        updates: Partial<ITaskReminderPreferences>
    ) => {
        const newPrefs = {
            ...taskReminderPreferences,
            ...updates,
        }
        setTaskReminderPreferences(newPrefs)
        mutate(newPrefs)
    }

    const handleEnabledChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        let emailSelected = taskReminderPreferences.send_email_reminder
        let slackSelected = taskReminderPreferences.send_slack_reminder
        let enabledOnWeekends = taskReminderPreferences.enabled_on_weekends

        if (event.target.value === "email") {
            emailSelected = event.target.checked
        } else if (event.target.value === "slack") {
            slackSelected = event.target.checked
        } else if (event.target.value === "enabled_on_weekends") {
            enabledOnWeekends = event.target.checked
        }

        saveTaskReminderPreferences({
            send_email_reminder: emailSelected,
            send_slack_reminder: slackSelected,
            enabled_on_weekends: enabledOnWeekends,
        })
    }

    const handleTimeChange = () => {
        const hourUtc = userTimezoneToUtc(reminderTime, "HH:mm", user)

        saveTaskReminderPreferences({
            reminder_time_utc: hourUtc,
        })
    }

    return (
        <SettingsSection
            title="Task Reminders"
            description="Receive automated task reminders at a specific time of day."
            id="task-reminders"
        >
            <SettingsOption title="Email" loading={loading}>
                <NotificationCheckboxOption
                    value={"email"}
                    checked={taskReminderPreferences.send_email_reminder}
                    onChange={handleEnabledChange}
                    label=""
                    loading={isPending}
                />
            </SettingsOption>
            <SettingsOption
                title="Slack"
                loading={loading}
                icon={
                    <SlackNotificationInfo
                        slackEnabled={props.slackEnabled}
                        email={user?.email ?? ""}
                    />
                }
            >
                <NotificationCheckboxOption
                    value={"slack"}
                    checked={taskReminderPreferences.send_slack_reminder}
                    onChange={handleEnabledChange}
                    label=""
                    disabled={!props.slackEnabled}
                    loading={isPending}
                />
            </SettingsOption>
            <SettingsOption title="Enable on weekends" loading={loading}>
                <NotificationCheckboxOption
                    value={"enabled_on_weekends"}
                    checked={taskReminderPreferences.enabled_on_weekends}
                    onChange={handleEnabledChange}
                    label=""
                    loading={isPending}
                />
            </SettingsOption>
            <SettingsOption title="Reminder time" loading={loading}>
                <div className="mt-2 flex w-fit flex-col space-y-1">
                    <input
                        id="reminder-time"
                        type="time"
                        value={reminderTime}
                        onChange={(e) => setReminderTime(e.target.value)}
                        onBlur={handleTimeChange}
                        step="3600"
                        className="w-32 rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isPending}
                    />
                    <div className="mr-2 self-end text-xs text-gray-500">
                        {user?.timezone}
                    </div>
                </div>
            </SettingsOption>
        </SettingsSection>
    )
}

function MentionsPreferences(props: { slackEnabled: boolean }) {
    const user = useUser()

    const [mentionsPreferences, setMentionsPreferences] =
        useState<IMentionsPreferences>({
            send_email_mentions: false,
            send_slack_mentions: false,
        })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (!user) return
        if (user.mentionsPreferences) {
            setMentionsPreferences(user.mentionsPreferences)
        }
        setLoading(false)
    }, [user])

    const { mutate, isPending } = useUpdateMentionsPreferences()

    const saveMentionsPreferences = (
        updates: Partial<IMentionsPreferences>
    ) => {
        const newPrefs = {
            ...mentionsPreferences,
            ...updates,
        }
        setMentionsPreferences(newPrefs)
        mutate(newPrefs)
    }

    const handleEnabledChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        saveMentionsPreferences({
            [`send_${event.target.value}_mentions`]: event.target.checked,
        })
    }

    return (
        <SettingsSection
            title="Mentions"
            description="Receive notifications when you are mentioned in a comment."
            id="mentions"
        >
            <SettingsOption title="Email" loading={loading}>
                <NotificationCheckboxOption
                    value={"email"}
                    checked={mentionsPreferences.send_email_mentions}
                    onChange={handleEnabledChange}
                    loading={isPending}
                    label=""
                />
            </SettingsOption>
            <SettingsOption
                title="Slack"
                loading={loading}
                icon={
                    <SlackNotificationInfo
                        slackEnabled={props.slackEnabled}
                        email={user?.email ?? ""}
                    />
                }
            >
                <div className="flex flex-row items-center gap-2">
                    <NotificationCheckboxOption
                        value={"slack"}
                        checked={mentionsPreferences.send_slack_mentions}
                        onChange={handleEnabledChange}
                        label=""
                        disabled={!props.slackEnabled}
                        loading={isPending}
                    />
                </div>
            </SettingsOption>
        </SettingsSection>
    )
}

function NotificationCheckboxOption(props: {
    value: string
    checked: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    label: string
    disabled?: boolean
    loading?: boolean
}) {
    return (
        <label
            className={`inline-flex items-center ${
                props.disabled && "opacity-50"
            }`}
        >
            <ToggleButton
                checked={props.checked}
                disabled={props.disabled}
                onChange={(checked) => {
                    // Create a synthetic event to pass to props.onChange
                    const syntheticEvent = {
                        target: { checked, value: props.value },
                        currentTarget: { checked, value: props.value },
                    } as React.ChangeEvent<HTMLInputElement>

                    props.onChange(syntheticEvent)
                }}
                value={props.value}
                loading={props.loading}
            />
            <span className="ml-2">{props.label}</span>
        </label>
    )
}

function NotificationRadioOption(props: {
    value: string
    checked: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    label: string
    group: string
}) {
    return (
        <label className="inline-flex items-center">
            <input
                type="radio"
                name={props.group}
                value={props.value}
                checked={props.checked}
                onChange={props.onChange}
                className="h-5 w-5 accent-gray-900"
            />
            <span className="ml-2">{props.label}</span>
        </label>
    )
}

function SlackNotificationInfo(props: {
    slackEnabled: boolean
    email: string
}) {
    return (
        <FontAwesomeIcon
            icon={faInfoCircle}
            className="text-gray-600"
            data-tooltip-id="tooltip-explanation"
            data-tooltip-html={
                props.slackEnabled
                    ? `Glyphic will search for a Slack user associated with your email <span class="font-bold">${props.email}</span> and send you a DM`
                    : "Please connect Glyphic to your Slack workspace to enable this option"
            }
        />
    )
}
