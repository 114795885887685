import { useMutation, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import { NotificationType } from "../../components/common/Notifcations"
import { useNotification } from "../../providers/NotificationProvider"
import { ITaskReminderPreferences } from "../../types/User"

export function useUpdateTaskReminderPreferences() {
    const { addNotification } = useNotification()
    const queryClient = useQueryClient()

    const { mutate, isPending } = useMutation({
        mutationFn: async (preferences: ITaskReminderPreferences) => {
            await axios.put(
                `${process.env.REACT_APP_API_DOMAIN}/user/task_reminder_preferences`,
                preferences
            )
        },
        onSuccess: () => {
            addNotification(
                "Task reminder preferences updated",
                "",
                NotificationType.Success
            )
        },
        onError: () => {
            addNotification(
                "Failed to update task reminder preferences",
                "",
                NotificationType.Error
            )
            // Invalidate the user query to prevent de-sync die to failed update
            queryClient.invalidateQueries({ queryKey: ["user"] })
        },
    })

    return {
        mutate,
        isPending,
    }
}
