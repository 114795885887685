import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CrunchbaseLogo } from "../../assets/CrunchbaseLogo"
import { ICompanyInfo } from "../../types/CompanyProfile"
import { LogoLink } from "../common/LogoLink"
import { faGlobe } from "@fortawesome/free-solid-svg-icons"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { SimpleCard } from "../common/SimpleCard"
import { crmIconFromUrl } from "../crm/utils/crmIconFromUrl"

interface CompanyDetailsProps {
    info: ICompanyInfo | null
    isLoading: boolean
}

export function CompanyDetails(props: CompanyDetailsProps) {
    if (props.isLoading) {
        return <LoadingProfile />
    }

    if (!props.info) {
        return null
    }

    const revenueString = formatCurrency(props.info.annual_revenue_usd)
    const fundingString = formatCurrency(
        props.info.total_funding_usd?.toString()
    )

    return (
        <SimpleCard className="w-full p-3">
            <div className="flex w-full flex-col gap-2 md:flex-row md:gap-4">
                <div className="flex w-full flex-nowrap gap-4">
                    {props.info.image_url && (
                        <div className="flex h-auto w-20 items-center">
                            <img
                                className="h-auto w-20 rounded-lg"
                                src={props.info?.image_url}
                                alt="Company logo"
                            />
                        </div>
                    )}
                    <div className="flex w-full flex-col gap-2">
                        <div className="flex flex-col flex-nowrap">
                            <span className="text-3xl font-bold leading-9 text-gray-900">
                                {props.info.name}{" "}
                            </span>
                            <CompanyLinks info={props.info} />{" "}
                        </div>
                        <div className="hidden w-full md:block">
                            <span
                                className="line-clamp-1 text-sm font-normal leading-tight tracking-tight text-gray-900"
                                data-tooltip-id="tooltip-explanation"
                                data-tooltip-content={props.info.description}
                            >
                                {props.info.description}
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    className="line-clamp-1 w-full text-sm font-normal leading-tight tracking-tight text-gray-900 md:hidden"
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content={props.info.description}
                >
                    {props.info.description}
                </div>
                <div className="flex min-w-fit items-center divide-x divide-gray-300 md:h-20">
                    <CompanyAttribute
                        label="Headcount"
                        value={props.info.num_employees}
                    />
                    <CompanyAttribute
                        label="Annual revenue"
                        value={revenueString}
                    />
                    <CompanyAttribute
                        label="Total money raised"
                        value={fundingString}
                    />
                </div>
            </div>
        </SimpleCard>
    )
}

function formatCurrency(value?: string | null): string | null {
    if (!value) return null

    try {
        const numberValue = Number(value.replace(/[$,]/g, ""))
        if (Number.isNaN(numberValue)) return value

        return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }).format(numberValue)
    } catch (e) {
        return value
    }
}

function LoadingProfile() {
    return (
        <SimpleCard>
            <div className="flex w-full animate-pulse items-start space-x-2 p-2 text-gray-300">
                <div className="font-bold">Loading Company Profile</div>
            </div>
        </SimpleCard>
    )
}

function CompanyLinks(props: { info: ICompanyInfo }) {
    return (
        <div className="flex items-center gap-2">
            {props.info.crunchbase_url && (
                <LogoLink
                    url={props.info.crunchbase_url}
                    logo={<CrunchbaseLogo className="h-auto w-5" />}
                />
            )}
            {props.info.crm_url && (
                <LogoLink
                    url={props.info.crm_url}
                    logo={
                        <FontAwesomeIcon
                            icon={crmIconFromUrl(props.info.crm_url)}
                            size="lg"
                        />
                    }
                />
            )}
            {props.info.website_url && (
                <LogoLink
                    url={props.info.website_url}
                    logo={<FontAwesomeIcon icon={faGlobe} size="lg" />}
                />
            )}
            {props.info.linkedin_url && (
                <LogoLink
                    url={props.info.linkedin_url}
                    logo={<FontAwesomeIcon icon={faLinkedin} size="lg" />}
                />
            )}
        </div>
    )
}

export function CompanyAttribute(props: {
    label: string
    value: string | null | undefined
}) {
    return (
        <div className="flex flex-col space-y-1 p-2 first:pl-0 last:pr-0 md:px-5">
            <span className="text-xs font-semibold leading-none tracking-tight text-gray-900">
                {props.label}
            </span>
            <span className="break-all text-xs font-normal leading-normal text-gray-900 md:text-base">
                {props.value || "Unspecified"}
            </span>
        </div>
    )
}
