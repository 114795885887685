import axios from "axios"
import React, { useState, useEffect } from "react"
import pathways from "../assets/pathways.svg"
import { IRegistrationUser } from "../types/User"
import LoadingSpinner from "./common/LoadingSpinner"
import { useNotification } from "../providers/NotificationProvider"
import { NotificationType } from "./common/Notifcations"

function Pathways() {
    return (
        <div className="flex-1 bg-delta">
            <img
                src={pathways}
                alt="pathways"
                className="h-full bg-delta object-cover object-left"
            />
        </div>
    )
}

function FormHeading(props: { title: string }) {
    return (
        <h1 className="mb-16 text-4xl font-bold text-delta">{props.title}</h1>
    )
}

function FormLabel(props: { label: string; id: string }) {
    return (
        <label htmlFor={props.id} className="text-lg font-bold text-delta">
            {props.label}
        </label>
    )
}

function FormButton(props: { label: string }) {
    return (
        <button
            type="submit"
            className="w-full rounded bg-delta px-4 py-2 text-white"
        >
            {props.label}
        </button>
    )
}

interface RegistrationState {
    isLoading: boolean
    userExists: boolean
    orgExists: boolean
}

export default function Registration(props: {
    onSuccess: () => void
}): JSX.Element {
    const { addNotification } = useNotification()
    const [registrationState, setRegistrationState] =
        useState<RegistrationState>({
            isLoading: true,
            userExists: false,
            orgExists: false,
        })

    function setUserExists(userExists: boolean) {
        setRegistrationState((prevState) => ({
            ...prevState,
            userExists: userExists,
        }))
    }

    function setOrgExists(orgExists: boolean) {
        setRegistrationState((prevState) => ({
            ...prevState,
            orgExists: orgExists,
        }))
    }

    useEffect(() => {
        if (registrationState.orgExists && registrationState.userExists) {
            props.onSuccess()
        }
    }, [registrationState, props])

    useEffect(() => {
        async function initialisation() {
            let userExists = false
            let orgExists = false

            // Check if user exists
            try {
                await axios.get(`${process.env.REACT_APP_API_DOMAIN}/user`)
                userExists = true
            } catch (error) {
                if (
                    axios.isAxiosError(error) &&
                    error.response?.status === 404
                ) {
                    userExists = false
                } else {
                    addNotification(
                        "Failed to fetch user",
                        `${error}`,
                        NotificationType.Error
                    )
                }
            }

            // Check if org exists
            try {
                await axios.get(
                    `${process.env.REACT_APP_API_DOMAIN}/organization`
                )
                orgExists = true
            } catch (error) {
                if (
                    axios.isAxiosError(error) &&
                    error.response?.status === 404
                ) {
                    orgExists = false
                } else {
                    addNotification(
                        "Failed to fetch organization",
                        `${error}`,
                        NotificationType.Error
                    )
                }
            }
            setRegistrationState({
                isLoading: false,
                userExists: userExists,
                orgExists: orgExists,
            })
        }
        initialisation()
    }, [addNotification])

    if (registrationState.isLoading) {
        return <LoadingSpinner />
    }

    return (
        <div>
            {!registrationState.userExists && (
                <CreateUser setUserExists={setUserExists} />
            )}
            {registrationState.userExists && !registrationState.orgExists && (
                <CreateOrg setOrgExists={setOrgExists} />
            )}
            {registrationState.orgExists && registrationState.userExists && (
                <div>
                    <h1>Organization already exists</h1>
                    <p>Redirecting to index...</p>
                </div>
            )}
        </div>
    )
}

export function CreateUser(props: {
    setUserExists: (a: boolean) => void
}): JSX.Element {
    const { addNotification } = useNotification()
    const [userInfo, setUserInfo] = useState<IRegistrationUser>({
        name: "",
        industry: undefined,
        job_title: undefined,
    })
    async function handleCreateUser(event: React.FormEvent) {
        event.preventDefault()
        await createUser()
    }

    async function createUser() {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_DOMAIN}/user`,
                {
                    name: userInfo.name.trim(),
                    industry: userInfo.industry?.trim(),
                    job_title: userInfo.job_title?.trim(),
                }
            )
            if (response.data.success) {
                props.setUserExists(true)
            }
        } catch (error) {
            addNotification(
                "Failed to create user",
                `${error}`,
                NotificationType.Error
            )
        }
    }

    function handleFormChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target
        setUserInfo({ ...userInfo, [name]: value })
    }
    return (
        <div className="flex h-screen flex-row">
            <div className="flex-1 bg-white p-10">
                <FormHeading title="Tell us about yourself" />

                <form onSubmit={handleCreateUser} className="space-y-4">
                    <div>
                        <FormLabel label="What's your full name?" id="name" />
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="w-full rounded border px-3 py-2"
                            value={userInfo.name}
                            onChange={handleFormChange}
                            placeholder="John Doe"
                            required
                        />
                    </div>
                    <div>
                        <FormLabel
                            label="What industry do you work in?"
                            id="industry"
                        />
                        <input
                            type="text"
                            id="industry"
                            name="industry"
                            className="w-full rounded border px-3 py-2"
                            value={userInfo.industry}
                            placeholder="Technology"
                            onChange={handleFormChange}
                        />
                    </div>
                    <div>
                        <FormLabel label="What's your role?" id="job_title" />
                        <input
                            type="text"
                            id="job_title"
                            name="job_title"
                            className="w-full rounded border px-3 py-2"
                            value={userInfo.job_title}
                            onChange={handleFormChange}
                            placeholder="Sales Manager"
                        />
                    </div>
                    <FormButton label="Continue" />
                </form>
            </div>
            <Pathways />
        </div>
    )
}

function CreateOrg(props: { setOrgExists: (a: boolean) => void }): JSX.Element {
    const { addNotification } = useNotification()
    const [orgName, setOrgName] = useState<string>("")

    async function createOrg() {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_DOMAIN}/organization`,
                {
                    org_name: orgName.trim(),
                }
            )
            if (response.data.success) {
                props.setOrgExists(true)
            }
        } catch (error) {
            addNotification(
                "Failed to create organization",
                `${error}`,
                NotificationType.Error
            )
        }
    }

    async function handleCreateOrgSubmit(event: React.FormEvent) {
        event.preventDefault()
        await createOrg()
    }

    return (
        <div className="flex h-screen flex-row">
            <div className="flex-1 bg-white p-10">
                <FormHeading title="What's your company name?" />
                <form onSubmit={handleCreateOrgSubmit} className="space-y-4">
                    <div>
                        <FormLabel label="Company name" id="value" />
                        <input
                            type="text"
                            id="value"
                            className="w-full rounded border px-3 py-2"
                            value={orgName}
                            onChange={(event) => setOrgName(event.target.value)}
                            placeholder="ACME inc."
                            required
                        />
                    </div>
                    <FormButton label="Continue" />
                </form>
            </div>
            <Pathways />
        </div>
    )
}
