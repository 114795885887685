import { useState } from "react"
import { DangerousButton, SecondaryButton } from "../common/Buttons"
import { Modal } from "../common/Modal"
import { faTrashCan, faWarning } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface DeleteUserButtonProps {
    userName: string
    onDeleteUser: () => void
}

export function DeleteUserButton(props: DeleteUserButtonProps) {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [isConfirmed, setIsConfirmed] = useState(false)

    return (
        <>
            <SecondaryButton onClick={() => setShowConfirmationModal(true)}>
                <FontAwesomeIcon icon={faTrashCan} />
            </SecondaryButton>
            <Modal
                isOpen={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
            >
                <div className="flex w-full flex-col gap-4 p-8">
                    <div className="mr-12 text-lg font-bold">
                        Are you sure you want to delete {props.userName}?
                    </div>
                    <WarningBanner />
                    <DeletionConfirmation
                        className="mt-6"
                        userName={props.userName}
                        setIsConfirmed={setIsConfirmed}
                    />
                    <div className="flex justify-end">
                        <SecondaryButton
                            onClick={() => setShowConfirmationModal(false)}
                            className="mr-4"
                        >
                            Cancel
                        </SecondaryButton>
                        <DangerousButton
                            disabled={!isConfirmed}
                            onClick={async () => {
                                setShowConfirmationModal(false)
                                props.onDeleteUser()
                            }}
                        >
                            Delete
                        </DangerousButton>
                    </div>
                </div>
            </Modal>
        </>
    )
}

function WarningBanner() {
    return (
        <div
            className={
                "flex max-w-2xl flex-row items-center justify-start gap-4 rounded-md border border-red-400 bg-red-50 p-4 text-red-700"
            }
        >
            <FontAwesomeIcon icon={faWarning} className="text-lg" />
            <p className="max-w-fit flex-shrink overflow-hidden break-words text-lg first-letter:capitalize">
                <strong>This action cannot be reversed!</strong>
            </p>
        </div>
    )
}

interface DeletionConfirmationProps {
    userName: string
    setIsConfirmed: React.Dispatch<React.SetStateAction<boolean>>
    className?: string
}

function DeletionConfirmation(props: DeletionConfirmationProps) {
    const [confirmationInput, setConfirmationInput] = useState("")

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmationInput(e.target.value)
        props.setIsConfirmed(e.target.value === props.userName)
    }

    return (
        <div className={"flex flex-col gap-1 " + props.className}>
            <p>
                Please type the user name '<code>{props.userName}</code>' below,
                for confirmation:
            </p>
            <div className="border-block flex w-full flex-row items-center gap-2 rounded-lg border bg-white py-1 pl-4 text-base">
                <input
                    autoFocus
                    className="w-full py-2 outline-none placeholder:text-gray-600"
                    type="text"
                    placeholder={
                        "Type '" + props.userName + "' for confirmation"
                    }
                    value={confirmationInput}
                    onChange={handleInputChange}
                />
            </div>
        </div>
    )
}
