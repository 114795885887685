import { useState, useEffect } from "react"
import { Modal } from "../../common/Modal"
import { PrimaryButton } from "../../common/Buttons"
import { CreateTaskMutation } from "../../../api/mutations/tasks"
import { DateTime } from "luxon"
import { useUser } from "../../../providers/UserProvider"

interface CreateTaskModalProps {
    isOpen: boolean
    onClose: () => void
    createTask: CreateTaskMutation
}

export function CreateTaskModal(props: CreateTaskModalProps) {
    const user = useUser()
    const [description, setDescription] = useState("")
    const [dueDate, setDueDate] = useState<string>(DateTime.now().toISODate()!)

    // Reset form when modal is closed
    useEffect(() => {
        if (!props.isOpen) {
            setDescription("")
            setDueDate(DateTime.now().toISODate()!)
        }
    }, [props.isOpen])

    const handleSubmit = async () => {
        await props.createTask({
            description,
            dueDate: dueDate ? DateTime.fromISO(dueDate).toJSDate() : undefined,
            assignee: user
                ? {
                      name: user.name,
                      email: user.email,
                      userId: user.id,
                  }
                : undefined,
        })
        props.onClose()
    }

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <div className="m-auto flex max-w-2xl flex-col rounded-lg border bg-gray-50">
                <div className="flex flex-col space-y-6 p-8">
                    <div className="space-y-1">
                        <h2 className="text-xl font-bold">Create New Task</h2>
                        <h4 className="text-gray-500">
                            Add a new task to your list
                        </h4>
                    </div>

                    <div className="space-y-4">
                        <div className="space-y-2">
                            <label className="text-sm font-medium text-gray-700">
                                Description
                            </label>
                            <textarea
                                className="w-full resize-none rounded-lg border border-gray-300 p-2 focus:border-blue-500 focus:outline-none"
                                value={description}
                                autoFocus
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="What needs to be done?"
                                rows={4}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault()
                                        if (description.trim()) {
                                            handleSubmit()
                                        }
                                    }
                                }}
                            />
                        </div>

                        <div className="space-y-2">
                            <label className="text-sm font-medium text-gray-700">
                                Due Date
                            </label>
                            <input
                                type="date"
                                className="w-full rounded-lg border border-gray-300 p-2 focus:border-blue-500 focus:outline-none"
                                value={dueDate}
                                onChange={(e) => setDueDate(e.target.value)}
                            />
                            <div className="flex gap-2">
                                <DateShortcut
                                    name="Today"
                                    targetDate={DateTime.now().toISODate()!}
                                    onSelect={setDueDate}
                                />
                                <DateShortcut
                                    name="Tomorrow"
                                    targetDate={
                                        DateTime.now()
                                            .plus({ days: 1 })
                                            .toISODate()!
                                    }
                                    onSelect={setDueDate}
                                />
                                <DateShortcut
                                    name="Clear"
                                    targetDate=""
                                    onSelect={setDueDate}
                                    variant="secondary"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-end">
                        <PrimaryButton
                            className="w-fit"
                            onClick={handleSubmit}
                            disabled={!description}
                        >
                            Create Task
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

interface DateShortcutProps {
    name: string
    targetDate: string
    onSelect: (date: string) => void
    variant?: "primary" | "secondary"
}

function DateShortcut({
    name,
    targetDate,
    onSelect,
    variant = "primary",
}: DateShortcutProps) {
    const colorClasses =
        variant === "primary"
            ? "text-blue-600 hover:text-blue-800"
            : "text-gray-600 hover:text-gray-800"

    return (
        <button
            type="button"
            onClick={() => onSelect(targetDate)}
            className={`text-sm ${colorClasses}`}
        >
            {name}
        </button>
    )
}
