import { useUser } from "../../providers/UserProvider"
import { MyActivity } from "./TalkStats"
import { DealsClosingThisQuarter, RecentlyViewedDeals } from "./Deals"
import { UpcomingCalls } from "./NextCall"
import { PastCalls } from "./PastCalls"
import { SetupBanner } from "../SetupBanner"
import _ from "lodash"
import { hasPermission } from "../../utils/Permissions"
import { Permission } from "../../types/Permission"
import { Tasks } from "./Tasks"
import { RecentMentions } from "./RecentMentions"

export function Home() {
    const user = useUser()
    const canRecordCalls = user && hasPermission(user, Permission.RECORD_CALLS)
    const canViewOthersCalls =
        user && hasPermission(user, Permission.VIEW_OTHERS_CALLS)

    return (
        <div className="grid h-full lg:grid-cols-2">
            <div className="col-span-1 h-full space-y-10 p-4 md:p-16">
                <h1 className="text-4xl font-bold">
                    {getGreetingMessage(user?.name!)}
                </h1>
                <SetupBanner />
                <RecentlyViewedDeals />
                {canRecordCalls && <PastCalls />}
                <Tasks />
                <RecentMentions />
                {canRecordCalls && <MyActivity />}
            </div>
            <div className="col-span-1 h-full space-y-10 bg-gray-100 p-4 md:p-16">
                <div className="hidden h-9 md:block" />
                <ReleaseNotesBanner />
                {canViewOthersCalls && <DealsClosingThisQuarter />}
                {canRecordCalls && <UpcomingCalls />}
            </div>
        </div>
    )
}

export function ReleaseNotesBanner() {
    return (
        /* @ts-ignore */
        <rn-banner prefix="New" target="modal" />
    )
}

function getGreetingMessage(fullName: string) {
    const firstName = _.capitalize(_.head(fullName.split(" ")) || fullName)

    let messages = [
        `Hi, ${firstName}! 👋`,
        `Welcome to Glyphic, ${firstName}! 🚀`,
    ]

    const now = new Date()

    const hour = now.getHours()
    const isMorning = hour >= 3 && hour < 12
    const isAfternoon = hour >= 12 && hour < 18
    const isEvening = hour >= 18 || hour < 3

    const day = now.getDay()
    const isMonday = day === 1
    const isFriday = day === 5

    if (isMorning) {
        messages.push(`Good morning, ${firstName}! ☕`)
    }
    if (isAfternoon) {
        messages.push(`Good afternoon, ${firstName}! 🌞`)
    }
    if (isEvening) {
        messages.push(`Good evening, ${firstName}! 🌛`)
    }
    if (isMonday) {
        messages.push(`Happy Monday, ${firstName}! 🌅`)
    }
    if (isFriday) {
        messages.push(`Happy Friday, ${firstName}! 🎉`)
    }

    // Randomly return one of the available messages
    return _.sample(messages)
}
