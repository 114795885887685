import axios from "axios"
import {
    UseMutateFunction,
    useMutation,
    useQueryClient,
} from "@tanstack/react-query"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../../components/common/Notifcations"
import { Task } from "../../components/tasks/types/Task"
import { taskQueries } from "../queries/tasks"

type UpdateTaskVariables = {
    taskId: string
    updates: Partial<Omit<Task, "id">>
}

export type UpdateTaskMutation = UseMutateFunction<
    Task,
    Error,
    UpdateTaskVariables,
    unknown
>

export const useUpdateTask = (queryKey: any[] | readonly any[]) => {
    const { addNotification } = useNotification()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async ({ taskId, updates }: UpdateTaskVariables) => {
            const response = await axios.patch(
                `${process.env.REACT_APP_API_DOMAIN}/tasks/${taskId}`,
                updates
            )
            return response.data as Task
        },

        onError: (error) => {
            let errorMessage = "An error occurred while updating the task."
            if (axios.isAxiosError(error)) {
                errorMessage =
                    error.response?.data?.detail?.message || errorMessage
            }
            addNotification(
                "Failed to update task",
                errorMessage,
                NotificationType.Error
            )
            console.error("Error updating task:", error)
        },

        onSuccess: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries({ queryKey })
            queryClient.invalidateQueries(taskQueries.count())
        },
    })
}

type CreateTaskVariables = {
    description: string
    callId?: string
    dueDate?: Date
    assignee?: {
        name?: string
        email?: string
        userId?: string
    }
    timestamp?: number
}

export type CreateTaskMutation = UseMutateFunction<
    Task,
    Error,
    CreateTaskVariables,
    unknown
>

export const useCreateTask = (queryKey: any[] | readonly any[]) => {
    const { addNotification } = useNotification()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (variables: CreateTaskVariables) => {
            const response = await axios.post(
                `${process.env.REACT_APP_API_DOMAIN}/tasks`,
                {
                    description: variables.description,
                    call_id: variables.callId,
                    due_date: variables.dueDate?.toISOString(),
                    assignee: variables.assignee
                        ? {
                              name: variables.assignee.name,
                              email: variables.assignee.email,
                              user_id: variables.assignee.userId,
                          }
                        : undefined,
                    timestamp: variables.timestamp,
                }
            )
            return response.data as Task
        },

        onError: (error) => {
            let errorMessage = "An error occurred while creating the task."
            if (axios.isAxiosError(error)) {
                errorMessage =
                    error.response?.data?.detail?.message || errorMessage
            }
            addNotification(
                "Failed to create task",
                errorMessage,
                NotificationType.Error
            )
            console.error("Error creating task:", error)
        },

        onSuccess: () => {
            addNotification("Task created", "", NotificationType.Success)
            // Invalidate and refetch
            queryClient.invalidateQueries({ queryKey })
            queryClient.invalidateQueries(taskQueries.count())
        },
    })
}
