import React, { useCallback } from "react"
import { CitationProperties, DocumentType } from "../../types/Citation"
import { getHeapInstance } from "../../utils/heap"

export interface CitationProps {
    citation: CitationProperties
}

export function CitationCard(props: CitationProps) {
    const url = getCitationUrl(props.citation)

    return (
        <LinkOrDiv
            url={url}
            className="block rounded-md border border-gray-200 bg-gray-50 p-2 transition-colors hover:bg-gray-100"
        >
            <div className="text-sm font-medium text-gray-800">
                {props.citation.title || "Citation"}
            </div>
            {props.citation?.cited_text && (
                <div className="text-sm text-gray-600">
                    {props.citation.cited_text}
                </div>
            )}
        </LinkOrDiv>
    )
}

export interface LinkOrDivProps {
    url: string | null
    className: string
    children: React.ReactNode
    [key: string]: any // Allow for additional props
}

export function LinkOrDiv({
    url,
    className,
    children,
    ...rest
}: LinkOrDivProps) {
    const handleClick = useCallback(() => {
        getHeapInstance()?.track("citation-clicked", { url })
    }, [url])

    return url ? (
        <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className={className}
            data-tooltip-id="citation-tooltip"
            onClick={handleClick}
            {...rest}
        >
            {children}
        </a>
    ) : (
        <div className={className} onClick={handleClick} {...rest}>
            {children}
        </div>
    )
}

export function getCitationUrl(citation: CitationProperties): string | null {
    switch (citation.type) {
        case DocumentType.CALL_SUMMARY:
        case DocumentType.CALL_TRANSCRIPT:
            if (citation.call_id) {
                return `/calls/${citation.call_id}`
            }
            return null
        case DocumentType.COMPANY_NOTES:
            if (citation.company_id) {
                return `/companies/${citation.company_id}`
            }
            return null
        default:
            return null
    }
}
