import { useState } from "react"
import { IParticipant } from "../../types/Call"
import { Participant } from "../call-prep/Participants"
import { SimpleCard } from "../common/SimpleCard"

const MAX_SHOW = 5

interface PeopleCardProps {
    people: IParticipant[]
    caption: string
}

export function PeopleCard(props: PeopleCardProps) {
    const [showAll, setShowAll] = useState(false)
    const toggleShowAll = () => setShowAll(!showAll)

    if (props.people.length === 0) return null

    return (
        <SimpleCard className="p-3">
            <div className="flex w-full flex-col space-y-6">
                <div className="text-base font-semibold leading-normal text-gray-900">
                    {props.caption}
                </div>
                {props.people
                    .slice(0, showAll ? props.people.length : MAX_SHOW)
                    .map((p) => (
                        <Participant key={p.email || p.name} participant={p} />
                    ))}
                {props.people.length > MAX_SHOW && (
                    <div
                        className="cursor-pointer text-gray-500"
                        onClick={toggleShowAll}
                    >
                        {showAll
                            ? "Show Less"
                            : `+ ${props.people.length - MAX_SHOW} more`}
                    </div>
                )}
            </div>
        </SimpleCard>
    )
}
