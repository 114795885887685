import clsx from "clsx"
import { Link } from "react-router-dom"
import { UpdateTaskMutation } from "../../../api/mutations/tasks"
import { CompanyLogo } from "../../company-view/CompanyLogo"
import { Task } from "../types/Task"
import { TaskCompleteButton } from "./TaskCompleteButton"
import { TaskDescription } from "./TaskDescription"
import { TaskDueDate, TaskDueDateEditor } from "./TaskDueDate"
import { ExpandedTaskView } from "./ExpandedTaskView"

export interface TaskUpdateProps {
    isUpdating?: boolean
    updateTask?: UpdateTaskMutation
}

export interface TaskItemProps<T extends Task = Task> extends TaskUpdateProps {
    task: T
    showDueDate?: boolean
    onTaskClick: () => void
    isExpanded: boolean
}

export function TaskItem(props: TaskItemProps) {
    const { task, updateTask, isUpdating } = props
    const isCompleted = task.completed_at_utc !== null

    return (
        <div className="flex w-full flex-col">
            <div
                className={clsx(
                    "group flex w-full max-w-full flex-col items-start justify-between gap-x-4 overflow-hidden",
                    "cursor-pointer rounded-lg p-2 hover:bg-gray-50",
                    "md:flex-row md:items-center",
                    "transition-all duration-200"
                )}
                onClick={props.onTaskClick}
            >
                <div className="flex min-w-0 max-w-full flex-1 flex-shrink-0 flex-row items-center gap-2">
                    <div className="flex min-w-0 flex-row items-center gap-2">
                        <TaskCompleteButton
                            taskId={task.id}
                            isCompleted={isCompleted}
                            updateTask={updateTask}
                            isUpdating={isUpdating}
                        />
                        <TaskDescription
                            isExpanded={props.isExpanded}
                            description={task.description}
                            isCompleted={isCompleted}
                        />
                    </div>
                </div>
                <div className="ml-6 flex flex-shrink-0 flex-row items-center gap-2 md:ml-0">
                    <CompanyLogos task={task} />
                    {props.showDueDate && task.due_date && (
                        <div className="w-24 items-center whitespace-nowrap text-sm">
                            <TaskDueDateEditor
                                taskId={task.id}
                                date={task.due_date}
                                updateTask={updateTask}
                            >
                                <TaskDueDate dueDateString={task.due_date} />
                            </TaskDueDateEditor>
                        </div>
                    )}
                </div>
            </div>
            <div
                className={clsx(
                    "grid transition-all duration-200 ease-in-out",
                    props.isExpanded ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
                )}
            >
                <div className="overflow-hidden">
                    <ExpandedTaskView
                        task={props.task}
                        updateTask={props.updateTask}
                    />
                </div>
            </div>
        </div>
    )
}

export function CompanyLogos(props: { task: Task }) {
    return (
        <div className="flex flex-row items-center">
            {props.task.company_info.map((company) => (
                <Link
                    key={company.domain}
                    to={`/companies/${company.domain}`}
                    className="scale-100 p-[1px] transition-transform hover:scale-110"
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content={company.name || company.domain}
                >
                    <CompanyLogo
                        image_url={company.image_url}
                        className="h-6 w-6 text-xs"
                    />
                </Link>
            ))}
        </div>
    )
}
