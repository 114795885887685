import { Link } from "react-router-dom"
import logo from "../../assets/logomark_sun_logotype_cotton.svg"
import { ReactNode } from "react"

export function AboutWrapper(props: { title: string; children: ReactNode }) {
    return (
        <div className="flex min-h-screen flex-col items-center space-y-10 bg-delta p-16 text-white">
            <AboutHeader />
            <div className="space-y-5">
                <h1 className="text-3xl font-bold">{props.title}</h1>
                {props.children}
            </div>
            <Link to="/about" className="underline">
                Home
            </Link>
        </div>
    )
}

export function AboutHeader() {
    return (
        <div className="space-y-4 text-center">
            <img src={logo} alt="Logo" className="mx-auto h-12" />
        </div>
    )
}
