import { useQuery, useQueryClient } from "@tanstack/react-query"
import { queries } from "../../api/queries"
import Select from "react-select"
import { CrmSettings } from "../../types/Settings"
import { useRef, useState } from "react"
import { SecondaryButton } from "../common/Buttons"
import axios from "axios"
import { NotificationType } from "../common/Notifcations"
import { CircleLoadingSpinner } from "../common/CircleLoadingSpinner"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { useNotification } from "../../providers/NotificationProvider"

export function CrmDealPipelineFilter(props: { settings: CrmSettings }) {
    return (
        <div>
            <span className="font-semibold">Visible Deal Pipelines</span>
            <p className="pb-2 text-sm text-gray-500">
                Select which CRM pipelines you want to see in Glyphic. Or
                deselect all to show all pipelines.
            </p>
            <PipelineOptions
                enabled_deal_pipelines={
                    props.settings.enabled_deal_pipelines || []
                }
            />
        </div>
    )
}

function PipelineOptions(props: { enabled_deal_pipelines: string[] }) {
    const [enabledPipelineIds, setEnabledPipelineIds] = useState<string[]>(
        props.enabled_deal_pipelines
    )
    const [saving, setSaving] = useState(false)
    const [saved, setSaved] = useState(false)
    const timeoutId = useRef<NodeJS.Timeout | null>(null)
    const { addNotification } = useNotification()
    const queryClient = useQueryClient()

    const {
        data: dealPipelines,
        isPending,
        isError,
    } = useQuery(queries.crm.dealPipelines(false))

    if (isPending) {
        return <div className="h-10 w-96 animate-pulse rounded bg-gray-200" />
    }

    if (isError) {
        return (
            <div className="flex h-10 w-96 items-center rounded-lg bg-red-50 px-2 text-red-500">
                ⚠️ Error loading deal pipelines
            </div>
        )
    }

    const handleSave = async () => {
        if (saving) return
        setSaving(true)
        setSaved(false)

        try {
            await axios.put(
                `${process.env.REACT_APP_API_DOMAIN}/organization/settings/crm/enabled_deal_pipelines`,
                { deal_pipeline_ids: enabledPipelineIds || null }
            )
            setSaved(true)
            timeoutId.current = setTimeout(() => {
                setSaved(false)
            }, 3000)
            queryClient.invalidateQueries({
                queryKey: queries.organization.settings().queryKey,
            })
        } catch (error) {
            const errorMessage =
                (axios.isAxiosError(error) && error.response?.data["detail"]) ||
                ""
            addNotification(
                "Failed to update deal pipelines.",
                errorMessage,
                NotificationType.Error
            )
        } finally {
            setSaving(false)
            if (timeoutId.current) {
                clearTimeout(timeoutId.current)
            }
        }
    }

    const options = dealPipelines?.map((pipeline) => ({
        label: pipeline.name,
        value: pipeline.crm_id,
    }))

    return (
        <div className="flex items-center gap-4">
            <Select
                className="w-96"
                options={options}
                value={options.filter((option) =>
                    enabledPipelineIds.includes(option.value)
                )}
                onChange={(selectedOptions) => {
                    setEnabledPipelineIds(
                        selectedOptions.map((option) => option.value)
                    )
                }}
                placeholder="All pipelines visible"
                isMulti={true}
            />
            <SecondaryButton className="h-full w-24" onClick={handleSave}>
                Save
            </SecondaryButton>
            {saving && <CircleLoadingSpinner className="text-emerald-500" />}
            {saved && (
                <FontAwesomeIcon icon={faCheck} className="text-emerald-500" />
            )}
        </div>
    )
}
