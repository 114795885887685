import { useState } from "react"
import colors from "tailwindcss/colors"
import {
    CompanySummarySection,
    ICompanyAnnotationRubricResults,
} from "../../types/Company"
import {
    annotationTypes,
    obstaclesTagNames,
} from "../../utils/getAnnotationSections"
import { SimpleCard } from "../common/SimpleCard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import clsx from "clsx"
import {
    StatusCircleEmpty,
    StatusCircleFull,
    StatusCircleHalf,
    StatusCircleThreeQuarters,
} from "../../assets/icons/rubric/StatusCircles"
import _ from "lodash"
import { getFormattedDateTime } from "../../utils/datetime"
import {
    ObstaclesConcerns,
    ObstaclesMajor,
    ObstaclesNoIssues,
    ObstaclesRisk,
} from "../../assets/icons/rubric/ObstacleCircles"
import { SectionTasks } from "./SectionTasks"

export function Summary(props: {
    updatedAt: string | null
    summary: CompanySummarySection[]
    companyDomain: string
}) {
    const tagToSummary = _.keyBy(props.summary, "tag")

    const updatedAtStr = props.updatedAt
        ? getFormattedDateTime(props.updatedAt, true)
        : null

    return (
        <div>
            <div className="space-y-4">
                <SectionTasks companyDomain={props.companyDomain} />
                {annotationTypes.map((supportedTag) => {
                    const summary = tagToSummary[supportedTag.tag]

                    if (!summary) return null

                    if (!summary.qualification_rubric_score) {
                        return (
                            <SummarySection
                                key={supportedTag.tag}
                                tag={supportedTag.tag}
                                label={supportedTag.label}
                                annotations={summary.annotations}
                            />
                        )
                    }

                    return (
                        <ScoredSummarySection
                            key={supportedTag.tag}
                            tag={supportedTag.tag}
                            label={supportedTag.label}
                            annotations={summary.annotations}
                            qualification_rubric_score={
                                summary.qualification_rubric_score
                            }
                        />
                    )
                })}
            </div>
            {updatedAtStr && (
                <div className="px-1 py-3 text-sm text-gray-500">
                    Overview is updated after every call. Last update:{" "}
                    {updatedAtStr}
                </div>
            )}
        </div>
    )
}

function SummarySection(props: {
    tag: string
    label: string
    annotations: string[]
}) {
    // Annotation without rubric scoring
    return (
        <SimpleCard className="p-3">
            <div className="flex w-full flex-col items-start text-base">
                <h3 className="text-base font-bold text-gray-900">
                    {props.label}
                </h3>
                <SectionAnnotations annotations={props.annotations} />
            </div>
        </SimpleCard>
    )
}

function ScoredSummarySection(props: {
    tag: string
    label: string
    annotations: string[]
    qualification_rubric_score: ICompanyAnnotationRubricResults
}) {
    // Annotation with rubric scoring
    const [showAnnotations, setShowAnnotations] = useState(false)

    return (
        <SimpleCard>
            <div className="flex w-full flex-col items-start text-base">
                <button
                    className="grid w-full grid-cols-10 rounded-lg p-3 text-left text-sm transition-colors hover:bg-gray-100"
                    onClick={() => setShowAnnotations((prev) => !prev)}
                >
                    <h3 className="col-span-2 font-bold leading-7 text-gray-900">
                        {props.label}
                    </h3>
                    <span className="col-span-5">
                        {props.qualification_rubric_score.feedback_headline}
                    </span>
                    <div className="col-span-3 flex justify-end space-x-8">
                        <StatusIndicator
                            score={props.qualification_rubric_score.score}
                            tag={props.tag}
                        />
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            className={clsx(
                                "text-gray-400 transition-transform",
                                showAnnotations && "rotate-180"
                            )}
                        />
                    </div>
                </button>
                {showAnnotations && (
                    <SectionAnnotations
                        annotations={props.annotations}
                        feedback={
                            props.qualification_rubric_score.score_explanation
                        }
                        improvement_suggestions={
                            props.qualification_rubric_score
                                .improvement_suggestions
                        }
                    />
                )}
            </div>
        </SimpleCard>
    )
}

function SectionAnnotations(props: {
    annotations: string[]
    feedback?: string | null
    improvement_suggestions?: string | null
}) {
    const lines = props.annotations.flatMap((annotation) =>
        annotation.split("\n").filter((line) => line.trim() !== "")
    )
    return (
        <div className="w-full space-y-3 p-3 text-sm">
            {lines.map((line, idx) => (
                <div
                    className="flex grow flex-row gap-3"
                    key={`${idx}:${line}`}
                >
                    <div className="w-1 rounded-lg bg-sun"></div>
                    <span className="flex flex-1 flex-col whitespace-pre-wrap font-normal leading-normal text-gray-900">
                        {line}
                    </span>
                </div>
            ))}
            {props.feedback && (
                <div className="flex flex-col gap-2 rounded-lg bg-gray-100 p-2">
                    <span className="font-semibold">Feedback</span>
                    <span>{props.feedback}</span>
                </div>
            )}
            {props.improvement_suggestions && (
                <div className="flex flex-col gap-2 rounded-lg bg-gray-100 p-2">
                    <span className="font-semibold">
                        Improvement Suggestion
                    </span>
                    <span>{props.improvement_suggestions}</span>
                </div>
            )}
        </div>
    )
}

interface IRubricStatus {
    text: string
    color: (typeof colors)[keyof typeof colors]
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
}

const statusScoreMap: { [key: number]: IRubricStatus } = {
    1: {
        text: "Insufficient",
        color: colors.red,
        icon: (props) => <StatusCircleEmpty {...props} />,
    },
    2: {
        text: "Incomplete",
        color: colors.yellow,
        icon: (props) => <StatusCircleHalf {...props} />,
    },
    3: {
        text: "Adequate",
        color: colors.lime,
        icon: (props) => <StatusCircleThreeQuarters {...props} />,
    },
    4: {
        text: "Thorough",
        color: colors.green,
        icon: (props) => <StatusCircleFull {...props} />,
    },
}

export const obstacleScoreMap: { [key: number]: IRubricStatus } = {
    1: {
        text: "Deal at Risk",
        color: colors.red,
        icon: (props) => <ObstaclesRisk {...props} />,
    },
    2: {
        text: "Major Obstacles",
        color: colors.yellow,
        icon: (props) => <ObstaclesMajor {...props} />,
    },
    3: {
        text: "Minor concerns",
        color: colors.lime,
        icon: (props) => <ObstaclesConcerns {...props} />,
    },
    4: {
        text: "No issues",
        color: colors.green,
        icon: (props) => <ObstaclesNoIssues {...props} />,
    },
}

function StatusIndicator(props: { score: number; tag: string }) {
    const status = statusScoreMap[props.score] || statusScoreMap[1]

    return (
        <div className="flex flex-nowrap items-center gap-2">
            <span
                className="hidden text-right font-semibold md:block"
                style={{ color: status.color[500] }}
            >
                {status.text}
            </span>
            <StatusIcon score={props.score} tag={props.tag} />
        </div>
    )
}

export function StatusIcon(props: { score: number; tag: string }) {
    const status = getStatus(props.score, props.tag)

    return (
        <status.icon
            className="h-4 w-4 flex-shrink-0"
            style={{ color: status.color[500] }}
            {...props}
        />
    )
}

function getStatus(score: number, tag: string): IRubricStatus {
    const scoreMap = obstaclesTagNames.has(tag)
        ? obstacleScoreMap
        : statusScoreMap
    return scoreMap[score] || scoreMap[1]
}
