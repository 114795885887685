import { SecondaryButton } from "../common/Buttons"
import { useState } from "react"
import axios from "axios"
import { VideoOnIcon } from "./VideoOnIcon"
import { VideoOffIcon } from "./VideoOffIcon"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { CircleLoadingSpinner } from "../common/CircleLoadingSpinner"
import { getHeapInstance } from "../../utils/heap"
import clsx from "clsx"
import {
    IUpcomingCall,
    RECALL_API_MISSING_INFORMATION_WILL_RECORD_REASON,
} from "../../types/UpcomingCall"
import { DateTime } from "luxon"
import { getLocalDateTime } from "../../utils/datetime"

export function OptionalUpcomingCallRecordButton(props: {
    call: IUpcomingCall
}) {
    // Shows UpcomingCallRecordButton if the meeting has not yet started

    const cannotRecord =
        props.call.will_record === false &&
        props.call.will_record_reason ===
            RECALL_API_MISSING_INFORMATION_WILL_RECORD_REASON

    const now = DateTime.local()
    const isNow =
        now >= getLocalDateTime(props.call.start_time) &&
        now <= getLocalDateTime(props.call.end_time)

    if (isNow) {
        return null
    }

    return (
        <UpcomingCallRecordButton
            callId={props.call.id}
            will_record={props.call.will_record}
            disabled={cannotRecord}
        />
    )
}

export function UpcomingCallRecordButton(props: {
    callId: string
    will_record: boolean
    disabled?: boolean
}) {
    const [willRecord, setWillRecord] = useState<boolean>(props.will_record)
    const { addNotification } = useNotification()
    const [loading, setLoading] = useState<boolean>(false)

    const handleToggle = async () => {
        try {
            setLoading(true)
            const { data } = await axios.put(
                process.env.REACT_APP_API_DOMAIN +
                    "/call_bot/calendar/meetings/" +
                    props.callId,
                {
                    should_record: !willRecord,
                }
            )
            getHeapInstance()?.track("call_recording_toggled", {
                meeting_id: props.callId,
                will_record: !willRecord,
            })
            setWillRecord(data.will_record)
        } catch (e) {
            addNotification(
                "Failed to change recording preference",
                `${e}`,
                NotificationType.Error
            )
        } finally {
            setLoading(false)
        }
    }

    const color = willRecord ? "bg-emerald-500" : "bg-red-500"
    const enabledTooltip = willRecord
        ? "Disable recording for this call"
        : "Enable recording for this call"

    let buttonContent = (
        <>
            <div className={`${color} h-2 w-2 rounded-full`} />
            {willRecord ? (
                <VideoOnIcon className="h-5 w-5" />
            ) : (
                <VideoOffIcon
                    className={clsx("h-5 w-5", props.disabled && "opacity-50")}
                />
            )}
        </>
    )
    if (loading) {
        buttonContent = <CircleLoadingSpinner className="mx-auto h-2 w-2" />
    }

    return (
        <div
            data-tooltip-id="tooltip-explanation"
            data-tooltip-content={
                props.disabled
                    ? "No meeting URL. Add one in your calendar to enable recording"
                    : enabledTooltip
            }
        >
            <SecondaryButton
                className="flex min-w-[60px] flex-row items-center gap-2"
                onClick={handleToggle}
                disabled={props.disabled}
            >
                {buttonContent}
            </SecondaryButton>
        </div>
    )
}
