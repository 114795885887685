import { useState, useCallback, useMemo } from "react"
import { pipeResponse } from "../utils/pipeResponse"
import { MessageBlock, Role, Thread, Turn } from "../types/ThreadTypes"
import axios from "axios"
import { ISearchFilters } from "../../../types/search"
import {
    closeLastBlock,
    parseFullTextToBlocks,
    updateBlocksWithDelta,
} from "../utils/blocks/parseMessageBlocks"

interface RawMessage {
    role: Role
    content: string
}

interface ServerThread {
    id: string
    messages: RawMessage[]
    created_at_utc: string
    filters: ISearchFilters
    crm_deal_id_filter: string | null
}

interface ThreadHook {
    getThreads: () => Promise<Thread[]>
    createThread: (body?: Record<string, any>) => Promise<Thread>
    rerunThread: (threadId: string) => Promise<void>
    sendMessage: (threadId: string, message: string) => Promise<void>
    isLoading: boolean
    responseBlocks: MessageBlock[]
    clearResponse: () => void
}

interface UseThreadOptions {
    resourceType?: string
    resourceId?: string
    onStreamError?: (error: Error) => void
}

export const useThread = ({
    resourceType,
    resourceId,
    onStreamError,
}: UseThreadOptions = {}): ThreadHook => {
    const [isLoading, setIsLoading] = useState(false)
    const [responseBlocks, setResponseBlocks] = useState<MessageBlock[]>([])

    const threadsUrl = useMemo(
        () =>
            resourceType && resourceId
                ? `${process.env.REACT_APP_API_DOMAIN}/${resourceType}/${resourceId}/askglyphic/threads`
                : `${process.env.REACT_APP_API_DOMAIN}/organization/askglyphic/threads`,
        [resourceType, resourceId]
    )

    const getThreads = useCallback(async () => {
        const response = await axios.get(threadsUrl)
        const serverThreads = response.data as ServerThread[]
        return serverThreads.map(convertServerThreadToThread)
    }, [threadsUrl])

    const createThread = useCallback(
        async (body?: Record<string, any>) => {
            const response = await axios.post(threadsUrl, body)
            const serverThread = response.data as ServerThread
            return convertServerThreadToThread(serverThread)
        },
        [threadsUrl]
    )

    const stream = useCallback(
        async (url: string, body?: Record<string, unknown>) => {
            setIsLoading(true)
            setResponseBlocks([])

            try {
                await pipeResponse(
                    url,
                    (delta) => {
                        setResponseBlocks((prev) =>
                            updateBlocksWithDelta(prev, delta)
                        )
                        return true
                    },
                    body
                )
                setResponseBlocks((prev) => closeLastBlock(prev))
            } catch (error) {
                onStreamError?.(error as Error)
            } finally {
                setIsLoading(false)
            }
        },
        [setIsLoading, onStreamError]
    )

    const sendMessage = useCallback(
        async (threadId: string, message: string) => {
            const url = `${threadsUrl}/${threadId}/messages/stream`
            const body = { message }
            await stream(url, body)
        },
        [stream, threadsUrl]
    )

    const rerunThread = useCallback(
        async (threadId: string) => {
            const url = `${threadsUrl}/${threadId}/rerun`
            await stream(url)
        },
        [stream, threadsUrl]
    )

    const clearResponse = useCallback(() => {
        setResponseBlocks([])
    }, [])

    return {
        getThreads,
        createThread,
        sendMessage,
        rerunThread,
        isLoading,
        responseBlocks,
        clearResponse,
    }
}

function convertServerThreadToThread(serverThread: ServerThread): Thread {
    return {
        id: serverThread.id,
        turns: serverThread.messages.map(convertRawMessageToTurn),
        created_at_utc: serverThread.created_at_utc,
        filters: serverThread.filters,
        crm_deal_id_filter: serverThread.crm_deal_id_filter,
    }
}

function convertRawMessageToTurn(rawMessage: RawMessage): Turn {
    /**
     * The server still returns everything as text, so we need to parse and
     * convert to the blocks list.
     */
    return {
        blocks: parseFullTextToBlocks(rawMessage.content),
        role: rawMessage.role,
    }
}
