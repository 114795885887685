import { ISearchFilters, CallsToInclude } from "../../../../../types/search"
import { FiltersMessageBlock } from "../../../types/ThreadTypes"
import { BlockHandler } from "./BaseBlockHandlers"

export class FiltersBlockHandler implements BlockHandler<FiltersMessageBlock> {
    onCreated(): FiltersMessageBlock {
        return {
            type: "filters",
            content: "",
            filters: {},
            closed: false,
        }
    }

    onContent(
        block: FiltersMessageBlock,
        content: string
    ): FiltersMessageBlock {
        const fullContent = (block.content + content).trim()

        const parsedData = JSON.parse(fullContent)

        const filters: ISearchFilters = {
            since_date: parsedData.since_date || null,
            until_date: parsedData.until_date || null,
            users: parsedData.users || [],
            tags: parsedData.tags || [],
            insights: parsedData.insights || [],
            include_calls: parsedData.include_calls || CallsToInclude.ALL,
        }
        return { ...block, content: fullContent, filters }
    }

    onClosed(block: FiltersMessageBlock): FiltersMessageBlock {
        return { ...block, closed: true }
    }
}
