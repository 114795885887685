import _ from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { ICompany } from "../../types/Company"
import { ICompanyInfo, ICompanyProfile } from "../../types/CompanyProfile"
import axios from "axios"
import LoadingSpinner from "../common/LoadingSpinner"
import { ErrorPage, IError } from "../common/ErrorPage"
import { Summary } from "./Summary"
import { TabHead } from "../common/Tabs"
import { CRMDeals } from "./CRMDeals"
import { Timeline } from "./Timeline"
import { PeopleCard } from "./PeopleCard"
import {
    ActivityItem,
    createCallActivity,
    createEmailThreadActivity,
    createPrepSheetActivity,
} from "./Activities"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { EmailListResponse, EmailThread } from "../../types/EmailThread"
import { ActivityList } from "./ActivityList"
import { ResourceType } from "../question-answering/types/ResourceTypes"
import AskGlyphic from "../question-answering/AskGlyphic"
import { sendFollowUpEmail } from "../../utils/createEmailLink"
import { CompanyDetails } from "./CompanyDetails"
import { CompanyNews } from "./CompanyNews"
import { SimpleCard } from "../common/SimpleCard"
import { useQuery } from "@tanstack/react-query"
import { CompanyOptions } from "./CompanyOptions"
import { BackButton } from "../common/NavigationButtons"
import { useSearchParam } from "../common/hooks/useSearchParam"
import { CallStatus } from "../../types/Call"
import { markCompanyAsViewed } from "../home-page/Deals"
import { CustomInsights } from "./CustomInsights"
import { PageTitle } from "../PageTitle"

enum Tabs {
    Overview = "Overview",
    Activity = "Activity",
    Insights = "Insights",
}
type Tab = { type: Tabs; label: string }

export const ACTIVE_TAB_PARAM = "tab"

export function Company() {
    const navigate = useNavigate()
    const { addNotification } = useNotification()
    const params = useParams()
    const location = useLocation()
    const domain: string = params.domain as string
    const [company, setCompany] = useState<ICompany | null>(null)
    const [emailThreads, setEmailThreads] = useState<EmailThread[]>([])
    const [companyLoadError, setCompanyLoadError] = useState<
        IError | undefined
    >()
    const [tabs, setTabs] = useState<Tab[]>([])
    const [activeTabParam, setActiveTabParam] =
        useSearchParam(ACTIVE_TAB_PARAM) || Tabs.Overview.toString()

    const [activeTab, activeTabIndex] = useMemo(() => {
        const tabIndex = tabs.findIndex(
            (tab) => tab.type.toLowerCase() === activeTabParam?.toLowerCase()
        )

        if (tabIndex === -1) {
            return [tabs[0], 0]
        }

        return [tabs[tabIndex], tabIndex]
    }, [tabs, activeTabParam])

    useEffect(() => {
        async function getCompany() {
            if (!domain) return
            try {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_API_DOMAIN}/companies/${domain}`
                )
                setCompany(data)
            } catch (error) {
                let errorCode: number | undefined
                let message: string | undefined
                if (axios.isAxiosError(error)) {
                    errorCode = error.response?.status
                    message = error.response?.statusText
                }
                setCompanyLoadError({
                    code: errorCode,
                    message: message,
                })
            }
        }
        getCompany()
    }, [domain])

    const {
        data: companyProfile,
        isPending: isProfilePending,
        isError: isProfileError,
    } = useQuery<ICompanyProfile>({
        queryKey: ["companies", domain, "profile"],
        queryFn: async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/companies/${domain}/profile`
            )
            return response.data
        },
        staleTime: Infinity,
    })

    useEffect(() => {
        async function getEmailThreads() {
            try {
                const { data }: { data: EmailListResponse } = await axios.get(
                    `${process.env.REACT_APP_API_DOMAIN}/companies/${domain}` +
                        "/crm/emails"
                )

                setEmailThreads(data.email_threads)
            } catch (error) {
                let message: string | undefined
                if (axios.isAxiosError(error)) {
                    const errorCode = error.response?.status || -1
                    if (errorCode >= 500) {
                        message = error.response?.statusText ?? "Unknown error"
                        addNotification(
                            "Failed to load emails!",
                            message,
                            NotificationType.Error
                        )
                    }
                }
            }
        }

        if (domain) {
            getEmailThreads()
        }
    }, [addNotification, domain])

    useEffect(() => {
        async function setAvailableTabs() {
            if (!company) return

            let availableTabs: Tab[] = []
            if (company.summary !== null) {
                availableTabs.push({
                    type: Tabs.Overview,
                    label: Tabs.Overview.toString(),
                })
                availableTabs.push({
                    type: Tabs.Insights,
                    label: Tabs.Insights.toString(),
                })
            }
            if (
                company.calls.length > 0 ||
                company.prep_sheets.length > 0 ||
                emailThreads.length
            ) {
                availableTabs.push({
                    type: Tabs.Activity,
                    label: Tabs.Activity.toString(),
                })
            }
            setTabs(availableTabs)
        }
        setAvailableTabs()
    }, [company, emailThreads])

    useEffect(() => {
        markCompanyAsViewed(domain)
    }, [domain])

    const companyName = useMemo(
        () => company?.name || company?.domain,
        [company]
    )

    const futureActivities = useMemo(() => {
        if (!company?.prep_sheets?.length) return []

        return company.prep_sheets.map(createPrepSheetActivity)
    }, [company])

    const pastActivities = useMemo(() => {
        const callActivities = company?.calls?.map(createCallActivity) ?? []
        const emailActivities =
            emailThreads
                .filter((thread) => thread.messages.length > 0)
                .map(createEmailThreadActivity) ?? []

        const activities = _.concat<ActivityItem>(
            callActivities,
            emailActivities
        )

        const sorted = _.sortBy(activities, "date")
        return sorted.reverse()
    }, [company, emailThreads])

    const sendEmail = useCallback(async (message: string) => {
        await sendFollowUpEmail(message)
    }, [])

    const clearQueryAndHash = () => {
        // Clear any URL query params or hash link
        navigate(location.pathname, { replace: true })
    }

    if (isProfileError) {
        addNotification(
            "Failed to load company profile",
            "",
            NotificationType.Error
        )
    }

    if (!!companyLoadError) {
        return <ErrorPage error={companyLoadError} />
    }

    if (!company) return <LoadingSpinner />

    const companyInfo = getCompanyInfo(companyProfile)

    const hasPreviousPage = window.history.length > 1
    const completedCalls = company.calls
        .filter((call) => call.status === CallStatus.Completed)
        .filter((call) => call.is_private !== true)
    const showAskGlyphic = completedCalls.length > 0 || emailThreads.length > 0
    return (
        <div>
            {companyName && <PageTitle title={companyName} />}
            {hasPreviousPage && (
                <div className="flex flex-row items-center justify-between space-x-2 border-b bg-white px-6 py-2 md:h-12">
                    <BackButton
                        defaultCaption="Companies"
                        defaultTo="/companies"
                    />
                    <CompanyOptions company={company} />
                </div>
            )}
            <div className="mx-auto max-w-7xl space-y-4 bg-gray-50 p-8">
                <CompanyDetails
                    info={companyInfo}
                    isLoading={isProfilePending}
                />
                <div className="flex flex-col-reverse gap-4 lg:flex-row">
                    <div className="min-w-0 flex-grow space-y-3">
                        <CRMDeals companyDomain={company.domain} />
                        {company.short_summary && (
                            <ShortSummary summary={company.short_summary} />
                        )}
                        {showAskGlyphic && (
                            <AskGlyphic
                                resourceId={company.domain}
                                type={ResourceType.Companies}
                                sendEmail={sendEmail}
                            />
                        )}
                        <Timeline
                            company={company}
                            emailThreads={emailThreads}
                        />
                        {tabs.length > 0 ? (
                            <>
                                <div className="w-fit">
                                    <TabHead
                                        tabs={tabs}
                                        activeTab={activeTabIndex}
                                        onTabChange={(index) => {
                                            clearQueryAndHash()
                                            setActiveTabParam(
                                                tabs[index].type
                                                    .toString()
                                                    .toLowerCase()
                                            )
                                        }}
                                    />
                                </div>
                                {activeTab.type === Tabs.Overview && (
                                    <Summary
                                        updatedAt={company.updated_at}
                                        summary={company.summary!}
                                        companyDomain={company.domain}
                                    />
                                )}
                                {activeTab.type === Tabs.Activity && (
                                    <ActivityList
                                        future={futureActivities}
                                        past={pastActivities}
                                    />
                                )}
                                {activeTab.type === Tabs.Insights && (
                                    <CustomInsights
                                        companyDomain={company.domain}
                                        companyCrmUrl={companyInfo?.crm_url}
                                    />
                                )}
                            </>
                        ) : (
                            <div className="p-4 text-gray-500">
                                No past or upcoming calls to display.
                            </div>
                        )}
                    </div>
                    <div className="flex-none space-y-3 lg:w-[300px]">
                        <SimpleCard className="min-h-[70px] p-2">
                            <CompanyNews
                                news={companyProfile?.news ?? []}
                                isLoading={isProfilePending}
                            />
                        </SimpleCard>
                        <PeopleCard caption="People" people={company.people} />
                    </div>
                </div>
            </div>
        </div>
    )
}

function ShortSummary(props: { summary: string }) {
    return (
        <SimpleCard className="p-3 text-sm">
            <div>
                <span className="font-bold">AI Summary 🪄: </span>
                <span>{props.summary}</span>
            </div>
        </SimpleCard>
    )
}

export function getCompanyInfo(
    companyProfile: ICompanyProfile | undefined
): ICompanyInfo | null {
    if (!companyProfile) {
        return null
    }

    // Starts with the crunchbase values (fallback) and overrides with existing
    // CRM values. We have to pickBy to remove null values (otherwise these
    // would override the crunchbase values).
    const companyInfo = {
        ...companyProfile.info.crunchbase,
        ..._.pickBy(
            companyProfile.info.crm,
            (value) => !(_.isNull(value) || _.isUndefined(value))
        ),
    } as ICompanyInfo

    // We always prefer crunchbase image url if available
    if (companyProfile.info.crunchbase?.image_url) {
        companyInfo.image_url = companyProfile.info.crunchbase?.image_url
    }

    return companyInfo
}
