import { Link } from "react-router-dom"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { OptionalUpcomingCallRecordButton } from "../schedule/UpcomingCallRecordButton"
import { useQuery } from "@tanstack/react-query"
import { IUpcomingCall } from "../../types/UpcomingCall"
import axios from "axios"
import { DateTime } from "luxon"
import { ICallPrepData } from "../../types/CallPrep"
import { getFormattedDateTime } from "../../utils/datetime"
import { LoadingPulse } from "../common/LoadingPulse"
import { ICompanyProfile } from "../../types/CompanyProfile"
import { Participant } from "../call-prep/Participants"
import { CompanyLogo } from "../company-view/CompanyLogo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faCalendarAlt,
    faChevronDown,
    faCog,
} from "@fortawesome/free-solid-svg-icons"
import { useMemo, useState } from "react"
import { DarkEmptyArea } from "./EmptyArea"
import { useIntegrations } from "../../hooks/useIntegrations"
import allCalendarsLogo from "../../assets/all_calendars.png"
import clsx from "clsx"
import { SendBotButton, isCallNowOrSoon } from "../schedule/UpcomingCalls"
import { getCompanyInfo } from "../company-view/Company"

export function UpcomingCalls() {
    const { hasCalendar, isPending: isIntegrationsPending } = useIntegrations()

    const now = useMemo(() => DateTime.now(), [])
    const fifteenMinutesAgo = useMemo(() => now.minus({ minutes: 15 }), [now])
    const sevenDaysFromNow = useMemo(() => now.plus({ days: 7 }), [now])
    const {
        data: nextWeeksCalls,
        isError: isUpcomingError,
        isPending: isUpcomingPending,
    } = useQuery<IUpcomingCall[]>({
        queryKey: [
            "call_bot/calendar/meetings",
            { until: sevenDaysFromNow.toISODate() },
        ],
        queryFn: async () => {
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN +
                    "/call_bot/calendar/meetings",
                {
                    params: {
                        after: fifteenMinutesAgo,
                        before: sevenDaysFromNow,
                    },
                }
            )
            return response.data as IUpcomingCall[]
        },
    })

    const nextCallsToDisplay = filterCalls(nextWeeksCalls ?? [])

    if (isUpcomingPending || isIntegrationsPending) {
        return (
            <div>
                <Header />

                <div className="rounded-lg bg-white p-6 shadow-md shadow-gray-200">
                    <LoadingPulse rows={6} />
                </div>
            </div>
        )
    }

    if (!hasCalendar) {
        return (
            <div>
                <Header hasCalendar={hasCalendar} />
                <DarkEmptyArea>
                    <div className="flex items-center gap-6">
                        <img
                            src={allCalendarsLogo}
                            className="hidden h-24 w-24 lg:block"
                            alt="Connect your calendar"
                        />
                        <div className="flex flex-col items-center gap-3">
                            <Link to="/settings/personal">
                                <SecondaryButton className="flex items-center gap-2">
                                    <FontAwesomeIcon icon={faCog} />
                                    <span>Connect your calendar</span>
                                </SecondaryButton>
                            </Link>
                            <span>
                                Connect Glyphic to your Google or Outlook
                                calendar to see your upcoming calls
                            </span>
                        </div>
                    </div>
                </DarkEmptyArea>
            </div>
        )
    }

    if (isUpcomingError || nextCallsToDisplay.length === 0) {
        return (
            <div>
                <Header />
                <DarkEmptyArea>
                    You have no upcoming external calls to prepare for 🏖️
                </DarkEmptyArea>
            </div>
        )
    }

    return (
        <div>
            <Header />
            <div className="space-y-2">
                {nextCallsToDisplay.map((call, idx) => (
                    <UpcomingCall
                        key={call.id}
                        call={call}
                        showFullDefault={idx === 0}
                    />
                ))}
            </div>
        </div>
    )
}

function filterCalls(calls: IUpcomingCall[]) {
    // If the user has many calls today, show the first 10
    // Otherwise, show the first 3 future calls

    const maxTodaysCalls = 5
    const minCalls = 3

    const now = DateTime.now()

    const callsWithPrepSheet = calls.filter(
        (call) => call.prep_sheet_id !== null
    )

    const todaysCalls = callsWithPrepSheet.filter((call) => {
        const callTime = DateTime.fromISO(call.start_time)
        return callTime.hasSame(now, "day")
    })

    if (todaysCalls.length >= minCalls) {
        return todaysCalls.slice(0, maxTodaysCalls)
    }

    return callsWithPrepSheet.slice(0, minCalls)
}

function Header(props: { hasCalendar?: boolean }) {
    const noCalendar = props.hasCalendar === false
    return (
        <div className="mb-3 flex items-center justify-between">
            <h3 className="text-lg font-bold">Upcoming calls</h3>
            <SecondaryButton disabled={noCalendar}>
                <Link
                    to="/schedule"
                    className={clsx(
                        "flex items-center space-x-2 whitespace-nowrap",
                        noCalendar && "pointer-events-none"
                    )}
                >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    <span>View all upcoming calls</span>
                </Link>
            </SecondaryButton>
        </div>
    )
}

function Company({ company }: { company?: ICompanyProfile }) {
    const companyInfo = getCompanyInfo(company)
    if (!companyInfo) {
        return null
    }

    const logo = companyInfo.image_url ?? null
    const name = companyInfo.name

    return (
        <Link
            to={`/companies/${companyInfo.domain}`}
            className="font-semibold"
            data-tooltip-id="tooltip-explanation"
            data-tooltip-content={name}
        >
            <div className="flex items-center space-x-2">
                <CompanyLogo image_url={logo} />
                <div className="font-semibold">{name}</div>
            </div>
        </Link>
    )
}

function UpcomingCall(props: {
    call: IUpcomingCall
    showFullDefault: boolean
}) {
    const call = props.call

    const [showFull, setShowFull] = useState<boolean>(props.showFullDefault)

    const {
        data: prepSheet,
        isPending: isPrepSheetPending,
        isError: isPrepSheetError,
    } = useQuery<ICallPrepData>({
        queryKey: ["call_prep", call?.prep_sheet_id],
        queryFn: async () => {
            if (!call || !call.prep_sheet_id) {
                return null
            }
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN +
                    `/call_prep/${call.prep_sheet_id}`
            )
            return response.data
        },
    })

    if (isPrepSheetPending) {
        return (
            <div className="rounded-lg bg-white p-6 shadow-md shadow-gray-200">
                <LoadingPulse rows={showFull ? 6 : 2} />
            </div>
        )
    }

    if (isPrepSheetError || !prepSheet) {
        return null
    }

    const meetingUrl = prepSheet.call_info.meeting_url

    const colCount =
        prepSheet.suggestions && prepSheet.participants
            ? "grid-cols-2"
            : "grid-cols-1"

    const isNowOrSoon = isCallNowOrSoon(call)
    const showMeetingJoinButtons = isNowOrSoon && meetingUrl

    return (
        <div
            className="cursor-pointer overflow-auto rounded-lg bg-white py-6 shadow-md shadow-gray-200 transition-colors hover:bg-gray-50"
            onClick={() => setShowFull((prev) => !prev)}
        >
            <div className="flex justify-between gap-2 px-6">
                <div
                    className={clsx(
                        "flex w-full items-start justify-between gap-2 xl:items-center",
                        showMeetingJoinButtons
                            ? "flex-col xl:flex-row"
                            : "flex-row"
                    )}
                >
                    <div>
                        <div className="font-semibold">
                            {prepSheet.call_info.title}
                        </div>
                        <div>
                            {getFormattedDateTime(
                                prepSheet.call_info.start_time
                            )}
                        </div>
                    </div>
                    <div className="flex items-end gap-1">
                        {showMeetingJoinButtons && (
                            <PrimaryButton
                                className="h-fit whitespace-nowrap"
                                onClick={() => {
                                    window.open(meetingUrl, "_blank")
                                }}
                            >
                                Join meeting
                            </PrimaryButton>
                        )}
                        {showMeetingJoinButtons && (
                            <SendBotButton
                                meetingId={call.id}
                                meetingUrl={call.meeting_url}
                            />
                        )}
                        <OptionalUpcomingCallRecordButton call={call} />
                    </div>
                </div>

                <FontAwesomeIcon
                    icon={faChevronDown}
                    className={clsx(
                        "text-gray-500 transition-transform",
                        !showFull && "-rotate-90"
                    )}
                />
            </div>
            <div
                className={clsx(
                    "overflow-auto transition-max-height",
                    showFull ? "max-h-[200vh]" : "max-h-0"
                )}
            >
                <hr className="mx-6 my-4 border-gray-200 " />
                <div className={`grid ${colCount} divide-x divide-gray-200`}>
                    {prepSheet.suggestions &&
                        prepSheet.suggestions.length > 0 && (
                            <div className="w-fit px-6">
                                <div className="font-semibold">
                                    Suggested discussion points
                                </div>
                                <ul className="list-inside list-disc">
                                    {prepSheet.suggestions?.map(
                                        (suggestion) => (
                                            <li key={suggestion}>
                                                {suggestion}
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        )}
                    <div className="w-full space-y-4 px-6">
                        <Company company={prepSheet.company} />
                        {prepSheet.participants.map((participant) => (
                            <Participant
                                key={participant.id}
                                participant={participant}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
