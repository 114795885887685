import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IParticipant } from "../../types/Call"
import { getParticipantDisplayName } from "../../utils/getParticipantDisplayName"
import { UserCircle } from "../UserCircle"
import {
    IconDefinition,
    faEarthAmericas,
    faEnvelope,
} from "@fortawesome/free-solid-svg-icons"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { crmIconFromUrl } from "../crm/utils/crmIconFromUrl"
import { LogoLink } from "../common/LogoLink"

export function Participant(props: { participant: IParticipant }) {
    const participant = props.participant
    const name = getParticipantDisplayName(participant)
    return (
        <div className="flex w-full space-x-2 overflow-x-clip">
            <div>
                <UserCircle user={participant} size="40px" />
            </div>

            <div className="flex w-full min-w-0">
                <div className="min-w-0 space-y-1">
                    <div>
                        <div className="overflow-clip text-ellipsis text-lg font-bold">
                            {name}
                        </div>
                        <div className="overflow-clip text-ellipsis text-base">
                            {participant.profile?.job_title}
                        </div>
                    </div>
                    <div>
                        <ParticipantField
                            field={participant.email}
                            icon={faEnvelope}
                        />
                        <ParticipantField
                            field={participant.profile?.location}
                            icon={faEarthAmericas}
                        />
                    </div>
                </div>
            </div>

            {participant.profile?.crm_url && (
                <LogoLink
                    url={participant.profile.crm_url}
                    logo={
                        <FontAwesomeIcon
                            icon={crmIconFromUrl(participant.profile.crm_url)}
                        />
                    }
                />
            )}

            {participant.profile?.linkedin_url && (
                <LogoLink
                    url={participant.profile.linkedin_url}
                    logo={<FontAwesomeIcon icon={faLinkedin} />}
                />
            )}
        </div>
    )
}

function ParticipantField(props: {
    field: string | null | undefined
    icon: IconDefinition
}) {
    if (!props.field) {
        return <></>
    }
    return (
        <div className="flex space-x-1 text-sm text-gray-600">
            <FontAwesomeIcon
                className="mt-[2px] text-gray-400"
                icon={props.icon}
            />
            <span className="overflow-clip text-ellipsis">{props.field}</span>
        </div>
    )
}
