import { ISearchFilters } from "../../../types/search"
import { CitationProperties } from "../../../types/Citation"

export type Role = "human" | "ai"

interface BaseMessageBlock {
    type: string
    content: string
    closed: boolean
}

export interface TextMessageBlock extends BaseMessageBlock {
    type: "text"
}

export function isTextMessageBlock(
    block: MessageBlock
): block is TextMessageBlock {
    return block.type === "text"
}

export function isCitationMessageBlock(
    block: MessageBlock
): block is CitationsMessageBlock {
    return block.type === "citation"
}

export interface CitationsMessageBlock extends BaseMessageBlock {
    type: "citation"
    citation?: CitationProperties
}

export interface FiltersMessageBlock extends BaseMessageBlock {
    type: "filters"
    filters: ISearchFilters
}

export interface LoadingDocsMessageBlock extends BaseMessageBlock {
    type: "loading-docs"
}

export interface MemoryUpdatedMessageBlock extends BaseMessageBlock {
    type: "memory-updated"
}

export interface UnknownMessageBlock extends BaseMessageBlock {
    type: "unknown"
    tag: string
}

export type MessageBlock =
    | TextMessageBlock
    | FiltersMessageBlock
    | CitationsMessageBlock
    | LoadingDocsMessageBlock
    | UnknownMessageBlock
    | MemoryUpdatedMessageBlock

export type DisplayMessageBlock = TextMessageBlock | CitationsMessageBlock

export type MessageBlockType = MessageBlock["type"]

export interface Turn {
    blocks: MessageBlock[]
    role: Role
}

export interface Thread {
    id: string
    turns: Turn[]
    created_at_utc: string
    filters: ISearchFilters
    crm_deal_id_filter: string | null
}
