import { useEffect, useState } from "react"

import logo from "../../assets/logomark_delta.svg"

export default function LoadingSpinner() {
    const [visible, setVisible] = useState(false)

    // Set a timer to transitiion in, so the spinner doesn't flash up if the screen loads quickly
    useEffect(() => {
        const timeout = setTimeout(() => {
            setVisible(true)
        }, 500)
        return () => clearTimeout(timeout)
    }, [])

    return (
        <img
            src={logo}
            alt="loading"
            className={`${
                visible ? "opacity-100" : "opacity-0"
            } absolute bottom-0 left-0 right-0 top-0 m-auto h-8 animate-pulse transition-opacity `}
        />
    )
}
