import { DateTime } from "luxon"
import { IUser } from "../types/User"

/**
/**
 * Converts a UTC time string to the user's timezone
 *
 * If user does not have a timezone, it will default to UTC
 */
export function utcToUserTimezone(
    utcTime: string,
    format: string,
    user: IUser | undefined
) {
    return DateTime.fromFormat(utcTime, format, { zone: "UTC" })
        .setZone(user?.timezone ?? "UTC")
        .toFormat(format)
}

/**
 * Converts from a user's local time to UTC
 *
 * If user does not have a timezone, it will default to UTC
 */
export function userTimezoneToUtc(
    localTime: string,
    format: string,
    user: IUser | undefined
) {
    return DateTime.fromFormat(localTime, format, {
        zone: user?.timezone ?? "UTC",
    })
        .setZone("UTC")
        .toFormat(format)
}
