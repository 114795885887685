import { Link } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { queries } from "../../api/queries"
import { UserCircle } from "../UserCircle"
import { getRelativeToNow } from "../../utils/datetime"

export function RecentMentions() {
    const { data: mentions, isLoading } = useQuery(queries.comments.mentions(5))

    if (isLoading) {
        return (
            <div className="space-y-4">
                <h2 className="text-lg font-bold">Recent Mentions</h2>
                <div className="rounded-xl bg-white p-4 shadow-sm">
                    <p className="text-gray-400">Loading mentions...</p>
                </div>
            </div>
        )
    }

    return (
        <div className="space-y-4">
            <h2 className="text-lg font-bold">Recent Mentions</h2>
            <div className="rounded-xl bg-white p-4 shadow-sm">
                {!mentions || mentions.length === 0 ? (
                    <p className="text-gray-400">
                        💬 No recent mentions to display
                    </p>
                ) : (
                    <div className="space-y-4">
                        {mentions.map((mention) => (
                            <div
                                key={mention.id}
                                className="flex flex-row items-center gap-2"
                            >
                                <UserCircle
                                    user={{
                                        name: mention.author.name,
                                        email: mention.author.email,
                                        profile: undefined,
                                    }}
                                    size="24px"
                                />
                                <div className="flex flex-col items-start gap-1">
                                    <div className="flex flex-row items-center gap-3">
                                        <span className="text-sm text-gray-700">
                                            <strong>
                                                {mention.author.name}
                                            </strong>{" "}
                                            mentioned you on a call
                                        </span>
                                        <span className="text-xs text-gray-500">
                                            {getRelativeToNow(
                                                mention.created_at_utc
                                            )}
                                        </span>
                                    </div>
                                    {mention.call_title && (
                                        <Link
                                            to={`/calls/${
                                                mention.call_id
                                            }?commentId=${
                                                // only top-level comments can be linked to
                                                mention.parent_id || mention.id
                                            }`}
                                            className="inline-block text-xs text-blue-600 hover:text-blue-700 hover:underline"
                                        >
                                            <p className="text-xs font-medium text-blue-500">
                                                {mention.call_title ??
                                                    "Unknown call name"}
                                            </p>
                                        </Link>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}
