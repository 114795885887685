import { QuickActionLibrary } from "../QuickActionLibrary"
import { BaseSettingsPage } from "./BaseSettingsPage"

export function QuickActionsPage() {
    return (
        <BaseSettingsPage pageTitle={"Quick Actions"} alwaysFullWidth>
            <QuickActionLibrary />
        </BaseSettingsPage>
    )
}
