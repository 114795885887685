import { faCalendar, faTag, faUser } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useQuery } from "@tanstack/react-query"
import { DateTime } from "luxon"
import { Dispatch, SetStateAction, useCallback, useMemo } from "react"
import Select, { components } from "react-select"
import colors from "tailwindcss/colors"
import { queries } from "../../api/queries"
import { IOrganizationUser } from "../../types/Organization"
import {
    ISearchFilters,
    ISearchFilterUser,
    ISearchFilterTag,
    ISearchFilterInsight,
} from "../../types/search"
import { getHeapInstance } from "../../utils/heap"
import { IMultiSelectOption, MultiSelectFilter } from "../MultiSelectFilter"
import { stringToColour } from "../../utils/stringToColour"
import { ICallTag } from "../../types/Call"
import {
    CustomInsightsFilter,
    IInsightFilter,
} from "../calls-list/CustomInsightsFilter"

export function SearchFilters({
    searchFilters,
    setSearchFilters,
}: {
    searchFilters: ISearchFilters
    setSearchFilters: Dispatch<SetStateAction<ISearchFilters>>
}) {
    const setSinceDate = useCallback(
        (date: DateTime | undefined) => {
            setSearchFilters((prevFilters) => ({
                ...prevFilters,
                since_date: date?.toISO() || undefined,
            }))
        },
        [setSearchFilters]
    )

    const setUsers = useCallback(
        (users: ISearchFilterUser[]) => {
            setSearchFilters((prevFilters) => ({
                ...prevFilters,
                users: users.length ? users : undefined,
            }))
        },
        [setSearchFilters]
    )

    const setTags = useCallback(
        (tags: ISearchFilterTag[]) => {
            setSearchFilters((prevFilters) => ({
                ...prevFilters,
                tags: tags.length ? tags : undefined,
            }))
        },
        [setSearchFilters]
    )

    const setInsights = useCallback(
        (insights: ISearchFilterInsight[]) => {
            setSearchFilters((prevFilters) => ({
                ...prevFilters,
                insights: insights.length ? insights : undefined,
            }))
        },
        [setSearchFilters]
    )

    return (
        <>
            <PeopleSelect
                selectedUsers={searchFilters.users || []}
                setSelectedUsers={setUsers}
            />
            <DateSelect
                sinceDate={searchFilters.since_date || undefined}
                setSinceDate={setSinceDate}
            />
            <TagFilter
                selectedTags={searchFilters.tags || []}
                setSelectedTags={setTags}
            />
            <CustomInsightsFilterContainer
                selectedInsight={{
                    insightName: searchFilters.insights?.[0].field_name || "",
                    values: searchFilters.insights?.[0].values || [],
                }}
                setSelectedInsight={setInsights}
            />
        </>
    )
}

function PeopleSelect({
    selectedUsers,
    setSelectedUsers,
}: {
    selectedUsers: ISearchFilterUser[]
    setSelectedUsers: (users: ISearchFilterUser[]) => void
}) {
    const { data: users, isPending } = useQuery(queries.users.list())

    // Generate the selected users options for the multi-select filter
    const selectedUserValues = useMemo(() => {
        return (
            users
                ?.filter((user) =>
                    selectedUsers.some(
                        (selectedUser) => selectedUser.id === user.id
                    )
                )
                .map((user) => ({
                    value: user.id,
                    label: user.name || user.email,
                })) || []
        )
    }, [selectedUsers, users])

    const idToUserMap: Map<string, IOrganizationUser> = useMemo(
        () => new Map(users?.map((user) => [user.id, user]) || []),
        [users]
    )

    const userToOption = (user: IOrganizationUser) => ({
        value: user.id,
        label: user.name || user.email,
    })

    const options = useMemo(() => {
        return (
            users
                ?.map(userToOption)
                .sort((a, b) => a.label.localeCompare(b.label)) || []
        )
    }, [users])

    const handleApply = useCallback(
        (selectedOptions: IMultiSelectOption[]) => {
            const selectedUserObjects = selectedOptions
                .map((option) => idToUserMap.get(option.value))
                .filter((user): user is IOrganizationUser => user !== undefined)
            setSelectedUsers(
                selectedUserObjects.map((user) => ({
                    id: user.id,
                    email: user.email,
                }))
            )
        },
        [idToUserMap, setSelectedUsers]
    )

    return (
        <MultiSelectFilter
            title="people"
            icon={<FontAwesomeIcon icon={faUser} className="text-gray-500" />}
            options={options}
            value={selectedUserValues}
            onApply={handleApply}
            isLoading={isPending}
        />
    )
}

const dateOptions = [
    { label: "All time", value: undefined },
    {
        label: "Last 7 days",
        value: DateTime.now().minus({ days: 7 }).startOf("day"),
    },
    {
        label: "Last 30 days",
        value: DateTime.now().minus({ days: 30 }).startOf("day"),
    },
    {
        label: "Last 3 months",
        value: DateTime.now().minus({ months: 3 }).startOf("day"),
    },
    {
        label: "Last 6 months",
        value: DateTime.now().minus({ months: 6 }).startOf("day"),
    },
]

function DateSelect(props: {
    sinceDate: string | undefined
    setSinceDate: (date: DateTime | undefined) => void
}) {
    const sinceOption = useMemo(() => {
        return dateOptions.find(
            (option) => option.value?.toISO() === props.sinceDate
        ) as (typeof dateOptions)[0]
    }, [props.sinceDate])

    return (
        <Select
            options={dateOptions}
            className="min-w-fit whitespace-nowrap text-sm"
            components={{
                Control: DateSelectControl,
            }}
            styles={{
                control: (provided) => ({
                    ...provided,
                    borderColor: colors.gray[200],
                    borderRadius: "0.5rem",
                    boxShadow: "none",
                    "&:hover": {
                        borderColor: colors.gray[300],
                        backgroundColor: colors.gray[50],
                    },
                }),
                menu: (provided: any) => ({
                    ...provided,
                    padding: "0px 4px 0px 4px",
                    borderRadius: "0.5rem",
                }),
                option: (provided: any) => ({
                    ...provided,
                    backgroundColor: "white",
                    color: "black",
                    borderRadius: "0.5rem",
                    "&:hover": {
                        backgroundColor: colors.gray[100],
                    },
                }),
            }}
            onChange={(option) => {
                if (option?.value === undefined) {
                    props.setSinceDate(undefined)
                } else {
                    props.setSinceDate(option?.value)
                }

                getHeapInstance()?.track("search-modal-date-selected", {
                    option: option?.label,
                })
            }}
            value={sinceOption}
            placeholder="All time"
            defaultValue={undefined}
            isSearchable={false}
        />
    )
}

function DateSelectControl({ children, ...rest }: any) {
    return (
        <components.Control {...rest}>
            <FontAwesomeIcon icon={faCalendar} className="ml-2 text-gray-500" />
            {children}
        </components.Control>
    )
}

function TagFilter({
    selectedTags,
    setSelectedTags,
}: {
    selectedTags: ISearchFilterTag[]
    setSelectedTags: (tags: ISearchFilterTag[]) => void
}) {
    const { data: tags, isPending } = useQuery(queries.callTags.list())

    // Generate the selected tags options for the multi-select filter
    const selectedTagValues = useMemo(() => {
        return (
            tags
                ?.filter((tag) =>
                    selectedTags.some(
                        (selectedTag) => selectedTag.id === tag.id
                    )
                )
                .map((tag) => ({
                    value: tag.id,
                    label: tag.name,
                })) || []
        )
    }, [selectedTags, tags])

    const idToTagMap = useMemo(() => {
        return new Map(tags?.map((tag) => [tag.id, tag]) || [])
    }, [tags])

    const handleTagChange = useCallback(
        (newValue: IMultiSelectOption[]) => {
            const selectedTagObjects = newValue
                .map((option) => idToTagMap.get(option.value))
                .filter((tag): tag is ICallTag => tag !== undefined)
            setSelectedTags(
                selectedTagObjects.map((tag) => ({
                    id: tag.id,
                    name: tag.name,
                }))
            )
        },
        [idToTagMap, setSelectedTags]
    )

    const options = useMemo(() => {
        return (
            tags
                ?.map((tag) => ({
                    value: tag.id,
                    label: tag.name,
                    color: stringToColour(tag.name)["400"],
                }))
                .sort((a, b) => a.label.localeCompare(b.label)) || []
        )
    }, [tags])

    return (
        <MultiSelectFilter
            title="Tags"
            icon={<FontAwesomeIcon icon={faTag} className="text-gray-500" />}
            options={options}
            value={selectedTagValues}
            onApply={handleTagChange}
            isLoading={isPending}
            isClearable={true}
            defaultMenuIsOpen={false}
        />
    )
}

function CustomInsightsFilterContainer({
    selectedInsight,
    setSelectedInsight,
}: {
    selectedInsight: IInsightFilter | undefined
    setSelectedInsight: (insight: ISearchFilterInsight[]) => void
}) {
    const onInsightChange = useCallback(
        (insight: IInsightFilter | undefined) => {
            if (insight?.insightName && insight?.values) {
                setSelectedInsight([
                    {
                        field_name: insight?.insightName,
                        values: insight?.values,
                    },
                ])
            } else {
                setSelectedInsight([])
            }
        },
        [setSelectedInsight]
    )

    return (
        <CustomInsightsFilter
            onInsightChange={onInsightChange}
            selectedInsight={selectedInsight}
            defaultMenuIsOpen={false}
        />
    )
}
