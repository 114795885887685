import { Task } from "./types/Task"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { taskQueries } from "../../api/queries/tasks"
import LoadingSpinner from "../common/LoadingSpinner"
import { useUpdateTask, useCreateTask } from "../../api/mutations/tasks"
import { useEffect, useMemo, useState } from "react"
import clsx from "clsx"
import { DateTime } from "luxon"
import { ErrorPage } from "../common/ErrorPage"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faStar,
    faBell,
    faCalendarDays,
    faLayerGroup,
    faBook,
    faPlus,
    faClock,
} from "@fortawesome/pro-solid-svg-icons"
import { getFormattedDate } from "../../utils/datetime"
import { TaskListView } from "./TaskListView"
import { useSearchParams } from "react-router-dom"
import { categorizeTasks } from "../../utils/taskCategories"
import { TaskUpdateProps } from "./items/TaskItem"
import { TaskItem, TaskItemProps } from "./items/TaskItem"
import { CreateTaskModal } from "./modals/CreateTaskModal"
import { HashLink } from "react-router-hash-link"

type TabType = "today" | "overdue" | "upcoming" | "unscheduled" | "completed"

interface Tab {
    id: TabType
    label: string
    icon: JSX.Element
    pillStyle: {
        background: string
        text: string
    }
}

const TABS: Tab[] = [
    {
        id: "today",
        label: "Today",
        icon: <FontAwesomeIcon icon={faStar} className="text-yellow-500" />,
        pillStyle: {
            background: "bg-gray-100",
            text: "text-gray-600",
        },
    },
    {
        id: "overdue",
        label: "Overdue",
        icon: <FontAwesomeIcon icon={faBell} className="text-red-500" />,
        pillStyle: {
            background: "bg-red-100",
            text: "text-red-600",
        },
    },
    {
        id: "upcoming",
        label: "Upcoming",
        icon: (
            <FontAwesomeIcon icon={faCalendarDays} className="text-blue-600" />
        ),
        pillStyle: {
            background: "bg-gray-100",
            text: "text-gray-600",
        },
    },
    {
        id: "unscheduled",
        label: "Unscheduled",
        icon: <FontAwesomeIcon icon={faLayerGroup} className="text-cyan-500" />,
        pillStyle: {
            background: "bg-gray-100",
            text: "text-gray-600",
        },
    },
    {
        id: "completed",
        label: "Completed",
        icon: <FontAwesomeIcon icon={faBook} className="text-green-500" />,
        pillStyle: {
            background: "bg-gray-100",
            text: "text-gray-600",
        },
    },
]

interface TaskCounts {
    today: number
    overdue: number
    upcoming: number
    unscheduled: number
    completed: number
}

function TabNavigation(props: {
    activeTab: TabType
    onTabChange: (tab: TabType) => void
    taskCounts: TaskCounts
}) {
    const getCountDisplay = (count: number) => {
        console.assert(count >= 0, "Count must be non-negative")
        if (count === 0) return null
        if (count > 999) return "∞"
        return count
    }

    return (
        <div className="flex w-full items-center justify-center gap-2 overflow-x-auto border-b p-2 text-lg md:w-fit md:flex-col md:items-start md:justify-start md:border-b-0">
            {TABS.map((tab) => {
                const count = getCountDisplay(props.taskCounts[tab.id])
                return (
                    <button
                        key={tab.id}
                        onClick={() => props.onTabChange(tab.id)}
                        className={clsx(
                            "flex items-center justify-start gap-2 whitespace-nowrap rounded-lg px-4 py-2 transition-all md:w-48",
                            props.activeTab === tab.id
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-500 hover:text-gray-700"
                        )}
                    >
                        <span className="flex w-6 flex-shrink-0 items-center justify-center">
                            {tab.icon}
                        </span>
                        <span className="flex flex-1 items-center justify-between">
                            <span className="hidden md:inline">
                                {tab.label}
                            </span>
                            {count !== null && (
                                <span
                                    className={clsx(
                                        "ml-2 rounded-full px-2 py-0.5 text-xs",
                                        tab.pillStyle.background,
                                        tab.pillStyle.text
                                    )}
                                >
                                    {count}
                                </span>
                            )}
                        </span>
                    </button>
                )
            })}
            <HashLink
                smooth
                replace // Don't add to browser history
                to={"/settings/personal#task-reminders"}
                className="ml-2 mt-16  hidden items-center justify-start gap-2 whitespace-nowrap rounded-lg px-4 py-2 text-gray-500 transition-all hover:text-gray-700 md:flex md:w-48"
            >
                <FontAwesomeIcon icon={faClock} />

                <div className="flex w-full justify-between">
                    <span>Reminders</span>
                </div>
            </HashLink>
        </div>
    )
}

function TaskListContainer(
    props: {
        activeTab: TabType
        dueToday: Task[]
        overdue: Task[]
        upcoming: Task[]
        unscheduled: Task[]
        completed: Task[]
    } & TaskUpdateProps
) {
    const taskProps = {
        isUpdating: props.isUpdating,
        updateTask: props.updateTask,
    }

    const renderActiveTaskList = () => {
        switch (props.activeTab) {
            case "today":
                return <TodayTasks tasks={props.dueToday} {...taskProps} />
            case "overdue":
                return <OverdueTasks tasks={props.overdue} {...taskProps} />
            case "upcoming":
                return <UpcomingTasks tasks={props.upcoming} {...taskProps} />
            case "unscheduled":
                return (
                    <UnscheduledTasks
                        tasks={props.unscheduled}
                        {...taskProps}
                    />
                )
            case "completed":
                return <CompletedTasks tasks={props.completed} {...taskProps} />
        }
    }

    return (
        <div className="h-full space-y-10 overflow-y-auto p-4 md:col-span-6 md:p-16 lg:col-span-5">
            {renderActiveTaskList()}
        </div>
    )
}

export function TasksPage() {
    const [searchParams, setSearchParams] = useSearchParams()
    const activeTab = (searchParams.get("tab") as TabType) || "today"

    useEffect(() => {
        if (!searchParams.get("tab")) {
            setSearchParams({ tab: "today" })
        }
    }, [searchParams, setSearchParams])

    const handleTabChange = (tab: TabType) => {
        setSearchParams({ tab })
    }

    const queryClient = useQueryClient()
    // Invalidate task count queries when refetching tasks
    // to ensure the Tasks count badge in the navbar is up-to-date
    queryClient.invalidateQueries(taskQueries.count())

    const {
        data: tasks,
        isError,
        isPending: isLoadingTasks,
    } = useQuery({ ...taskQueries.list() })

    const { mutate: updateTask, isPending: isUpdating } = useUpdateTask(
        taskQueries.list().queryKey
    )

    const { dueToday, overdue, upcoming, unscheduled, completed } =
        useMemo(() => {
            if (!tasks)
                return {
                    dueToday: [],
                    overdue: [],
                    upcoming: [],
                    unscheduled: [],
                    completed: [],
                }
            return categorizeTasks(tasks)
        }, [tasks])

    if (isLoadingTasks) {
        return <LoadingSpinner />
    }

    if (isError) {
        return <ErrorPage error={{ message: "Failed to load tasks" }} />
    }

    return (
        <div className="h-full md:grid md:grid-cols-8">
            <div className="col-span-2 flex flex-col items-end gap-1 md:py-20">
                <AddTaskButton />
                <TabNavigation
                    activeTab={activeTab}
                    onTabChange={handleTabChange}
                    taskCounts={{
                        today: dueToday.filter((t) => !t.completed_at_utc)
                            .length,
                        overdue: overdue.filter((t) => !t.completed_at_utc)
                            .length,
                        upcoming: upcoming.filter((t) => !t.completed_at_utc)
                            .length,
                        unscheduled: unscheduled.filter(
                            (t) => !t.completed_at_utc
                        ).length,
                        completed: 0, // We don't want to show the count
                    }}
                />
            </div>
            <TaskListContainer
                activeTab={activeTab}
                dueToday={dueToday}
                overdue={overdue}
                upcoming={upcoming}
                unscheduled={unscheduled}
                completed={completed}
                isUpdating={isUpdating}
                updateTask={updateTask}
            />
        </div>
    )
}

function AddTaskButton() {
    const [showModal, setShowModal] = useState(false)
    const { mutate: createTask } = useCreateTask(taskQueries.list().queryKey)

    return (
        <>
            <div className="hidden md:flex">
                <FullSizeAddTaskButton onClick={() => setShowModal(true)} />
            </div>
            <div className="md:hidden">
                <FloatingAddTaskButton onClick={() => setShowModal(true)} />
            </div>
            <CreateTaskModal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                createTask={createTask}
            />
        </>
    )
}

interface AddTaskButtonProps {
    onClick: () => void
}

function FullSizeAddTaskButton({ onClick }: AddTaskButtonProps) {
    return (
        <button
            className="flex w-48 items-center gap-2 rounded-lg px-4 py-2 text-gray-600 transition-all hover:text-gray-800"
            onClick={onClick}
        >
            <FontAwesomeIcon icon={faPlus} />
            <span>Add task</span>
        </button>
    )
}

function FloatingAddTaskButton({ onClick }: AddTaskButtonProps) {
    return (
        <div className="fixed bottom-8 right-6">
            <button
                className="flex h-12 w-12 items-center justify-center rounded-full bg-amber-500 text-white shadow-lg transition-all hover:bg-amber-600"
                onClick={onClick}
            >
                <FontAwesomeIcon icon={faPlus} />
            </button>
        </div>
    )
}

interface TasksProps extends TaskUpdateProps {
    tasks: Task[]
}

function TodayTasks({ tasks, isUpdating, updateTask }: TasksProps) {
    return (
        <TaskListView
            title="Today"
            tasks={tasks}
            isUpdating={isUpdating}
            updateTask={updateTask}
            emptyMessage="🎉 No more tasks due today"
            showDueDate={false}
            showProgress={true}
            renderTask={(props: TaskItemProps) => <TaskItem {...props} />}
        />
    )
}

function OverdueTasks({ tasks, isUpdating, updateTask }: TasksProps) {
    return (
        <TaskListView
            title="Overdue"
            tasks={tasks}
            isUpdating={isUpdating}
            updateTask={updateTask}
            emptyMessage="😎 No overdue tasks"
            showDueDate={true}
            renderTask={(props: TaskItemProps) => <TaskItem {...props} />}
        />
    )
}

/**
 * UpcomingTasks displays tasks grouped by their due dates in the following way:
 * 1. Tasks due tomorrow are shown in a "Tomorrow" group
 * 2. Tasks due in the next 2-7 days are shown in daily groups with date
 * 3. Tasks due beyond 7 days are grouped in a "Later" section
 */
function UpcomingTasks({ tasks, isUpdating, updateTask }: TasksProps) {
    const groupedTasks = useGroupTasksByDueDate(tasks)

    // If no upcoming tasks, show empty state
    if (!tasks.length) {
        return (
            <TaskListView
                title="Upcoming"
                tasks={[]}
                isUpdating={isUpdating}
                updateTask={updateTask}
                emptyMessage="🗓️ No upcoming tasks"
                renderTask={(props: TaskItemProps) => <TaskItem {...props} />}
            />
        )
    }

    // Render each group of tasks
    return (
        <div className="space-y-8">
            {(
                Object.entries(groupedTasks) as [
                    string,
                    { title: string; tasks: Task[] }
                ][]
            ).map(([key, group]) => (
                <TaskListView
                    key={key}
                    title={group.title}
                    tasks={group.tasks}
                    isUpdating={isUpdating}
                    updateTask={updateTask}
                    emptyMessage="No tasks for this period"
                    showDueDate={key === "later"}
                    renderTask={(props: TaskItemProps) => (
                        <TaskItem {...props} />
                    )}
                />
            ))}
        </div>
    )
}

interface GroupedTasks {
    [key: string]: {
        title: string
        tasks: Task[]
    }
}

/**
 * Groups tasks by their due dates into predefined time buckets:
 * - Next 7 days: Each day gets its own group
 * - Beyond 7 days: Tasks go into a "Later" group
 * Empty groups are filtered out from the final result
 */
function useGroupTasksByDueDate(tasks: Task[]) {
    return useMemo(() => {
        const groups: GroupedTasks = {}
        const today = DateTime.now().setZone("utc").startOf("day")

        // Create groups for the next 7 days
        for (let i = 1; i <= 7; i++) {
            const date = today.plus({ days: i })
            const key = date.toISODate()!
            groups[key] = {
                title: getFormattedDate(date, {
                    weekday: "short",
                    month: "short",
                    day: "numeric",
                }),
                tasks: [],
            }
        }

        // Create a group for tasks due beyond 7 days
        const laterKey = "later"
        groups[laterKey] = {
            title: "Later",
            tasks: [],
        }

        // Distribute tasks into appropriate groups
        tasks.forEach((task) => {
            if (!task.due_date) return

            const dueDate = DateTime.fromISO(task.due_date, {
                zone: "utc",
            }).startOf("day")
            const daysDiff = dueDate.diff(today, "days").days

            if (daysDiff > 7) {
                // Tasks due more than 7 days from now
                groups[laterKey].tasks.push(task)
            } else if (daysDiff > 0) {
                // Tasks due within the next 7 days
                const key = dueDate.toISODate()!
                if (groups[key]) {
                    groups[key].tasks.push(task)
                }
            }
        })

        // Remove any groups that don't have any tasks
        return Object.entries(groups)
            .filter(([_, group]) => group.tasks.length > 0)
            .reduce((acc, [key, group]) => ({ ...acc, [key]: group }), {})
    }, [tasks])
}

function UnscheduledTasks({ tasks, isUpdating, updateTask }: TasksProps) {
    return (
        <TaskListView
            title="Unscheduled"
            tasks={tasks}
            isUpdating={isUpdating}
            updateTask={updateTask}
            emptyMessage="🕒 No unscheduled tasks"
            renderTask={(props: TaskItemProps) => <TaskItem {...props} />}
        />
    )
}

function CompletedTasks({ tasks, isUpdating, updateTask }: TasksProps) {
    return (
        <TaskListView
            title="Completed"
            tasks={tasks}
            isUpdating={isUpdating}
            updateTask={updateTask}
            emptyMessage="📖 No completed tasks yet"
            renderTask={(props: TaskItemProps) => <TaskItem {...props} />}
        />
    )
}
