import React, { useState, useMemo, useEffect } from "react"
import { useAddComment } from "../../api/mutations/call-comments"
import { ICall, ITranscriptTurn, IParticipant } from "../../types/Call"
import { ICallComment } from "../../types/Comment"
import { secondsToTimestamp, timestampToSeconds } from "../../utils/datetime"
import { screenSmallerThan, ScreenSize } from "../../utils/screenSize"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import ToggleButton from "../common/ToggleButton"
import { CommentBox, Comment } from "./CallComments"
import { TranscriptTurn } from "./CallTranscript"
import { mergeCommentsAndTurns } from "./utils/comments"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as Popover from "@radix-ui/react-popover"
import { getCacheValue, setCacheValue } from "../../utils/localStorageCache"
import { useQuery } from "@tanstack/react-query"
import { queries } from "../../api/queries"
import { getHeapInstance } from "../../utils/heap"
import { faMessagePlus } from "@fortawesome/pro-regular-svg-icons"

const SHOW_COMMENTS_CACHE_KEY = "show-call-comments"
const SHOW_TRANSCRIPT_CACHE_KEY = "show-call-transcript"

export function TranscriptAndComments(props: {
    call: ICall
    currentTimestamp: number
    highlightedTurns: ITranscriptTurn[]
    goToTimestamp: (timestamp: string) => void
    followMedia: boolean
    setFollowMedia: (follow: boolean) => void
    transcriptTurns: ITranscriptTurn[]
    parties: IParticipant[]
    highlightColour?: string
    highlightedCommentId?: string
}) {
    const { data: comments = [], isLoading: isCommentsLoading } = useQuery(
        queries.comments.byCallId(props.call.id)
    )

    const [showTranscript, setShowTranscript] = useState<boolean>(
        getCacheValue(SHOW_TRANSCRIPT_CACHE_KEY, true)
    )
    const [showComments, setShowComments] = useState<boolean>(
        !!props.highlightedCommentId ||
            getCacheValue(SHOW_COMMENTS_CACHE_KEY, true)
    )

    const transcriptOnlyAndEmpty =
        showTranscript && !showComments && props.transcriptTurns.length === 0

    const commentsOnlyAndEmpty =
        showComments && !showTranscript && comments.length === 0

    const commentsAndTranscriptEmpty =
        showTranscript &&
        showComments &&
        props.transcriptTurns.length === 0 &&
        comments.length === 0

    const commentCount = useMemo(() => getCommentCount(comments), [comments])

    return (
        <div className="flex flex-grow flex-col overflow-y-auto">
            <div className="mb-3 flex flex-col justify-between gap-2 sm:flex-row">
                <div className="flex w-full flex-wrap gap-2 rounded-lg border border-gray-200 p-3 sm:w-fit sm:gap-4">
                    <div className="flex flex-row items-center gap-2">
                        <ToggleButton
                            checked={showTranscript}
                            onChange={() => {
                                setShowTranscript(!showTranscript)
                                setCacheValue(
                                    SHOW_TRANSCRIPT_CACHE_KEY,
                                    !showTranscript
                                )
                            }}
                        />
                        <span className="font-semibold">Transcript</span>
                    </div>
                    <div className="flex items-center gap-2">
                        <ToggleButton
                            checked={showComments && !isCommentsLoading}
                            disabled={isCommentsLoading}
                            onChange={() => {
                                setShowComments(!showComments)
                                setCacheValue(
                                    SHOW_COMMENTS_CACHE_KEY,
                                    !showComments
                                )
                            }}
                        />
                        <span className="font-semibold">Comments</span>
                        {commentCount > 0 && (
                            <span className="flex h-5 w-5 items-center justify-center rounded-full bg-sun text-sm font-semibold">
                                {commentCount}
                            </span>
                        )}
                    </div>
                </div>
                <div className="mt-2 flex justify-end sm:mt-0">
                    <AddCommentButton
                        callId={props.call.id}
                        timestamp={props.currentTimestamp}
                    />
                </div>
            </div>
            {transcriptOnlyAndEmpty && (
                <Empty message="No transcript to display" />
            )}
            {commentsOnlyAndEmpty && (
                <Empty message="No comments yet. Be the first to add one!" />
            )}
            {commentsAndTranscriptEmpty && (
                <Empty message="No transcript or comments to display" />
            )}
            <MergedView
                {...props}
                comments={comments}
                showTranscript={showTranscript}
                showComments={showComments}
                highlightedCommentId={props.highlightedCommentId}
            />
        </div>
    )
}

function MergedView(props: {
    call: ICall
    highlightedTurns: ITranscriptTurn[]
    goToTimestamp: (timestamp: string) => void
    followMedia: boolean
    setFollowMedia: (follow: boolean) => void
    transcriptTurns: ITranscriptTurn[]
    parties: IParticipant[]
    highlightColour?: string
    comments: ICallComment[]
    showTranscript: boolean
    showComments: boolean
    highlightedCommentId?: string
}) {
    const { mutateAsync: addComment, isPending: isAddingComment } =
        useAddComment()
    const [showCommentBoxForTurn, setShowCommentBoxForTurn] = useState<
        number | null
    >(null)

    // Create map of turnIds to References, to allow us to scroll to a given turn
    const turnToRefMap: Map<
        number,
        React.RefObject<HTMLDivElement>
    > = useMemo(() => {
        if (!props.transcriptTurns) {
            return new Map()
        }

        return new Map(
            props.transcriptTurns.map((turn) => [
                turn.turn_start,
                React.createRef<HTMLDivElement>(),
            ])
        )
    }, [props.transcriptTurns])

    // same as above but for comments
    const commentToRefMap: Map<
        string,
        React.RefObject<HTMLDivElement>
    > = useMemo(() => {
        if (!props.comments) {
            return new Map()
        }

        return new Map(
            props.comments.map((comment) => [
                comment.id,
                React.createRef<HTMLDivElement>(),
            ])
        )
    }, [props.comments])

    const mergedItems = useMemo(() => {
        return mergeCommentsAndTurns(props.comments, props.transcriptTurns)
    }, [props.transcriptTurns, props.comments])

    const handleManualScroll = () => {
        props.setFollowMedia && props.setFollowMedia(false)
    }

    const handleResumeTranscriptScrollClicked = () => {
        props.setFollowMedia && props.setFollowMedia(true)
    }

    const showResumeAutoScrollButton =
        props.showTranscript &&
        !props.followMedia &&
        !screenSmallerThan(ScreenSize.md)

    useEffect(() => {
        const scrollToTurn = (turn: ITranscriptTurn) => {
            if (!props.showTranscript) {
                return
            }

            if (!props.followMedia) {
                return
            }

            if (screenSmallerThan(ScreenSize.md)) {
                // Do not follow media when in mobile view
                return
            }

            const ref = turnToRefMap.get(turn.turn_start)!

            ref.current!.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            })
        }

        // We need to run this in useEffect as React will not have created the DOM elements on the first render
        // https://beta.reactjs.org/learn/manipulating-the-dom-with-refs#when-react-attaches-the-refs
        if (props.highlightedTurns.length > 0) {
            scrollToTurn(props.highlightedTurns[0])
        }
    }, [
        props.highlightedTurns,
        props.followMedia,
        turnToRefMap,
        props.showTranscript,
    ])

    useEffect(() => {
        if (props.highlightedCommentId) {
            const ref = commentToRefMap.get(props.highlightedCommentId)
            ref?.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            })
        }
    }, [props.highlightedCommentId, commentToRefMap])

    return (
        <div
            onWheel={handleManualScroll}
            className="relative flex-grow overflow-y-auto pr-3"
        >
            <div className="flex flex-col gap-2">
                {mergedItems.map((item) => {
                    if (item.type === "turn" && props.showTranscript) {
                        const turn = item.data as ITranscriptTurn
                        const id = turn.turn_start
                        const party = props.parties.find(
                            (p) => p.id === turn.party_id
                        )!
                        return (
                            <div key={id}>
                                <TranscriptTurn
                                    transcriptTurn={turn}
                                    party={party}
                                    isHighlighted={props.highlightedTurns.includes(
                                        turn
                                    )}
                                    ref={turnToRefMap.get(id)!}
                                    goToTimestamp={props.goToTimestamp}
                                    highlightColour={props.highlightColour}
                                    onAddComment={() =>
                                        setShowCommentBoxForTurn(id)
                                    }
                                />
                                {showCommentBoxForTurn === id && (
                                    <CommentBox
                                        timestamp={timestampToSeconds(
                                            turn.timestamp
                                        )}
                                        disabled={isAddingComment}
                                        onSubmit={async (content) => {
                                            await addComment({
                                                callId: props.call.id,
                                                content,
                                                call_timestamp: turn.timestamp,
                                            })
                                            getHeapInstance()?.track(
                                                "comment-created-from-transcript",
                                                {
                                                    callTimestamp:
                                                        turn.timestamp,
                                                }
                                            )
                                            setShowCommentBoxForTurn(null)
                                        }}
                                        onDismiss={() =>
                                            setShowCommentBoxForTurn(null)
                                        }
                                    />
                                )}
                            </div>
                        )
                    }
                    if (item.type === "comment" && props.showComments) {
                        const comment = item.data as ICallComment
                        const id = comment.id
                        return (
                            <div ref={commentToRefMap.get(id)!} key={id}>
                                <Comment
                                    callId={props.call.id}
                                    comment={comment}
                                    goToTimestamp={props.goToTimestamp}
                                    highlighted={
                                        props.highlightedCommentId === id
                                    }
                                />
                            </div>
                        )
                    }
                    return null
                })}
            </div>
            <PrimaryButton
                onClick={handleResumeTranscriptScrollClicked}
                disabled={!showResumeAutoScrollButton}
                className={`sticky bottom-6 left-1/2 my-2 -translate-x-1/2 whitespace-nowrap transition-opacity ${
                    showResumeAutoScrollButton ? "opacity-100" : "opacity-0"
                }`}
            >
                <div className="flex flex-row items-center gap-2">
                    Resume Transcript Auto-Scroll
                </div>
            </PrimaryButton>
        </div>
    )
}

function AddCommentButton(props: { callId: string; timestamp: number }) {
    const { mutateAsync: addComment, isPending: isAddingComment } =
        useAddComment()

    const [open, setOpen] = useState(false)
    return (
        <Popover.Root open={open} onOpenChange={setOpen}>
            <Popover.Trigger>
                <SecondaryButton
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content="Leave a comment"
                >
                    <FontAwesomeIcon
                        icon={faMessagePlus}
                        className="h-5 w-5 translate-y-0.5"
                    />
                </SecondaryButton>
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content sideOffset={10} align="end">
                    <div className="w-96 shadow-lg md:w-[450px]">
                        <CommentBox
                            timestamp={props.timestamp}
                            disabled={isAddingComment}
                            onSubmit={async (content) => {
                                const callTimestamp = secondsToTimestamp(
                                    props.timestamp
                                )
                                await addComment({
                                    callId: props.callId,
                                    content,
                                    call_timestamp: callTimestamp,
                                })
                                getHeapInstance()?.track("comment-created", {
                                    callTimestamp,
                                })
                                setOpen(false)
                            }}
                        />
                    </div>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    )
}

function Empty(props: { message: string }) {
    return (
        <div className="h-12 w-full rounded-lg bg-gray-100 p-3 text-center text-gray-500">
            {props.message}
        </div>
    )
}

function getCommentCount(comments: ICallComment[]) {
    return comments.reduce(
        (count, comment) => count + 1 + (comment.replies?.length || 0),
        0
    )
}
