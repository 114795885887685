import { faCircleRight } from "@fortawesome/free-regular-svg-icons"
import { faChevronDown, faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNotification } from "./../providers/NotificationProvider"
import LoadingMessage from "./common/LoadingMessage"
import { NotificationType } from "./common/Notifcations"
import { useCallback, useMemo, useState } from "react"
import { clsx } from "clsx"
import { IInferredCrmFieldValue, IResolvedCrmFieldValue } from "./crm/types/Crm"
import { SecondaryButton } from "./common/Buttons"
import axios from "axios"
import _ from "lodash"

export const DEFAULT_GROUP_NAME = "General"

export function getDisplayName(field: IInferredCrmFieldValue): string {
    return field.field_display_name || field.field_name || ""
}

export function ReadOnlyFieldList({
    fields,
}: {
    fields: IInferredCrmFieldValue[]
}) {
    return (
        <FieldList
            fields={fields}
            showSyncUI={false}
            callId="" /* read-only so callId is not needed (might need to refactor this later) */
        />
    )
}

export function FieldList({
    fields,
    showSyncUI,
    callId,
}: {
    fields: IInferredCrmFieldValue[]
    showSyncUI: boolean
    callId: string
}) {
    const sortedGroupedFields = useMemo(() => {
        const grouped = _.groupBy(fields, (field) => field.group_name ?? "")
        return Object.entries(grouped).sort(([a], [b]) => {
            if (a === "") return -1
            if (b === "") return 1
            return a.localeCompare(b)
        })
    }, [fields])

    if (sortedGroupedFields.length > 1) {
        return (
            <GroupListView
                groupedFields={sortedGroupedFields}
                showSyncUI={showSyncUI}
                callId={callId}
            />
        )
    } else {
        return (
            <FlatListView
                fields={fields}
                showSyncUI={showSyncUI}
                callId={callId}
            />
        )
    }
}

function GroupListView({
    groupedFields,
    showSyncUI,
    callId,
}: {
    groupedFields: [string, IInferredCrmFieldValue[]][]
    showSyncUI: boolean
    callId: string
}) {
    return (
        <div className="space-y-4">
            {groupedFields.map(([groupName, fields]) => (
                <GroupView
                    key={groupName}
                    groupName={groupName}
                    fields={fields}
                    showSyncUI={showSyncUI}
                    callId={callId}
                />
            ))}
        </div>
    )
}

function GroupView({
    groupName,
    fields,
    showSyncUI,
    callId,
}: {
    groupName: string
    fields: IInferredCrmFieldValue[]
    showSyncUI: boolean
    callId: string
}) {
    const [collapsed, setCollapsed] = useState(false)

    const groupNameValue = groupName || DEFAULT_GROUP_NAME
    const totalFieldCount = fields.length
    const nonEmptyFieldCount = fields.filter(hasValue).length
    const tooltipContent =
        `${nonEmptyFieldCount} out of the ${totalFieldCount} ${groupNameValue} insights ` +
        (showSyncUI ? "can be synced." : "were mentioned.")

    return (
        <div className="space-y-2 divide-y">
            <div
                className="flex cursor-pointer items-center justify-between"
                onClick={() => setCollapsed(!collapsed)}
            >
                <span className="flex items-center">
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        className={clsx(
                            "mr-2 transition-transform",
                            collapsed && "-rotate-90"
                        )}
                    />
                    <h3 className="text-lg font-semibold">{groupNameValue}</h3>
                </span>
                <span
                    className="inline-flex justify-center rounded-lg bg-gray-100 px-2 text-gray-500"
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-html={tooltipContent}
                >
                    {nonEmptyFieldCount} / {totalFieldCount}
                </span>
            </div>
            <div
                className={clsx(
                    "overflow-auto pl-2 pt-2 transition-max-height duration-500",
                    collapsed ? "max-h-0" : "max-h-[200vh]"
                )}
            >
                <FlatListView
                    fields={fields}
                    showSyncUI={showSyncUI}
                    callId={callId}
                />
            </div>
        </div>
    )
}

function FlatListView({
    fields,
    showSyncUI,
    callId,
}: {
    fields: IInferredCrmFieldValue[]
    showSyncUI: boolean
    callId: string
}) {
    return (
        <div className="space-y-2">
            {fields.map((field) => (
                <div
                    key={field.field_name}
                    className="flex flex-row items-center space-x-3"
                >
                    {showSyncUI ? (
                        <FieldSyncPreview
                            field={field as IResolvedCrmFieldValue}
                            callId={callId}
                        />
                    ) : (
                        <Field field={field} />
                    )}
                </div>
            ))}
        </div>
    )
}

export function Field({ field }: { field: IInferredCrmFieldValue }) {
    const bgColor = hasValue(field) ? "bg-indigo-500" : "bg-gray-300"
    return (
        <div className="flex grow flex-row gap-3">
            <div className={`${bgColor} w-1 flex-none rounded-lg`}></div>
            <div className="flex-grow">
                <p className="font-semibold">
                    {field.field_display_name || field.field_name}
                </p>
                <FieldValue
                    value={field.value}
                    displayValue={field.display_value}
                    valueAdditionalContext={field.value_additional_context}
                />
            </div>
        </div>
    )
}

function hasValue(field: IInferredCrmFieldValue): boolean {
    // Whether inference has found some value for this field
    return !!field.value || !!field.value_additional_context
}

function FieldSyncPreview({
    callId,
    field,
}: {
    callId: string
    field: IResolvedCrmFieldValue
}) {
    const bgColor = field.value ? "bg-indigo-500" : "bg-gray-300"
    return (
        <div className="flex grow flex-row gap-3">
            <div className={`${bgColor} w-1 flex-none rounded-lg`}></div>
            <div className="flex grow flex-row items-start gap-3">
                <div className="flex-grow space-y-1">
                    <div>
                        <p className="font-semibold">{getDisplayName(field)}</p>
                        <FieldValue
                            value={field.value}
                            displayValue={field.display_value}
                            valueAdditionalContext={
                                field.value_additional_context
                            }
                        />
                    </div>
                    <div className="w-full rounded bg-gray-100 px-3 py-2 text-gray-600">
                        {field.existing_crm_value ? (
                            <p>
                                Currently in CRM:{" "}
                                <b className="font-semibold">
                                    {field.existing_crm_value}
                                </b>
                            </p>
                        ) : (
                            <p>CRM field is empty</p>
                        )}
                    </div>
                </div>
                <FieldSyncControls callId={callId} field={field} />
            </div>
        </div>
    )
}

export function FieldValue({
    value,
    displayValue,
    valueAdditionalContext,
}: {
    value: string | null
    displayValue?: string | null
    valueAdditionalContext?: string | null
}) {
    // if there is additional context but no value,
    // it means that the CI was covered in the call but we failed to map it to a valid CRM value
    const emptyValue = valueAdditionalContext
        ? "Extracted value does not match pre-defined options"
        : "Not covered"

    return (
        <div className="flex flex-row items-center gap-2">
            <p className={!value ? "opacity-50" : ""}>
                {displayValue || value || emptyValue}
            </p>
            {valueAdditionalContext && (
                <FontAwesomeIcon
                    className="mt-0.5 text-gray-400"
                    icon={faInfoCircle}
                    data-tooltip-content={`Extracted value: "${valueAdditionalContext}"`}
                    data-tooltip-id="tooltip-explanation"
                />
            )}
        </div>
    )
}

function FieldSyncControls({
    callId,
    field,
}: {
    callId: string
    field: IResolvedCrmFieldValue
}) {
    const { addNotification } = useNotification()
    const [loading, setLoading] = useState(false)

    const onSync = useCallback(async () => {
        setLoading(true)
        try {
            await axios.post(
                `${process.env.REACT_APP_API_DOMAIN}/calls/${callId}/crm/fields`,
                {
                    fields: [field],
                }
            )
            addNotification(
                "Sent to CRM!",
                "You can find it in the company properties.",
                NotificationType.Success
            )
        } catch (error) {
            console.error("Failed to sync CRM", error)
            addNotification(
                "Failed to sync CRM",
                `${error}`,
                NotificationType.Error
            )
        } finally {
            setLoading(false)
        }
    }, [field, callId, addNotification])

    const canSync = !loading && field.value && field.source_id

    return (
        <div className="flex flex-row items-center space-x-2">
            <SecondaryButton onClick={onSync} disabled={!canSync}>
                <span className="flex w-16 items-center justify-center space-x-1 whitespace-nowrap">
                    {loading ? (
                        <LoadingMessage message="Syncing" />
                    ) : (
                        <>
                            <FontAwesomeIcon icon={faCircleRight} size="lg" />
                            <span>{"Sync"}</span>
                        </>
                    )}
                </span>
            </SecondaryButton>
        </div>
    )
}
