import { useCallback, useEffect, useState } from "react"
import { SimpleCard } from "../common/SimpleCard"
import { TaskItemProps, TaskUpdateProps } from "../tasks/items/TaskItem"
import { InlineTitle, TitleStyle } from "../tasks/TaskListView"
import { Task } from "../tasks/types/Task"

type SectionConfig<T extends Task> = {
    key: string
    title: string
    tasks: T[]
    showDueDate: boolean
    emptyMessage: string
}

export function TaskListSectionView<T extends Task = Task>(
    props: {
        title: string
        dueTodayTasks: T[]
        upcomingTasks: T[]
        overdueTasks: T[]
        unscheduledTasks: T[]
        titleStyle?: TitleStyle
        isLoading?: boolean
        renderTask: (props: TaskItemProps<T>) => React.ReactNode
    } & TaskUpdateProps
) {
    const [expandedSections, setExpandedSections] = useState<
        Record<string, boolean>
    >({
        today: false,
        upcoming: false,
        overdue: false,
        unscheduled: false,
    })

    useEffect(() => {
        setExpandedSections({
            today: props.dueTodayTasks.length > 0,
            upcoming: props.upcomingTasks.length > 0,
            overdue: props.overdueTasks.length > 0,
            unscheduled: props.unscheduledTasks.length > 0,
        })
    }, [
        props.dueTodayTasks.length,
        props.upcomingTasks.length,
        props.overdueTasks.length,
        props.unscheduledTasks.length,
    ])

    const toggleSection = (section: string) => {
        setExpandedSections((prev) => ({
            ...prev,
            [section]: !prev[section],
        }))
    }

    const sections: SectionConfig<T>[] = [
        {
            key: "today",
            title: "Today's Tasks",
            tasks: props.dueTodayTasks,
            showDueDate: false,
            emptyMessage: props.isLoading
                ? "Loading tasks..."
                : "🎉 No more tasks due today",
        },
        {
            key: "upcoming",
            title: "Upcoming Tasks",
            tasks: props.upcomingTasks,
            showDueDate: false,
            emptyMessage: props.isLoading
                ? "Loading tasks..."
                : "🗓️ No upcoming tasks",
        },
        {
            key: "overdue",
            title: "Overdue Tasks",
            tasks: props.overdueTasks,
            showDueDate: true,
            emptyMessage: props.isLoading
                ? "Loading tasks..."
                : "😎 No overdue tasks",
        },
        {
            key: "unscheduled",
            title: "Unscheduled Tasks",
            tasks: props.unscheduledTasks,
            showDueDate: false,
            emptyMessage: props.isLoading
                ? "Loading tasks..."
                : "🔍 No unscheduled tasks",
        },
    ]

    return (
        <div className="flex flex-col gap-4">
            <SimpleCard className="p-4">
                <div className="w-full flex-grow space-y-2 text-base">
                    <InlineTitle
                        title={props.title}
                        titleStyle={props.titleStyle}
                    />
                    <div className="flex max-h-96 max-w-full flex-col gap-2 overflow-y-auto">
                        {sections.map((section) => (
                            <div key={section.key}>
                                <SectionToggleButton
                                    isExpanded={expandedSections[section.key]}
                                    title={section.title}
                                    taskCount={section.tasks.length}
                                    onClick={() => toggleSection(section.key)}
                                />
                                {expandedSections[section.key] && (
                                    <TaskListSection
                                        tasks={section.tasks}
                                        emptyMessage={section.emptyMessage}
                                        showDueDate={section.showDueDate}
                                        showProgress={true}
                                        titleStyle={props.titleStyle}
                                        completedCount={0}
                                        totalCount={0}
                                        isUpdating={props.isUpdating}
                                        updateTask={props.updateTask}
                                        renderTask={props.renderTask}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </SimpleCard>
        </div>
    )
}

function SectionToggleButton({
    isExpanded,
    title,
    taskCount,
    onClick,
}: {
    isExpanded: boolean
    title: string
    taskCount: number
    onClick: () => void
}) {
    return (
        <button
            onClick={onClick}
            className="flex w-full items-center gap-2 text-left"
        >
            <span className="text-gray-500">{isExpanded ? "▼" : "▶"}</span>
            <h3 className="text-lg font-semibold">{title}</h3>
            <span className="text-gray-500">({taskCount})</span>
        </button>
    )
}

function TaskListSection<T extends Task = Task>(
    props: {
        tasks: T[]
        emptyMessage: string
        showDueDate?: boolean
        showProgress?: boolean
        titleStyle?: TitleStyle
        completedCount: number
        totalCount: number
        renderTask: (props: TaskItemProps<T>) => React.ReactNode
    } & TaskUpdateProps
) {
    const [expandedTaskId, setExpandedTaskId] = useState<string | null>(null)

    const handleTaskClick = useCallback((taskId: string) => {
        setExpandedTaskId((prevId) => (prevId === taskId ? null : taskId))
    }, [])

    return (
        <div className="mt-2">
            <div className="flex w-full max-w-full flex-col gap-2 overflow-hidden">
                {!props.tasks.length ? (
                    <span className="text-gray-400">{props.emptyMessage}</span>
                ) : (
                    props.tasks.map((task) => {
                        const taskProps: TaskItemProps<T> = {
                            task,
                            isUpdating: props.isUpdating,
                            updateTask: props.updateTask,
                            showDueDate: props.showDueDate,
                            onTaskClick: () => handleTaskClick(task.id),
                            isExpanded: expandedTaskId === task.id,
                        }
                        return (
                            <div key={task.id}>
                                {props.renderTask(taskProps)}
                            </div>
                        )
                    })
                )}
            </div>
        </div>
    )
}
