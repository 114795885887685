import { ICallTag } from "../types/Call"

/**
 * Encodes a tag into a URL-friendly format that preserves group information
 * Format: "groupName:tagName" for grouped tags or just "tagName" for ungrouped tags
 *
 * @param tag The tag to encode, or tag name and group separately
 * @returns URL-encoded tag string
 */
export const encodeTag = (
    tag: ICallTag | { name: string; group?: string | null }
): string => {
    if (tag.group && tag.group !== "ungrouped") {
        // Format: "groupName:tagName"
        return `${encodeURIComponent(tag.group)}:${encodeURIComponent(
            tag.name
        )}`
    }
    // Just the tag name for ungrouped tags
    return encodeURIComponent(tag.name)
}

/**
 * Decodes a tag string from URL format back into name and group
 *
 * @param encodedTag The encoded tag string (e.g., "groupName:tagName" or just "tagName")
 * @returns Object with name and optional group
 */
export const decodeTag = (
    encodedTag: string
): { name: string; group?: string } => {
    // We need to handle the special case where the tag might contain a colon
    // The format we use is "encodedGroup:encodedName"
    const colonIndex = encodedTag.indexOf(":")

    if (colonIndex > 0) {
        const group = decodeURIComponent(encodedTag.substring(0, colonIndex))
        const name = decodeURIComponent(encodedTag.substring(colonIndex + 1))

        return {
            group,
            name,
        }
    }

    return {
        group: undefined,
        name: decodeURIComponent(encodedTag),
    }
}
