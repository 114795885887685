import { useIntegrations } from "../../../hooks/useIntegrations"

import axios from "axios"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import googleCalendarLogo from "../../../assets/google-calendar.png"
import microsoftCalendarLogo from "../../../assets/outlook-calendar.png"
import { IntegrationSetting } from "../IntegrationSetting"
import {
    MeetingBotConfig,
    setDefaultRecordingPreferences,
} from "../MeetingBotConfig"

import { useNotification } from "../../../providers/NotificationProvider"
import { NotificationType } from "../../common/Notifcations"
import { SettingsSection } from "../Card"

import { navigateToExternal } from "../oauth_utils"
import { ZoomMeetingSDKSettings } from "../ZoomMeetingSDKSettings"

import { useUser } from "../../../providers/UserProvider"
import { Permission } from "../../../types/Permission"
import { hasPermission } from "../../../utils/Permissions"
import { CalendarType } from "../../../hooks/useIntegrations"
import { QueryClient, useQueryClient } from "@tanstack/react-query"

import { UserMemory } from "../UserMemory"

import { BaseSettingsPage } from "./BaseSettingsPage"

export function PersonalPage() {
    const user = useUser()
    const { hasGong, hasZoom, hasGoogleCal, hasMicrosoftCal } =
        useIntegrations()

    const canConnectCalendar = !!(
        user && hasPermission(user, Permission.RECORD_CALLS)
    )

    const hasExternalCallRecorder = hasGong || hasZoom
    return (
        <BaseSettingsPage pageTitle={"Personal Settings"}>
            {canConnectCalendar && (
                <SettingsSection
                    title="Calendar integration"
                    description="Enable call preparation and call processing by connecting your calendar."
                >
                    <GoogleCalendarSettings
                        authorized={hasGoogleCal}
                        hasExternalCallRecorder={hasExternalCallRecorder}
                    />
                    <MicrosoftCalendarSettings
                        authorized={hasMicrosoftCal}
                        hasExternalCallRecorder={hasExternalCallRecorder}
                    />
                    <ZoomMeetingSDKSettings />
                </SettingsSection>
            )}

            <UserMemory />
        </BaseSettingsPage>
    )
}

function GoogleCalendarSettings(props: {
    authorized: boolean
    hasExternalCallRecorder: boolean
}) {
    const [searchParams] = useSearchParams()
    const { addNotification } = useNotification()
    const [showConfig, setShowConfig] = useState<boolean>(false)
    const queryClient = useQueryClient()

    useEffect(() => {
        async function generateTokenFromCallback() {
            if (searchParams.get("googleCalendarCallback") === "true") {
                const callbackError = searchParams.get("error")
                if (callbackError) {
                    addNotification(
                        "Error from Google Calendar!",
                        callbackError,
                        NotificationType.Error
                    )
                    return
                }

                // We do not want the user to refresh the page, go back or be
                // suggested by the browser and trigger this again.
                window.history.replaceState({}, "", "/settings/personal")

                await setDefaultRecordingPreferences()
                refreshAuthStatus(queryClient, CalendarType.Google)
                setShowConfig(true) // We only want to display config popup *after* default prefs have been set
            }
        }
        generateTokenFromCallback()
    }, [searchParams, queryClient, setShowConfig, addNotification])

    return (
        <IntegrationSetting
            name="Google Calendar"
            logo={googleCalendarLogo}
            onEnable={async () => enableGoogleCalendar()}
            onDisable={async () => {
                await disableCalendar(CalendarType.Google)
                await refreshAuthStatus(queryClient, CalendarType.Google)
            }}
            authorized={props.authorized}
            config={
                <MeetingBotConfig
                    hasExternalCallRecorder={props.hasExternalCallRecorder}
                />
            }
            showConfig={showConfig}
        />
    )
}

async function enableGoogleCalendar() {
    try {
        const calendarAccessToken = await generateCalendarAccessToken()
        navigateToExternal(googleCalendarAuthUrl(calendarAccessToken))
    } catch (error) {
        console.log(error)
    }
}

async function disableCalendar(calendarType: CalendarType) {
    try {
        await axios.delete(
            `${process.env.REACT_APP_API_DOMAIN}/call_bot/calendar/${calendarType}`
        )
    } catch (error) {
        console.log(error)
    }
}

function refreshAuthStatus(
    queryClient: QueryClient,
    calendarType: CalendarType
) {
    queryClient.refetchQueries({
        queryKey: ["calendar", calendarType],
    })
}

function googleCalendarAuthUrl(calendarAccessToken: string) {
    // This follows https://recallai.readme.io/reference/google-calendar-setup

    // Google OAuth must first redirect to an API owned by us, which we forward to Recall
    const redirect_uri = `${process.env.REACT_APP_API_DOMAIN}/call_bot/calendar/google/oauth_callback`
    const state = JSON.stringify({
        recall_calendar_auth_token: calendarAccessToken,
        google_oauth_redirect_url: redirect_uri,
        success_url: `${process.env.REACT_APP_WEBSITE_DOMAIN}/settings?googleCalendarCallback=true`,
        error_url: `${process.env.REACT_APP_WEBSITE_DOMAIN}/settings`,
    })

    const urlPrefix = "https://accounts.google.com/o/oauth2/v2/auth"
    let url = new URL(urlPrefix)
    url.searchParams.set(
        "scope",
        "https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/userinfo.email"
    )
    url.searchParams.set("access_type", "offline")
    url.searchParams.set("prompt", "consent")
    url.searchParams.set("include_granted_scopes", "true")
    url.searchParams.set("response_type", "code")
    url.searchParams.set("state", state)
    url.searchParams.set("redirect_uri", redirect_uri)
    url.searchParams.set(
        "client_id",
        process.env.REACT_APP_GOOGLE_CANENDAR_APP_ID!
    )

    return url.toString()
}

function MicrosoftCalendarSettings(props: {
    authorized: boolean
    hasExternalCallRecorder: boolean
}) {
    const [searchParams] = useSearchParams()
    const { addNotification } = useNotification()
    const [showConfig, setShowConfig] = useState<boolean>(false)
    const queryClient = useQueryClient()

    useEffect(() => {
        async function generateTokenFromCallback() {
            if (searchParams.get("microsoftCalendarCallback") === "true") {
                // We do not want the user to refresh the page, go back or be
                // suggested by the browser and trigger this again.
                window.history.replaceState({}, "", "/settings/personal")

                await setDefaultRecordingPreferences()
                refreshAuthStatus(queryClient, CalendarType.Microsoft)
                setShowConfig(true) // We only want to display config popup *after* default prefs have been set
            } else if (
                searchParams.get("microsoftCalendarCallback") === "false"
            ) {
                const callbackError = searchParams.get("error")
                const errorMsg = callbackError || "Unknown Error"
                addNotification(
                    "Error from Outlook Calendar!",
                    errorMsg,
                    NotificationType.Error
                )
                return
            }
        }
        generateTokenFromCallback()
    }, [searchParams, queryClient, setShowConfig, addNotification])

    return (
        <IntegrationSetting
            name="Microsoft Outlook Calendar"
            logo={microsoftCalendarLogo}
            onEnable={async () => enableMicrosoftCalendar()}
            onDisable={async () => {
                await disableCalendar(CalendarType.Microsoft)
                await refreshAuthStatus(queryClient, CalendarType.Microsoft)
            }}
            authorized={props.authorized}
            config={
                <MeetingBotConfig
                    hasExternalCallRecorder={props.hasExternalCallRecorder}
                />
            }
            showConfig={showConfig}
        />
    )
}

async function enableMicrosoftCalendar() {
    try {
        const calendarAccessToken = await generateCalendarAccessToken()
        navigateToExternal(microsoftCalendarAuthUrl(calendarAccessToken))
    } catch (error) {
        console.log(error)
    }
}

function microsoftCalendarAuthUrl(calendarAccessToken: string) {
    // This follows https://recallai.readme.io/reference/microsoft-outlook-calendar-setup

    // Microsoft OAuth must first redirect to an API owned by us, which we forward to Recall
    const redirect_uri = `${process.env.REACT_APP_API_DOMAIN}/call_bot/calendar/microsoft/oauth_callback`
    const state = JSON.stringify({
        recall_calendar_auth_token: calendarAccessToken,
        ms_oauth_redirect_url: redirect_uri,
        success_url: `${process.env.REACT_APP_WEBSITE_DOMAIN}/settings?microsoftCalendarCallback=true`,
        error_url: `${process.env.REACT_APP_WEBSITE_DOMAIN}/settings?microsoftCalendarCallback=false`,
    })

    const urlPrefix =
        "https://login.microsoftonline.com/common/oauth2/v2.0/authorize"
    let url = new URL(urlPrefix)
    url.searchParams.set(
        "scope",
        "offline_access openid email https://graph.microsoft.com/Calendars.Read"
    )
    url.searchParams.set("response_mode", "query")
    url.searchParams.set("response_type", "code")
    url.searchParams.set("state", state)
    url.searchParams.set("redirect_uri", redirect_uri)
    url.searchParams.set(
        "client_id",
        process.env.REACT_APP_OUTLOOK_CANENDAR_APP_ID!
    )

    return url.toString()
}

async function generateCalendarAccessToken(): Promise<string> {
    // Generate Recall's calendar access token for the user
    const response = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/call_bot/calendar/auth`
    )
    return response.data.token
}
