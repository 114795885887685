import { useMutation, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import { NotificationType } from "../../components/common/Notifcations"
import { useNotification } from "../../providers/NotificationProvider"
import { queries } from "../queries"

export function useUpdateProductDescription() {
    const { addNotification } = useNotification()
    const queryClient = useQueryClient()

    const { mutate, isPending } = useMutation({
        mutationFn: async (new_desc: string) => {
            await axios.put(
                `${process.env.REACT_APP_API_DOMAIN}/organization/settings/product-description`,
                { product_description: new_desc }
            )
        },
        onSuccess: () => {
            addNotification(
                "Product description updated",
                "",
                NotificationType.Success
            )
            queryClient.invalidateQueries(
                queries.organization.productDescription()
            )
        },
        onError: () => {
            addNotification(
                "Failed to update product description",
                "",
                NotificationType.Error
            )
        },
    })

    return {
        mutate,
        isPending,
    }
}
