import React, {
    Dispatch,
    KeyboardEventHandler,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react"
import axios from "axios"
import validator from "validator"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLink, faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons"
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons"
import ReactPlayer from "react-player"
import { Range } from "react-range"

import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import CreatableSelect from "react-select/creatable"
import {
    ICall,
    IParticipant,
    IShareOptions,
    ITranscriptTurn,
} from "../../types/Call"
import DividerLine from "../common/DividerLine"
import _ from "lodash"
import ToggleButton from "../common/ToggleButton"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useUser } from "../../providers/UserProvider"
import { getHeapInstance } from "../../utils/heap"
import { queries } from "../../api/queries"
import { Modal } from "../common/Modal"
import { CallTranscript } from "./CallTranscript"
import { secondsToTimestamp, timestampToSeconds } from "../../utils/datetime"
import { TabHead } from "../common/Tabs"
import clsx from "clsx"
import colors from "tailwindcss/colors"
import { SharedCallPreview } from "./SharedCallPreview"
import { LoadingPulse } from "../common/LoadingPulse"
import { queryClient } from "../../utils/reactQueryClient"
import { getCacheValue, setCacheValue } from "../../utils/localStorageCache"

enum ShareTabsEnum {
    WholeCall = "Share whole call",
    Snippet = "Share call snippet",
}

const TABS = Object.values(ShareTabsEnum).map((tab) => ({
    type: tab,
    label: tab,
}))

interface SnippetOptions {
    start: number
    end: number
}

const EXPIRY_DAYS_CACHE_KEY = "share-call-expiry-days"

export function ShareCallButton(props: { call: ICall }) {
    const [showPopup, setShowPopup] = useState(false)

    const handleButtonClick = () => {
        setShowPopup((prev) => !prev)
    }

    return (
        <>
            <button
                className="flex flex-col items-center justify-center text-sm text-gray-600 hover:text-gray-800"
                onClick={handleButtonClick}
            >
                <FontAwesomeIcon
                    icon={faArrowUpFromBracket}
                    className="h-4 w-4"
                />
                <span className="hidden md:block">Share</span>
            </button>
            {showPopup && (
                <Modal isOpen={showPopup} onClose={() => setShowPopup(false)}>
                    <ShareCallPopup call={props.call} />
                </Modal>
            )}
        </>
    )
}

function ShareCallPopup(props: { call: ICall }) {
    const user = useUser()

    const [expiryDays, setExpiryDays] = useState<number>(
        getCacheValue(EXPIRY_DAYS_CACHE_KEY, 90)
    )
    const [emailsToShare, setEmailsToShare] = useState<string[]>(
        _.uniq(getParticipantEmails(props.call.parties)).filter(
            (email) => email !== user?.email
        )
    )
    const expiryDaysMin = 1
    const expiryDaysMax = 365

    const [shareOptions, setShareOptions] = useState<IShareOptions>({
        start_time: null,
        share_summary: true,
        enable_ask_glyphic: false,
        share_annotations: false,
        share_custom_insights: false,
        share_talk_stats: false,
    })
    const [snippet, setSnippet] = useState<SnippetOptions>({
        start: 0,
        end: props.call.duration || 1,
    })

    function handleExpiryDaysChange(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const newValue = event.target.value
        if (
            parseInt(newValue) >= expiryDaysMin &&
            parseInt(newValue) <= expiryDaysMax
        ) {
            setExpiryDays(parseInt(newValue))
            setCacheValue(EXPIRY_DAYS_CACHE_KEY, parseInt(newValue))
        }
    }

    const [activeTab, setActiveTab] = useState(0)
    const shareCallSelected = TABS[activeTab].type === ShareTabsEnum.WholeCall
    const shareSnippetSelected = TABS[activeTab].type === ShareTabsEnum.Snippet

    return (
        <div className="flex w-screen max-w-[1200px] space-x-4 text-base">
            <div className="flex w-full flex-col justify-between gap-4 p-4 md:w-2/3">
                <div className="space-y-4">
                    <TabHead
                        tabs={TABS}
                        activeTab={activeTab}
                        onTabChange={(index: number) => {
                            setActiveTab(index)
                        }}
                    />

                    {shareCallSelected && (
                        <ShareWholeCallOptions
                            shareOptions={shareOptions}
                            setShareOptions={setShareOptions}
                        />
                    )}
                    {shareSnippetSelected && (
                        <ShareSnippetOptions
                            call={props.call}
                            snippet={snippet}
                            setSnippet={setSnippet}
                        />
                    )}
                </div>

                <div className="space-y-4">
                    <DividerLine />
                    <EmailShareSection
                        emails={emailsToShare}
                        setEmails={setEmailsToShare}
                    />
                    <div>
                        <span>This link will expire in </span>
                        <input
                            className="w-14 rounded-lg border px-1 text-right"
                            type="number"
                            min={expiryDaysMin}
                            max={expiryDaysMax}
                            value={expiryDays}
                            onChange={handleExpiryDaysChange}
                        />
                        <span> days.</span>
                    </div>
                    <DividerLine />
                    <ShareButtonsFooter
                        callId={props.call.id}
                        expiryDays={expiryDays}
                        emailsToShare={emailsToShare}
                        options={shareCallSelected ? shareOptions : undefined}
                        snippet={shareSnippetSelected ? snippet : undefined}
                    />
                </div>
            </div>
            <div className="hidden bg-gray-50 p-4 md:block md:w-1/3">
                <ShareHistory callId={props.call.id} />
            </div>
        </div>
    )
}

function ShareHistory(props: { callId: string }) {
    const { data: shares } = useQuery(queries.calls.shares(props.callId))

    return (
        <div>
            <h1 className="mb-5 text-lg font-bold">Previously shared</h1>
            <div className="h-[600px] space-y-4 overflow-y-auto">
                {!shares && <LoadingPulse rows={5} />}
                {shares && shares.length === 0 && (
                    <div className="text-gray-600">
                        This call has not been shared yet.
                    </div>
                )}
                {shares &&
                    shares.map((share) => (
                        <SharedCallPreview
                            key={share.share_id}
                            sharedCall={share}
                        />
                    ))}
            </div>
        </div>
    )
}

function ShareWholeCallOptions(props: {
    shareOptions: IShareOptions
    setShareOptions: Dispatch<SetStateAction<IShareOptions>>
}) {
    const handleOptionChange = (
        option: keyof IShareOptions,
        checked: boolean
    ) => {
        props.setShareOptions((prevOptions: IShareOptions) => ({
            ...prevOptions,
            [option]: checked,
        }))
    }

    return (
        <div className="space-y-2 px-2">
            <div className="pb-2">
                Share the entire recording and transcript
            </div>
            <ShareOptions
                options={props.shareOptions}
                onOptionChange={handleOptionChange}
            />
            <div className="inline-flex items-center justify-center gap-1">
                <span>Share recording from </span>
                <TimestampInput
                    setTimestamp={(stringTs) => {
                        props.setShareOptions((prevOptions) => ({
                            ...prevOptions,
                            start_time: stringTs,
                        }))
                    }}
                    timestamp={props.shareOptions.start_time || undefined}
                />
                <span> onwards.</span>
            </div>
        </div>
    )
}

function ShareSnippetOptions(props: {
    call: ICall
    snippet: SnippetOptions
    setSnippet: Dispatch<SetStateAction<SnippetOptions>>
}) {
    const { snippet, setSnippet } = props
    const playerRef = useRef<ReactPlayer>(null)
    const callDuration = props.call.duration || 1

    const { data: media } = useQuery({
        ...queries.calls.media(props.call.id),
        staleTime: Infinity, // Don't refetch media as it will disrupt playback
    })
    const playerHeight = media?.type === "video" ? "100%" : "50px"

    const setStartValue = useCallback(
        (value: number) => {
            setSnippet((currentValues) => ({
                start: value,
                end: Math.max(value, currentValues.end),
            }))
        },
        [setSnippet]
    )

    const setEndValue = useCallback(
        (value: number) => {
            setSnippet((currentValues) => ({
                start: Math.min(value, currentValues.start),
                end: value,
            }))
        },
        [setSnippet]
    )

    // Enforce start and end are less than duration
    useEffect(() => {
        if (snippet.start > callDuration) {
            setStartValue(callDuration)
        }
        if (snippet.end > callDuration) {
            setEndValue(callDuration)
        }
    }, [snippet.start, snippet.end, callDuration, setStartValue, setEndValue])

    // Highlight turns in the snippet
    const highlightedTurns = useMemo(() => {
        return findTurnsInTimePeriod(
            props.call.transcript_turns || [],
            snippet.start,
            snippet.end
        )
    }, [snippet.start, snippet.end, props.call.transcript_turns])

    return (
        <div className="space-y-2 px-2">
            <div>Share a cropped snippet of the recording and transcript</div>
            <div className="grid grid-cols-5 space-x-2">
                <div className="col-span-3 space-y-2 px-4">
                    <div className="overflow-hidden rounded-lg">
                        <ReactPlayer
                            id="media-player"
                            url={media?.signed_url || undefined}
                            controls={true}
                            ref={playerRef}
                            height={playerHeight}
                            width="100%"
                            onProgress={({ playedSeconds }) => {
                                // Keep playback within snippet boundaries
                                if (
                                    playedSeconds < snippet.start ||
                                    playedSeconds > snippet.end
                                ) {
                                    playerRef.current?.seekTo(snippet.start)
                                }
                            }}
                        />
                    </div>
                    <MediaRange
                        callDuration={callDuration}
                        snippet={snippet}
                        playerRef={playerRef}
                        setSnippet={setSnippet}
                    />
                    <SnippetTimestampInput
                        snippet={snippet}
                        setStartValue={setStartValue}
                        setEndValue={setEndValue}
                        getPlayerTime={() =>
                            playerRef.current?.getCurrentTime() || 0
                        }
                    />
                </div>
                <div className="col-span-2 h-[340px] overflow-auto rounded-lg border bg-gray-100 text-sm">
                    <CallTranscript
                        transcriptTurns={props.call.transcript_turns}
                        highlightedTurns={highlightedTurns}
                        parties={props.call.parties}
                        followMedia={true}
                        goToTimestamp={(timestamp) => {
                            seekToPosition(
                                playerRef,
                                timestampToSeconds(timestamp)
                            )
                        }}
                        highlightColour={colors.blue[100]}
                    />
                </div>
            </div>
        </div>
    )
}

function MediaRange(props: {
    callDuration: number
    snippet: SnippetOptions
    playerRef: React.RefObject<ReactPlayer>
    setSnippet: Dispatch<SetStateAction<SnippetOptions>>
}) {
    const { callDuration, snippet, playerRef, setSnippet } = props

    // Get current media playback time to highlight in the range component
    const [currentPlaybackTime, setCurrentPlaybackTime] = useState(0)
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentPlaybackTime(playerRef.current?.getCurrentTime() || 0)
        }, 100)

        return () => clearInterval(interval)
    }, [playerRef])

    const [prevSnippet, setPrevSnippet] = React.useState<SnippetOptions>({
        start: 0,
        end: callDuration,
    }) // previous snippet values used to determine which thumb is being dragged

    return (
        <div>
            <Range
                label="Select your value"
                step={1}
                min={0}
                max={callDuration}
                values={[snippet.start, snippet.end]}
                allowOverlap={false}
                onChange={(newValues) => {
                    const newSnippet: SnippetOptions = {
                        start: newValues[0],
                        end: newValues[1],
                    }
                    if (newSnippet.start !== prevSnippet.start) {
                        // Start thumb changed, seek to start position
                        seekToPosition(playerRef, newSnippet.start)
                    } else if (newSnippet.end !== prevSnippet.end) {
                        // End thumb changed, seek to end position and pause playback
                        seekToPosition(playerRef, newSnippet.end)
                        playerRef.current?.getInternalPlayer()?.pause()
                    }

                    setPrevSnippet(newSnippet)
                    setSnippet(newSnippet)
                }}
                renderTrack={({ props, children }) => {
                    const snippetDuration = snippet.end - snippet.start
                    const playbackPercentage = Math.min(
                        100,
                        Math.max(
                            0,
                            ((currentPlaybackTime - snippet.start) /
                                snippetDuration) *
                                100
                        )
                    )

                    return (
                        <div
                            {...props}
                            className="relative h-8 w-full rounded-lg bg-gray-200"
                        >
                            {/* Selected snippet region */}
                            <div
                                className="absolute h-8 border-y border-blue-500"
                                style={{
                                    left: `${
                                        (snippet.start / callDuration) * 100
                                    }%`,
                                    right: `${
                                        100 - (snippet.end / callDuration) * 100
                                    }%`,
                                }}
                            >
                                {/* Played portion of the snippet */}
                                <div
                                    className="absolute h-full border-r border-blue-400 bg-blue-300"
                                    style={{
                                        left: 0,
                                        right: `${100 - playbackPercentage}%`,
                                    }}
                                />
                                {/* Unplayed portion of the snippet */}
                                <div
                                    className="absolute h-full bg-blue-200"
                                    style={{
                                        left: `${playbackPercentage}%`,
                                        right: 0,
                                    }}
                                />
                            </div>
                            {children}
                        </div>
                    )
                }}
                renderThumb={({ props, isDragged, index }) => (
                    <div
                        {...props}
                        className={clsx(
                            "flex h-8 w-10 items-center transition-colors duration-200",
                            index === 0 ? "flex-row" : "flex-row-reverse"
                        )}
                    >
                        <div
                            className={clsx(
                                "flex h-8 w-5 items-center justify-center bg-blue-500",
                                isDragged && "bg-blue-600",
                                index === 0 ? "rounded-l-lg" : "rounded-r-lg"
                            )}
                        >
                            <div className={clsx("flex space-x-[2px]")}>
                                <div className="h-4 w-[1px] bg-blue-100"></div>
                                <div className="h-4 w-[1px] bg-blue-100"></div>
                            </div>
                        </div>
                        {/* Invisible half, needed to center the thumb on the inner edge */}
                        <div className="w-5 opacity-0" />
                    </div>
                )}
            />
        </div>
    )
}

function SnippetTimestampInput(props: {
    snippet: SnippetOptions
    setStartValue: (seconds: number) => void
    setEndValue: (seconds: number) => void
    getPlayerTime: () => number
}) {
    const { snippet, setStartValue, setEndValue, getPlayerTime } = props

    return (
        <div>
            <div className="flex items-center justify-between gap-2">
                <span className="w-14">
                    <TimestampInput
                        setTimestamp={(stringTs) => {
                            const seconds = timestampToSeconds(stringTs)
                            setStartValue(seconds)
                        }}
                        timestamp={secondsToTimestamp(snippet.start)}
                    />
                </span>
                <span>{secondsToTimestamp(snippet.end - snippet.start)}</span>
                <span className="w-14">
                    <TimestampInput
                        setTimestamp={(stringTs) => {
                            setEndValue(timestampToSeconds(stringTs))
                        }}
                        timestamp={secondsToTimestamp(snippet.end)}
                    />
                </span>
            </div>
            <div className="flex justify-between gap-2 px-3">
                <button
                    className="hover:text-gray-500"
                    onClick={() => {
                        setStartValue(getPlayerTime())
                    }}
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content="Set snippet start to the current playback time"
                >
                    Start
                </button>
                <span>Duration</span>
                <button
                    className="hover:text-gray-500"
                    onClick={() => {
                        setEndValue(getPlayerTime())
                    }}
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content="Set snippet end to the current playback time"
                >
                    End
                </button>
            </div>
        </div>
    )
}

function seekToPosition(
    playerRef: React.RefObject<ReactPlayer>,
    seconds: number
) {
    playerRef.current?.seekTo(seconds, "seconds")
}

function findTurnsInTimePeriod(
    turns: ITranscriptTurn[],
    start: number,
    end: number
): ITranscriptTurn[] {
    // Find first and last turns that cover the time period
    const firstTurnIndex = Math.max(
        0,
        turns.findIndex((turn) => timestampToSeconds(turn.timestamp) > start) -
            1 // Use "- 1" to include the previous turn to be safe, as it may include some of the media
    )
    const lastTurnIndex = turns.findIndex(
        (turn) => timestampToSeconds(turn.timestamp) > end
    )
    // handle case where end is after last turn
    if (lastTurnIndex === -1) {
        return turns.slice(firstTurnIndex)
    }

    // return all turns between these two
    return turns.slice(firstTurnIndex, lastTurnIndex)
}

function ShareButtonsFooter(props: {
    callId: string
    expiryDays: number
    emailsToShare: string[]
    snippet?: SnippetOptions
    options?: IShareOptions
}) {
    const { addNotification } = useNotification()
    const sendEmailsEnabled = props.emailsToShare.length > 0

    const handleSendEmailsClicked = useMutation({
        mutationFn: async () => {
            const sharedCallUrl = await generateSharedCall(
                props.callId,
                props.expiryDays,
                props.emailsToShare,
                props.options,
                props.snippet
            )
            addNotification(
                "Call shared!",
                `This call will be accessible to anyone for the next ${props.expiryDays} days at: ${sharedCallUrl}`,
                NotificationType.Success
            )
        },
        onSuccess: () => {
            queryClient.refetchQueries(queries.calls.shares(props.callId))
        },
        onError: (error) => {
            addNotification(
                "Failed to share call!",
                `${error}`,
                NotificationType.Error
            )
        },
    })

    const handleCopyLinkClicked = useMutation({
        mutationFn: async () => {
            copyAsyncResultToClipboard(async () => {
                const text = await generateSharedCall(
                    props.callId,
                    props.expiryDays,
                    [],
                    props.options,
                    props.snippet
                )
                return text
            })
            addNotification(
                "Shareable link copied!",
                `This call will be accessible to anyone with the copied link for the next ${props.expiryDays} days.`,
                NotificationType.Success
            )
        },
        onSuccess: () => {
            queryClient.refetchQueries(queries.calls.shares(props.callId))
        },
        onError: (error) => {
            addNotification(
                "Failed to share call!",
                `${error}`,
                NotificationType.Error
            )
        },
    })
    return (
        <div className="flex justify-end space-x-2">
            <SecondaryButton
                className="space-x-2"
                onClick={() => handleCopyLinkClicked.mutate()}
            >
                <FontAwesomeIcon icon={faLink} />
                <span>Copy link</span>
            </SecondaryButton>
            <PrimaryButton
                className="space-x-2"
                disabled={!sendEmailsEnabled}
                onClick={() => handleSendEmailsClicked.mutate()}
            >
                <FontAwesomeIcon icon={faPaperPlane} />
                <span>Send emails</span>
            </PrimaryButton>
        </div>
    )
}

function ShareOptions(props: {
    options: IShareOptions
    onOptionChange: (option: keyof IShareOptions, checked: boolean) => void
}) {
    return (
        <div className="w-fit space-y-2">
            <span className="flex space-x-2">
                <ToggleButton
                    checked={props.options.share_summary}
                    onChange={(checked) =>
                        props.onOptionChange("share_summary", checked)
                    }
                />
                <span>Share AI Summary</span>
            </span>
            <span className="flex space-x-2">
                <ToggleButton
                    checked={props.options.share_annotations}
                    onChange={(checked) =>
                        props.onOptionChange("share_annotations", checked)
                    }
                />
                <span>Share Overview tab</span>
            </span>
            <span className="flex space-x-2">
                <ToggleButton
                    checked={props.options.share_custom_insights}
                    onChange={(checked) =>
                        props.onOptionChange("share_custom_insights", checked)
                    }
                />
                <span>Share Insights tab</span>
            </span>
            <span className="flex space-x-2">
                <ToggleButton
                    checked={props.options.share_talk_stats}
                    onChange={(checked) =>
                        props.onOptionChange("share_talk_stats", checked)
                    }
                />
                <span>Share Talk Stats tab</span>
            </span>
            <span className="flex space-x-2">
                <ToggleButton
                    checked={props.options.enable_ask_glyphic}
                    onChange={(checked) =>
                        props.onOptionChange("enable_ask_glyphic", checked)
                    }
                />
                <span>Enable Ask Glyphic</span>
            </span>
        </div>
    )
}

function EmailShareSection(props: {
    emails: string[]
    setEmails: React.Dispatch<React.SetStateAction<string[]>>
}) {
    interface SelectValue {
        readonly label: string
        readonly value: string
    }

    const createSelectValue = (label: string) => ({
        label,
        value: label,
    })

    const { addNotification } = useNotification()
    const [inputValue, setInputValue] = useState("")
    const [value, setValue] = useState<readonly SelectValue[]>(
        props.emails.map(createSelectValue)
    )
    const setEmails = props.setEmails

    useEffect(() => {
        const emails: string[] = _.uniq(
            value.map((selectValue) => selectValue.value)
        )
        setEmails(emails)
    }, [value, setEmails])

    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (!inputValue) return
        switch (event.key) {
            case "Enter":
            case "Tab":
            case ",":
            case " ":
                enterValue()
                event.preventDefault()
        }
    }

    function enterValue() {
        if (!inputValue) return

        if (validator.isEmail(inputValue)) {
            setValue((prev) =>
                _.uniqBy([...prev, createSelectValue(inputValue)], "value")
            )
            setInputValue("")
        } else {
            addNotification(
                `Invalid email "${inputValue}"`,
                "Please enter a valid email address",
                NotificationType.Error
            )
        }
    }

    return (
        <div className="space-y-2 text-base">
            <div>Share this call via email.</div>
            <CreatableSelect
                className="w-full"
                components={{
                    DropdownIndicator: null,
                }}
                inputValue={inputValue}
                isClearable
                isMulti
                onBlur={enterValue}
                menuIsOpen={false}
                onChange={(newValue) => setValue(newValue)}
                onInputChange={(newValue) => setInputValue(newValue)}
                onKeyDown={handleKeyDown}
                placeholder="Enter space-separated email addresses"
                value={value}
                // Override default styling of CreatableSelect
                styles={{
                    multiValue: (styles, { data }) => {
                        return {
                            ...styles,
                            borderRadius: "8px",
                            backgroundColor: "#F3F4F6",
                        }
                    },
                    control: (styles, state) => ({
                        ...styles,
                        borderRadius: "8px",
                    }),
                }}
            />
        </div>
    )
}

type SetTimestampFunction = (formattedInput: string) => void

interface TimestampInputProps {
    setTimestamp: SetTimestampFunction
    timestamp: string | undefined
}

function TimestampInput(props: TimestampInputProps) {
    const [isValid, setIsValid] = useState(true)

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value
        const filteredInput = input.replace(/\D/g, "")
        let formattedInput = ""
        if (filteredInput.length >= 2) {
            formattedInput = `${filteredInput.slice(
                0,
                -2
            )}:${filteredInput.slice(-2)}`
        } else {
            formattedInput = filteredInput
        }
        props.setTimestamp(formattedInput)
        const regex = /^(\d*:[0-9][0-9]|\d+)$/
        setIsValid(regex.test(formattedInput))
        if (input === "") {
            setIsValid(true)
        }
    }

    return (
        <div>
            <input
                className={`inline-flex w-16 justify-center rounded-lg border px-2 py-1 text-right ${
                    isValid ? "" : "border-red-500"
                }`}
                type="string"
                onChange={handleInputChange}
                placeholder="00:00"
                value={props.timestamp}
            />
        </div>
    )
}

function getParticipantEmails(participants: IParticipant[]): string[] {
    // Returns list of participant emails
    // If participants do not have an email, they are not returned

    let emails: string[] = []
    participants.forEach((p) => {
        if (p.email) {
            emails.push(p.email)
        }
    })
    return emails
}

async function generateSharedCall(
    call_id: string,
    expiryDays: number,
    emailsToNotify: string[],
    shareOptions?: IShareOptions,
    snippet?: SnippetOptions
): Promise<string> {
    getHeapInstance()?.track("share-call-clicked")
    const response = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/calls/${call_id}/shares`,
        {
            expiry_days: expiryDays,
            emails_to_notify: emailsToNotify,
            start_time: shareOptions?.start_time,
            enable_ask_glyphic: shareOptions?.enable_ask_glyphic,
            share_summary: shareOptions?.share_summary,
            share_annotations: shareOptions?.share_annotations,
            share_custom_insights: shareOptions?.share_custom_insights,
            share_talk_stats: shareOptions?.share_talk_stats,
            snippet,
        }
    )
    return response.data.url
}

function copyAsyncResultToClipboard(fetchAsyncResult: () => Promise<string>) {
    // See https://wolfgangrittner.dev/how-to-use-clipboard-api-in-firefox/
    if (typeof ClipboardItem && navigator.clipboard.write) {
        // NOTE: Safari locks down the clipboard API to only work when triggered
        //   by a direct user interaction. You can't use it async in a promise.
        //   But! You can wrap the promise in a ClipboardItem, and give that to
        //   the clipboard API.
        //   Found this on https://developer.apple.com/forums/thread/691873
        const text = new ClipboardItem({
            "text/plain": fetchAsyncResult().then(
                (text) => new Blob([text], { type: "text/plain" })
            ),
        })
        navigator.clipboard.write([text])
    } else {
        // NOTE: Firefox has support for ClipboardItem and navigator.clipboard.write,
        //   but those are behind `dom.events.asyncClipboard.clipboardItem` preference.
        //   Good news is that other than Safari, Firefox does not care about
        //   Clipboard API being used async in a Promise.
        fetchAsyncResult().then((text) => navigator.clipboard.writeText(text))
    }
}
