import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileAlt, faLandmark } from "@fortawesome/pro-solid-svg-icons"
import { DocumentType } from "../../types/Citation"
import { RecordingIcon } from "../../assets/icons/RecordingIcon"
import {
    CitationsMessageBlock,
    DisplayMessageBlock,
    isCitationMessageBlock,
    isTextMessageBlock,
    TextMessageBlock,
} from "./types/ThreadTypes"
import { CitationProps, LinkOrDiv, getCitationUrl } from "./CitationCard"

type DisplayBlockPair = {
    textBlock: TextMessageBlock
    citations: CitationsMessageBlock[]
}

/**
 * Creates a list of text blocks and their corresponding citations.
 * This helper function helps pairing up citations with their corresponding
 * text blocks. This is useful for rendering the citations in the UI, inline
 * with the text that they are associated with (see `AiMessage`).
 *
 * This code assumes:
 * - we can have multiple citations per block.
 * - citations arrive before the text block that they are associated with.
 *
 * @param blocks The full list of available display message blocks to pair
 * @returns A list of text blocks and their corresponding citations paired up
 */
export function createDisplayBlockPairs(
    blocks: DisplayMessageBlock[]
): DisplayBlockPair[] {
    const pairs: DisplayBlockPair[] = []
    let currentCitations: CitationsMessageBlock[] = []

    // Whenever we see a text block, we pair up any citations that have
    // arrived so far with it, and reset the list of current citations.
    for (const block of blocks) {
        if (isTextMessageBlock(block)) {
            pairs.push({
                textBlock: block,
                citations: currentCitations,
            })
            currentCitations = []
        } else if (isCitationMessageBlock(block) && block.citation) {
            currentCitations.push(block)
        }
    }

    return pairs
}

export function CitationPill(props: CitationProps) {
    const getIconForDocumentType = () => {
        switch (props.citation.type) {
            case DocumentType.CALL_SUMMARY:
                return (
                    <FontAwesomeIcon
                        icon={faFileAlt}
                        className="text-gray-500"
                    />
                )
            case DocumentType.CALL_TRANSCRIPT:
                return <RecordingIcon className="h-4 w-4 text-gray-500" />
            case DocumentType.COMPANY_NOTES:
                return (
                    <FontAwesomeIcon
                        icon={faLandmark}
                        className="text-gray-500"
                    />
                )
            default:
                return (
                    <FontAwesomeIcon
                        icon={faFileAlt}
                        className="text-gray-500"
                    />
                )
        }
    }

    const url = getCitationUrl(props.citation)
    return (
        <LinkOrDiv
            url={url}
            className="flex w-fit items-center gap-1 rounded-md bg-gray-50 p-1 text-xs font-normal text-gray-800 hover:bg-gray-100"
            data-title={props.citation.title || "Citation"}
            data-content={props.citation.cited_text}
        >
            {getIconForDocumentType()}
            <span className="max-w-[70px] overflow-hidden text-ellipsis whitespace-nowrap">
                {props.citation.title || "Citation"}
            </span>
        </LinkOrDiv>
    )
}
