import CrmSyncWindow from "./CrmSyncWindow"
import { useCallback, useEffect, useState } from "react"
import { useIntegrationApp } from "@integration-app/react"
import { CrmType } from "./types/Crm"
import { findValidCrmType } from "./utils/integration"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileExport } from "@fortawesome/pro-regular-svg-icons"
import { getHeapInstance } from "../../utils/heap"
import clsx from "clsx"

interface CrmInteractionProps {
    callId: string
    notes: string
}

/**
 * Component used to switch between the different CRM buttons states while
 * keeping it isolated from users without access to CRM feature.
 */
export function SyncNotesToCrmButton({ callId, notes }: CrmInteractionProps) {
    // We need this component so that we do no integration app related
    // operations if the user has no access to the feature.
    const crmClient = useIntegrationApp()
    const { addNotification } = useNotification()

    const [crmType, setCrmType] = useState<CrmType | undefined>()

    useEffect(() => {
        async function findConnections() {
            try {
                try {
                    const connections = (await crmClient.connections.find())
                        .items
                    setCrmType(findValidCrmType(connections))
                } catch (e) {
                    addNotification(
                        "Oops!",
                        "There's a problem with the CRM connection, please check your settings.",
                        NotificationType.Error
                    )
                }
            } catch (e) {
                console.error("Integration app: Failed to find connections", e)
            }
        }

        if (crmClient && crmClient.token) {
            findConnections()
        }
    }, [crmClient, addNotification])

    return <CrmSyncButton crmType={crmType} callId={callId} notes={notes} />
}

interface CrmSyncButtonProps {
    crmType: CrmType | undefined
    callId: string
    notes: string
}

function CrmSyncButton({ crmType, callId, notes }: CrmSyncButtonProps) {
    const [showCrmWindow, setShowCrmWindow] = useState(false)

    const handleShowEditor = useCallback(() => {
        getHeapInstance()?.track("call-sync-notes-opened")
        setShowCrmWindow(true)
    }, [])

    const handleCloseEditor = useCallback(() => {
        setShowCrmWindow(false)
    }, [])

    const enabled = crmType !== undefined

    return (
        <>
            <button
                className={clsx(
                    "flex flex-col items-center text-sm",
                    enabled
                        ? "text-gray-600 hover:text-gray-800"
                        : "cursor-not-allowed text-gray-400"
                )}
                onClick={handleShowEditor}
                disabled={!enabled}
                data-tooltip-id="tooltip-explanation"
                data-tooltip-content={
                    enabled ? "" : "Connect CRM to send notes"
                }
            >
                <FontAwesomeIcon icon={faFileExport} className="h-4 w-4" />
                <span className="hidden md:block">Sync notes</span>
            </button>

            {crmType && (
                <CrmSyncWindow
                    crmType={crmType}
                    callId={callId}
                    notes={notes}
                    isOpen={showCrmWindow}
                    onClose={handleCloseEditor}
                />
            )}
        </>
    )
}
