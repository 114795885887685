import { useEffect, useState } from "react"

import { ICallPrepData } from "../../types/CallPrep"
import { Cues } from "./Cues"
import { TabHead } from "../common/Tabs"
import { CaseStudies } from "./CaseStudies"
import { CompanyCard } from "./CompanyCard"
import { UpcomingCallInfo } from "./UpcomingCallInfo"
import { PeopleCard } from "../company-view/PeopleCard"

enum Tabs {
    Overview = "Overview",
    CaseStudies = "Similar Case Studies",
}
type Tab = { type: Tabs; label: string }

export function CallPrep(props: { sheet: ICallPrepData }) {
    const { sheet } = props
    const [tabs, setTabs] = useState<Tab[]>([])
    const [activeTab, setActiveTab] = useState(0)
    const handleTabChange = (index: number) => {
        setActiveTab(index)
    }

    useEffect(() => {
        let availableTabs: Tab[] = []
        if (sheet.cues !== null) {
            availableTabs.push({
                type: Tabs.Overview,
                label: Tabs.Overview.toString(),
            })
        }
        if (sheet.case_studies !== null) {
            availableTabs.push({
                type: Tabs.CaseStudies,
                label: Tabs.CaseStudies.toString(),
            })
        }
        setTabs(availableTabs)
    }, [sheet])

    const participantsExist =
        sheet.participants !== undefined && sheet.participants.length > 0

    // If there is no playbook/case study info to show, we only display
    // company/contact info
    const columnsToDisplay = tabs.length > 0 ? "grid-cols-3" : "grid-cols-1"

    return (
        <div className="space-y-6 bg-gray-50 p-8">
            <h1 className="text-2xl font-bold">Prepare for your call</h1>
            {sheet.call_info && <UpcomingCallInfo callInfo={sheet.call_info} />}
            <div className={`grid ${columnsToDisplay} space-x-4`}>
                {tabs.length > 0 && (
                    <div className="col-span-2 space-y-4 whitespace-pre-wrap">
                        <div className="w-fit">
                            <TabHead
                                tabs={tabs}
                                activeTab={activeTab}
                                onTabChange={handleTabChange}
                            />
                        </div>
                        {tabs[activeTab].type === Tabs.Overview &&
                            sheet.cues && <Cues cues={sheet.cues} />}
                        {tabs[activeTab].type === Tabs.CaseStudies &&
                            sheet.case_studies && (
                                <CaseStudies caseStudies={sheet.case_studies} />
                            )}
                    </div>
                )}
                <div className="col-span-1 space-y-3">
                    {sheet.company_metadata?.domain && (
                        <CompanyCard
                            companyDomain={sheet.company_metadata?.domain}
                        />
                    )}
                    {participantsExist && (
                        <PeopleCard
                            caption="In this call"
                            people={sheet.participants}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
