import { Helmet } from "react-helmet"

interface PageTitleProps {
    title: string
    suffix?: string
}

export const PageTitle = ({
    title,
    suffix = process.env.REACT_APP_DOCUMENT_TITLE,
}: PageTitleProps) => {
    const fullTitle = suffix ? `${title} | ${suffix}` : title

    return (
        <Helmet>
            <title>{fullTitle}</title>
        </Helmet>
    )
}
