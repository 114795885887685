import { useEffect, useState, useCallback } from "react"
import { useIntegrationApp } from "@integration-app/react"
import hubspotLogo from "../../assets/crm-logos/hubspot.png"
import salesforceLogo from "../../assets/crm-logos/salesforce.png"
import pipedriveLogo from "../../assets/crm-logos/pipedrive.png"

import { IntegrationSetting } from "./IntegrationSetting"
import { CrmType } from "../crm/types/Crm"
import { findValidCrmType } from "../crm/utils/integration"
import { CrmConfig } from "./CrmConfig"
import { SettingsSection } from "./Card"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle as icon } from "@fortawesome/free-solid-svg-icons"

export function CrmIntegrationSettings() {
    const [activeConnectionType, setActiveConnectionType] = useState<
        CrmType | undefined
    >()
    const crmClient = useIntegrationApp()

    async function openCrmConnection(crmType: CrmType) {
        await crmClient
            .integration(crmType)
            .openNewConnection({ showPoweredBy: false })
        updateActiveConnection()
    }

    async function closeCrmConnection(crmType: CrmType) {
        await crmClient.integration(crmType).disconnect()
        updateActiveConnection()
    }

    const updateActiveConnection = useCallback(async () => {
        try {
            const response = await crmClient.connections.find()
            const connections = response.items
            setActiveConnectionType(findValidCrmType(connections))
        } catch (error) {
            console.error("Failed to set CRM connection", error)
        }
    }, [crmClient?.connections])

    useEffect(() => {
        updateActiveConnection()
    }, [updateActiveConnection])

    return (
        <SettingsSection
            title="CRM data sync"
            description="Sync data between your CRM and Glyphic."
            id="crm"
        >
            <>
                {activeConnectionType === undefined && <AdminWarning />}

                <ConnectedCrmOption
                    name="HubSpot"
                    logo={hubspotLogo}
                    crmType={CrmType.Hubspot}
                    onConnect={openCrmConnection}
                    onDisconnect={closeCrmConnection}
                    activeConnectionType={activeConnectionType}
                />
            </>
            <ConnectedCrmOption
                name="Salesforce"
                logo={salesforceLogo}
                crmType={CrmType.Salesforce}
                onConnect={openCrmConnection}
                onDisconnect={closeCrmConnection}
                activeConnectionType={activeConnectionType}
            />
            <ConnectedCrmOption
                name="Pipedrive"
                logo={pipedriveLogo}
                crmType={CrmType.Pipedrive}
                onConnect={openCrmConnection}
                onDisconnect={closeCrmConnection}
                activeConnectionType={activeConnectionType}
            />
        </SettingsSection>
    )
}

interface CrmOptionProps {
    name: string
    logo: string
    crmType: CrmType
}

interface ConnectedCrmOptionProps extends CrmOptionProps {
    onConnect: (crmType: CrmType) => Promise<void>
    onDisconnect: (crmType: CrmType) => Promise<void>
    activeConnectionType?: CrmType
}

function ConnectedCrmOption(props: ConnectedCrmOptionProps) {
    const handleConnect = async () => {
        await props.onConnect(props.crmType)
    }
    const handleDisconnect = async () => {
        await props.onDisconnect(props.crmType)
    }

    const isActiveConnection = props.activeConnectionType === props.crmType
    if (props.activeConnectionType && !isActiveConnection) {
        // Another CRM is already connected
        return <DisabledCrmIntegrationSetting {...props} />
    }

    return (
        <IntegrationSetting
            name={props.name}
            logo={props.logo}
            onEnable={handleConnect}
            onDisable={handleDisconnect}
            authorized={isActiveConnection}
            config={<CrmConfig crmType={props.crmType} />}
        />
    )
}

function AdminWarning() {
    return (
        <div className="mb-4 flex w-fit flex-row items-center justify-start gap-4 rounded-md border border-yellow-400 bg-yellow-50 p-3 text-sm text-yellow-600">
            <FontAwesomeIcon icon={icon} className="text-base" />
            <p className="max-w-fit flex-shrink overflow-hidden break-words text-sm first-letter:capitalize">
                <strong>
                    Please ensure the account used to connect a CRM has
                    administrative permissions.
                </strong>
            </p>
        </div>
    )
}

function DisabledCrmIntegrationSetting(props: CrmOptionProps) {
    return (
        <div className="flex items-center justify-between opacity-50">
            <div className="flex space-x-4">
                <img
                    src={props.logo}
                    alt={props.name}
                    className="h-10 rounded-lg"
                />{" "}
                <div className="self-center font-bold">{props.name}</div>
            </div>

            <div className="hidden space-x-2 italic md:block">
                Disconnect other CRMs to enable this one.
            </div>
        </div>
    )
}
