import React, { useEffect, useMemo } from "react"

import { IParticipant, ITranscriptTurn } from "../../types/Call"
import { UserCircle } from "../UserCircle"
import { getParticipantDisplayName } from "../../utils/getParticipantDisplayName"
import { ScreenSize, screenSmallerThan } from "../../utils/screenSize"
import { PrimaryButton } from "../common/Buttons"
import colors from "tailwindcss/colors"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMessagePlus } from "@fortawesome/pro-regular-svg-icons"

export function CallTranscript(props: {
    transcriptTurns: ITranscriptTurn[] | null
    highlightedTurns: ITranscriptTurn[]
    parties: IParticipant[]
    followMedia: boolean
    setFollowMedia?: (enabled: boolean) => void
    goToTimestamp: (timestamp: string) => void
    highlightColour?: string
}) {
    const handleManualScroll = () => {
        props.setFollowMedia && props.setFollowMedia(false)
    }

    const handleResumeTranscriptScrollClicked = () => {
        props.setFollowMedia && props.setFollowMedia(true)
    }

    // Create map of turnIds to References, to allow us to scroll to a given turn
    const turnToRefMap: Map<
        number,
        React.RefObject<HTMLDivElement>
    > = useMemo(() => {
        if (!props.transcriptTurns) {
            return new Map()
        }

        return new Map(
            props.transcriptTurns.map((turn) => [
                turn.turn_start,
                React.createRef<HTMLDivElement>(),
            ])
        )
    }, [props.transcriptTurns])

    useEffect(() => {
        const scrollToTurn = (turn: ITranscriptTurn) => {
            if (!props.followMedia) {
                return
            }

            if (screenSmallerThan(ScreenSize.md)) {
                // Do not follow media when in mobile view
                return
            }

            const ref = turnToRefMap.get(turn.turn_start)!

            ref.current!.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            })
        }

        // We need to run this in useEffect as React will not have created the DOM elements on the first render
        // https://beta.reactjs.org/learn/manipulating-the-dom-with-refs#when-react-attaches-the-refs
        if (props.highlightedTurns.length > 0) {
            scrollToTurn(props.highlightedTurns[0])
        }
    }, [props.highlightedTurns, props.followMedia, turnToRefMap])

    if (props.transcriptTurns === null || props.transcriptTurns.length === 0) {
        return (
            <div className="h-12 w-full rounded-lg bg-gray-100 p-3 text-center text-gray-500">
                No transcript to display
            </div>
        )
    }

    const showResumeAutoScrollButton =
        !props.followMedia && !screenSmallerThan(ScreenSize.md)

    return (
        <div className="flex-grow overflow-y-auto rounded-xl shadow">
            <div onWheel={handleManualScroll} className="relative">
                <div className="h-full w-full space-y-2 overflow-y-auto px-3 py-2">
                    {props.transcriptTurns.map((turn) => {
                        const id = turn.turn_start
                        const party = props.parties.find(
                            (p) => p.id === turn.party_id
                        )!
                        return (
                            <TranscriptTurn
                                key={id}
                                transcriptTurn={turn}
                                party={party}
                                isHighlighted={props.highlightedTurns.includes(
                                    turn
                                )}
                                ref={turnToRefMap.get(id)!}
                                goToTimestamp={props.goToTimestamp}
                                highlightColour={props.highlightColour}
                            />
                        )
                    })}
                </div>
                <PrimaryButton
                    onClick={handleResumeTranscriptScrollClicked}
                    className={`sticky bottom-6 left-1/2 my-2 -translate-x-1/2 whitespace-nowrap transition-opacity ${
                        showResumeAutoScrollButton ? "opacity-100" : "opacity-0"
                    }`}
                >
                    <div className="flex flex-row items-center gap-2">
                        Resume Transcript Auto-Scroll
                    </div>
                </PrimaryButton>
            </div>
        </div>
    )
}

export const TranscriptTurn = React.forwardRef(
    (
        props: {
            transcriptTurn: ITranscriptTurn
            isHighlighted: boolean
            party: IParticipant
            goToTimestamp: (timestamp: string) => void
            highlightColour?: string
            onAddComment?: () => void
        },
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        const turn = props.transcriptTurn
        const partyName = getParticipantDisplayName(props.party)

        const highlightColour = props.highlightColour || colors.gray[100]

        return (
            <div
                className="group scroll-mt-2 rounded-md p-1 transition-colors"
                style={
                    props.isHighlighted
                        ? { backgroundColor: highlightColour }
                        : undefined
                }
                ref={ref}
            >
                <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                        <UserCircle user={props.party} size="24px" />
                        <span className="font-bold">{partyName}</span>
                        <button
                            className="text-gray-500 hover:font-semibold"
                            onClick={() => props.goToTimestamp(turn.timestamp)}
                        >
                            {turn.timestamp}
                        </button>
                    </div>
                    {props.onAddComment && (
                        <FontAwesomeIcon
                            icon={faMessagePlus}
                            className="cursor-pointer text-gray-600 opacity-0 transition-opacity hover:text-gray-800 group-hover:opacity-100"
                            data-tooltip-id="tooltip-explanation"
                            data-tooltip-content="Leave a comment"
                            onClick={props.onAddComment}
                        />
                    )}
                </div>

                {turn.turn_text}
            </div>
        )
    }
)
