import { createQueryKeys } from "@lukemorales/query-key-factory"
import axios from "axios"
import { OrgSettingsResponse } from "../../types/Settings"
import { getOrgSettings } from "../Settings"

export const organizationQueries = createQueryKeys("organization", {
    settings: () => ({
        queryKey: ["organization/settings"],
        queryFn: async () => {
            return (await getOrgSettings()) as OrgSettingsResponse
        },
    }),
    productDescription: () => ({
        queryKey: ["organization/settings/product-desc"],
        queryFn: async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/organization/settings/product-description`
            )
            return response.data as string
        },
    }),
    orgMemory: () => ({
        queryKey: ["organization/settings/memory"],
        queryFn: async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/organization/settings/memory`
            )
            return response.data as string
        },
    }),
})
