import { useCallback, useEffect, useState } from "react"
import { useRef } from "react"
import _ from "lodash"

import axios from "axios"
import ReactPlayer from "react-player"
import { useParams } from "react-router-dom"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { CallStatus, IParticipant, ITranscriptTurn } from "../../types/Call"
import { IAnnotation } from "../../types/Annotation"
import LoadingSpinner from "../common/LoadingSpinner"
import AskGlyphic from "../question-answering/AskGlyphic"
import { CallDetails } from "./CallDetails"
import {
    getTurnAtTimestamp,
    goToFirstTimestamp,
    goToTimestamp,
} from "./utils/mediaPlayer"
import { ErrorPage } from "../common/ErrorPage"
import { ResourceType } from "../question-answering/types/ResourceTypes"
import { CallOptions } from "./CallOptions"
import { CallAnalysis, Tabs, TabsEnum } from "./CallAnalysis"
import { sendFollowUpEmailForCall } from "../../utils/createEmailLink"
import { BackButton } from "../common/NavigationButtons"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"

import { FrigadeAskGlyphicTour } from "../Frigade"
import { LockIcon } from "../StatusIcon"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { queries } from "../../api/queries"
import { TranscriptAndComments } from "./TranscriptAndComments"
import { PageTitle } from "../PageTitle"
import { useSearchParam } from "../common/hooks/useSearchParam"

export default function Call() {
    const queryClient = useQueryClient()
    const params = useParams()
    const playerRef = useRef<ReactPlayer | null>(null)
    const callId: string = params.call_id as string
    console.assert(!!callId, "Call page should always have a call id!")
    const [selectedAnnotation, setSelectedAnnotation] = useState<
        IAnnotation | undefined
    >()
    const [playerReady, setPlayerReady] = useState<boolean>(false)
    const [mediaPlayedSeconds, setMediaPlayedSeconds] = useState<number>(0)
    const [transcriptFollowMedia, setTranscriptFollowMedia] =
        useState<boolean>(true)
    const [highlightedCommentId] = useSearchParam("commentId")
    const [startTimestamp] = useSearchParam("timestamp")
    const { addNotification } = useNotification()

    const tabs = [
        Tabs[TabsEnum.Annotations],
        Tabs[TabsEnum.CustomInsights],
        Tabs[TabsEnum.TalkStats],
    ]

    const selectAnnotation = (annotation: IAnnotation) => {
        setTranscriptFollowMedia(true)
        setSelectedAnnotation((prevSelectedAnnotation) => {
            if (prevSelectedAnnotation === annotation) {
                return undefined // unselect the annotation
            }
            return annotation
        })
    }

    const { data: call, error: callLoadError } = useQuery({
        ...queries.calls.byId(callId),
        refetchInterval: (query) => {
            return query.state.data?.status === CallStatus.InProgress
                ? 30000 // refetch every 30 seconds while call is in progress
                : false
        },
    })

    const { data: signedUrl, error: mediaError } = useQuery({
        ...queries.calls.media(callId),
        staleTime: Infinity, // Don't refetch media as it will disrupt playback
    })
    const playerHeight = signedUrl?.type === "video" ? "100%" : "50px"

    const mediaAccessDenied =
        mediaError &&
        axios.isAxiosError(mediaError) &&
        mediaError.response?.status === 403

    // If annotations have been selected, find the turns and highlight them
    // TODO: support highlighting parts of a turn, not just whole turns
    const [highlightedTurns, setHighlightedTurns] = useState<ITranscriptTurn[]>(
        []
    )

    // Set initial media timestamp to the specified timestamp or the first turn
    useEffect(() => {
        // if a comment is highlighted, don't auto-scroll to the start timestamp
        // we instead want to scroll to the comment
        if (highlightedCommentId) {
            return
        }
        if (startTimestamp) {
            goToTimestamp(playerRef)(startTimestamp)
        } else {
            goToFirstTimestamp(playerRef, call?.transcript_turns)
        }
    }, [
        playerReady,
        call?.transcript_turns,
        startTimestamp,
        highlightedCommentId,
    ])

    useEffect(() => {
        if (call && selectedAnnotation) {
            setHighlightedTurns(
                findTurnsInAnnotation(call.transcript_turns, selectedAnnotation)
            )
        }
    }, [call, selectedAnnotation])

    const sendEmail = useCallback(
        async (message: string) => {
            if (!call) return
            await sendFollowUpEmailForCall(message, call)
        },
        [call]
    )

    const updateAttendees = useMutation({
        mutationFn: async (newAttendees: IParticipant[]) => {
            if (!call || _.isEqual(newAttendees, call.parties)) return
            const response = await axios.put(
                process.env.REACT_APP_API_DOMAIN +
                    `/calls/${callId}/participants`,
                newAttendees
            )
            return response.data
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: queries.calls.byId(callId).queryKey,
            })
        },
        onError: (error) => {
            addNotification(
                "Failed to update attendees",
                "",
                NotificationType.Error
            )
        },
    })

    if (!!callLoadError) {
        let errorCode: number | undefined
        if (axios.isAxiosError(callLoadError)) {
            errorCode = callLoadError.response?.status
        }
        return (
            <ErrorPage
                error={{
                    code: errorCode,
                    message: "Failed to load call.",
                }}
            />
        )
    }

    if (!call) {
        return <LoadingSpinner />
    }

    const callTitle = call?.title ?? "Untitled call"

    function onMediaProgress(mediaProgress: {
        played: number
        playedSeconds: number
        loaded: number
        loadedSeconds: number
    }) {
        const turn = getTurnAtTimestamp(
            call!.transcript_turns,
            mediaProgress.playedSeconds
        )

        // If we just highlighted turns from pressing an annotation, only move
        // to the next highlight once the media has moved past the first
        // selected turn.
        if (turn !== null && turn !== highlightedTurns[0]) {
            setHighlightedTurns([turn])
        }

        setMediaPlayedSeconds(mediaProgress.playedSeconds)
    }

    function resumeTranscriptScroll() {
        setTranscriptFollowMedia(true)
        setSelectedAnnotation(undefined) // unselect any annotiation
    }

    const callIsCompleted = call.status === CallStatus.Completed
    const callIsCancelled = call.status === CallStatus.Cancelled
    const callIsInProgress =
        call.status === CallStatus.InProgress || !call.status
    const callIsFailed = call.status === CallStatus.Failed
    const canViewDetails = callIsCompleted && call.can_view

    return (
        <div>
            <PageTitle title={callTitle} />
            <div className="flex flex-row items-center justify-between space-x-4 border-b bg-white px-6 py-2 md:h-12">
                <BackButton defaultCaption="All calls" defaultTo={"/calls"} />
                {call.can_view && <CallOptions call={call} />}
            </div>
            <div className="mx-auto grid max-w-7xl py-3 md:h-[calc(100vh-48px)] md:grid-cols-5">
                <div className="col-span-3 overflow-y-auto">
                    <section className="space-y-4 px-6 pb-6">
                        <div className="space-y-5">
                            <div className="flex flex-row flex-nowrap items-center gap-2">
                                {call.is_private && <LockIcon />}
                                <h1 className="text-2xl font-bold">
                                    {callTitle}
                                </h1>
                            </div>
                            <CallDetails
                                call={call}
                                showTags={call.can_view}
                                showCrmRecords={call.can_view}
                                onChange={(newAttendees: IParticipant[]) => {
                                    updateAttendees.mutate(newAttendees)
                                }}
                            />
                        </div>
                        {canViewDetails && (
                            <>
                                <FrigadeAskGlyphicTour />
                                <AskGlyphic
                                    resourceId={callId}
                                    type={ResourceType.Calls}
                                    sendEmail={sendEmail}
                                    autoFocus={false}
                                />

                                <CallAnalysis
                                    tabs={tabs}
                                    call={call}
                                    selectedAnnotation={selectedAnnotation}
                                    selectAnnotation={selectAnnotation}
                                    playerRef={playerRef}
                                />
                            </>
                        )}
                        {callIsCancelled && (
                            <CallCancelledWarning
                                statusReason={call.status_reason}
                            />
                        )}
                        {callIsInProgress && <CallInProgressWarning />}
                        {callIsFailed && <CallFailedWarning />}
                        {!call.can_view && <CallPrivateWarning />}
                    </section>
                </div>

                <div className="col-span-2 flex flex-col space-y-6 overflow-y-auto px-6">
                    <div>
                        {mediaAccessDenied ? (
                            <MediaAccessDeniedWarning />
                        ) : (
                            signedUrl && (
                                <div className="overflow-hidden rounded-lg">
                                    <ReactPlayer
                                        id="media-player"
                                        url={signedUrl.signed_url || undefined}
                                        controls={true}
                                        ref={playerRef}
                                        height={playerHeight}
                                        width="100%"
                                        onProgress={onMediaProgress}
                                        onPlay={() => {
                                            resumeTranscriptScroll()
                                        }}
                                        onReady={() => setPlayerReady(true)}
                                    />
                                </div>
                            )
                        )}
                    </div>
                    <TranscriptAndComments
                        call={call}
                        currentTimestamp={mediaPlayedSeconds}
                        highlightedTurns={highlightedTurns}
                        goToTimestamp={goToTimestamp(playerRef)}
                        followMedia={transcriptFollowMedia}
                        setFollowMedia={setTranscriptFollowMedia}
                        transcriptTurns={call.transcript_turns || []}
                        parties={call.parties}
                        highlightedCommentId={highlightedCommentId}
                    />
                </div>
            </div>
        </div>
    )
}

function CallCancelledWarning(props: { statusReason: string | null }) {
    return (
        <div className="rounded-lg bg-gray-900 p-3 text-center text-white">
            <div>This call could not be processed.</div>
            {props.statusReason && <div>Reason: {props.statusReason}</div>}
        </div>
    )
}

function CallInProgressWarning() {
    return (
        <div className="space-y-4 rounded-lg bg-gray-900 p-3 text-center text-white">
            <div>
                This call is being processed.
                <br />
                Please check back in a few minutes.
            </div>
            <FontAwesomeIcon
                className={"h-6 w-6 font-bold"}
                icon={faSpinner}
                spin
            />
        </div>
    )
}

function CallFailedWarning() {
    return (
        <div className="rounded-lg bg-gray-900 p-3 text-center text-white">
            <div>This call could not be processed.</div>
        </div>
    )
}

function CallPrivateWarning() {
    return (
        <div className="flex flex-row items-center justify-center gap-2 rounded-lg bg-gray-900 p-3 text-white">
            <LockIcon />
            <div>
                This call is private. Only participants can view its details.
            </div>
        </div>
    )
}

function MediaAccessDeniedWarning() {
    return (
        <div className="flex flex-row items-center justify-center gap-2 rounded-lg bg-gray-100 p-3 text-gray-500">
            <LockIcon />
            <div>Access to media for this call is restricted.</div>
        </div>
    )
}

function findTurnsInAnnotation(
    turns: ITranscriptTurn[] | null,
    annotation: IAnnotation
): ITranscriptTurn[] {
    if (!turns) {
        return []
    }
    // Find first and last turns that cover the annotation
    const firstTurnIndex = turns.findIndex(
        (turn) => turn.turn_end > annotation.start
    )
    const lastTurnIndex = turns.findIndex(
        (turn) => turn.turn_start > annotation.end
    )
    // handle case where annotation is after last turn
    if (lastTurnIndex === -1) {
        return turns.slice(firstTurnIndex)
    }

    // return all turns between these two
    return turns.slice(firstTurnIndex, lastTurnIndex)
}
