import _ from "lodash"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { CrmTable, ICrmObjectReference } from "../crm/types/Crm"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { crmIconFromUrl, iconFromCrmTable } from "../crm/utils/crmIconFromUrl"
import { HashLink } from "react-router-hash-link"
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons"
import { getHeapInstance } from "../../utils/heap"
import { queries } from "../../api/queries"
import { useMemo } from "react"

const CrmTableOrder = [
    CrmTable.Deals,
    CrmTable.Companies,
    CrmTable.Contacts,
    CrmTable.Notes, // added for completion, shouldn't be used
    CrmTable.Users, // added for completion, shouldn't be used
]

export function CrmRecords(props: { callId: string }) {
    const {
        data: crmObjects,
        isPending,
        isError,
        error,
    } = useQuery(queries.calls.crmObjects(props.callId))

    const sortedCrmObjects = useMemo(() => {
        if (!crmObjects) return []
        return _.orderBy(
            crmObjects,
            [
                "has_synced",
                (crmObject) => CrmTableOrder.indexOf(crmObject.crm_table),
                "name",
            ],
            ["desc", "asc", "asc"]
        )
    }, [crmObjects])

    const noCrmConntected =
        isError && axios.isAxiosError(error) && error.response?.status === 412

    return (
        <div className="flex h-8 w-full items-start gap-2">
            <h3 className="flex-none text-base font-bold">CRM Records:</h3>
            {isPending ? (
                <div className="h-full w-48 animate-pulse rounded bg-slate-100" />
            ) : (
                <div className="flex gap-1 overflow-hidden overflow-x-auto">
                    {sortedCrmObjects?.map((crmObject) => (
                        <CrmObjectLink
                            key={crmObject.crm_object_id}
                            crmObject={crmObject}
                        />
                    ))}
                    {!sortedCrmObjects?.length && (
                        <span className="space-x-2">
                            <span>None</span>
                            {noCrmConntected && <MissingCRMIntegration />}
                        </span>
                    )}
                </div>
            )}
        </div>
    )
}

function MissingCRMIntegration() {
    return (
        <HashLink smooth to="/settings/organizational#crm">
            <span
                className="overflow-hidden overflow-ellipsis whitespace-nowrap rounded border border-gray-200 bg-gray-50
                px-2 py-1 text-sm text-gray-600 transition-colors hover:bg-gray-200"
            >
                Connect CRM to enable sync
            </span>
        </HashLink>
    )
}

function CrmObjectLink(props: { crmObject: ICrmObjectReference }) {
    const displayName = props.crmObject.name || props.crmObject.email
    return (
        <a
            className="flex w-fit max-w-full items-center gap-1 rounded border border-gray-200 bg-gray-50
                     px-2 py-1 text-sm text-gray-800 transition-colors hover:bg-gray-200"
            href={props.crmObject.crm_object_url}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
                getHeapInstance()?.track("crm-record-link-click", {
                    crm_table: props.crmObject.crm_table,
                    has_synced: props.crmObject.has_synced,
                })
            }}
        >
            <FontAwesomeIcon
                icon={crmIconFromUrl(props.crmObject.crm_object_url)}
            />
            <FontAwesomeIcon
                icon={iconFromCrmTable(props.crmObject.crm_table)}
            />
            <span className="inline-block overflow-hidden overflow-ellipsis whitespace-nowrap">
                {displayName}
            </span>
            {props.crmObject.has_synced && (
                <div
                    className="ml-1 flex flex-none rounded-full bg-green-100 text-green-600"
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content={`Successfully synced to '${displayName}'`}
                >
                    <FontAwesomeIcon size="lg" icon={faCircleCheck} />
                </div>
            )}
        </a>
    )
}
