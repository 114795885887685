import { useQuery } from "@tanstack/react-query"
import { useUpdateTask } from "../../api/mutations/tasks"
import { taskQueries } from "../../api/queries/tasks"
import { ICall, ISharedCall, isSharedCall } from "../../types/Call"
import { CallTaskItem } from "./items/CallTaskItem"
import { TaskListView } from "./TaskListView"
import { CallTask } from "./types/Task"

interface TasksProps {
    call: ICall | ISharedCall
    goToTimestamp: (timestamp: string) => void
}

export function Tasks(props: TasksProps) {
    if (isSharedCall(props.call)) {
        return (
            <SharedCallTaskList
                call={props.call}
                goToTimestamp={props.goToTimestamp}
            />
        )
    }

    return (
        <CallTaskList call={props.call} goToTimestamp={props.goToTimestamp} />
    )
}

interface CallTaskListProps {
    call: ISharedCall | ICall
    goToTimestamp: (timestamp: string) => void
}

function SharedCallTaskList({
    call,
    goToTimestamp,
}: CallTaskListProps & { call: ISharedCall }) {
    const query = taskQueries.byShareId(call.share_id)
    const { data: tasks = [], isPending } = useQuery(query)

    const emptyMessage = isPending ? "Loading tasks..." : "No tasks"

    return (
        <TaskListView<CallTask>
            title="Tasks"
            titleStyle="inline"
            tasks={tasks}
            emptyMessage={emptyMessage}
            isUpdating={false}
            updateTask={undefined}
            renderTask={(itemProps) => (
                <CallTaskItem
                    {...itemProps}
                    call={call}
                    goToTimestamp={goToTimestamp}
                />
            )}
        />
    )
}

function CallTaskList({ call, goToTimestamp }: CallTaskListProps) {
    const query = taskQueries.byCallId(call.id)
    const { data: tasks = [], isPending } = useQuery(query)

    const { mutate: updateTask, isPending: isUpdating } = useUpdateTask(
        query.queryKey
    )
    const emptyMessage = isPending ? "Loading tasks..." : "No tasks"

    return (
        <TaskListView<CallTask>
            title="Tasks"
            titleStyle="inline"
            tasks={tasks}
            emptyMessage={emptyMessage}
            isUpdating={isUpdating}
            updateTask={updateTask}
            renderTask={(itemProps) => (
                <CallTaskItem
                    {...itemProps}
                    call={call}
                    goToTimestamp={goToTimestamp}
                />
            )}
        />
    )
}
