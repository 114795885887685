import _ from "lodash"
import { Permission } from "./Permission"

export enum UserRole {
    ADMIN = "admin",
    USER = "user",
    SUPPORT = "support",
    VIEWER = "viewer",
    RESTRICTED = "restricted",
}

export function formatRole(role: string): string {
    return _.capitalize(role)
}

/**
 * Interface defining the user attributes during the initial signup process
 * with SuperTokens.
 */
export interface IRegistrationUser {
    name: string
    industry: string | undefined
    job_title: string | undefined
}

export enum ICallProcessedNotificationType {
    Participant = "participant",
    Host = "host",
    None = "none",
}

export type ICallPrepMinsBeforeType = number | null
export interface INotificationPreferences {
    call_processed_emails: ICallProcessedNotificationType
    call_prep_emails_mins_before: ICallPrepMinsBeforeType
    call_prep_slack_mins_before: ICallPrepMinsBeforeType
}

export interface IRecordingPreferences {
    zoom_meeting_sdk_oauth_id: string | null
}

export interface ITaskReminderPreferences {
    send_slack_reminder: boolean
    send_email_reminder: boolean
    reminder_time_utc: string | null
    enabled_on_weekends: boolean
}

export interface IMentionsPreferences {
    send_slack_mentions: boolean
    send_email_mentions: boolean
}

/**
 * Interface defining the user attributes as we get from our API.
 */
export interface IUser {
    id: string
    createdAtUtc: string
    name: string
    email: string
    organizationId: string
    organizationName: string
    permissions: Permission[]
    notificationPreferences: INotificationPreferences
    recordingPreferences: IRecordingPreferences | null
    taskReminderPreferences: ITaskReminderPreferences
    mentionsPreferences: IMentionsPreferences
    timezone: string
    memory: string | null
}
