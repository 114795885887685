import { createQueryKeys } from "@lukemorales/query-key-factory"
import axios from "axios"
import { Task, CallTask } from "../../components/tasks/types/Task"

export const taskQueries = createQueryKeys("tasks", {
    list: () => ({
        queryKey: ["tasks"],
        queryFn: async (): Promise<Task[]> => {
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN + "/tasks"
            )
            return response.data as Task[]
        },
    }),

    byCompanyDomain: (companyId: string) => ({
        queryKey: ["tasks", companyId],
        queryFn: async () => {
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN + "/tasks/company/" + companyId
            )
            return response.data as CallTask[]
        },
    }),

    byCallId: (callId: string) => ({
        queryKey: ["tasks", callId],
        queryFn: async (): Promise<CallTask[]> => {
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN + "/tasks/call/" + callId
            )
            return response.data as CallTask[]
        },
    }),

    byShareId: (shareId: string) => ({
        queryKey: ["tasks", shareId],
        queryFn: async (): Promise<CallTask[]> => {
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN +
                    "/tasks/shared/call/" +
                    shareId
            )
            return response.data as CallTask[]
        },
    }),

    count: (params?: { completed?: boolean; due?: "today" }) => ({
        queryKey: params ? ["tasks", "count", params] : ["tasks", "count"],
        queryFn: async (): Promise<number> => {
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN + "/tasks/count",
                {
                    params,
                }
            )
            return response.data as number
        },
    }),
})
