import { ITranscriptTurn } from "../../../types/Call"
import { ICallComment } from "../../../types/Comment"
import { timestampToSeconds } from "../../../utils/datetime"

export const mergeCommentsAndTurns = (
    comments: ICallComment[],
    transcriptTurns: ITranscriptTurn[]
): (
    | { type: "turn"; data: ITranscriptTurn }
    | { type: "comment"; data: ICallComment }
)[] => {
    if (!comments) {
        return transcriptTurns.map((turn) => ({
            type: "turn",
            data: turn,
        }))
    }

    // Sort comments by timestamp
    const sortedComments = [...comments].sort((a, b) => {
        const aTimestamp = timestampToSeconds(a.call_timestamp)
        const bTimestamp = timestampToSeconds(b.call_timestamp)
        return aTimestamp - bTimestamp
    })

    // Use the sorted comments for merging
    comments = sortedComments

    if (!transcriptTurns) {
        return sortedComments.map((comment) => ({
            type: "comment",
            data: comment,
        }))
    }

    const merged = []
    let turnIndex = 0
    let commentIndex = 0

    while (
        turnIndex < transcriptTurns.length ||
        commentIndex < comments.length
    ) {
        const currentTurn = transcriptTurns[turnIndex]
        const currentComment = comments[commentIndex]

        // Early exit conditions
        if (!currentComment) {
            // Add all remaining turns
            merged.push(
                ...transcriptTurns
                    .slice(turnIndex)
                    .map((turn) => ({ type: "turn", data: turn }))
            )
            break
        }
        if (!currentTurn) {
            // Add all remaining comments
            merged.push(
                ...comments
                    .slice(commentIndex)
                    .map((comment) => ({ type: "comment", data: comment }))
            )
            break
        }

        const turnTimestamp = timestampToSeconds(currentTurn.timestamp)
        const commentTimestamp = timestampToSeconds(
            currentComment.call_timestamp
        )

        if (turnTimestamp <= commentTimestamp) {
            merged.push({ type: "turn", data: currentTurn })
            turnIndex++
        } else {
            merged.push({ type: "comment", data: currentComment })
            commentIndex++
        }
    }

    return merged as (
        | { type: "turn"; data: ITranscriptTurn }
        | { type: "comment"; data: ICallComment }
    )[]
}
