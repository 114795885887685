import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ICallPreview } from "../../types/Call"
import { faChevronRight as openIcon } from "@fortawesome/free-solid-svg-icons"
import { CallPageParticipantsList } from "../calls-list/ParticipantsList"
import { ActivityPreview } from "./ActivityPreview"
import { RecordingIcon } from "../../assets/icons/RecordingIcon"
import { useNavigate } from "react-router-dom"
import CallTitle from "../calls-list/CallTitle"

export function CallPreview(props: {
    call: ICallPreview
    highlightBorder?: boolean
}) {
    const navigate = useNavigate()

    return (
        <ActivityPreview
            heading={
                <CallTitle
                    title={props.call.title}
                    status={props.call.status}
                    // statusReason={props.call.status_reason}
                    language={props.call.language}
                    is_private={props.call.is_private}
                />
            }
            subheading={
                <CallPageParticipantsList parties={props.call.parties} />
            }
            date={props.call.start_time}
            icon={<RecordingIcon className="mt-1" />}
            button={<FontAwesomeIcon icon={openIcon}></FontAwesomeIcon>}
            onClick={() => {
                navigate(`/calls/${props.call.id}`)
            }}
            rowClickable={true}
            highlightBorder={props.highlightBorder}
        >
            {!props.call.is_private && ( // Do not display summary for private calls
                <div className="text-sm text-gray-600">
                    {props.call.summary}
                </div>
            )}
        </ActivityPreview>
    )
}
