import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { queries } from "../../api/queries"
import { LineChart } from "./line-chart/LineChart"
import {
    Granularity,
    InsightCategoryType,
    InsightResult,
    InsightsResponse,
} from "../../types/Insights"
import { DateTime } from "luxon"
import { useMemo, useState } from "react"
import Select from "react-select"
import colors from "tailwindcss/colors"
import { DealOutcomesChart } from "./DealOutcomes"
import { MultiSelectFilter } from "../MultiSelectFilter"
import { IOrganizationUser } from "../../types/Organization"
import { LossReasons } from "./LossReasons"
import { LoadingPulse } from "../common/LoadingPulse"
import { Contents } from "./Contents"
import {
    LosingFormulaChart,
    WinningFormulaChart,
} from "./summary-charts/DealPatternsCharts"
import { MostMentionsChart } from "./summary-charts/MostMentionsChart"
import { SimpleCard } from "../common/SimpleCard"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons"
import { CREATE_INSIGHT_PATH } from "../settings/insights/CreateInsights"
import { Link } from "react-router-dom"
import { hasPermission } from "../../utils/Permissions"
import { Permission } from "../../types/Permission"
import { useUser } from "../../providers/UserProvider"
import { EmptyState } from "./EmptyState"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@radix-ui/react-dropdown-menu"
import {
    faChevronDown,
    faRocket,
    faXmark,
} from "@fortawesome/pro-solid-svg-icons"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { faChartScatter, faSparkles } from "@fortawesome/pro-solid-svg-icons"
import { ICrmFieldType } from "../crm/types/Crm"
import clsx from "clsx"
import { SetupSuggestion } from "../SetupSuggestion"
import { getCacheValue, setCacheValue } from "../../utils/localStorageCache"

type DealAmountRange = {
    min?: number
    max?: number
}

const ONBAORDING_VIDEO =
    "https://www.loom.com/embed/2ae4a65745f041b1a9275bb42f5b9dc8?sid=6cc7f29c-e271-4af1-a673-e0aa9bae686e"

export function StrategicInsights() {
    const user = useUser()
    const canEditCustomInsights =
        user && hasPermission(user, Permission.EDIT_CUSTOM_INSIGHTS)

    const [sinceDate, setSinceDate] = useState<DateTime>(
        DateTime.now().minus({ months: 12 }).startOf("day")
    )
    const [granularity, setGranularity] = useState<Granularity>(
        Granularity.MONTHLY
    )

    const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])
    const [dealAmountRange, setDealAmountRange] = useState<DealAmountRange>({})

    const { data: users, isPending: isUsersPending } = useQuery(
        queries.users.list()
    )

    const insightsQuery = useQuery({
        ...queries.insights.get(
            sinceDate,
            granularity,
            selectedUserIds,
            dealAmountRange.min,
            dealAmountRange.max
        ),
        staleTime: Infinity,
    })

    const lossReasonsQuery = useQuery({
        ...queries.insights.getLossReasons(
            sinceDate,
            selectedUserIds,
            dealAmountRange.min,
            dealAmountRange.max
        ),
        staleTime: Infinity,
    })

    const isEmpty =
        !insightsQuery.isPending &&
        !insightsQuery.data?.insights.length &&
        !lossReasonsQuery.isPending &&
        !lossReasonsQuery.data?.loss_reasons.length

    if (isEmpty)
        return (
            <div className="flex h-full items-center justify-center">
                <EmptyState />
            </div>
        )

    return (
        <section className="flex flex-col space-y-4 overflow-hidden rounded-lg px-8 py-6 lg:h-[calc(100vh)]">
            <div className="flex flex-shrink-0 flex-col justify-between gap-2">
                <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center gap-2">
                        <h1 className="text-2xl font-bold">
                            Strategic Insights
                        </h1>
                    </div>
                    {canEditCustomInsights ? (
                        <Link to={CREATE_INSIGHT_PATH}>
                            <SecondaryButton className="flex items-center gap-2">
                                <FontAwesomeIcon icon={faPlusCircle} />
                                Create new insight
                            </SecondaryButton>
                        </Link>
                    ) : (
                        <SecondaryButton
                            className="flex items-center gap-2"
                            disabled
                            data-tooltip-id="tooltip-explanation"
                            data-tooltip-content="You need to be an admin to create a new insight"
                        >
                            <FontAwesomeIcon icon={faPlusCircle} />
                            Create new insight
                        </SecondaryButton>
                    )}
                </div>
                <p className="text-gray-500">
                    Strategic Insights help you identify trends and
                    opportunities from your conversations.
                </p>

                <div className="mt-4 flex items-center gap-2 space-x-1">
                    <span className="font-semibold text-gray-900">Date</span>
                    <DateSelect
                        sinceDate={sinceDate}
                        setSinceDate={setSinceDate}
                        setGranularity={setGranularity}
                    />
                    <span className="pl-4 font-semibold text-gray-900">
                        Deal amount
                    </span>
                    <DealFilter
                        amountRange={dealAmountRange}
                        setAmountRange={setDealAmountRange}
                    />
                    <span className="pl-4 font-semibold text-gray-900">
                        People
                    </span>
                    <PeopleSelect
                        users={users}
                        selectedUserIds={selectedUserIds}
                        setSelectedUserIds={setSelectedUserIds}
                        isLoading={isUsersPending}
                    />
                </div>
            </div>
            <div className="grid-cols-4 gap-4 overflow-hidden lg:grid">
                <div className="col-span-1 mb-4 overflow-y-auto lg:mb-0">
                    <Contents
                        lossReasonsQueryResult={lossReasonsQuery}
                        insightsQueryResult={insightsQuery}
                    />
                </div>
                <div className="col-span-3 space-y-4 overflow-y-scroll scroll-smooth">
                    <VideoTour />
                    <LossReasons lossReasonsQueryResult={lossReasonsQuery} />
                    <MostMentionsChart insightsQueryResult={insightsQuery} />
                    <WinningFormulaChart insightsQueryResult={insightsQuery} />
                    <LosingFormulaChart insightsQueryResult={insightsQuery} />
                    <InsightsCharts
                        insightsQueryResult={insightsQuery}
                        sinceDate={insightsQuery.data?.since}
                        granularity={granularity}
                        userIds={selectedUserIds}
                    />
                </div>
            </div>
        </section>
    )
}

function VideoTour() {
    const DISMISS_CACHE_KEY = "strategic-insights-tour-dismissed"
    const [isDismissed, setIsDismissed] = useState(() =>
        getCacheValue(DISMISS_CACHE_KEY, false)
    )

    if (isDismissed) return null

    return (
        <div className="p-3">
            <SetupSuggestion
                name="strategic-insights-tour"
                onDismiss={() => {
                    setCacheValue(DISMISS_CACHE_KEY, true)
                    setIsDismissed(true)
                }}
                children={
                    <div>
                        <div className="flex flex-row items-center gap-3">
                            <FontAwesomeIcon
                                icon={faRocket}
                                className="text-4xl"
                            />
                            <div>
                                <div className="font-bold">Quick tour</div>
                                <div className="text-gray-600">
                                    Check out our video tour of Strategic
                                    Insights to help you get up and running.
                                </div>
                            </div>
                        </div>
                        <div className="relative w-full pb-[64%]">
                            <iframe
                                title="Strategy insights onboarding video"
                                src={ONBAORDING_VIDEO}
                                allowFullScreen
                                className="absolute left-0 top-0 h-full w-full rounded"
                            />
                        </div>
                    </div>
                }
            />
        </div>
    )
}

function InsightsCharts(props: {
    insightsQueryResult: UseQueryResult<InsightsResponse, Error>
    sinceDate: string | undefined
    granularity: Granularity
    userIds: string[]
}) {
    const result = props.insightsQueryResult
    if (result.isPending)
        return (
            <div>
                <LoadingPulse rows={2} height="h-80" />
            </div>
        )

    if (
        result.isError ||
        !result.data?.insights ||
        result.data?.insights.length === 0
    ) {
        return <Empty />
    }

    return (
        <div className="space-y-4">
            {result.data.insights.map((insight, index) => (
                <Insight
                    key={index}
                    insight={insight}
                    sinceDate={props.sinceDate!}
                    userIds={props.userIds}
                />
            ))}
        </div>
    )
}

function Empty() {
    return (
        <div className="flex h-64 w-full items-center justify-center">
            <span className="rounded-lg bg-gray-200 p-2 text-gray-800">
                No insights to display
            </span>
        </div>
    )
}

function Insight(props: {
    insight: InsightResult
    sinceDate: string
    userIds: string[]
}) {
    const noData = !props.insight.lineChart && !props.insight.dealOutcomes
    return (
        <SimpleCard>
            <div
                className={clsx(
                    "flex w-full flex-col rounded-lg p-6",
                    noData && "bg-gray-100 text-gray-500"
                )}
                id={props.insight.id} // Used by <Contents /> for navigation
            >
                <h2 className="mb-3 text-2xl font-bold">
                    {props.insight.displayName}
                    {props.insight.insightCategoryType ===
                        InsightCategoryType.AUTO_GENERATED && (
                        <AutoGeneratedCategoriesIcon
                            fieldType={props.insight.fieldType}
                        />
                    )}
                </h2>

                <div className="space-y-4">
                    {noData && <NoDataMessage insight={props.insight} />}
                    {props.insight.lineChart && (
                        <LineChart
                            insight={props.insight}
                            data={props.insight.lineChart.data}
                            granularity={props.insight.lineChart.granularity}
                        />
                    )}
                    {props.insight.dealOutcomes && (
                        <DealOutcomesChart
                            insightId={props.insight.id}
                            insightName={props.insight.displayName}
                            data={props.insight.dealOutcomes.data}
                            sinceDate={props.sinceDate}
                            userIds={props.userIds}
                        />
                    )}
                </div>
            </div>
        </SimpleCard>
    )
}

function NoDataMessage(props: { insight: InsightResult }) {
    const icon =
        props.insight.insightCategoryType ===
        InsightCategoryType.AUTO_GENERATION_PENDING
            ? faSparkles
            : faChartScatter

    const message =
        props.insight.insightCategoryType ===
        InsightCategoryType.AUTO_GENERATION_PENDING
            ? `Once this ${getFieldTypeDisplayName(
                  props.insight.fieldType
              )} insight has been mentioned on enough calls, groups will be automatically generated to display this chart.`
            : "No mentions of this insight within the selected filters."

    return (
        <div className="flex items-center gap-2 text-gray-500">
            <FontAwesomeIcon
                icon={icon}
                className="h-4 w-4 rounded-lg bg-gray-100 p-2"
            />
            <p>{message}</p>
        </div>
    )
}

function AutoGeneratedCategoriesIcon(props: { fieldType: ICrmFieldType }) {
    return (
        <span
            className="group ml-2 inline-flex h-6 cursor-default items-center justify-center
                       space-x-1 rounded-full bg-gradient-to-br
                     from-yellow-300 to-orange-400 p-2 text-sm font-normal text-gray-900"
            data-tooltip-id="tooltip-explanation"
            data-tooltip-html={`Your <b>${getFieldTypeDisplayName(
                props.fieldType
            )}</b> answers for this insight have been grouped using Glyphic's AI in this chart.`}
        >
            <FontAwesomeIcon
                icon={faSparkles}
                className="transition-transform duration-200 group-hover:rotate-12 group-hover:scale-125"
            />
            <span className="hidden md:inline">Grouped using AI</span>
        </span>
    )
}

function getFieldTypeDisplayName(fieldType: ICrmFieldType) {
    return {
        [ICrmFieldType.Text]: "free text",
        [ICrmFieldType.Number]: "numeric",
        [ICrmFieldType.Checklist]: "checklist",
        [ICrmFieldType.MultiChecklist]: "multi select",
    }[fieldType]
}

function DateSelect(props: {
    sinceDate: DateTime
    setSinceDate: (date: DateTime) => void
    setGranularity: (granularity: Granularity) => void
}) {
    const dateOptions = useMemo(
        () => [
            {
                label: "Last 30 days",
                value: DateTime.now().minus({ days: 30 }).startOf("day"),
                granularity: Granularity.WEEKLY,
            },
            {
                label: "Last 3 months",
                value: DateTime.now().minus({ months: 3 }).startOf("day"),
                granularity: Granularity.WEEKLY,
            },
            {
                label: "Last 6 months",
                value: DateTime.now().minus({ months: 6 }).startOf("day"),
                granularity: Granularity.WEEKLY,
            },
            {
                label: "Last 9 months",
                value: DateTime.now().minus({ months: 9 }).startOf("day"),
                granularity: Granularity.MONTHLY,
            },
            {
                label: "Last 12 months",
                value: DateTime.now().minus({ months: 12 }).startOf("day"),
                granularity: Granularity.MONTHLY,
            },
            {
                label: "Last 18 months",
                value: DateTime.now().minus({ months: 18 }).startOf("day"),
                granularity: Granularity.MONTHLY,
            },
        ],
        []
    )

    const currentOption = useMemo(() => {
        return (
            dateOptions.find(
                (option) =>
                    option.value?.toMillis() === props.sinceDate?.toMillis()
            ) || null
        )
    }, [props.sinceDate, dateOptions])

    return (
        <Select
            options={dateOptions}
            className="w-56 text-sm"
            styles={{
                control: (provided) => ({
                    ...provided,
                    borderColor: colors.gray[200],
                    borderRadius: "0.5rem",
                    boxShadow: "none",
                    "&:hover": {
                        borderColor: colors.gray[300],
                        backgroundColor: colors.gray[50],
                    },
                }),
                menu: (provided: any) => ({
                    ...provided,
                    padding: "0px 4px 0px 4px",
                    borderRadius: "0.5rem",
                    zIndex: 50,
                }),
                option: (provided: any) => ({
                    ...provided,
                    backgroundColor: "white",
                    color: "black",
                    borderRadius: "0.5rem",
                    "&:hover": {
                        backgroundColor: colors.gray[100],
                    },
                }),
            }}
            onChange={(option) => {
                if (!option) return
                props.setSinceDate(option.value)
                props.setGranularity(option.granularity)
            }}
            value={currentOption}
            isSearchable={false}
        />
    )
}

function PeopleSelect(props: {
    users?: IOrganizationUser[]
    selectedUserIds: string[]
    setSelectedUserIds: (userIds: string[]) => void
    isLoading: boolean
}) {
    const userToOption = (user: IOrganizationUser) => ({
        value: user.id,
        label: user.name || user.id,
    })

    const options = useMemo(() => {
        return (
            props.users
                ?.map(userToOption)
                .sort((a, b) => a.label.localeCompare(b.label)) || []
        )
    }, [props.users])

    const value = useMemo(() => {
        return (
            props.users
                ?.filter((user) => props.selectedUserIds.includes(user.id))
                .map(userToOption) || []
        )
    }, [props.selectedUserIds, props.users])

    return (
        <MultiSelectFilter
            title="people"
            icon="👤"
            options={options}
            value={value}
            onApply={(selectedOption) => {
                props.setSelectedUserIds(
                    selectedOption.map((option) => option.value)
                )
            }}
            isLoading={props.isLoading}
        />
    )
}

function DealFilter(props: {
    amountRange: DealAmountRange
    setAmountRange: (range: DealAmountRange) => void
}) {
    const { addNotification } = useNotification()
    const [open, setOpen] = useState(false)
    const [amountRange, setAmountRange] = useState<DealAmountRange>(
        props.amountRange
    )

    const numberFormat = new Intl.NumberFormat()

    const format = (value: number | undefined) =>
        value !== undefined ? numberFormat.format(value) : ""

    const getDisplayValue = ({ min, max }: DealAmountRange) => {
        if (min && max) {
            return `${format(min)} - ${format(max)}`
        }

        if (min) {
            return `> ${format(min)}`
        }

        if (max) {
            return `< ${format(max)}`
        }

        return "Any"
    }

    const handleInputChange =
        (field: keyof DealAmountRange) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const parsedInt = parseInt(
                event.target.value.replace(/[^0-9]/g, "")
            )
            const amount = isNaN(parsedInt) ? undefined : parsedInt
            setAmountRange((prev) => ({ ...prev, [field]: amount }))
        }

    const handleApply = (amountRange: DealAmountRange) => {
        const { min, max } = amountRange
        if (min !== undefined && max !== undefined && min > max) {
            addNotification(
                "Min amount must be less than Max amount",
                "",
                NotificationType.Error
            )
            return
        }
        props.setAmountRange(amountRange)
        setOpen(false)
    }

    const hasRange =
        props.amountRange.min !== undefined ||
        props.amountRange.max !== undefined

    return (
        <DropdownMenu open={open} onOpenChange={setOpen}>
            <div className="flex w-64 cursor-default items-center justify-between rounded-md border bg-white p-2 hover:bg-gray-50 focus:outline-none">
                <DropdownMenuTrigger asChild>
                    <span className="w-full">
                        {getDisplayValue(props.amountRange)}
                    </span>
                </DropdownMenuTrigger>
                <div className="flex items-center gap-2">
                    {hasRange && (
                        <FontAwesomeIcon
                            icon={faXmark}
                            className={clsx(
                                "h-4 w-4 text-gray-600 hover:text-gray-800",
                                !open && "opacity-30"
                            )}
                            onClick={() => {
                                props.setAmountRange({})
                                setAmountRange({})
                            }}
                        />
                    )}
                    <div
                        className="flex items-center gap-2"
                        onClick={() => setOpen(true)}
                    >
                        <div className="h-6 w-px bg-gray-300" />
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            className={clsx(
                                "h-4 w-4 text-gray-600 hover:text-gray-800",
                                !open && "opacity-30"
                            )}
                        />
                    </div>
                </div>
            </div>
            <DropdownMenuContent
                className="z-10 rounded-md border border-gray-200 bg-white shadow-lg"
                align="start"
                sideOffset={10}
                alignOffset={-10}
            >
                <div className="space-y-4 p-3">
                    <div className="flex gap-4">
                        <div className="flex-1">
                            <label className="mb-1 block text-sm font-medium">
                                Min Amount
                            </label>
                            <input
                                type="text"
                                placeholder="None"
                                value={format(amountRange.min)}
                                onChange={handleInputChange("min")}
                                className="w-full rounded border px-3 py-2"
                            />
                        </div>
                        <div className="flex-1">
                            <label className="mb-1 block text-sm font-medium">
                                Max Amount
                            </label>
                            <input
                                type="text"
                                placeholder="None"
                                value={format(amountRange.max)}
                                onChange={handleInputChange("max")}
                                className="w-full rounded border px-3 py-2"
                            />
                        </div>
                    </div>

                    <div className="flex justify-end border-t pt-2">
                        <PrimaryButton onClick={() => handleApply(amountRange)}>
                            Apply
                        </PrimaryButton>
                    </div>
                </div>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
