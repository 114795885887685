import { useQuery } from "@tanstack/react-query"
import { OrgSettingsResponse } from "../../types/Settings"
import { getOrgSettings } from "../../api/Settings"
import { SecondaryButton } from "../common/Buttons"
import { CircleLoadingSpinner } from "../common/CircleLoadingSpinner"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { useNotification } from "../../providers/NotificationProvider"
import { useEffect, useRef, useState } from "react"
import axios from "axios"
import { NotificationType } from "../common/Notifcations"
import { CrmType } from "../crm/types/Crm"
import { parseAxiosError } from "../../utils/parseAxiosError"

export function CrmCompanyUrlFieldSetting(props: { crmType: CrmType }) {
    const { data, isSuccess } = useQuery<OrgSettingsResponse>({
        queryKey: ["organization/settings"],
        queryFn: getOrgSettings,
    })
    const inputRef = useRef<HTMLInputElement>(null)
    const timeoutId = useRef<NodeJS.Timeout | null>(null)

    const { addNotification } = useNotification()
    const [fieldName, setFieldName] = useState("")
    const [saving, setSaving] = useState(false)
    const [saved, setSaved] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    useEffect(() => {
        if (isSuccess) {
            setFieldName(data.crm_settings?.company_page_url_field || "")
        }
    }, [data, isSuccess])

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus()
        }
    }, [isEditing])

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        if (saving) return
        setIsEditing(false)
        setSaving(true)

        try {
            await axios.put(
                `${process.env.REACT_APP_API_DOMAIN}/organization/settings/crm/company_page_url_field`,
                { field_name: fieldName }
            )
            setSaved(true)
            timeoutId.current = setTimeout(() => {
                setSaved(false)
            }, 3000)
        } catch (error) {
            const errorMessage = parseAxiosError(error)
            addNotification(
                "Failed to update field name.",
                errorMessage,
                NotificationType.Error
            )
        } finally {
            setSaving(false)
            if (timeoutId.current) {
                clearTimeout(timeoutId.current)
            }
        }
    }

    const tableName =
        props.crmType === CrmType.Salesforce ? "Account" : "Company"
    const inputPlaceholder =
        props.crmType === CrmType.Salesforce
            ? "API field name (e.g. Glyphic_Company_Url__c)"
            : "Internal field name (e.g. glyphic_company_url)"

    return (
        <div className="max-w-full">
            <div className="flex flex-row items-center gap-2">
                <h3 className="text-md font-semibold">Glyphic link field</h3>
                <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="text-gray-600"
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content="This will only affect new companies that appear in Glyphic after this field is set"
                />
            </div>
            <p className="text-sm text-gray-500">
                {`Easily navigate from your CRM to Glyphic: Enter the name of a field in your CRM's ${tableName} object to automatically populate with a link to the Glyphic Company page.`}
            </p>
            <div className="mt-2 flex flex-row items-center justify-start gap-2">
                <form
                    onSubmit={handleSubmit}
                    className="flex h-10 w-fit flex-row items-center space-x-4"
                >
                    <input
                        ref={inputRef}
                        type="text"
                        className="h-full w-full max-w-full rounded-lg border border-gray-300 bg-transparent bg-white px-2 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-black md:w-96"
                        value={fieldName}
                        placeholder={inputPlaceholder}
                        onChange={(e) => setFieldName(e.target.value)}
                        disabled={saving || !isEditing}
                    />
                    {isEditing ? (
                        <SecondaryButton className="h-full w-24" type="submit">
                            Save
                        </SecondaryButton>
                    ) : (
                        <SecondaryButton
                            className="h-full w-24"
                            type="button"
                            disabled={saving}
                            onClick={(e) => {
                                e.preventDefault()
                                setIsEditing(true)
                            }}
                        >
                            Edit
                        </SecondaryButton>
                    )}
                </form>
                {saving && (
                    <CircleLoadingSpinner className="text-emerald-500" />
                )}
                {saved && (
                    <FontAwesomeIcon
                        icon={faCheck}
                        className="text-emerald-500"
                    />
                )}
            </div>
        </div>
    )
}
