import { faBars, faSearch } from "@fortawesome/free-solid-svg-icons"
import { faGrid } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import _ from "lodash"
import { Interval } from "luxon"
import { useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { HashLink } from "react-router-hash-link"
import { useUpdateDeal } from "../../api/mutations"
import { queries } from "../../api/queries"
import { useCrmIntegration } from "../../hooks/useIntegrations"
import { ICRMDeal } from "../../types/Deal"
import { IOrganizationUser } from "../../types/Organization"
import { getHeapInstance } from "../../utils/heap"
import { getCacheValue, setCacheValue } from "../../utils/localStorageCache"
import { formatMonetaryAmount } from "../../utils/monetaryAmount"
import { SecondaryButton } from "../common/Buttons"
import { ErrorPage } from "../common/ErrorPage"
import { useSearchParam } from "../common/hooks/useSearchParam"
import LoadingSpinner from "../common/LoadingSpinner"
import { TabHead } from "../common/Tabs"
import {
    CrmType,
    ICrmDealPipeline,
    ICrmDealStage,
    ICrmDealType,
    ICrmUser,
} from "../crm/types/Crm"
import { DealsDetailsTable } from "./DealDetailsTable"
import { AnnotationFilters, DealTableFilters } from "./DealTableFilters"
import { Kanban } from "./Kanban"
import { DEAL_CLOSE_DATE_RANGE, getTimeInterval } from "./utils/dealCloseDate"

const VIEW_TAB_CACHE_KEY = "deal-table-view-tab"
const CLOSE_DATE_FILTER_CACHE_KEY = "deal-table-close-date"
const OWNER_IDS_FILTER_CACHE_KEY = "deal-table-owner-ids"
const PIPELINE_ID_FILTER_CACHE_KEY = "deal-table-pipeline-id"
const STAGE_IDS_FILTER_CACHE_KEY = "deal-table-stage-ids"
const HIDE_NO_CALLS_CACHE_KEY = "deal-table-hide-no-calls"
const ANNOTATION_FILTERS_CACHE_KEY = "deal-table-annotation-filters"
const FORECAST_CATEGORIES_FILTER_CACHE_KEY = "deal-table-forecast-categories"
const DEAL_TYPE_FILTER_CACHE_KEY = "deal-table-deal-type"

const SUPPORTED_CRMS = [CrmType.Hubspot, CrmType.Salesforce]

enum ViewTabs {
    Board = "Board",
    List = "List",
}

const VIEW_TABS = [
    {
        label: (
            <span>
                <FontAwesomeIcon icon={faGrid} />
                <span className="ml-2">Board</span>
            </span>
        ),
        type: ViewTabs.Board,
    },
    {
        label: (
            <span>
                <FontAwesomeIcon icon={faBars} />
                <span className="ml-2">List</span>
            </span>
        ),
        type: ViewTabs.List,
    },
]

export function DealsList() {
    const { crmType } = useCrmIntegration()
    const hasUnsupportedCrm =
        crmType !== undefined && !SUPPORTED_CRMS.includes(crmType)

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const [activeViewTabIndex, setActiveViewTabIndex] = useState(() =>
        getActiveViewTabIndex(searchParams)
    )

    const { data: orgUsers } = useQuery(queries.users.list())
    const { data: crmUsers } = useQuery(queries.crm.users())
    const { data: dealPipelines } = useQuery(queries.crm.dealPipelines(true))
    const { data: allStages } = useQuery(queries.crm.dealStages())

    const { data: annotationTags } = useQuery<string[]>({
        queryKey: ["organization/annotation-tags/company"],
        queryFn: async () => {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/organization/annotation-tags/company`
            )
            return data
        },
    })

    const ownerTeams = useMemo(
        () => getOwnerTeams(orgUsers, crmUsers),
        [orgUsers, crmUsers]
    )

    const [closeDateFilter, setCloseDateFilter] =
        useState<DEAL_CLOSE_DATE_RANGE>(
            getCacheValue(
                CLOSE_DATE_FILTER_CACHE_KEY,
                DEAL_CLOSE_DATE_RANGE.ThisMonth
            )
        )
    const [ownerIds, setOwnerIds] = useState<string[]>(
        getCacheValue(OWNER_IDS_FILTER_CACHE_KEY, [])
    )
    const [filterTerm, setFilterTerm] = useSearchParam("filter")

    const [selectedPipeline, setSelectedPipeline] = useState<
        ICrmDealPipeline | undefined
    >(undefined)

    useEffect(() => {
        const cachedPipelineId = getCacheValue(
            PIPELINE_ID_FILTER_CACHE_KEY,
            null
        )
        const cachedPipeline = dealPipelines?.find(
            (pipeline) => pipeline.crm_id === cachedPipelineId
        )

        setSelectedPipeline(cachedPipeline || dealPipelines?.[0])
    }, [dealPipelines])

    const availableStages = useMemo(
        () => selectedPipeline?.stages || allStages || [],
        [selectedPipeline, allStages]
    )

    const [selectedStages, setSelectedStages] = useState<ICrmDealStage[]>([])

    const [forecastCategories, setForecastCategories] = useState<string[]>(
        getCacheValue(FORECAST_CATEGORIES_FILTER_CACHE_KEY, [])
    )

    const [selectedDealTypes, setSelectedDealTypes] = useState<ICrmDealType[]>(
        getCacheValue(DEAL_TYPE_FILTER_CACHE_KEY, [
            "new_business",
            "existing_business",
            "unknown",
        ])
    )

    useEffect(() => {
        const cachedStageIds = getCacheValue(
            STAGE_IDS_FILTER_CACHE_KEY,
            []
        ) as string[]
        const cachedStages = availableStages.filter((stage) =>
            cachedStageIds.includes(stage.crm_id)
        )
        setSelectedStages(cachedStages)
    }, [availableStages])

    const closeDateInterval = useMemo(
        () => getTimeInterval(closeDateFilter),
        [closeDateFilter]
    )

    const [hideNoCalls, setHideNoCalls] = useState<boolean>(
        getCacheValue(HIDE_NO_CALLS_CACHE_KEY, false)
    )

    const [annotationFilters, setAnnotationFilters] = useState<string[]>(
        getCacheValue(ANNOTATION_FILTERS_CACHE_KEY, [
            "summary",
            "obstacles",
            "meddpicc_paper_process",
        ])
    )

    const queryKey = useMemo(
        () => ["deals", closeDateInterval.toISO(), ownerIds],
        [closeDateInterval, ownerIds]
    )

    const {
        data: deals,
        isPending,
        isError,
        error,
    } = useQuery<ICRMDeal[]>({
        queryKey,
        queryFn: async () => {
            return await fetchDeals(closeDateInterval, ownerIds)
        },
        staleTime: 5 * 60 * 1000, // Only consider data stale & refetch after 5 minutes
    })

    const updateDealMutation = useUpdateDeal(queryKey)

    useEffect(() => {
        document.title = "Deals - " + process.env.REACT_APP_DOCUMENT_TITLE

        return () => {
            // Reset the title when the component unmounts
            document.title = process.env.REACT_APP_DOCUMENT_TITLE!
        }
    })

    const filteredDeals = useMemo(() => {
        if (!deals) return deals

        const hasCalls = (deal: ICRMDeal) =>
            !hideNoCalls || !!deal.company?.last_call_time

        const isSelectedStage = (deal: ICRMDeal) => {
            if (selectedStages.length === 0 && !selectedPipeline) {
                return true
            }

            if (selectedStages.length > 0) {
                return selectedStages.some(
                    (stage) => stage.crm_id === deal.stage?.crm_id
                )
            }

            if (selectedPipeline) {
                return selectedPipeline.stages.some(
                    (stage) => stage.crm_id === deal.stage?.crm_id
                )
            }

            return false
        }

        const hasSelectedForecastCategory = (deal: ICRMDeal) => {
            if (forecastCategories.length === 0) {
                return true
            }
            return (
                deal.forecast_category &&
                forecastCategories.includes(deal.forecast_category)
            )
        }

        const hasSelectedDealType = (deal: ICRMDeal) => {
            if (selectedDealTypes.length === 0) {
                return true
            }
            return selectedDealTypes.includes(deal.deal_type)
        }

        return deals.filter(
            (deal) =>
                hasCalls(deal) &&
                isSelectedStage(deal) &&
                hasSelectedForecastCategory(deal) &&
                hasSelectedDealType(deal)
        )
    }, [
        deals,
        selectedStages,
        hideNoCalls,
        selectedPipeline,
        forecastCategories,
        selectedDealTypes,
    ])

    const availableForecastCategories = useMemo(() => {
        if (!deals) return []

        // Extract unique forecast categories using an array
        const categories: string[] = []
        for (const deal of deals) {
            if (
                deal.forecast_category &&
                !categories.includes(deal.forecast_category)
            ) {
                categories.push(deal.forecast_category)
            }
        }

        return categories.sort()
    }, [deals])

    if (hasUnsupportedCrm) {
        return <UnSupportedCRM />
    }

    if (isError) {
        if (axios.isAxiosError(error) && error.response?.status === 412) {
            return MissingCRMIntegration()
        } else {
            return <ErrorPage error={{ message: "Failed to load deals" }} />
        }
    }

    return (
        <section className="flex h-screen flex-col gap-4 px-8 py-6">
            <div className="flex w-full flex-col gap-4 md:flex-row">
                <ViewTabHead
                    activeViewTabIndex={activeViewTabIndex}
                    setActiveViewTabIndex={setActiveViewTabIndex}
                />
                <DealTableFilters
                    allCrmUsers={crmUsers || []}
                    pipelines={dealPipelines || []}
                    stages={availableStages}
                    closeDate={closeDateFilter}
                    setCloseDate={(range) => {
                        setCloseDateFilter(range)
                        setCacheValue(CLOSE_DATE_FILTER_CACHE_KEY, range)
                    }}
                    ownerTeams={ownerTeams}
                    ownerIds={ownerIds}
                    setOwnerIds={(ids) => {
                        setOwnerIds(ids)
                        setCacheValue(OWNER_IDS_FILTER_CACHE_KEY, ids)
                    }}
                    selectedPipeline={selectedPipeline}
                    setSelectedPipeline={(pipeline) => {
                        setSelectedPipeline(pipeline)
                        setCacheValue(
                            PIPELINE_ID_FILTER_CACHE_KEY,
                            pipeline?.crm_id || null
                        )
                    }}
                    selectedStages={selectedStages}
                    setSelectedStages={(stages) => {
                        setSelectedStages(stages)
                        setCacheValue(
                            STAGE_IDS_FILTER_CACHE_KEY,
                            stages.map((s) => s.crm_id)
                        )
                    }}
                    forecastCategories={forecastCategories}
                    setForecastCategories={(categories) => {
                        setForecastCategories(categories)
                        setCacheValue(
                            FORECAST_CATEGORIES_FILTER_CACHE_KEY,
                            categories
                        )
                    }}
                    availableForecastCategories={availableForecastCategories}
                    dealTypes={selectedDealTypes}
                    setDealTypes={(types) => {
                        setSelectedDealTypes(types)
                        setCacheValue(DEAL_TYPE_FILTER_CACHE_KEY, types)
                    }}
                    hideNoCalls={hideNoCalls}
                    setHideNoCalls={(hideNoCalls) => {
                        setHideNoCalls(hideNoCalls)
                        setCacheValue(HIDE_NO_CALLS_CACHE_KEY, hideNoCalls)
                    }}
                />
            </div>

            {filteredDeals && <DealStats deals={filteredDeals} />}
            {isPending || filteredDeals === undefined ? (
                <LoadingSpinner />
            ) : VIEW_TABS[activeViewTabIndex].type === ViewTabs.Board ? (
                <Kanban
                    deals={filteredDeals}
                    stages={availableStages}
                    updateDeal={updateDealMutation.mutateAsync}
                />
            ) : (
                <div>
                    <div className="flex flex-row gap-2">
                        <Filter
                            filterTerm={filterTerm}
                            setFilterTerm={setFilterTerm}
                        />
                        <AnnotationFilters
                            annotationTags={annotationTags || []}
                            annotationFilters={annotationFilters}
                            setAnnotationFilters={(annotationFilters) => {
                                setAnnotationFilters(annotationFilters)
                                setCacheValue(
                                    ANNOTATION_FILTERS_CACHE_KEY,
                                    annotationFilters
                                )
                                getHeapInstance()?.track(
                                    "deals-annotation-filters",
                                    {
                                        annotationFilters,
                                    }
                                )
                            }}
                        />
                    </div>
                    <DealsDetailsTable
                        deals={filteredDeals}
                        dealStages={availableStages}
                        filterTerm={filterTerm}
                        annotationTags={annotationTags || []}
                        annotationFilters={annotationFilters}
                        updateDeal={updateDealMutation.mutateAsync}
                    />
                </div>
            )}
        </section>
    )
}

function ViewTabHead(props: {
    activeViewTabIndex: number
    setActiveViewTabIndex: (index: number) => void
}) {
    const { activeViewTabIndex, setActiveViewTabIndex } = props
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        setCacheValue(VIEW_TAB_CACHE_KEY, activeViewTabIndex)
        const tabName = VIEW_TABS[activeViewTabIndex].type.toLowerCase()
        const newSearchParams = new URLSearchParams(location.search)
        newSearchParams.set("view", tabName)
        navigate({ search: newSearchParams.toString() }, { replace: true })
    }, [location.search, navigate, activeViewTabIndex])

    return (
        <TabHead
            tabs={VIEW_TABS}
            activeTab={activeViewTabIndex}
            onTabChange={setActiveViewTabIndex}
        />
    )
}

function getActiveViewTabIndex(searchParams: URLSearchParams) {
    const urlViewTab = searchParams.get("view")

    if (urlViewTab) {
        const tabIndex = VIEW_TABS.findIndex(
            (tab) => tab.type.toLowerCase() === urlViewTab.toLowerCase()
        )
        if (tabIndex !== -1) {
            return tabIndex
        }
    }
    return getCacheValue(VIEW_TAB_CACHE_KEY, 0)
}

function Filter(props: {
    filterTerm: string
    setFilterTerm: (filterTerm: string) => void
}) {
    return (
        <div className="max-w-72 border-block flex h-10 w-full flex-row items-center gap-2 rounded-lg border border-gray-300 bg-white py-1 pl-4 text-base md:w-72">
            <FontAwesomeIcon icon={faSearch} className="text-gray-500" />
            <input
                autoFocus
                className="w-full outline-none placeholder:text-gray-600"
                type="text"
                placeholder="Filter by name"
                value={props.filterTerm}
                onChange={(e) => props.setFilterTerm(e.target.value)}
            />
        </div>
    )
}

function DealStats(props: { deals: ICRMDeal[] }) {
    const totalAmount = useMemo(
        () => props.deals.reduce((acc, deal) => acc + (deal.amount || 0), 0),
        [props.deals]
    )

    const openAmount = useMemo(
        () =>
            props.deals.reduce(
                (acc, deal) => acc + (!deal.is_closed ? deal.amount || 0 : 0),
                0
            ),
        [props.deals]
    )

    const closedWonAmount = useMemo(
        () =>
            props.deals.reduce(
                (acc, deal) =>
                    acc +
                    (deal.is_closed && deal.is_won ? deal.amount || 0 : 0),
                0
            ),
        [props.deals]
    )

    const weightedTotalAmount = useMemo(
        () =>
            props.deals.reduce(
                (acc, deal) =>
                    acc + (deal.amount || 0) * (deal.stage?.probability || 0),
                0
            ),
        [props.deals]
    )

    if (props.deals.length === 0) {
        return null
    }

    // All deals have the same currency
    const currency = props.deals[0].currency

    return (
        <div className="flex w-fit flex-row gap-8 rounded-lg border border-gray-100 bg-white p-4">
            <Stat
                label="Total amount"
                value={formatMonetaryAmount(totalAmount, currency)}
            />
            {openAmount > 0 && openAmount !== totalAmount && (
                <Stat
                    label="Open amount"
                    value={formatMonetaryAmount(openAmount, currency)}
                />
            )}
            {closedWonAmount > 0 && (
                <Stat
                    label="Closed won amount"
                    value={formatMonetaryAmount(closedWonAmount, currency)}
                />
            )}
            {weightedTotalAmount > 0 && (
                <Stat
                    label="Weighted total amount"
                    value={formatMonetaryAmount(weightedTotalAmount, currency)}
                />
            )}
        </div>
    )

    function Stat(props: { label: string; value: string | null }) {
        if (props.value === null) {
            return null
        }
        return (
            <div className="flex flex-row items-end gap-2">
                <span className="text-3xl">{props.value}</span>
                <span className="text-sm text-gray-600">{props.label}</span>
            </div>
        )
    }
}

export async function fetchDeals(
    closeDateInterval: Interval,
    ownerIds: string[],
    isClosed?: boolean
) {
    const start = closeDateInterval.start!.toUTC().toISO()!
    const end = closeDateInterval.end!.toUTC().toISO()!

    const params = new URLSearchParams({
        close_date_after: start,
        close_date_before: end,
    })
    if (isClosed !== undefined) {
        params.append("is_closed", isClosed.toString())
    }
    ownerIds.forEach((id) => params.append("owner_ids", id))

    const response = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/deals/crm`,
        {
            params,
        }
    )
    return response.data
}

function MissingCRMIntegration() {
    return (
        <span className="my-auto flex h-full flex-col items-center justify-center space-y-4">
            <span className="text-base text-gray-600">
                Connect Glyphic to your CRM to see your deals
            </span>
            <HashLink smooth to="/settings/organizational#crm">
                <SecondaryButton>Settings</SecondaryButton>
            </HashLink>
        </span>
    )
}

function UnSupportedCRM() {
    return (
        <span className="my-auto flex h-full flex-col items-center justify-center space-y-4">
            <span className="text-base text-gray-600">
                This feature requires a Hubspot or Salesforce integration.
            </span>
        </span>
    )
}

/**
 * Returns a map of owner ids to a list of owner ids in the team (direct
 * reports).
 */
function getOwnerTeams(
    orgUsers: IOrganizationUser[] | undefined,
    crmUsers: ICrmUser[] | undefined
): Record<string, string[]> {
    // This function requires a few steps unfortunately!
    // We have to go from a hierarchy based on glyphic ids to a map based on
    // crm ids.
    // This requires mapping users by email.

    // 1. Create a helper map from email to crm id.
    const emailToCrmId = _(crmUsers).keyBy("email").mapValues("crm_id").value()

    // 2. Create a helper map from glyphic id to crm id.
    const glyphicToCrmId = _(orgUsers)
        .keyBy("id")
        .mapValues((user) => {
            return emailToCrmId[user.email]
        })
        .pickBy(_.identity) // Remove undefined values
        .value()

    // 3. Group org users by manager id.
    const teamsByManager = _(orgUsers)
        .groupBy("manager_id")
        .mapValues((users) =>
            // 4. Map the grouped users to their crm ids.
            // Ignore users that don't have a crm id
            users.map((user) => glyphicToCrmId[user.id]).filter(_.identity)
        )
        // 5. Convert the manager ids to crm ids.
        .mapKeys((users, managerId) => managerId && glyphicToCrmId[managerId])
        .value()

    // 6. Add managers as members of their own teams
    return _.mapValues(teamsByManager, (team, managerId) => {
        if (!managerId) return team
        return _.uniq([...team, managerId])
    })
}
