import React from "react"
import { SimpleCard } from "../common/SimpleCard"
import clsx from "clsx"

interface CardProps {
    title: string
    // label can be a node to allow for formatted labels
    label?: string | React.ReactNode
    children: React.ReactNode
    id?: string
}

export function Card(props: CardProps) {
    return (
        <SimpleCard className="p-6">
            <div className="w-full space-y-6">
                <div className="flex w-full flex-col items-center justify-between md:flex-row">
                    <div className="flex w-full flex-col">
                        <h2 className="text-xl font-bold" id={props.id}>
                            {props.title}
                        </h2>
                        {props.label && (
                            <span className="text-base text-gray-600">
                                {props.label}
                            </span>
                        )}
                    </div>
                </div>
                {props.children}
            </div>
        </SimpleCard>
    )
}

export function SettingsCard(props: { children: React.ReactNode }) {
    return (
        <SimpleCard className="p-4">
            <div className="flex w-full flex-col gap-2 text-sm">
                {React.Children.map(props.children, (child, index) => (
                    <>
                        <div key={index}>{child}</div>
                        {index !== React.Children.count(props.children) - 1 && (
                            <div className="border-t border-gray-300"></div>
                        )}
                    </>
                ))}
            </div>
        </SimpleCard>
    )
}

export function SettingsSection(props: {
    title: string
    description: string | React.ReactNode
    children: React.ReactNode
    id?: string
}) {
    return (
        <div className="flex flex-col gap-2">
            <div>
                <h2 className="mb-0 ml-2 text-xl font-bold" id={props.id}>
                    {props.title}
                </h2>
                <p className="ml-2 mt-0 text-sm text-gray-500">
                    {props.description}
                </p>
            </div>
            <SettingsCard>{props.children}</SettingsCard>
        </div>
    )
}

export function SettingsOption(props: {
    title: string
    description?: string | React.ReactNode
    children: React.ReactNode
    loading?: boolean
    icon?: React.ReactNode
}) {
    return (
        <div
            className={clsx(
                "flex justify-between space-y-2 text-sm",
                props.loading && "animate-pulse opacity-20"
            )}
        >
            <div
                className={clsx(
                    "flex w-1/3 flex-col gap-2",
                    !props.description && "justify-center"
                )}
            >
                <div className="flex flex-row items-center gap-2">
                    <h2 className="text-md font-bold">{props.title}</h2>
                    {props.icon}
                </div>
                {props.description && (
                    <div className="text-xs text-gray-600">
                        {props.description}
                    </div>
                )}
            </div>
            <div className="flex flex-col gap-2">
                {React.Children.map(props.children, (child, index) => (
                    <div key={index}>{child}</div>
                ))}
            </div>
        </div>
    )
}
