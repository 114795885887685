import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import { useCallback, useEffect, useRef, useState } from "react"
import { useNotification } from "../../providers/NotificationProvider"
import { CircleLoadingSpinner } from "../common/CircleLoadingSpinner"
import { NotificationType } from "../common/Notifcations"
import { SecondaryButton } from "../common/Buttons"
import { OrgSettingsResponse } from "../../types/Settings"
import { getOrgSettings } from "../../api/Settings"
import { useQuery } from "@tanstack/react-query"

const MAX_NAME_LENGTH = 60

interface BotNameSettingProps {
    orgName?: string
}

export function BotNameSetting({ orgName }: BotNameSettingProps) {
    const { data, isSuccess } = useQuery<OrgSettingsResponse>({
        queryKey: ["organization/settings"],
        queryFn: getOrgSettings,
    })
    const inputRef = useRef<HTMLInputElement>(null)
    const timeoutId = useRef<NodeJS.Timeout | null>(null)

    const { addNotification } = useNotification()
    // Used to know if any changes were made to the bot name.
    const [serverBotName, setServerBotName] = useState<string>("")
    const [botName, setBotName] = useState("")
    const [saving, setSaving] = useState(false)
    const [saved, setSaved] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    useEffect(() => {
        if (isSuccess) {
            setBotName(data.call_bot_name)
            setServerBotName(data.call_bot_name)
        }
    }, [data, isSuccess, addNotification])

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus()
        }
    }, [isEditing])

    const saveBotName = useCallback(async () => {
        if (saving) return

        const cleanBotName = botName.trim()
        if (serverBotName === cleanBotName || cleanBotName.length === 0) {
            // If the user clears the bot name, reset it to the server's value.
            setBotName(serverBotName)
            return
        }

        try {
            setSaving(true)

            await axios.put(
                `${process.env.REACT_APP_API_DOMAIN}/organization/settings`,
                { call_bot_name: cleanBotName }
            )

            setSaved(true)
            if (timeoutId.current) {
                clearTimeout(timeoutId.current)
            }
            timeoutId.current = setTimeout(() => {
                setSaved(false)
            }, 3000)
        } catch (error) {
            setSaved(false)
            addNotification(
                "Failed to update bot name",
                `${error}`,
                NotificationType.Error
            )
        } finally {
            setSaving(false)
        }
    }, [addNotification, botName, saving, serverBotName])

    const organizationName = orgName || "Organization"

    return (
        <div className="max-w-full space-y-2">
            <h3 className="text-md font-semibold">Glyphic Copilot Name</h3>
            <p className="text-base text-gray-600">
                {`Change the name of ${organizationName}'s recording bot.`}
            </p>
            <div className="flex flex-row items-center justify-start gap-2">
                <form
                    onSubmit={async (e) => {
                        e.preventDefault()
                        setIsEditing(false)
                        await saveBotName()
                    }}
                    className="flex h-10 w-fit flex-row items-center space-x-4"
                >
                    <input
                        ref={inputRef}
                        type="text"
                        className="h-full w-full max-w-full rounded-lg border border-gray-300 bg-transparent bg-white px-2 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-black md:w-96"
                        value={botName}
                        onChange={(e) => setBotName(e.target.value)}
                        maxLength={MAX_NAME_LENGTH}
                        disabled={saving || !isEditing}
                    />
                    {isEditing ? (
                        <SecondaryButton className="h-full w-24" type="submit">
                            Save
                        </SecondaryButton>
                    ) : (
                        <SecondaryButton
                            className="h-full w-24"
                            type="button"
                            disabled={saving}
                            onClick={(e) => {
                                e.preventDefault()
                                setIsEditing(true)
                            }}
                        >
                            Edit
                        </SecondaryButton>
                    )}
                </form>
                {MAX_NAME_LENGTH - botName.length < 10 && (
                    <span className="ml-2 text-sm text-gray-400">
                        {MAX_NAME_LENGTH - botName.length}
                    </span>
                )}
                {saving && (
                    <CircleLoadingSpinner className="text-emerald-500" />
                )}
                {saved && (
                    <FontAwesomeIcon
                        icon={faCheck}
                        className="text-emerald-500"
                    />
                )}
            </div>
        </div>
    )
}
