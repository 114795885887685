import axios from "axios"
import { IUser } from "../types/User"
import { getHeapInstance } from "../utils/heap"

/**
 * Updates the user's timezone based on their browser's timezone.
 *
 * Currently only run on every successful login
 */
export async function updateUserTimezone(user: IUser) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    if (user.timezone === timezone) {
        return
    }

    try {
        await axios.put(
            `${process.env.REACT_APP_API_DOMAIN}/user/update_timezone`,
            { timezone }
        )
        getHeapInstance()?.track("updated-user-timezone")
    } catch (e) {
        getHeapInstance()?.track("error-updating-timezone", {
            error: e,
        })
    }
}
