import { Column, HeaderGroup, flexRender } from "@tanstack/react-table"

interface DetailsTableHeaderProps<T extends object> {
    headerGroup: HeaderGroup<T>
}

export default function DetailsTableHeaderGroup<T extends object>({
    headerGroup,
}: DetailsTableHeaderProps<T>) {
    return (
        <tr className="border-b p-2 text-left leading-normal">
            {headerGroup.headers.map((header) => (
                <th
                    key={header.id}
                    className={`group/th overflow-hidden whitespace-nowrap p-2 text-xs font-semibold md:text-sm
                    ${getColumnCursor({ column: header.column })}
                    ${getColumnWidth({ column: header.column })}`}
                    onClick={() => {
                        if (header.column.getCanSort()) {
                            header.column.toggleSorting()
                        }
                    }}
                >
                    {header.isPlaceholder
                        ? null
                        : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                          )}
                    {header.column.getCanSort() && (
                        <ColumnSortIcon column={header.column} />
                    )}
                </th>
            ))}
        </tr>
    )
}

function getColumnCursor(props: { column: Column<any, any> }): string {
    if (props.column.getCanSort()) {
        return "cursor-pointer"
    } else {
        return ""
    }
}

function getColumnWidth(props: { column: Column<any, any> }): string {
    if (props.column.getSize() >= 0) {
        return `w-${props.column.getSize()}`
    } else {
        return ""
    }
}

function ColumnSortIcon(props: { column: Column<any, any> }) {
    return (
        <span
            className={`${
                props.column.getIsSorted()
                    ? "opacity-100"
                    : "opacity-0 group-hover/th:opacity-20"
            }`}
        >
            {props.column.getIsSorted() === "asc" ? " ▲" : " ▼"}
        </span>
    )
}
