import clsx from "clsx"
import { useCallback, useState } from "react"
import { CircularProgress } from "../common/CircularProgress"
import { SimpleCard } from "../common/SimpleCard"
import { TaskItemProps, TaskUpdateProps } from "./items/TaskItem"
import { Task } from "./types/Task"

export type TitleStyle = "large" | "small" | "inline"

export function TaskListView<T extends Task = Task>(
    props: {
        title: string
        tasks: T[]
        emptyMessage: string
        showDueDate?: boolean
        showProgress?: boolean
        titleStyle?: TitleStyle
        restrictHeight?: boolean
        renderTask: (props: TaskItemProps<T>) => React.ReactNode
    } & TaskUpdateProps
) {
    const [expandedTaskId, setExpandedTaskId] = useState<string | null>(null)

    const handleTaskClick = useCallback((taskId: string) => {
        setExpandedTaskId((prevId) => (prevId === taskId ? null : taskId))
    }, [])

    const completedCount = props.tasks.filter(
        (task) => task.completed_at_utc
    ).length
    const totalCount = props.tasks.length

    return (
        <div className="flex flex-col gap-4">
            <OuterTitle
                title={props.title}
                titleStyle={props.titleStyle}
                showProgress={props.showProgress}
                completedCount={completedCount}
                totalCount={totalCount}
            />
            <SimpleCard className="p-4">
                <div className="w-full flex-grow space-y-2 text-base">
                    <InlineTitle
                        title={props.title}
                        titleStyle={props.titleStyle}
                    />
                    <div
                        className={clsx(
                            props.restrictHeight
                                ? "flex max-h-96 max-w-full  flex-col gap-2 overflow-y-auto"
                                : "flex max-w-full flex-col gap-2 overflow-hidden"
                        )}
                    >
                        {!props.tasks.length ? (
                            <span className="text-gray-400">
                                {props.emptyMessage}
                            </span>
                        ) : (
                            props.tasks.map((task) => {
                                const taskProps: TaskItemProps<T> = {
                                    task,
                                    isUpdating: props.isUpdating,
                                    updateTask: props.updateTask,
                                    showDueDate: props.showDueDate,
                                    onTaskClick: () => handleTaskClick(task.id),
                                    isExpanded: expandedTaskId === task.id,
                                }
                                return (
                                    <div key={task.id}>
                                        {props.renderTask(taskProps)}
                                    </div>
                                )
                            })
                        )}
                    </div>
                </div>
            </SimpleCard>
        </div>
    )
}

function OuterTitle(props: {
    title: string
    titleStyle?: TitleStyle
    showProgress?: boolean
    completedCount: number
    totalCount: number
}) {
    if (props.titleStyle === "inline") {
        return null
    }

    const titleSize = props.titleStyle === "small" ? "text-lg" : "text-4xl"
    return (
        <div className="flex items-baseline justify-between">
            <h1 className={clsx("font-bold", titleSize)}>{props.title}</h1>
            {props.showProgress && (
                <CircularProgress
                    completed={props.completedCount}
                    total={props.totalCount}
                />
            )}
        </div>
    )
}

export function InlineTitle(props: { title: string; titleStyle?: TitleStyle }) {
    if (props.titleStyle !== "inline") {
        return null
    }

    return (
        <div className="flex flex-row items-center justify-between">
            <h2 className="text-xl font-bold leading-7 text-gray-900">
                {props.title}
            </h2>
        </div>
    )
}
