import { useState } from "react"
import { SecondaryButton } from "../common/Buttons"
import { CircleLoadingSpinner } from "../common/CircleLoadingSpinner"
import clsx from "clsx"

export function ConnectButton(props: {
    connected: boolean
    onConnect: () => void
    onDisconnect: () => void
}) {
    const [isHovered, setIsHovered] = useState(false)
    const [isPending, setIsPending] = useState(false)

    const handleMouseEnter = () => {
        setIsHovered(true)
    }

    const handleMouseLeave = () => {
        setIsHovered(false)
    }

    async function handleClick() {
        setIsPending(true)
        if (props.connected) {
            await props.onDisconnect()
        } else {
            await props.onConnect()
        }
        setIsPending(false)
    }

    let text = ""
    if (props.connected) {
        text = isHovered ? "Disconnect" : "Connected"
    } else {
        text = "Connect"
    }

    return (
        <SecondaryButton
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={clsx(
                !isPending &&
                    props.connected &&
                    "hover:border-red-500 hover:bg-red-500 hover:text-white"
            )}
            disabled={isPending}
        >
            <div className="flex w-24 flex-row flex-nowrap items-center justify-center gap-2">
                {isPending && <CircleLoadingSpinner />}

                {!isPending && props.connected && !isHovered && (
                    <div className="flex h-2 w-2 rounded-full bg-emerald-500" />
                )}
                {!isPending && text}
            </div>
        </SecondaryButton>
    )
}
