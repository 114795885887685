import { useEffect, useState } from "react"
import { PrimaryButton } from "../common/Buttons"
import { isMacOS } from "../../utils/os"

export function TextArea(props: {
    value: string
    placeholder: string
    onSave: (value: string) => void
    isPending: boolean
}) {
    const originalValue = props.value

    const [value, setValue] = useState(originalValue)

    const shortcutKey = isMacOS() ? "⌘S" : "ctrl+S"

    useEffect(() => {
        setValue(originalValue)
    }, [originalValue])

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === "s" && (event.metaKey || event.ctrlKey)) {
            event.preventDefault() // Prevent browser save dialog
            if (!props.isPending) {
                handleSubmit()
            }
        }
    }

    const handleSubmit = () => {
        props.onSave(value)
    }

    return (
        <>
            <textarea
                name="message"
                value={value}
                onChange={(e) => {
                    setValue(e.target.value)
                }}
                onKeyDown={handleKeyDown}
                placeholder={props.placeholder}
                className="h-64 w-full resize-none rounded-lg border border-gray-300 p-4 outline-neutral-800"
                rows={12}
                disabled={props.isPending}
            />

            <div className="mt-8 flex justify-end">
                <PrimaryButton
                    onClick={handleSubmit}
                    disabled={props.isPending || value === originalValue}
                >
                    Save
                    <span className="ml-2 text-xs opacity-50">
                        {shortcutKey}
                    </span>
                </PrimaryButton>
            </div>
        </>
    )
}
