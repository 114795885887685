import {
    ICrmDealStage,
    ICrmDealType,
    ICrmUser,
} from "../components/crm/types/Crm"
import { ISectionSummary } from "./Annotation"
import { ICompanyAnnotationRubricResults } from "./Company"
export interface IDealCompany {
    domain: string
    name: string | null
    summary: string | null
    annotations: ISectionSummary[] | null
    next_steps: string[] | null
    image_url: string | null
    last_call_time: string | null
    next_call_time: string | null
    qualification_rubric_scores: ICompanyAnnotationRubricResults[] | null
}

export interface IDealLossReason {
    loss_category: string
    description: string
    reasoning: string
    matched_option: string | null
    matched_suboption: string | null
}

export interface ICRMDeal {
    id: string
    name: string
    updated_at_date: string
    owner: ICrmUser | null
    stage: ICrmDealStage | null
    close_date: string | null
    is_closed: boolean
    is_won: boolean | null
    amount: number | null
    currency: string | null
    crm_url: string | null
    company: IDealCompany | null
    loss_reasons: IDealLossReason[] | null
    forecast_category: string | null
    deal_type: ICrmDealType
}

const DEAL_TYPE_LABEL_MAP = {
    new_business: "New Business",
    existing_business: "Existing Business",
    unknown: "Unknown",
}

export const getDealTypeLabel = (type: ICrmDealType) => {
    return DEAL_TYPE_LABEL_MAP[type]
}

export type ICrmDealUpdate = Partial<ICRMDeal> & {
    id: string
}
