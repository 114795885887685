import { useQuery } from "@tanstack/react-query"
import { useState, useEffect } from "react"
import { IMultiSelectOption, MultiSelectFilter } from "../MultiSelectFilter"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTag } from "@fortawesome/pro-solid-svg-icons"
import { queries } from "../../api/queries"
import { stringToColour } from "../../utils/stringToColour"
import { encodeTag, decodeTag } from "../../utils/tagUtils"
import { ICallTag } from "../../types/Call"

// Constant for the ungrouped value to avoid inconsistencies
export const UNGROUPED = "ungrouped"

// Helper function to check if a tag matches the given name and group
const isSameTag = (tag: ICallTag, name: string, group?: string): boolean => {
    const nameMatches = tag.name === name
    const groupMatches =
        (!group && (!tag.group || tag.group === UNGROUPED)) ||
        (group !== undefined && tag.group === group)

    return nameMatches && groupMatches
}

export function TagFilter(props: {
    onTagChange: (tags: string[]) => void
    selectedTags: string[]
    defaultMenuIsOpen?: boolean
}) {
    const [selectedOptions, setSelectedOptions] = useState<
        IMultiSelectOption[]
    >([])

    const { data: tags, isPending } = useQuery(queries.callTags.list())

    // We now use the utility functions from tagUtils.ts

    useEffect(() => {
        if (tags) {
            const initialSelectedOptions = props.selectedTags.map(
                (encodedTag) => {
                    const { name, group } = decodeTag(encodedTag)

                    // Find the matching tag - we typically have a small number of tags,
                    // so this inner loop won't cause performance issues
                    const matchingTag = tags.find((tag) =>
                        isSameTag(tag, name, group)
                    )

                    const tagValue = matchingTag
                        ? encodeTag(matchingTag)
                        : encodedTag
                    return {
                        value: tagValue,
                        label: group ? `${group}: ${name}` : name,
                        color: stringToColour(name)[400],
                    }
                }
            )

            setSelectedOptions(initialSelectedOptions)
        }
    }, [tags, props.selectedTags])

    const handleTagChange = (newValue: IMultiSelectOption[]) => {
        setSelectedOptions(newValue)
        props.onTagChange(newValue.map((option) => option.value))
    }

    const options: IMultiSelectOption[] =
        tags?.map((tag) => ({
            value: encodeTag(tag),
            label:
                tag.group && tag.group !== UNGROUPED
                    ? `${tag.group}: ${tag.name}`
                    : tag.name,
            color: stringToColour(tag.name)[400],
        })) || []

    return (
        <MultiSelectFilter
            title="Tags"
            icon={<FontAwesomeIcon icon={faTag} className="text-gray-500" />}
            options={options}
            value={selectedOptions}
            onApply={handleTagChange}
            isLoading={isPending}
            isClearable={false}
            defaultMenuIsOpen={props.defaultMenuIsOpen ?? true}
        />
    )
}
