import { createQueryKeys } from "@lukemorales/query-key-factory"
import axios from "axios"
import { ICallComment, IRecentMention } from "../../types/Comment"

export const commentQueries = createQueryKeys("comments", {
    byCallId: (callId: string) => ({
        queryKey: ["comments", "call", callId],
        queryFn: async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/comments/call/${callId}`
            )
            return response.data as ICallComment[]
        },
    }),
    mentions: (limit: number = 5) => ({
        queryKey: ["comments", "mentions", limit],
        queryFn: async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/comments/mentions`,
                { params: { limit } }
            )
            return response.data as IRecentMention[]
        },
    }),
})
