import { useState, useMemo, useId } from "react"
import { DateTime } from "luxon"
import * as Popover from "@radix-ui/react-popover"
import { SecondaryButton } from "../../common/Buttons"
import { UpdateTaskMutation } from "../../../api/mutations/tasks"
import clsx from "clsx"
import { getHeapInstance } from "../../../utils/heap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencil } from "@fortawesome/pro-solid-svg-icons"

export function TaskDueDate(props: { dueDateString?: string | null }) {
    if (!props.dueDateString) return null

    const today = DateTime.now().setZone("utc").startOf("day")
    const dueDate = DateTime.fromISO(props.dueDateString, {
        zone: "utc",
    }).startOf("day")

    const isOverdue = dueDate < today
    return (
        <span
            className={clsx(
                "items-center whitespace-nowrap text-sm",
                isOverdue ? "text-red-400" : "text-gray-500"
            )}
        >
            {formatToRelativeDate(dueDate)}
        </span>
    )
}

function formatToRelativeDate(dueDate: DateTime) {
    const base = DateTime.now().setZone("utc").startOf("day")
    if (dueDate.hasSame(base, "day")) {
        return "today"
    }
    return dueDate.toRelative({ base })
}
interface TaskDueDateEditorProps {
    taskId: string
    date: string | null
    updateTask?: UpdateTaskMutation
    children: React.ReactNode
    permanentEditIcon?: boolean
}

export function TaskDueDateEditor(props: TaskDueDateEditorProps) {
    const [isOpen, setIsOpen] = useState(false)
    const [newDate, setNewDate] = useState(props.date)

    const datePickerValue = useMemo(
        () => (newDate ? DateTime.fromISO(newDate).toISODate() ?? "" : ""),
        [newDate]
    )

    const tooltipId = useId()

    if (!props.updateTask) {
        return <>{props.children}</>
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const dateWithTime = DateTime.fromISO(e.target.value).toISO()!
        setNewDate(dateWithTime)
    }

    const handleUpdate = async () => {
        setIsOpen(false)
        if (!props.updateTask) return

        getHeapInstance()?.track("task-due-date-updated", {
            task_id: props.taskId,
            due_date: newDate,
        })

        props.updateTask({
            taskId: props.taskId,
            updates: {
                due_date: newDate || null,
            },
        })
    }

    return (
        <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
            <Popover.Trigger asChild>
                <button
                    className="flex items-center gap-2"
                    onClick={(e) => {
                        // So we don't expand the task
                        e.stopPropagation()
                    }}
                    data-tooltip-id={tooltipId}
                >
                    {props.children}
                    <FontAwesomeIcon
                        icon={faPencil}
                        className={clsx(
                            "text-xs text-gray-500",
                            props.permanentEditIcon || "hidden"
                        )}
                    />
                </button>
            </Popover.Trigger>

            <Popover.Portal>
                <Popover.Content
                    className="w-64 rounded-lg border border-gray-200 bg-white p-4 shadow-lg"
                    sideOffset={5}
                >
                    <div className="space-y-2">
                        <label
                            htmlFor="due-date"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Due date
                        </label>
                        <input
                            id="due-date"
                            type="date"
                            value={datePickerValue}
                            onChange={handleChange}
                            className="w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <SecondaryButton
                            type="button"
                            onClick={handleUpdate}
                            className="w-full"
                        >
                            Update
                        </SecondaryButton>
                    </div>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    )
}
