import {
    DndContext,
    DragEndEvent,
    DragOverlay,
    DragStartEvent,
    PointerSensor,
    useDraggable,
    useDroppable,
    useSensor,
} from "@dnd-kit/core"
import { CSS } from "@dnd-kit/utilities"
import clsx from "clsx"
import _ from "lodash"
import { memo, useMemo, useState } from "react"
import { ICRMDeal, ICrmDealUpdate } from "../../types/Deal"
import { showSideCannons } from "../../utils/confetti"
import { getHeapInstance } from "../../utils/heap"
import { formatMonetaryAmount } from "../../utils/monetaryAmount"
import { ICrmDealStage } from "../crm/types/Crm"
import { DealCard } from "./DealCard"

// This is to prevent deal cards from re-rendering on drag events
// Without this, drag/drop is laggy when you have many deals
const MemoizedDealCard = memo(DealCard)

export function Kanban(props: {
    stages: ICrmDealStage[]
    deals: ICRMDeal[]
    updateDeal: (deal: ICrmDealUpdate) => Promise<any>
}) {
    const [draggedDealId, setDraggedDealId] = useState<string | null>(null)

    const draggedDeal = useMemo(
        () =>
            draggedDealId
                ? props.deals.find((deal) => deal.id === draggedDealId)
                : null,
        [draggedDealId, props.deals]
    )

    const dealsByStage = useMemo(
        () => _.groupBy(props.deals, (deal) => deal.stage?.crm_id),
        [props.deals]
    )

    const handleDragStart = (event: DragStartEvent) => {
        setDraggedDealId(event.active.id as string)
    }

    const handleDragEnd = async (event: DragEndEvent) => {
        setDraggedDealId(null)

        const dealId = event.active.id as string
        const currentStageId = props.deals.find((deal) => deal.id === dealId)
            ?.stage?.crm_id
        const newStageId = event.over?.id

        if (!newStageId || newStageId === currentStageId) {
            return
        }

        const newStage = props.stages.find(
            (stage) => stage.crm_id === newStageId
        )

        if (!newStage) {
            return
        }

        await props.updateDeal({
            id: dealId,
            stage: newStage,
        })

        if (newStage.is_closed && newStage.is_won) {
            showSideCannons()
        }

        getHeapInstance()?.track("deal-moved-kanban")
    }

    const handleDragCancel = () => {
        setDraggedDealId(null)
    }

    // we delay the activation of the drag by 100ms
    // to still allow user to click on a deal without dragging it
    const pointerSensor = useSensor(PointerSensor, {
        activationConstraint: {
            delay: 150,
            tolerance: 5,
        },
    })

    return (
        <DndContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
            sensors={[pointerSensor]}
        >
            <div className="scrollbar-hide flex h-full items-start gap-3 overflow-x-auto">
                {props.stages.map((stage) => (
                    <DealStageColumn
                        key={stage.crm_id}
                        stageId={stage.crm_id}
                        stageName={stage.name || "Unnamed stage"}
                        deals={dealsByStage[stage.crm_id] || []}
                        draggedDealId={draggedDealId}
                        probability={stage.probability}
                    />
                ))}
            </div>
            <DragOverlay dropAnimation={null}>
                {draggedDeal ? <MemoizedDealCard deal={draggedDeal} /> : null}
            </DragOverlay>
        </DndContext>
    )
}

function DraggableDealCard(props: { deal: ICRMDeal; isDragged: boolean }) {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: props.deal.id,
    })
    const style = {
        transform: CSS.Translate.toString(transform),
    }

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...listeners}
            {...attributes}
            // The deal card is being shown in the DragOverlay when it's being dragged
            // so we hide it here.
            // Without that, user sees two cards stacked on top of each other
            className={clsx(props.isDragged && "opacity-0")}
        >
            <MemoizedDealCard deal={props.deal} />
        </div>
    )
}

function DealStageColumn(props: {
    stageId: string
    stageName: string
    deals: ICRMDeal[]
    draggedDealId: string | null
    probability: number | null
}) {
    const { isOver, setNodeRef } = useDroppable({
        id: props.stageId,
    })

    const totalAmount = useMemo(
        () =>
            props.deals.reduce((total, deal) => total + (deal.amount || 0), 0),
        [props.deals]
    )

    const sortedDeals = useMemo(() => {
        return _.orderBy(
            props.deals,
            [(deal) => (deal.amount == null ? -Infinity : deal.amount)],
            ["desc"]
        )
    }, [props.deals])

    const currency = props.deals[0]?.currency

    return (
        <div
            ref={setNodeRef}
            className={clsx(
                "flex h-full w-[300px] flex-none flex-col gap-0 rounded-lg border-2 border-blue-500 bg-gray-100 shadow-md",
                !isOver && "border-opacity-0"
            )}
        >
            <div className="flex flex-col gap-1 border-b-[1px] border-gray-200 p-3 text-lg">
                <div className="flex items-center justify-between gap-2">
                    <div className="flex min-w-0 items-center">
                        <h2 className="truncate font-bold">
                            {props.stageName}
                        </h2>
                    </div>

                    <div className="flex items-center gap-2">
                        <span>{props.deals.length}</span>
                    </div>
                </div>
                <span className="min-h-[3.5rem] whitespace-nowrap text-gray-600">
                    <b>Total</b> {formatMonetaryAmount(totalAmount, currency)}
                    <br />
                    {props.probability !== 1 &&
                        props.probability !== 0 &&
                        props.probability !== null && (
                            <>
                                <b>Weighted</b>{" "}
                                {formatMonetaryAmount(
                                    totalAmount * (props.probability || 0),
                                    currency
                                )}{" "}
                                ({(props.probability * 100).toFixed(0)}%)
                            </>
                        )}
                </span>
            </div>
            <div className="scrollbar-hide flex flex-col gap-1 overflow-y-auto p-1">
                {sortedDeals.map((deal) => (
                    <DraggableDealCard
                        key={deal.id}
                        deal={deal}
                        isDragged={props.draggedDealId === deal.id}
                    />
                ))}
            </div>
        </div>
    )
}
