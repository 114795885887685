import { IntegrationSetting } from "./IntegrationSetting"
import slackLogo from "../../assets/slack.png"
import {
    deleteOAuthAccessToken,
    generateOAuthAccessToken,
    navigateToExternal,
} from "./oauth_utils"
import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { IChannel, ISlackSettings } from "../../types/Slack"
import Select from "react-select"
import ToggleButton from "../common/ToggleButton"
import { PrimaryButton } from "../common/Buttons"
import axios from "axios"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"

/*
The description of each permission
and the reason for requesting it can be found at https://api.slack.com/apps/A05UFN8N7AL/oauth? under Scopes.

Note that we need history permissions to read the messages in a thread when Glyphic is mentioned.
(e.g., to find the call url in a call summary message under which Glyphic was mentioned)
'channels' are the public channels, 'groups' are private channels, 'mpim' and 'im' are group and 1-1 DM channels respectively.
We're not using all four (yet), but it makes sense to request all of them at once. The Slack API history endpoint even seems to require it.
*/
const scopes = [
    "channels:read",
    "groups:read",
    "chat:write",
    "chat:write.public",
    "users:read",
    "users:read.email",
    "app_mentions:read",
    "channels:history",
    "groups:history",
    "mpim:history",
    "im:history",
    "reactions:read",
    "reactions:write",
    "channels:manage",
]

export function SlackSettings({
    authorized,
    setAuthorized,
}: {
    authorized: boolean
    setAuthorized: (b: boolean) => void
}) {
    const navigate = useNavigate()
    const { addNotification } = useNotification()
    const [searchParams] = useSearchParams()
    const [showConfig, setShowConfig] = useState<boolean>(false)
    const oauthCallbackUrl = `${process.env.REACT_APP_WEBSITE_DOMAIN}/settings?slackCallback=true`

    useEffect(() => {
        async function generateTokenFromCallback() {
            if (searchParams.get("slackCallback") === "true") {
                const slackAuthCode = searchParams.get("code")
                if (slackAuthCode) {
                    const success = await generateOAuthAccessToken(
                        "slack_token",
                        slackAuthCode,
                        oauthCallbackUrl
                    )
                    if (!success) {
                        addNotification(
                            "Failed to save Slack authentication",
                            "",
                            NotificationType.Error
                        )
                        return
                    }

                    // We do not want the user to refresh the page, go back or be
                    // suggested by the browser and trigger this again.
                    window.history.replaceState(
                        {},
                        "",
                        "/settings/organizational"
                    )
                    setAuthorized(true)
                    setShowConfig(true)
                }
            }
        }
        generateTokenFromCallback()
    }, [
        searchParams,
        navigate,
        setAuthorized,
        oauthCallbackUrl,
        addNotification,
    ])

    async function deleteSlackToken() {
        const success = await deleteOAuthAccessToken("slack_token")
        if (success) {
            setAuthorized(false)
        }
    }

    async function handleSaved() {
        setShowConfig(false)
        addNotification("Slack settings updated", "", NotificationType.Success)
    }

    return (
        <IntegrationSetting
            name="Slack"
            logo={slackLogo}
            onEnable={async () =>
                navigateToExternal(slackUrl(oauthCallbackUrl))
            }
            onDisable={async () => deleteSlackToken()}
            authorized={authorized}
            showConfig={showConfig}
            config={<Config onSaved={handleSaved} />}
            onShowConfig={() => setShowConfig(true)}
        />
    )
}

function Config({ onSaved }: { onSaved: () => void }) {
    const [slackChannels, setSlackChannels] = useState<IChannel[]>([])
    const [slackSettings, setSlackSettings] = useState<ISlackSettings>({
        call_summary_destination_channel: null,
    })
    const [isCallSummaryToggleOn, setIsCallSummaryToggleOn] =
        useState<boolean>(false)

    async function updateSettings() {
        await axios.put(
            `${process.env.REACT_APP_API_DOMAIN}/organization/settings`,
            {
                slack_settings: {
                    ...slackSettings,
                    call_summary_destination_channel: isCallSummaryToggleOn
                        ? slackSettings.call_summary_destination_channel
                        : null,
                },
            }
        )
        onSaved()
    }

    useEffect(() => {
        async function getSlackChannels() {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/slack/channels`
            )
            setSlackChannels(data)
        }

        async function getSettings() {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/organization/settings`
            )
            const settings = data.slack_settings
            setSlackSettings(settings)
            setIsCallSummaryToggleOn(
                settings.call_summary_destination_channel !== null
            )
        }
        getSettings()
        getSlackChannels()
    }, [])

    return (
        <div className="min-h-[200px]">
            <h2 className="text-lg font-bold">Slack Preferences</h2>
            <div className="text-sm text-gray-500">
                By default, only public channels are listed in the dropdown
                below. <br />
                To select a private channel, first invite Glyphic to it by
                typing
                <span className="font-bold"> /invite @Glyphic </span> in the
                channel.
                <br />
                The channel will then appear in the dropdown when you refresh.
            </div>
            <hr className="my-4" />
            <div className="space-y-4">
                <div className="flex flex-row items-center gap-2">
                    <ToggleButton
                        checked={isCallSummaryToggleOn}
                        onChange={(checked) => {
                            setIsCallSummaryToggleOn(checked)
                        }}
                    />
                    <span className="font-bold">
                        Send summaries of all calls to channel:
                    </span>
                    <Select
                        className="basic-single w-64"
                        classNamePrefix="select"
                        value={slackChannels.find(
                            (option) =>
                                option.id ===
                                slackSettings.call_summary_destination_channel
                        )}
                        onChange={(option) => {
                            if (option) {
                                setSlackSettings({
                                    ...slackSettings,
                                    call_summary_destination_channel: option.id,
                                })
                            }
                        }}
                        isDisabled={
                            !isCallSummaryToggleOn || slackChannels.length === 0
                        }
                        isLoading={slackChannels.length === 0}
                        isSearchable={true}
                        name="channel"
                        options={slackChannels}
                        getOptionValue={(ch) => ch.id}
                        getOptionLabel={(ch) => `# ${ch.name}`}
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 100,
                            }),
                        }}
                    />
                </div>
            </div>
            <div className="absolute bottom-0 right-0 flex flex-row gap-2 p-4">
                <PrimaryButton onClick={updateSettings}>
                    Save Changes
                </PrimaryButton>
            </div>
        </div>
    )
}

function slackUrl(redirectUri: string) {
    const slackUrlPrefix = "https://slack.com/oauth/v2/authorize"
    let url = new URL(slackUrlPrefix)
    url.searchParams.set("client_id", process.env.REACT_APP_SLACK_CLIENT_ID!)

    url.searchParams.set("scope", scopes.join(","))
    url.searchParams.set("redirect_uri", redirectUri)

    return url.toString()
}
